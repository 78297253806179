<template>
  <div style="width: 100%; height: 100%">
    <div
      style="
        width: 0.5376vw;
        height: 100%;
        display: flex;
        float: left;
        background: rgba(240, 240, 240, 1);
      "
    ></div>
    <div style="width: 6%; height: 100%; float: left; display: flex"></div>
    <div style="width: 93%; height: 100%; float: left; overflow: auto">
      <div style="width: 100%; height: 4.5%"></div>
      <div
        style="width: 100%; height: 5.5%; align-items: center; display: flex"
      >
        <div
          style="
            width: 20%;
            height: 100%;
            float: left;
            align-items: center;
            display: flex;
          "
          class="v41_161"
        >
          <span v-if="Lflag == true" class="v35_1047" style="width: 15%"
            >数据展示</span
          >
          <span v-if="Lflag == false" class="v35_1047" style="width: 15%"
            >Data display</span
          >
        </div>
        <div
          style="
            width: 60%;
            height: 100%;
            float: left;
            align-items: center;
            display: flex;
          "
        ></div>
        <div
          style="
            width: 20%;
            height: 100%;
            float: right;
            align-items: center;
            display: flex;
          "
          class="v41_161"
        >
          <span span v-if="Lflag == true" class="v41_161"
            >所有设备：{{ this.project }}
          </span>
          <span span v-if="Lflag == false" class="v41_161"
            >All Devices：{{ this.project }}
          </span>
        </div>
      </div>
      <div style="width: 100%; height: 2%"></div>
      <div style="width: 96.2%; height: 5%; display: flex">
        <div style="width: 30%; height: 100%; float: left">
          <el-tooltip
            v-if="Lflag == true"
            :disabled="!selectDis"
            effect="light"
            content="页面展示图表时禁止选择参数和日期，需清除页面图表"
            placement="bottom"
          >
            <el-select
              multiple
              :popper-append-to-body="false"
              collapse-tags
              v-model="checkArr"
              @remove-tag="removeTag"
              placeholder="请选择参数"
              :disabled="selectDis"
              style="width: 100%; height: 100%"
            >
              <el-option
                style="
                  width: 100%;
                  height: 1.828vw;
                  font-size: 0.8602vw;
                  align-items: center;
                  display: flex;
                "
                label="全选"
                value="全选"
                @click.native="selectAll"
              ></el-option>
              <el-checkbox-group v-model="checkArr" style="width: 14.086vw">
                <el-col
                  v-for="item in optionalColumnList"
                  :key="item.paramIndex"
                >
                  <el-checkbox
                    :label="item.paramName"
                    @change="menuChange(item)"
                    style="width: 100%"
                  ></el-checkbox>
                </el-col>
              </el-checkbox-group>
            </el-select>
          </el-tooltip>
          <el-tooltip
            v-else-if="Lflag == false"
            :disabled="!selectDis"
            effect="light"
            content="When displaying charts on a page, you are prohibited from selecting parameters and dates, and you need to clear the charts on the page"
            placement="bottom"
          >
            <el-select
              multiple
              :popper-append-to-body="false"
              collapse-tags
              v-model="checkArr"
              @remove-tag="removeTag"
              placeholder="Please select a parameter"
              :disabled="selectDis"
              style="width: 100%; height: 100%"
            >
              <el-option
                style="
                  width: 100%;
                  height: 1.828vw;
                  font-size: 0.8602vw;
                  align-items: center;
                  display: flex;
                "
                label="Select all"
                value="全选"
                @click.native="selectAll"
              ></el-option>
              <el-checkbox-group v-model="checkArr" style="width: 14.086vw">
                <el-col
                  v-for="item in optionalColumnList"
                  :key="item.paramIndex"
                >
                  <el-checkbox
                    :label="item.paramName"
                    @change="menuChange(item)"
                    style="width: 100%"
                  ></el-checkbox>
                </el-col>
              </el-checkbox-group>
            </el-select>
          </el-tooltip>
        </div>
        <div style="width: 2%; height: 100%; float: left"></div>
        <div style="width: 53%; height: 100%; float: left">
          <el-tooltip
            v-if="Lflag == true"
            effect="light"
            content="先选择开始结束日期，确定日期后可进行具体的时间调整"
            placement="top"
          >
            <el-date-picker
              v-model="requestTime"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="DisableDatesOption"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00']"
              :disabled="timeDis"
              style="width: 100%"
              popper-class="picker-popper"
            >
            </el-date-picker>
          </el-tooltip>
          <el-tooltip
            v-else-if="Lflag == false"
            effect="light"
            content="Select the start and end date first, and then adjust the specific time after the date is confirmed"
            placement="top"
          >
            <el-date-picker
              v-model="requestTime"
              type="datetimerange"
              start-placeholder="Start date"
              end-placeholder="End date "
              :picker-options="DisableDatesOption"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00']"
              :disabled="timeDis"
              style="width: 100%"
              popper-class="picker-popper"
            >
            </el-date-picker>
          </el-tooltip>
        </div>
        <div
          style="
            width: 15%;
            height: 100%;
            float: left;
            justify-content: right;
            display: flex;
          "
        >
          <el-button
            v-if="Lflag == true"
            type="botton"
            style="
              background-color: rgba(25, 44, 250, 1);
              padding-left: 2.0968vw;
              padding-right: 2.0968vw;
              padding-top: 0.6452vw;
              padding-bottom: 0.6452vw;
              align-items: center;
              display: flex;
              border-radius: 0.2688vw;
              color: #fff;
              font-family: Microsoft YaHei UI;
              font-weight: Regular;
              font-size: 0.8602vw;
            "
            @click="findbutton"
            plain
            >查询</el-button
          >
          <el-button
            v-if="Lflag == false"
            type="botton"
            style="
              background-color: rgba(25, 44, 250, 1);
              padding-left: 2.0968vw;
              padding-right: 2.0968vw;
              padding-top: 0.6452vw;
              padding-bottom: 0.6452vw;
              align-items: center;
              display: flex;
              border-radius: 0.2688vw;
              color: #fff;
              font-family: Microsoft YaHei UI;
              font-weight: Regular;
              font-size: 0.8602vw;
            "
            @click="findbutton"
            plain
            >Search</el-button
          >
        </div>
      </div>
      <div style="width: 96.2%; height: 2%; display: flex"></div>
      <div style="width: 96.2%; height: 1.3978vw">
        <div
          class="v41_160"
          style="
            width: 4.3011vw;
            height: 100%;
            float: right;
            justify-content: center;
            display: flex;
            align-items: center;
          "
        >
          <el-button
            v-if="Lflag == true"
            type="text"
            @click="cleardata"
            class="v41_161"
            style="
              border-radius: 0.2688vw;
              padding: 0vw;
              color: rgba(25, 44, 250, 1);
              font-family: Microsoft YaHei UI;
              font-weight: Regular;
              font-size: 0.8602vw;
            "
            >清除</el-button
          >
          <el-button
            v-if="Lflag == false"
            type="text"
            @click="cleardata"
            class="v41_161"
            style="
              border-radius: 0.2688vw;
              padding: 0vw;
              color: rgba(25, 44, 250, 1);
              font-family: Microsoft YaHei UI;
              font-weight: Regular;
              font-size: 0.8602vw;
            "
            >Clear</el-button
          >
        </div>
        <div style="width: 2%; height: 100%; float: right"></div>
        <div
          class="v41_160"
          style="
            width: 4.3011vw;
            height: 100%;
            float: right;
            justify-content: center;
            display: flex;
            align-items: center;
          "
        >
          <el-tooltip
            v-if="Lflag == true"
            class="v41_161"
            effect="light"
            content="txt文件下载需要选择具体参数和时间段，bin文件下载只需选择时间段"
            placement="bottom"
          >
            <el-button
              type="text"
              @click="downloadbutton"
              class="v41_161"
              style="
                border-radius: 0.2688vw;
                padding: 0vw;
                color: rgba(25, 44, 250, 1);
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
              "
              >下载</el-button
            >
          </el-tooltip>
          <el-tooltip
            v-else-if="Lflag == false"
            class="v41_161"
            effect="light"
            content="To download a txt file, you need to select a specific parameter and time period, and you only need to select a time period to download a bin file"
            placement="bottom"
          >
            <el-button
              type="text"
              @click="downloadbutton"
              class="v41_161"
              style="
                border-radius: 0.2688vw;
                padding: 0vw;
                color: rgba(25, 44, 250, 1);
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
              "
              >Download</el-button
            >
          </el-tooltip>
        </div>
        <div style="width: 2%; height: 100%; float: right"></div>
        <div
          style="
            width: 6.9892vw;
            height: 1.7204vw;
            float: right;
            background: rgba(255, 255, 255, 1);
            justify-content: center;
            display: flex;
            align-items: center;
            border-radius: 0.2151vw;
            border-color: rgba(25, 44, 250, 1);
            border-style: solid;
            border-width: 0.1075vw;
          "
        >
          <el-tooltip
            v-if="Lflag == true"
            class="v41_161"
            effect="light"
            content="查询到数据后，点击可进行数据绘图，再次点击可清除绘图"
            placement="bottom"
          >
            <el-button
              style="
                width: 100%;
                height: 97%;
                justify-content: center;
                display: flex;
                align-items: center;
                border: 0vw;
                padding: 0vw;
                border-radius: 0.2688vw;
              "
              @click="chartbutton"
              plain
              ><div
                style="
                  width: 100%;
                  height: 100%;
                  justify-content: center;
                  display: flex;
                  align-items: center;
                "
              >
                <img
                  src="@/assets/图表展示.png"
                  style="width: 1.0753vw; height: 1.0753vw"
                />
                <div
                  style="
                    width: 75%;
                    height: 100%;
                    float: right;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    color: rgba(25, 44, 250, 1);
                    font-family: Microsoft YaHei UI;
                    font-weight: Regular;
                    font-size: 0.8602vw;
                  "
                >
                  {{ lable1 }}
                </div>
              </div></el-button
            >
          </el-tooltip>
          <el-tooltip
            v-else-if="Lflag == false"
            class="v41_161"
            effect="light"
            content="After querying the data, click to plot the data, and click again to clear the plot"
            placement="bottom"
          >
            <el-button
              style="
                width: 100%;
                height: 97%;
                justify-content: center;
                display: flex;
                align-items: center;
                border: 0vw;
                padding: 0vw;
                border-radius: 0.2688vw;
              "
              @click="chartbutton"
              plain
              ><div
                style="
                  width: 100%;
                  height: 100%;
                  justify-content: center;
                  display: flex;
                  align-items: center;
                "
              >
                <img
                  src="@/assets/图表展示.png"
                  style="width: 1.0753vw; height: 1.0753vw"
                />
                <div
                  style="
                    width: 75%;
                    height: 100%;
                    float: right;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    color: rgba(25, 44, 250, 1);
                    font-family: Microsoft YaHei UI;
                    font-weight: Regular;
                    font-size: 0.8602vw;
                  "
                >
                  {{ lable1 }}
                </div>
              </div></el-button
            >
          </el-tooltip>
        </div>
        <div style="width: 2%; height: 100%; float: right"></div>
        <div
          style="
            width: 6.9892vw;
            height: 1.7204vw;
            float: right;
            background: rgba(255, 255, 255, 1);
            justify-content: center;
            display: flex;
            align-items: center;
            border-radius: 0.2151vw;
            border-color: rgba(25, 44, 250, 1);
            border-style: solid;
            border-width: 0.1075vw;
          "
        >
          <el-tooltip
            v-if="Lflag == true"
            class="v41_161"
            effect="light"
            content="查询到数据后，点击可进行数据表格展示，再次点击可清除表格"
            placement="bottom"
          >
            <el-button
              style="
                width: 100%;
                height: 100%;
                justify-content: center;
                display: flex;
                align-items: center;
                border: 0vw;
                padding: 0vw;
                border-radius: 0.2688vw;
              "
              @click="tablebutton"
              plain
              ><div
                style="
                  width: 100%;
                  height: 100%;
                  justify-content: center;
                  display: flex;
                  align-items: center;
                "
              >
                <img
                  src="@/assets/数据表格.png"
                  style="width: 1.0753vw; height: 1.0753vw"
                />
                <div
                  style="
                    width: 75%;
                    height: 100%;
                    float: right;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    color: rgba(25, 44, 250, 1);
                    font-family: Microsoft YaHei UI;
                    font-weight: Regular;
                    font-size: 0.8602vw;
                  "
                >
                  {{ lable2 }}
                </div>
              </div>
            </el-button>
          </el-tooltip>
          <el-tooltip
            v-else-if="Lflag == false"
            class="v41_161"
            effect="light"
            content="After the data is queried, click to display the data table, and click again to clear the table"
            placement="bottom"
          >
            <el-button
              style="
                width: 100%;
                height: 100%;
                justify-content: center;
                display: flex;
                align-items: center;
                border: 0vw;
                padding: 0vw;
                border-radius: 0.2688vw;
              "
              @click="tablebutton"
              plain
              ><div
                style="
                  width: 100%;
                  height: 100%;
                  justify-content: center;
                  display: flex;
                  align-items: center;
                "
              >
                <img
                  src="@/assets/数据表格.png"
                  style="width: 1.0753vw; height: 1.0753vw"
                />
                <div
                  style="
                    width: 75%;
                    height: 100%;
                    float: right;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    color: rgba(25, 44, 250, 1);
                    font-family: Microsoft YaHei UI;
                    font-weight: Regular;
                    font-size: 0.8602vw;
                  "
                >
                  {{ lable2 }}
                </div>
              </div>
            </el-button>
          </el-tooltip>
        </div>

        <div
          style="
            width: 20%;
            height: 100%;
            float: left;
            align-items: center;
            display: flex;
          "
          class="v41_161"
          v-if="tableButtonFlag == true || chartFlag == true"
        >
          <el-tooltip
            v-if="Lflag == true"
            class="v41_161"
            effect="light"
            content="认定接受到的设备数据包中设备信息全部为空的数据包为丢包数据，丢包率=丢包数据/总接收到的数据包"
            placement="bottom"
          >
            <span class="v41_161">丢包率 : {{ this.loss }} %</span>
          </el-tooltip>
          <el-tooltip
            v-else-if="Lflag == false"
            class="v41_161"
            effect="light"
            content="Packets with empty device information in the received device packets are considered to be packet loss data, and the packet loss rate = packet loss data/total received packets"
            placement="bottom"
          >
            <span class="v41_161">Loss Rate : {{ this.loss }} %</span>
          </el-tooltip>
        </div>
        <div
          style="
            width: 20%;
            height: 100%;
            float: left;
            align-items: center;
            display: flex;
          "
          class="v41_161"
          v-if="tableButtonFlag == true || chartFlag == true"
        >
          <el-tooltip
            v-if="Lflag == true"
            class="v41_161"
            effect="light"
            content=""
            placement="bottom"
          >
            <span class="v41_161">缺失值 : {{ this.miss }} %</span>
          </el-tooltip>
          <el-tooltip
            v-else-if="Lflag == false"
            class="v41_161"
            effect="light"
            content=""
            placement="bottom"
          >
            <span class="v41_161">Missing Value : {{ this.miss }} %</span>
          </el-tooltip>
        </div>
      </div>
      <div style="width: 96.2%; height: 1%; display: flex"></div>
      <div
        class="table"
        id="TABLE"
        style="width: 96.2%; height: 62%; overflow-x: auto"
      >
        <div
          class="wrapper"
          id="specificechart"
          ref="specific"
          style="display: table; width: 100%; height: 100%"
        >
          <div v-if="tableButtonFlag == true">
            <el-table
              class="fixedtableHeight"
              v-loading="listLoading"
              ref="multipleTable"
              id="tableshow"
              :data="
                showList.slice(
                  (currentPage - 1) * pageSize,
                  currentPage * pageSize
                )
              "
              height="500"
              style="width: 100%; margin-top: 1%; padding: 0vw"
            >
              <af-table-column
                show-overflow-tooltip
                :label="item.paramName"
                :property="item.paramIndex"
                v-for="item in tableColumnList"
                :key="item.paramIndex"
                align="center"
                style="padding: 0vw; width: 100%; height: 100%"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row[scope.column.property] }}</span>
                </template>
              </af-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div
        v-if="tableButtonFlag == true"
        style="
          width: 96.2%;
          height: 5%;
          justify-content: right;
          display: flex;
          align-items: center;
        "
      >
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20, 40, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="showList.length"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      v-if="Lflag == true"
      title="选择下载方式"
      :visible.sync="dialogVisible"
      @close="resetflag"
    >
      <a> 下载bin文件请先选择设备： </a>
      <el-container v-for="item of project" :key="item.id">
        <el-checkbox>
          <span @click="choosepro(item)" style="border: 0">{{ item }}</span>
        </el-checkbox>
      </el-container>
      <div style="display: flex; height: 5.3763vw">
        <div
          style="
            width: 40%;
            float: left;
            justify-content: right;
            display: flex;
            align-items: center;
          "
        >
          <el-select
            multiple
            collapse-tags
            v-model="checkArr"
            @remove-tag="removeTag"
            placeholder="请选择参数"
            :disabled="selectDis"
            style="width: 100%"
          >
            <el-option
              style="width: 100%"
              label="全选"
              value="全选"
              @click.native="selectAll"
            ></el-option>
            <el-checkbox-group v-model="checkArr" style="width: 14.086vw">
              <el-col v-for="item in optionalColumnList" :key="item.paramIndex">
                <el-checkbox
                  :label="item.paramName"
                  @change="menuChange(item)"
                  style="width: 100%"
                ></el-checkbox>
              </el-col>
            </el-checkbox-group>
          </el-select>
        </div>
        <div style="width: 5%; float: left"></div>
        <div
          style="
            width: 55%;
            float: left;
            justify-content: left;
            display: flex;
            align-items: center;
          "
        >
          <el-date-picker
            v-model="requestTime"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期 "
            :picker-options="DisableDatesOption"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00']"
            :disabled="timeDis"
            style="width: 100%"
            popper-class="picker-popper"
          >
          </el-date-picker>
        </div>
      </div>
      <div
        style="
          width: 100%;
          height: 50%;
          justify-content: center;
          display: flex;
          align-items: center;
        "
      >
        <el-radio
          v-model="radio"
          label="1"
          @change="changeflag"
          style="font-size: 0.8602vw"
          >下载bin文件</el-radio
        >
        <el-radio
          v-model="radio"
          label="2"
          @change="changeflag"
          style="font-size: 0.8602vw"
          >下载txt文件</el-radio
        >
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="Lflag == true" @click="resetflag">取 消</el-button>
        <el-button v-if="Lflag == false" @click="resetflag">Cancel</el-button>
        <el-button v-if="Lflag == true" type="primary" @click="download"
          >确 定</el-button
        >
        <el-button v-if="Lflag == false" type="primary" @click="download"
          >OK</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      v-else-if="Lflag == false"
      title="Select a download method"
      :visible.sync="dialogVisible"
      @close="resetflag"
    >
      <div style="display: flex; height: 5.3763vw">
        <div
          style="
            width: 40%;
            float: left;
            justify-content: right;
            display: flex;
            align-items: center;
          "
        >
          <el-select
            multiple
            collapse-tags
            v-model="checkArr"
            @remove-tag="removeTag"
            placeholder="Please select a parameter"
            :disabled="selectDis"
            style="width: 100%"
          >
            <el-option
              style="width: 100%"
              label="Select all"
              value="全选"
              @click.native="selectAll"
            ></el-option>
            <el-checkbox-group v-model="checkArr" style="width: 14.086vw">
              <el-col v-for="item in optionalColumnList" :key="item.paramIndex">
                <el-checkbox
                  :label="item.paramName"
                  @change="menuChange(item)"
                  style="width: 100%"
                ></el-checkbox>
              </el-col>
            </el-checkbox-group>
          </el-select>
        </div>
        <div style="width: 5%; float: left"></div>
        <div
          style="
            width: 55%;
            float: left;
            justify-content: left;
            display: flex;
            align-items: center;
          "
        >
          <el-date-picker
            v-model="requestTime"
            type="datetimerange"
            start-placeholder="Start date"
            end-placeholder="End date "
            :picker-options="DisableDatesOption"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00']"
            :disabled="timeDis"
            style="width: 100%"
            popper-class="picker-popper"
          >
          </el-date-picker>
        </div>
      </div>
      <div
        style="
          width: 100%;
          height: 50%;
          justify-content: center;
          display: flex;
          align-items: center;
        "
      >
        <el-radio
          v-model="radio"
          label="1"
          @change="changeflag"
          style="font-size: 0.8602vw"
          >Download the bin file</el-radio
        >
        <el-radio
          v-model="radio"
          label="2"
          @change="changeflag"
          style="font-size: 0.8602vw"
          >Download the txt file</el-radio
        >
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="Lflag == true" @click="resetflag">取 消</el-button>
        <el-button v-if="Lflag == false" @click="resetflag">Cancel</el-button>
        <el-button v-if="Lflag == true" type="primary" @click="download"
          >确 定</el-button
        >
        <el-button v-if="Lflag == false" type="primary" @click="download"
          >OK</el-button
        >
      </div>
    </el-dialog>
    <router-view></router-view>
  </div>
</template>

<script>
import axios from "axios";
import { checkToken } from "@/api/user";
import * as echarts from "echarts";
import { getSelectInfo } from "@/api/data";
import { paramInfo } from "@/api/data";
import { inittime } from "@/api/data";
import { bintime } from "@/api/data";

export default {
  data() {
    const that = this;
    return {
      lable1: " \u00a0  " + "图表展示",
      lable2: " \u00a0  " + "数据表格",
      requestTime: [],
      dialogVisible: false,
      radio: "2",
      selectDis: false,
      timeDis: false,
      listLoading: false,
      showList: [],
      tableColumnList: [
        { paramIndex: "id", paramName: "Id" },
        { paramIndex: "time", paramName: "Time" },
        { paramIndex: "record_id", paramName: "Record_Id" },
      ],
      tableShow: [],
      dataList: [],
      optionalColumnList: [],
      checkArr: [],
      tableButtonFlag: false,
      chartFlag: false,
      selsectFlag: false,
      findFlag: false,
      xAlis: [],
      yAlis: [],
      requestArr: ["Id", "Time", "Record_Id"],
      projList: [],
      database: "",
      table: "",
      project: "",
      projectArray: [],
      currentPage: 1, // 当前页码
      total: 0, // 总条数
      pageSize: 20, // 每页的数据条数
      flag1: false,
      flag2: false,
      timedata: [],
      loss: 0, //丢包率
      miss: 0, //缺失值
      timedata: [], //存在数据的日期
      timex: [],
      endtime: "",
      start: "",
      databasebin: "",
      checkList: [],
      databaseNew: "",
      DisableDatesOption: {
        //日历上存在数据的日期用红点标出
        cellClassName: (time) => {
          if (that.timedata.includes(this.parseTime(time, "{y}-{m}-{d}"))) {
            return "badge";
          }
        },
        disabledDate(date) {
          return date.getTime() > Date.now() + 86400000; //当前时间延后一天
        },
      },
      Lflag: true,
      prefix_id: 0,
    };
  },
  created() {
    //const select = ''
    this.$store.commit("restoretoken"); //将保存在本地的token传给state
    this.Lflag = this.$store.state.Language; //store里的传到该组件
    if (this.Lflag == true) {
      this.lable1 = " \u00a0  " + "图表展示";
      this.lable2 = " \u00a0  " + "数据表格";
    } else {
      this.lable1 = " \u00a0  " + "Chart display";
      this.lable2 = " \u00a0  " + "Data tables";
    }
    checkToken(this.$qs.stringify({ token: this.$store.state.token })).then(
      (res) => {
        //发送请求：让后端检查token，
        //console.log(res)
      }
    );
    this.$store.commit("restoreproject"); //将保存在本地的项目名传给state
    this.project = this.$store.state.project; //将state里的project赋值给组件里的project
    this.projList = this.$store.state.project;
    console.log(this.$store.state.site);

    this.table = "`" + this.$store.state.site + "_map" + "`"; //将分割出的第二部分赋值给table并且加上后缀

    console.log(this.database);
    console.log(this.table);
    this.prefix_id = 1;
    this.fetchSequentially();
  },
  methods: {
    async fetchSequentially() {
      for (var i = 0; i < this.projList.length; i++) {
        await getSelectInfo(
          this.$qs.stringify({
            database: "`" + this.projList[i] + "`",
            table: this.table,
          })
        ).then((res) => {
          console.log(this.database, res.data);
          this.optionalColumnList = this.processAndMergeTables(
            this.optionalColumnList,
            res.data
          );
          //console.log(this.optionalColumnList);

          // console.log("初次加载信息====", res);
          // for (var j in res.data) {
          //   console.log(res.data[j].paramName);

          //   if (
          //     !this.optionalColumnList.some(
          //       (item) => item.paramName === res.data[j].paramName
          //     )
          //   ) {
          //     this.optionalColumnList.push(res.data[j]);
          //   }
          // }
        });
      }
      let startstr = new Date().getTime() + 86400000;
      let endstr = new Date().getTime() - 5184000000 * 3; //当前时间前两个月*3
      //let start = new Date(str);
      let str1 = new Date(parseInt(startstr)).toLocaleDateString();
      let arr = str1.split("/");
      let starttime = arr[0] + "-" + arr[1] + "-" + arr[2] + " " + "00:00:00";
      //console.log(starttime);
      str1 = new Date(parseInt(endstr)).toLocaleDateString();
      arr = str1.split("/");
      let endtime = arr[0] + "-" + arr[1] + "-" + arr[2] + " " + "00:00:00";
      //console.log(endtime);
      this.database = "`" + this.projList[0] + "`"; //将分割出的第一部分赋值给database
      this.table = "`" + this.$store.state.site + "_dat" + "`";

      await inittime(
        this.$qs.stringify({
          start: starttime,
          end: endtime,
          database: this.database,
          table: this.table,
        })
      ).then((res) => {
        this.timedata = res.data;
        console.log(res);
        //console.log(this.timedata);
        this.selectAll();
        if (res.data.length > 0) {
          this.endtime = this.timedata[this.timedata.length - 1];
          let str = new Date(this.endtime).getTime() - 86400000;
          //let start = new Date(str);
          let str1 = new Date(parseInt(str)).toLocaleDateString();
          let arr = str1.split("/");
          this.start = arr[0] + "-" + arr[1] + "-" + arr[2] + " " + "00:00:00";
          this.endtime = new Date(this.start).getTime() + 86400000 * 2;
          str1 = new Date(parseInt(this.endtime)).toLocaleDateString();
          arr = str1.split("/");
          this.endtime =
            arr[0] + "-" + arr[1] + "-" + arr[2] + " " + "00:00:00";
          if (this.$store.state.data_time != null) {
            this.start = this.$store.state.data_time;
            console.log(this.start, this.endtime);
          }
          this.$set(this.requestTime, 0, this.start);
          this.$set(this.requestTime, 1, this.endtime);
          this.findbutton();
        }
      });
    },
    mergeTables(tableA, tableB) {
      // 主键字段
      const primaryKey = ["record_id", "time"];

      // 处理表A，添加前缀_1_

      const processedA = tableA.map((record) => {
        return Object.entries(record).reduce((acc, [key, value]) => {
          if (primaryKey.includes(key)) {
            acc[key] = value;
          } else {
            if (this.prefix_id == 1) {
              acc[`_${this.prefix_id}_${key}`] = value;
            } else {
              acc[key] = value;
            }
          }
          return acc;
        }, {});
      });
      this.prefix_id++;

      // 处理表B，添加前缀_2_
      const processedB = tableB.map((record) => {
        return Object.entries(record).reduce((acc, [key, value]) => {
          if (primaryKey.includes(key)) {
            acc[key] = value;
          } else {
            acc[`_${this.prefix_id}_${key}`] = value;
          }
          return acc;
        }, {});
      });

      // 创建Map来存储处理后的表B记录，根据主键查找
      const mapB = new Map();
      processedB.forEach((record) => {
        const key = primaryKey.map((k) => record[k]).join("|");
        mapB.set(key, record);
      });

      // 合并数据
      const merged = processedA.map((record) => {
        const key = primaryKey.map((k) => record[k]).join("|");
        const recordB = mapB.get(key) || {};
        const mergedRecord = { ...record, ...recordB };
        return mergedRecord;
      });

      return merged;
    },
    processAndMergeTables(table1, table2) {
      const mergedTable = [];

      // 处理第一个表格
      const processTable = (table, prefix) => {
        return table.map((record) => {
          const newRecord = {};
          Object.entries(record).forEach(([key, value]) => {
            if (key === "paramIndex") {
              newRecord[key] = `${prefix}${value}`;
            } else {
              newRecord[key] = value;
            }
          });
          return newRecord;
        });
      };

      // 处理并合并第一个表格
      if (this.prefix_id == 1) {
        const processedTable1 = processTable(table1, `_${this.prefix_id}_`);
        mergedTable.push(...processedTable1);
      } else {
        mergedTable.push(...table1);
      }

      // 处理并合并第二个表格
      const processedTable2 = processTable(table2, `_${this.prefix_id}_`);
      mergedTable.push(...processedTable2);
      this.prefix_id++;

      return mergedTable;
    },
    parseTime(time, cFormat) {
      //时间格式转换
      if (arguments.length === 0 || !time) {
        return null;
      }
      const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
      let date;
      if (typeof time === "object") {
        date = time;
      } else {
        if (typeof time === "string") {
          if (/^[0-9]+$/.test(time)) {
            time = parseInt(time);
          } else {
            time = time.replace(new RegExp(/-/gm), "/");
          }
        }

        if (typeof time === "number" && time.toString().length === 10) {
          time = time * 1000;
        }
        date = new Date(time);
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
      };
      const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key];
        if (key === "a") {
          return ["日", "一", "二", "三", "四", "五", "六"][value];
        }
        return value.toString().padStart(2, "0");
      });
      return time_str;
    },
    selectAll() {
      // 参数全选
      this.clearArr();
      console.log(this.optionalColumnList);
      if (this.checkArr.length < this.optionalColumnList.length) {
        this.checkArr = [];
        this.optionalColumnList.map((item) => {
          this.checkArr.push(item.paramName);
        });
        this.tableColumnList = this.tableColumnList.concat(
          this.optionalColumnList
        );
      } else {
        this.checkArr = [];
      }
      console.log(this.tableColumnList);
    },
    removeTag(val) {
      if (val === "全选") {
        this.clearArr();
      }
    },
    async sendRequests() {
      console.log(this.projList);
      for (let item of this.projList) {
        try {
          const res = await paramInfo(
            this.$qs.stringify({
              start: this.requestTime[0],
              end: this.requestTime[1],
              database: item,
              table: this.table,
            })
          );

          console.log(item, res.data.filteredList);
          if (this.prefix_id == 0) {
            this.dataList = res.data.filteredList;
            this.prefix_id = 1;
          } else {
            this.dataList = this.mergeTables(
              this.dataList,
              res.data.filteredList
            );
          }

          this.loss = parseFloat(res.data.loss * 100).toFixed(3);
          this.miss = parseFloat(res.data.miss * 100).toFixed(3);

          this.total = this.dataList.length;
          if (this.dataList.length != 0) {
            this.findFlag = true;
            if (this.optionMin) {
              this.optionMin.close();
            }
            if (this.prefix_id == this.projList.length) {
              this.$message({
                showClose: true,
                message: "已查询到数据",
                center: true,
                type: "success",
                customClass: "customclass",
              });
              if (this.chartFlag == true) {
                this.chartbutton();
                this.chartbutton();
              } else {
                this.chartbutton();
              }
            }
          } else {
            this.findFlag = false;
            if (this.optionMin) {
              this.optionMin.close();
            }
            this.$message({
              showClose: true,
              message: "数据为空",
              center: true,
              type: "error",
              customClass: "customclass",
            });
          }
        } catch (error) {
          console.error("请求失败:", error);
        }
      }
    },
    findbutton() {
      //查询数据
      //console.log(this.requestTime);
      this.prefix_id = 0;
      this.dataList = [];

      if (this.Lflag == true) {
        if (this.checkArr.length < 1) {
          this.$message({
            showClose: true,
            message: "请选择参数",
            duration: 2 * 1000,
            center: true,
            type: "error",
            customClass: "customclass",
          });
        } else if (this.requestTime == null) {
          this.$message({
            showClose: true,
            message: "请选择日期",
            duration: 2 * 1000,
            center: true,
            type: "error",
            customClass: "customclass",
          });
        } else {
          this.table = "`" + this.$store.state.site + "_dat" + "`"; //将分割出的第二部分赋值给table并且加上后缀
          // this.table = '1234_dat'
          this.optionMin = this.$message({
            showClose: true,
            message: "查询中，请稍后",
            duration: 30 * 1000,
            center: true,
            type: "success",
            customClass: "customclass",
          });
          //console.log(this.projList.length);

          this.sendRequests();
          console.log("this======", this.dataList);
        }
      } else {
        if (this.checkArr.length < 1) {
          this.$message({
            showClose: true,
            message: "Please select a parameter",
            duration: 2 * 1000,
            center: true,
            type: "error",
            customClass: "customclass",
          });
        } else if (this.requestTime == null) {
          this.$message({
            showClose: true,
            message: "Please select a date",
            duration: 2 * 1000,
            center: true,
            type: "error",
            customClass: "customclass",
          });
        } else {
          this.table = "`" + this.$store.state.site + "_dat" + "`"; //将分割出的第二部分赋值给table并且加上后缀
          // this.table = '1234_dat'
          this.optionMin = this.$message({
            showClose: true,
            message: "Inquiry, please wait",
            duration: 30 * 1000,
            center: true,
            type: "success",
            customClass: "customclass",
          });
          this.sendRequests();
        }
      }
    },
    //每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    clear() {
      //清除页面上的图表
      if (this.selsectFlag == true) {
        if (this.tableButtonFlag == false) {
          document.getElementById("tableshow").innerHTML = "";
        } else if (this.chartFlag == false) {
          //document.getElementById("TABLE").innerHTML = ""
          document.getElementById("specificechart1").innerHTML = "";
          document.getElementById("specificechart2").innerHTML = "";
        }
      }
    },
    cleardata() {
      //清除数据
      this.tableButtonFlag = !this.tableButtonFlag;
      this.chartFlag = !this.chartFlag;
      this.clear();
      this.tableButtonFlag = false;
      this.chartFlag = false;
      this.selectDis = false;
      this.timeDis = false;
      this.selsectFlag = false;
    },
    clearArr() {
      //清除参数数组
      this.tableColumnList = [
        { paramIndex: "id", paramName: "Id" },
        { paramIndex: "time", paramName: "Time" },
        { paramIndex: "record_id", paramName: "Record_Id" },
      ];
    },
    tablebutton() {
      //绘制表格
      this.tableButtonFlag = !this.tableButtonFlag;
      //console.log(this.checkArr);
      //console.log(this.tableColumnList);
      if (this.tableButtonFlag == false) {
        //this.clearArr()
        //console.log(this.tableColumnList);
        this.selsectFlag = false;
        if (this.chartFlag == false) {
          this.selectDis = false;
          this.timeDis = false;
        }
      } else {
        if (this.findFlag == true) {
          this.tableShow = this.tableColumnList;
          this.selectDis = true;
          this.timeDis = true;
          this.chartFlag = false;
          this.clear();
          this.selsectFlag = true;
        } else {
          this.tableButtonFlag = false;
          if (this.Lflag == true) {
            this.$message({
              showClose: true,
              message: "请先查询数据",
              center: true,
              type: "warning",
              customClass: "customclass",
            });
          } else {
            this.$message({
              showClose: true,
              message: "Please query the data first",
              center: true,
              type: "warning",
              customClass: "customclass",
            });
          }
        }
      }
      this.showList = this.dataList;
    },
    chartbutton() {
      //绘图
      this.chartFlag = !this.chartFlag;
      if (this.chartFlag == false) {
        //document.getElementById("TABLE").innerHTML = ""
        document.getElementById("specificechart1").innerHTML = "";
        document.getElementById("specificechart2").innerHTML = "";
        this.selsectFlag = false;
        if (this.tableButtonFlag == false) {
          this.selectDis = false;
          this.timeDis = false;
        }
      } else {
        if (this.findFlag == true) {
          this.selectDis = true;
          this.timeDis = true;
          this.tableButtonFlag = false;
          this.clear();
          this.selsectFlag = true;
          this.draw();
        } else {
          this.chartFlag = false;
          if (this.Lflag == true) {
            this.$message({
              showClose: true,
              message: "请先查询数据",
              center: true,
              type: "warning",
              customClass: "customclass",
            });
          } else {
            this.$message({
              showClose: true,
              message: "Please query the data first",
              center: true,
              type: "warning",
              customClass: "customclass",
            });
          }
        }
      }
    },
    draw() {
      //绘图参数预处理
      //var div1 = '<div class="wrapper" id="specificechart" ref="specific" style="display: table;"></div>'
      //document.getElementsByClassName('table')[0].insertAdjacentHTML("beforeend", div1)
      var div2 =
        '<div class="wrapper1" id = "specificechart1" style = "display: table-cell;" ></div>';
      document
        .getElementsByClassName("wrapper")[0]
        .insertAdjacentHTML("beforeend", div2);
      var div3 =
        ' <div class="wrapper2" id="specificechart2" style="display: table-cell;"></div>';
      document
        .getElementsByClassName("wrapper")[0]
        .insertAdjacentHTML("beforeend", div3);
      function getAttrs(array, attr) {
        var arr = array.map((item) => {
          return item[attr];
        });
        return arr;
      }
      let k, index;
      for (k in this.dataList[0]) {
        if (k == "time") {
          this.xAlis = getAttrs(this.dataList, k);
        }
        //console.log("x====", this.xAlis);
      }
      let arr = [],
        x = 0,
        flag = 0;
      for (let x = 0; x < this.dataList.length; x++) {
        for (k in this.dataList[x]) {
          for (let l = 0; l < arr.length; l++) {
            if (k == arr[l]) {
              flag = 1;
            }
          }
          if (flag == 0) {
            arr.push(k);
          } else {
            flag = 0;
          }
        }
      }
      //console.log(arr);
      this.timex = [];
      for (let n = 0; n < arr.length; n++) {
        for (index = 0; index < this.checkArr.length; index++) {
          for (let j = 0; j < this.optionalColumnList.length; j++) {
            if (this.optionalColumnList[j].paramName == this.checkArr[index]) {
              if (arr[n] == this.optionalColumnList[j].paramIndex) {
                this.yAlis = getAttrs(this.dataList, arr[n]);
                //console.log(arr[n]);
                let x = 0;
                for (let l = 0; l < this.xAlis.length; l++) {
                  let t1 = [],
                    t2 = [];
                  t1 = this.xAlis[l];
                  t2 = this.yAlis[l];
                  this.timex[x] = [t1, t2];
                  x++;
                  if (
                    Date.parse(this.xAlis[l + 1]) - Date.parse(this.xAlis[l]) !=
                    1800000
                  ) {
                    t1 = this.dateFormat(Date.parse(this.xAlis[l]) + 1800000);
                    t2 = null;
                    this.timex[x] = [t1, t2];
                    x++;
                    //console.log(this.timex);
                  }
                }
                //console.log(this.timex);
                this.draw_specific(index);
                break;
              }
            }
          }
        }
      }
      if (index % 2 == 1) {
        index++;
        var divs =
          '<div style="height:14.5161vw;width:32.2581vw" id=' +
          index +
          "></div>";
        document
          .getElementsByClassName("wrapper2")[0]
          .insertAdjacentHTML("beforeend", divs);
      }
    },
    dateFormat(key) {
      key = key; //注意：key应为格式为13位unix时间戳，如果是10位的unix时间戳，需要乘以1000
      const dt = new Date(key);
      const y = dt.getFullYear();
      const m = (dt.getMonth() + 1 + "").padStart(2, "0");
      const d = (dt.getDate() + "").padStart(2, "0");
      const hh = (dt.getHours() + "").padStart(2, "0");
      const mm = (dt.getMinutes() + "").padStart(2, "0");
      const ss = (dt.getSeconds() + "").padStart(2, "0");
      //时间格式年月日、时分秒
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
    },
    changepro(item) {
      console.log(item);
      // 检查 item 是否已经在 checkList 中
      const itemExists = this.checkList.includes(item);
      if (!itemExists) {
        // 如果 item 不在 checkList 中，添加它
        this.checkList.push(item);
      } else {
        // 如果 item 已存在，从 checkList 中移除它
        this.checkList.splice(this.checkList.indexOf(item), 1);
      }
      console.log(this.checkList);
      this.getInfo(this.checkList);
    },
    choosepro(item) {
      this.databasebin = item;
      console.log(this.databasebin);
    },
    draw_specific(index) {
      //绘图函数
      function fontSize(res) {
        let clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        if (!clientWidth) return;
        let fontSize = clientWidth / 1860;
        return res * fontSize;
      }
      var divs =
        '<div style="height:14.5161vw;width:32.2581vw" id=' + index + "></div>";
      if (index % 2 == 0) {
        document
          .getElementsByClassName("wrapper1")[0]
          .insertAdjacentHTML("beforeend", divs);
      } else {
        document
          .getElementsByClassName("wrapper2")[0]
          .insertAdjacentHTML("beforeend", divs);
      }
      //console.log(this.xAlis);
      //console.log(this.yAlis);
      var myChart = echarts.init(document.getElementById(index));
      var option;
      option = {
        dataZoom: [
          {
            type: "inside",
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          textStyle: {
            fontSize: fontSize(16),
          },
          // formatter: "{a} <br/>{b} : {c}"
        },
        title: {
          textStyle: {
            fontSize: fontSize(16),
          },
        },
        grid: {
          top: "20%",
          left: "10%",
          right: "15%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "time",
          name: "time", // x轴名称
          nameTextStyle: {
            fontSize: fontSize(16),
          },
          //data: this.xAlis, // x轴数据
          /* axisLabel: {
            rotate: 45, // 设置标签倾斜角度，单位为度
          },*/
          axisLabel: {
            textStyle: {
              fontSize: fontSize(15),
            },
          },
        },
        yAxis: {
          name: this.checkArr[index], // y轴名称
          nameTextStyle: {
            fontSize: fontSize(14),
          },
          /*  nameTextStyle: {
            padding: [0, 0, 0, 100], // 四个数字分别为上右下左与原位置距离
          }, */
          min: (value) => {
            if (Math.abs(value) < 1 && Math.abs(value) > 0.00001) {
              let value2 = value * 100000;

              let value2min = Math.floor(value2);

              let num = 10 ** (value2.toString().length - 3);

              return parseFloat(Math.floor(value2min / num) * num).toFixed(4);
            } else {
              if (value >= 0) {
                //当数据位数不固定时,最小值向下取整
                let value1 = Math.floor(value.min);
                let num = 1 ** (value1.toString().length - 3);
                return Math.floor(value1 / num) * num;
              }
              //return Math.floor(value * 10000) / 10000; // 保留四位小数
            }
          },
          max: (value) => {
            if (Math.abs(value) < 1 && Math.abs(value) > 0.00001) {
              let value2 = value * 100000;
              let value2max = Math.ceil(value2);

              let num = 10 ** (value2max.toString().length - 3);

              return parseFloat(Math.ceil(value2max / num) * num).toFixed(4);
            } else {
              //return Math.ceil(value * 10000) / 10000; // 保留四位小数
              if (value >= 0) {
                //当数据位数不固定时,最大值向上取整
                let value1 = Math.ceil(value.max);
                //let value1 = value1.toFixed(4); 抛弃
                let num = 10 ** (value1.toString().length - 3);
                return Math.ceil(value1 / num) * num;
              }
            }
          },
          scale: true, //自适应
          axisLabel: {
            textStyle: {
              fontSize: fontSize(16),
            },
          },
        },
        series: [
          {
            //data: this.yAlis,
            data: this.timex,
            symbol: "circle",
            symbolSize: fontSize(2),
            showSymbol: true, // 只有在 tooltip hover 的时候显示symbol
            itemStyle: {
              // symbol样式
              normal: {
                color: "rgb(255, 255, 255)",
                borderColor: "#94cc74", // symbol边框颜色
                borderWidth: fontSize(2), // symbol边框宽度
              },
            },
            lable: {
              textStyle: {
                fontSize: fontSize(16),
              },
            },
            type: "line",
            connectNulls: false,
            lineStyle: {
              color: "#94cc74",
              width: fontSize(4),
            },
          },
        ],
      };
      if (this.chartFlag == false) {
        myChart.clear();
      } else {
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          // 让我们的图表调用 resize这个方法
          myChart.resize();
        });
      }
    },
    menuChange(item) {
      //选择参数
      let flag = true;
      for (var i = 0; i < this.checkArr.length; i++) {
        if (this.checkArr[i] === item.paramName) {
          flag = false;
          break;
        }
      }
      if (!flag) {
        this.tableColumnList.push(item);
      }
      if (flag) {
        Array.prototype.contains = function (obj) {
          var j = this.length;
          while (j--) {
            if (this[j] === obj) {
              return j; // 返回的这个 i 就是元素的索引下标，
            }
          }
          return false;
        };
        this.tableColumnList.splice(this.tableColumnList.contains(item), 1);
      }
    },
    downloadbutton() {
      //下载按钮
      this.dialogVisible = true;
      this.flag1 = this.selectDis;
      this.flag2 = this.timeDis;
      this.changeflag();
    },
    changeflag() {
      //切换下载模式时进行的处理
      if (this.radio == "1") {
        this.selectDis = true;
        this.timeDis = false;
        this.database = this.databasebin;
        this.table = this.$store.state.site;
        bintime(
          this.$qs.stringify({
            database: this.databasebin,
            table: this.table,
          })
        ).then((res) => {
          this.timedata = res.data;
          console.log("binwenjian =====", res);
        });
      } else if (this.radio == "2") {
        this.selectDis = false;
        this.timeDis = false;
        let startstr = new Date().getTime() + 86400000;
        let endstr = new Date().getTime() - 5184000000 * 3; //当前时间前两个月*3
        //let start = new Date(str);
        let str1 = new Date(parseInt(startstr)).toLocaleDateString();
        let arr = str1.split("/");
        let starttime = arr[0] + "-" + arr[1] + "-" + arr[2] + " " + "00:00:00";
        console.log(starttime);
        str1 = new Date(parseInt(endstr)).toLocaleDateString();
        arr = str1.split("/");
        let endtime = arr[0] + "-" + arr[1] + "-" + arr[2] + " " + "00:00:00";
        console.log(endtime);
        this.database = "`" + this.projList[0] + "`";
        this.table = "`" + this.$store.state.site + "_dat" + "`";
        inittime(
          this.$qs.stringify({
            start: starttime,
            end: endtime,
            database: this.database,
            table: this.table,
          })
        ).then((res) => {
          this.timedata = res.data;
          console.log(res.data);
        });
      }
    },
    resetflag() {
      this.selectDis = this.flag1;
      this.timeDis = this.flag2;
      this.dialogVisible = false;
      let startstr = new Date().getTime() + 86400000;
      let endstr = new Date().getTime() - 5184000000 * 3; //当前时间前两个月*3
      //let start = new Date(str);
      let str1 = new Date(parseInt(startstr)).toLocaleDateString();
      let arr = str1.split("/");
      let starttime = arr[0] + "-" + arr[1] + "-" + arr[2] + " " + "00:00:00";
      console.log(starttime);
      str1 = new Date(parseInt(endstr)).toLocaleDateString();
      arr = str1.split("/");
      let endtime = arr[0] + "-" + arr[1] + "-" + arr[2] + " " + "00:00:00";
      console.log(endtime);
      this.database = "`" + this.projList[0] + "`";
      this.table = "`" + this.$store.state.site + "_dat" + "`";
      inittime(
        this.$qs.stringify({
          start: starttime,
          end: endtime,
          database: this.database,
          table: this.table,
        })
      ).then((res) => {
        this.timedata = res.data;
        console.log(res.data);
      });
    },
    exportText(dataStr, filename) {
      const name = filename;
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(dataStr)
      );
      element.setAttribute("download", name);
      element.style.display = "none";
      element.click();
    },
    sleep(ms) {
      //sleep延迟方法
      var unixtime_ms = new Date().getTime();
      while (new Date().getTime() < unixtime_ms + ms) {}
    },
    async download_request() {
      let list = [];
      this.table = "`" + this.$store.state.site + "_dat" + "`"; //将分割出的第二部分赋值给table并且加上后缀
      var filename =
        this.database +
        "_" +
        this.table +
        "_" +
        this.requestTime[0] +
        "-" +
        this.requestTime[1];
      this.prefix_id = 0;
      for (var i of this.projList) {
        await paramInfo(
          this.$qs.stringify({
            start: this.requestTime[0],
            end: this.requestTime[1],
            database: i,
            table: this.table,
          })
        ).then((res) => {
          if (this.prefix_id == 0) {
            list = res.data.filteredList;
            this.prefix_id = 1;
          } else {
            list = this.mergeTables(list, res.data.filteredList);
          }

          //list = res.data.filteredList;
          //console.log(list);
          if (this.prefix_id == this.projList.length) {
            console.log(list);
            console.log(this.tableColumnList);
            let Split = ",";
            let strArr = "";
            for (let i = 0; i < this.tableColumnList.length; i++) {
              strArr += this.tableColumnList[i].paramName;
              if (i == this.tableColumnList.length - 1) {
                strArr += "\n";
              } else {
                strArr += Split;
              }
            }
            for (let i = 0; i < list.length; i++) {
              for (let j = 0; j < this.tableColumnList.length; j++) {
                for (let k in list[i]) {
                  if (k == this.tableColumnList[j].paramIndex) {
                    strArr += list[i][k];
                    break;
                  }
                }
                if (j == this.tableColumnList.length - 1) {
                  strArr += "\n";
                } else {
                  strArr += Split;
                }
              }
            }
            if (list.length > 0) {
              this.exportText(strArr, filename);
            } else {
              if (this.Lflag == true) {
                this.$message({
                  showClose: true,
                  message: "未读取到数据，请重试",
                  type: "error",
                  customClass: "customclass",
                });
              } else {
                this.$message({
                  showClose: true,
                  message: "No data was read, please try again",
                  type: "error",
                  customClass: "customclass",
                });
              }
            }
          }
        });
      }
    },
    download() {
      this.database = this.projList[0]; //将分割出的第一部分赋值给database
      this.table = this.$store.state.site;
      // this.table = '1234_dat'
      if (this.radio == "1") {
        //axios.get("http://localhost:8088/file",
        console.log(this.database);
        console.log(this.table);
        if (this.Lflag == true) {
          this.optionMin = this.$message({
            showClose: true,
            message: "正在处理，请稍后",
            center: true,
            type: "success",
            customClass: "customclass",
          });
        } else {
          this.optionMin = this.$message({
            showClose: true,
            message: "Processing, please later",
            center: true,
            type: "success",
            customClass: "customclass",
          });
        }

        axios
          .get("'http://121.40.95.239:8088/file", {
            /*  axios
          .get("http://localhost:8088/file", { */
            //key: value  key固定写法 params 参数对象
            params: {
              //再写用户的参数
              database: this.database,
              table: this.table,
              maxTime: this.requestTime[1],
              minTime: this.requestTime[0],
            },
            //responseType: 'blob' // 指定响应数据的类型为二进制流，用于下载文件
          })
          .then((response) => {
            console.log(response.data);
            /* const url = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')// 创建一个a标签来模拟下载文件
                        link.href = url
                        link.setAttribute('download', filename) // 设置文件名
                        document.body.appendChild(link)
                        link.click()
                        window.URL.revokeObjectURL(url) */
            let max = this.requestTime[1].split(" ");
            let min = this.requestTime[0].split(" ");
            /* let str =
              "http://localhost:8088/download?" +
              "database=" +
              this.database +
              "&table=" +
              this.table +
              "&maxTime=" +
              max[0] +
              "+" +
              max[1] +
              "&minTime=" +
              min[0] +
              "+" +
              min[1]; */
            let str =
              "http://www.htcloud.info:8088/download?" +
              "database=" +
              this.database +
              "&table=" +
              this.table +
              "&maxTime=" +
              max[0] +
              "+" +
              max[1] +
              "&minTime=" +
              min[0] +
              "+" +
              min[1];
            let a = document.createElement("a");
            a.href = str;
            a.click();
          })
          .catch((e) => {
            console.log(e.response.data.error);
            if (this.optionMin) {
              this.optionMin.close();
            }
            if (this.Lflag == true) {
              this.$message({
                showClose: true,
                message: "无该时间段bin文件",
                center: true,
                type: "error",
                customClass: "customclass",
              });
            } else {
              this.$message({
                showClose: true,
                message: "There is no bin file for this time period",
                center: true,
                type: "error",
                customClass: "customclass",
              });
            }
          });
      } else if (this.radio == "2") {
        if (this.checkArr.length < 1) {
          if (this.Lflag == true) {
            this.$message({
              showClose: true,
              message: "请选择参数",
              duration: 2 * 1000,
              center: true,
              type: "error",
              customClass: "customclass",
            });
          } else {
            this.$message({
              showClose: true,
              message: "Please select a parameter",
              duration: 2 * 1000,
              center: true,
              type: "error",
              customClass: "customclass",
            });
          }
        } else if (this.requestTime == null) {
          if (this.Lflag == true) {
            this.$message({
              showClose: true,
              message: "请选择日期",
              duration: 2 * 1000,
              center: true,
              type: "error",
              customClass: "customclass",
            });
          } else {
            this.$message({
              showClose: true,
              message: "Please select a date",
              duration: 2 * 1000,
              center: true,
              type: "error",
              customClass: "customclass",
            });
          }
        } else {
          console.log(this.database);
          console.log(this.table);
          this.download_request();
        }
      }
    },
  },
};
</script>

<style scoped>
::v-deep .el-table tr {
  font-size: 0.8602vw;
  background-color: rgb(255, 255, 255, 1);
}

::v-deep .el-table td {
  font-size: 0.8602vw;
  background-color: rgba(228, 241, 255, 1);
  padding: 0vw;
  border-color: rgb(255, 255, 255);
  border-right: 0.1613vw;
}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  font-size: 0.8602vw;
  border-radius: 0.2688vw;
  border-bottom: 0.2688vw solid rgb(255, 255, 255);
  padding: 0vw;
  border-color: rgb(255, 255, 255);
  border-right: 0.2688vw solid rgb(255, 255, 255);
}
::v-deep .el-table col[name="gutter"] {
  display: table-cell !important;
}
::v-deep .el-input--suffix .el-input__inner {
  height: 2.1vw;
  padding-left: 0.8065vw;
  padding-right: 1.6129vw;
  display: flex;
  align-content: center;
  justify-content: center;
}
::v-deep .el-tag.el-tag--info {
  height: 1.9vw;
  line-height: 1.9vw;
  font-size: 0.8602vw;
  margin: 0.1075vw;
  border: 0.0538vw;
  padding-left: 0.1vw;
  padding-right: 0.1vw;
}
::v-deep .el-tag--small .el-icon-close {
  width: 0.8602vw;
  height: 0.8602vw;
  content: "";
}
::v-deep .el-select > .el-input {
  height: 2.1505vw;
}
::v-deep .el-icon-arrow-up:before {
  content: "";
}
::v-deep .el-input__suffix {
  position: static;
}
::v-deep .el-select.el-icon-close:before {
  content: "";
}
::v-deep .el-range-editor.el-input__inner {
  display: flex;
  padding: 0.1613vw 0.5376vw;
  height: 2.1505vw;
  line-height: 2.1505vw;
  font-size: 0.7527vw;
}
::v-deep .el-date-editor .el-range__icon {
  line-height: 2.1505vw;
  font-size: 0.7527vw;
}
::v-deep .el-date-editor .el-range-separator {
  line-height: 2.1505vw;
  font-size: 0.7527vw;
}
::v-deep .el-date-editor .el-range-input {
  font-size: 0.7527vw;
}
</style>

<style>
.el-select__tags {
  align-content: center;
  white-space: nowrap;
  overflow: hidden;
}
.picker-popper .el-date-table .badge::after {
  position: absolute;
  content: "";
  right: 0.2151vw;
  top: 0.2151vw;
  width: 0.2688vw;
  height: 0.2688vw;
  border-radius: 50%;
  background-color: red;
}
.el-message__content {
  color: rgba(25, 44, 250, 1) !important;
  font-family: Microsoft YaHei UI !important;
  font-weight: Regular !important;
  font-size: 0.8602vw !important;
}
.customclass {
  background-color: rgba(228, 241, 255, 1) !important;
  font-size: 0.8602vw;
}
.v41_144 {
  color: rgba(25, 44, 250, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}

.v41_161 {
  color: rgba(25, 44, 250, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}

.v41_160 {
  background: rgba(228, 241, 255, 1);
  opacity: 1;
  border-top-left-radius: 0.2688vw;
  border-top-right-radius: 0.2688vw;
  border-bottom-left-radius: 0.2688vw;
  border-bottom-right-radius: 0.2688vw;
  overflow: hidden;
}

#specificechart {
  width: 100%;
  height: 100%;
}
</style>
