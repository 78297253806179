<!-- 主界面（包含：基本信息界面/数据展示界面/报警记录界面/数据下载界面） -->
<template>
  <div class="v35_316">
    <div class="v14_4873">
      <div
        class="head_left"
        style="
          width: 4%;
          height: 100%;
          float: left;
          display: flex;
          justify-content: right;
          align-items: center;
        "
      ></div>
      <div
        style="
          width: 3.5%;
          height: 100%;
          float: left;
          display: flex;
          justify-content: left;
          align-items: center;
        "
      >
        <img
          src="@/assets/logo.png"
          style="width: 2.2043vw; height: 1.6129vw"
        />
      </div>
      <div
        class="head_mid"
        style="
          width: 73%;
          height: 100%;
          float: left;
          align-items: center;
          display: flex;
        "
      >
        <span v-if="Lflag == true" class="v14_4874" style="width: 15%"
          >青昕云</span
        >
        <span v-else-if="Lflag == false" class="v14_4874" style="width: 15%"
          >Healthy Cloud</span
        >
      </div>
      <div style="width: 4%; height: 100%; float: right"></div>
      <div
        class="head_right"
        style="
          width: 12%;
          height: 100%;
          float: right;
          display: flex;
          align-items: center;
        "
      >
        <div
          style="
            width: 80%;
            height: 100%;
            float: right;
            display: flex;
            align-items: center;
            justify-content: right;
          "
        >
          <img
            src="@/assets/用户.png"
            style="width: 1.5054vw; height: 1.6129vw"
          />
        </div>
        <div
          style="
            width: 20%;
            height: 100%;
            float: right;
            display: flex;
            align-items: center;
          "
        >
          <el-button type="text" @click="dialogFormVisible1 = true">
            <!-- <span class="v14_4877">{{ this.$store.state.username }}</span> -->
            <span class="v14_4877">User </span>
          </el-button>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 1%"></div>
    <div class="down1" style="width: 100%; height: 89%">
      <div class="left1" style="background: rgba(255, 255, 255, 1)">
        <div style="width: 25%; height: 90%; float: left"></div>
        <div style="width: 65%; height: 90%; float: left">
          <div style="width: 100%; height: 30%">
            <div style="width: 100%; height: 15%"></div>
            <div style="width: 100%; height: 20%">
              <div
                style="
                  width: 25%;
                  height: 100%;
                  justify-content: left;
                  align-items: center;
                  float: left;
                  display: flex;
                "
              >
                <div class="v41_16"></div>
              </div>

              <div
                style="
                  width: 60%;
                  height: 100%;
                  justify-content: left;
                  align-items: center;
                  float: left;
                  display: flex;
                "
              >
                <router-link
                  to="/Project_select"
                  class="no-underline"
                  style="
                    justify-content: left;
                    align-items: center;
                    display: flex;
                  "
                >
                  <el-tooltip
                    v-if="Lflag == true"
                    class="v35_1033"
                    effect="light"
                    content="返回项目列表"
                    placement="right"
                  >
                    <span class="v35_1033" style="width: 10%">项目列表</span>
                  </el-tooltip>
                  <el-tooltip
                    v-else-if="Lflag == false"
                    class="v35_1033"
                    effect="light"
                    content="Return to the list of project"
                    placement="right"
                  >
                    <span class="v35_1033" style="width: 10%"
                      >Project list</span
                    >
                  </el-tooltip>
                </router-link>
              </div>
            </div>
            <div style="width: 100%; height: 20%">
              <div
                style="
                  width: 25%;
                  height: 100%;
                  justify-content: left;
                  align-items: center;
                  float: left;
                  display: flex;
                "
              >
                <div :class="{ v41_16: !infoshow, v35_1032: infoshow }"></div>
              </div>

              <div
                style="
                  width: 60%;
                  height: 100%;
                  justify-content: left;
                  align-items: center;
                  float: left;
                  display: flex;
                "
              >
                <router-link
                  to="/Main/Basic_info"
                  class="no-underline"
                  style="
                    justify-content: left;
                    align-items: center;
                    display: flex;
                  "
                >
                  <el-tooltip
                    v-if="Lflag == true"
                    class="v35_1033"
                    effect="light"
                    content="点击启用编辑后可进行信息编辑，完成编辑后需进行保存"
                    placement="right"
                  >
                    <span class="v35_1033" @click="SHOW(1)" style="width: 10%"
                      >基本信息</span
                    >
                  </el-tooltip>
                  <el-tooltip
                    v-else-if="Lflag == false"
                    class="v35_1033"
                    effect="light"
                    content="Click Enable Editing to edit the information, and save it when you finish editing"
                    placement="right"
                  >
                    <span class="v35_1033" @click="SHOW(1)" style="width: 10%"
                      >Basic Information</span
                    >
                  </el-tooltip>
                </router-link>
              </div>
            </div>
            <div style="width: 100%; height: 20%">
              <div
                style="
                  width: 25%;
                  height: 100%;
                  justify-content: left;
                  align-items: center;
                  float: left;
                  display: flex;
                "
              >
                <div :class="{ v41_16: !datashow, v35_1032: datashow }"></div>
              </div>

              <div
                style="
                  width: 60%;
                  height: 100%;
                  justify-content: left;
                  align-items: center;
                  float: left;
                  display: flex;
                "
              >
                <router-link
                  v-if="project[0] != 'ECO'"
                  to="/Main/Data_display"
                  class="no-underline"
                  style="
                    justify-content: left;
                    align-items: center;
                    display: flex;
                  "
                >
                  <el-tooltip
                    v-if="Lflag == true"
                    class="v35_1033"
                    effect="light"
                    content="默认使用存在数据的前后一天数据绘图，使用时必须先选择参数和时间段，点击查询按钮，查询到数据后默认绘图，可自行切换表格展示"
                    placement="right"
                  >
                    <span class="v35_1033" @click="SHOW(2)" style="width: 10%"
                      >数据展示</span
                    >
                  </el-tooltip>
                  <el-tooltip
                    v-else-if="Lflag == false"
                    class="v35_1033"
                    effect="light"
                    content="By default, you must select the parameters and time period first, click the query button, and the default plot will be used after the data is queried, and you can switch the table display by yourself"
                    placement="right"
                  >
                    <span class="v35_1033" @click="SHOW(2)" style="width: 10%"
                      >Data Display</span
                    >
                  </el-tooltip>
                </router-link>
                <router-link
                  v-if="project[0] == 'ECO'"
                  to="/Main/Eco_display"
                  class="no-underline"
                  style="
                    justify-content: left;
                    align-items: center;
                    display: flex;
                  "
                >
                  <el-tooltip
                    v-if="Lflag == true"
                    class="v35_1033"
                    effect="light"
                    content="实时数据绘图"
                    placement="right"
                  >
                    <span class="v35_1033" @click="SHOW(2)" style="width: 10%"
                      >数据展示</span
                    >
                  </el-tooltip>
                  <el-tooltip
                    v-else-if="Lflag == false"
                    class="v35_1033"
                    effect="light"
                    content="Real-time data plotting"
                    placement="right"
                  >
                    <span class="v35_1033" @click="SHOW(2)" style="width: 10%"
                      >Data Display</span
                    >
                  </el-tooltip>
                </router-link>
              </div>
            </div>
            <div style="width: 100%; height: 20%" v-if="project[0] != 'ECO'">
              <div
                style="
                  width: 25%;
                  height: 100%;
                  justify-content: left;
                  align-items: center;
                  float: left;
                  display: flex;
                "
              >
                <div :class="{ v41_16: !alarmshow, v35_1032: alarmshow }"></div>
              </div>

              <div
                style="
                  width: 60%;
                  height: 100%;
                  justify-content: left;
                  align-items: center;
                  float: left;
                  display: flex;
                "
              >
                <router-link
                  to="/Main/Alarm_device"
                  class="no-underline"
                  style="
                    justify-content: left;
                    align-items: center;
                    display: flex;
                  "
                >
                  <el-tooltip
                    v-if="Lflag == true"
                    class="v35_1033"
                    effect="light"
                    content="报警设备信息"
                    placement="right"
                  >
                    <span class="v35_1033" @click="SHOW(3)" style="width: 10%"
                      >报警设备</span
                    >
                  </el-tooltip>
                  <el-tooltip
                    v-else-if="Lflag == false"
                    class="v35_1033"
                    effect="light"
                    content="Alarm Device Information"
                    placement="right"
                  >
                    <span class="v35_1033" @click="SHOW(3)" style="width: 10%"
                      >Alarm Device</span
                    >
                  </el-tooltip>
                </router-link>
              </div>
            </div>
            <div style="width: 100%; height: 20%" v-if="project[0] != 'ECO'">
              <div
                style="
                  width: 25%;
                  height: 100%;
                  justify-content: left;
                  align-items: center;
                  float: left;
                  display: flex;
                "
              >
                <div
                  :class="{
                    v41_16: !contourLineshow,
                    v35_1032: contourLineshow,
                  }"
                ></div>
              </div>

              <div
                style="
                  width: 60%;
                  height: 100%;
                  justify-content: left;
                  align-items: center;
                  float: left;
                  display: flex;
                "
              >
                <router-link
                  to="/Main/Contour_line"
                  class="no-underline"
                  style="
                    justify-content: left;
                    align-items: center;
                    display: flex;
                  "
                >
                  <el-tooltip
                    v-if="Lflag == true"
                    class="v35_1033"
                    effect="light"
                    content="实时源区展示"
                    placement="right"
                  >
                    <span class="v35_1033" @click="SHOW(4)" style="width: 10%"
                      >实时源区</span
                    >
                  </el-tooltip>
                  <el-tooltip
                    v-else-if="Lflag == false"
                    class="v35_1033"
                    effect="light"
                    content="Footprint display"
                    placement="right"
                  >
                    <span class="v35_1033" @click="SHOW(4)" style="width: 10%"
                      >Footprint</span
                    >
                  </el-tooltip>
                </router-link>
              </div>
            </div>
            <div style="width: 100%; height: 20%" v-if="project[0] == 'ECO'">
              <div
                style="
                  width: 25%;
                  height: 100%;
                  justify-content: left;
                  align-items: center;
                  float: left;
                  display: flex;
                "
              >
                <div
                  :class="{
                    v41_16: !cruiseshow,
                    v35_1032: cruiseshow,
                  }"
                ></div>
              </div>

              <div
                style="
                  width: 60%;
                  height: 100%;
                  justify-content: left;
                  align-items: center;
                  float: left;
                  display: flex;
                "
              >
                <router-link
                  to="/Main/Cruise_monitoring_main"
                  class="no-underline"
                  style="
                    justify-content: left;
                    align-items: center;
                    display: flex;
                  "
                >
                  <el-tooltip
                    v-if="Lflag == true"
                    class="v35_1033"
                    effect="light"
                    content="走航监测"
                    placement="right"
                  >
                    <span class="v35_1033" @click="SHOW(5)" style="width: 10%"
                      >走航监测</span
                    >
                  </el-tooltip>
                  <el-tooltip
                    v-else-if="Lflag == false"
                    class="v35_1033"
                    effect="light"
                    content="Footprint display"
                    placement="right"
                  >
                    <span class="v35_1033" @click="SHOW(5)" style="width: 10%"
                      >EcoTracX</span
                    >
                  </el-tooltip>
                </router-link>
              </div>
            </div>
            <div style="width: 100%; height: 20%" v-if="project[0] == 'ECO1'">
              <div
                style="
                  width: 25%;
                  height: 100%;
                  justify-content: left;
                  align-items: center;
                  float: left;
                  display: flex;
                "
              >
                <div
                  :class="{
                    v41_16: !calculationshow,
                    v35_1032: calculationshow,
                  }"
                ></div>
              </div>

              <div
                style="
                  width: 60%;
                  height: 100%;
                  justify-content: left;
                  align-items: center;
                  float: left;
                  display: flex;
                "
              >
                <router-link
                  to="/Main/Cruise_monitoring_compute"
                  class="no-underline"
                  style="
                    justify-content: left;
                    align-items: center;
                    display: flex;
                  "
                >
                  <el-tooltip
                    v-if="Lflag == true"
                    class="v35_1033"
                    effect="light"
                    content="羽流计算"
                    placement="right"
                  >
                    <span class="v35_1033" @click="SHOW(6)" style="width: 10%"
                      >羽流计算</span
                    >
                  </el-tooltip>
                  <el-tooltip
                    v-else-if="Lflag == false"
                    class="v35_1033"
                    effect="light"
                    content="Plume calculations"
                    placement="right"
                  >
                    <span class="v35_1033" @click="SHOW(6)" style="width: 10%"
                      >Plume calculation</span
                    >
                  </el-tooltip>
                </router-link>
              </div>
            </div>
          </div>
          <div style="width: 100%; height: 40%"></div>
          <div style="width: 100%; height: 25%">
            <div
              style="
                width: 35%;
                height: 100%;
                justify-content: left;
                align-items: center;
                float: left;
                display: flex;
              "
            >
              <el-button type="text" @click="logout">
                <img
                  src="@/assets/登出.png"
                  style="width: 2.1505vw; height: 2.1505vw"
              /></el-button>
            </div>
            <div
              style="width: 5%; height: 90%; float: left; display: flex"
            ></div>
            <div
              style="
                width: 60%;
                height: 95%;
                justify-content: left;
                align-items: center;
                float: right;
                display: flex;
              "
            >
              <el-button type="text" @click="logout"
                ><span v-if="Lflag == true" class="v35_1026">登出</span>
                <span v-if="Lflag == false" class="v35_1026"
                  >Log out</span
                ></el-button
              >
            </div>
            <div
              style="width: 60%; height: 10%; float: left; display: flex"
            ></div>
          </div>
        </div>
        <div style="width: 10%; height: 90%; float: left"></div>
        <div
          style="
            text-align: center;
            bottom: 0;
            margin: 0 auto;
            width: 100%;
            height: 10%;
          "
        >
          <a
            target="_blank"
            element.style.textDecoration="none"
            style="text-decoration: none; color: inherit; font-size: 0.6989vw"
            href="https://beian.miit.gov.cn"
            >浙ICP备20026509号-3</a
          >
        </div>
      </div>
      <div class="right1" style="background: rgba(255, 255, 255, 1)">
        <router-view></router-view>
      </div>
    </div>
    <!-- 账户信息 -->
    <el-dialog
      title="账户信息"
      :visible="dialogFormVisible1"
      style="text-align: center"
      :show-close="true"
      @close="dialogFormVisible1 = false"
      v-if="Lflag == true"
    >
      <el-form ref="form">
        <el-form-item>
          <el-form-item
            label="账户名:"
            label-width="10vw"
            style="width: 30vw; margin-left: 13%; margin-bottom: 3.4vh"
          >
            <el-input
              v-model="username"
              auto-complete="off"
              style="width: 90%"
              type="text"
              disabled="disabled"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="邮箱:"
            label-width="10vw"
            style="width: 30vw; margin-left: 13%"
          >
            <el-input
              v-model="maskedEmail"
              auto-complete="off"
              style="width: 90%"
              disabled="disabled"
            ></el-input>
          </el-form-item>
        </el-form-item>
      </el-form>

      <div
        slot="footer"
        class="dialog-footer"
        style="width: 100%; display: flex; justify-content: center"
      >
        <el-button
          @click="
            dialogFormVisible1 = false;
            dialogFormVisible2 = true;
          "
          style="margin-right: 2vw"
          :disabled="isDisable"
          >修改密码</el-button
        >
        <el-button @click="btsetemail" style="margin-left: 3vw">
          设置邮箱</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="Account Information"
      :visible="dialogFormVisible1"
      style="text-align: center"
      :show-close="true"
      @close="dialogFormVisible1 = false"
      v-if="Lflag == false"
    >
      <el-form ref="form">
        <el-form-item>
          <el-form-item
            label="Account:"
            label-width="10vw"
            style="width: 30vw; margin-left: 13%; margin-bottom: 3.4vh"
          >
            <el-input
              v-model="username"
              auto-complete="off"
              style="width: 90%"
              type="text"
              disabled="disabled"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Email:"
            label-width="10vw"
            style="width: 30vw; margin-left: 13%"
          >
            <el-input
              v-model="maskedEmail"
              auto-complete="off"
              style="width: 90%"
              disabled="disabled"
            ></el-input>
          </el-form-item>
        </el-form-item>
      </el-form>

      <div
        slot="footer"
        class="dialog-footer"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 5vh;
        "
      >
        <el-button
          @click="
            dialogFormVisible1 = false;
            dialogFormVisible2 = true;
          "
          style="margin-right: 2vw"
          :disabled="isDisable"
          >Modify Password</el-button
        >
        <el-button @click="btsetemail" style="margin-left: 3vw">
          Setting up the mailbox</el-button
        >
      </div>
    </el-dialog>

    <!-- 修改密码 -->
    <el-dialog
      title="修改密码:"
      :visible="dialogFormVisible2"
      style="text-align: center"
      :show-close="true"
      @close="dialogFormVisible2 = false"
      v-if="Lflag == true"
    >
      <el-form :model="form" :rules="resetrules" ref="form">
        <el-form-item
          label="新密码:"
          prop="newpassword"
          label-width="12.59vw"
          style="width: 29.7vw; margin-left: 13.2%; margin-bottom: 4vh"
        >
          <el-input
            v-model="form.newpassword"
            auto-complete="off"
            show-password
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认新密码:"
          prop="confirmpassword"
          label-width="12.59vw"
          style="width: 29.7vw; margin-left: 13.2%; margin-bottom: 4vh"
        >
          <el-input
            v-model="form.confrimpassword"
            auto-complete="off"
            show-password
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="验证码:"
          label-width="12.59vw"
          style="
            width: 29.7vw;
            margin-left: 13.2%;
            float: left;
            margin-bottom: 4vh;
          "
        >
          <el-input v-model="form.passcaptcha" auto-complete="off"></el-input>
        </el-form-item>
        <el-button
          type="text"
          style="float: left"
          @click="countDown2"
          :disabled="!this.canClick2"
        >
          {{ contenttwozh }}
        </el-button>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 5vh;
        "
      >
        <el-button type="primary" @click="changepass" style="padding: 1.5vh 5vw"
          >验证</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="Change your password:"
      :visible="dialogFormVisible2"
      style="text-align: center"
      :show-close="true"
      @close="dialogFormVisible2 = false"
      v-if="Lflag == false"
    >
      <el-form :model="form" :rules="resetrules" ref="form">
        <el-form-item
          label="New Password:"
          prop="newpassword"
          label-width="12.59vw"
          style="width: 29.7vw; margin-left: 13.2%; margin-bottom: 4vh"
        >
          <el-input
            v-model="form.newpassword"
            auto-complete="off"
            show-password
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Confirm New Password:"
          prop="confirmpassword"
          label-width="12.59vw"
          style="width: 29.7vw; margin-left: 13.2%; margin-bottom: 4vh"
        >
          <el-input
            v-model="form.confrimpassword"
            auto-complete="off"
            show-password
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Captcha:"
          label-width="12.59vw"
          style="
            width: 29.7vw;
            margin-left: 13.2%;
            float: left;
            margin-bottom: 4vh;
          "
        >
          <el-input v-model="form.passcaptcha" auto-complete="off"></el-input>
        </el-form-item>
        <el-button
          type="text"
          style="float: left"
          @click="countDown2"
          :disabled="!this.canClick2"
        >
          {{ contenttwo }}
        </el-button>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 5vh;
        "
      >
        <el-button type="primary" @click="changepass" style="padding: 1.5vh 5vw"
          >verification</el-button
        >
      </div>
    </el-dialog>

    <!-- 当前邮箱验证 -->
    <el-dialog
      title="当前邮箱验证:"
      :visible="dialogFormVisible3"
      style="text-align: center"
      :show-close="true"
      @close="dialogFormVisible3 = false"
      v-if="Lflag == true"
    >
      <el-form :model="form">
        <el-form-item
          label="验证码:"
          label-width="8vw"
          style="width: 28vw; margin-left: 20%; float: left"
        >
          <el-input v-model="form.captcha" auto-complete="off"></el-input>
        </el-form-item>
        <el-button
          type="text"
          style="float: left"
          @click="countDown"
          :disabled="!this.canClick"
        >
          {{ contentzh }}
        </el-button>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 5vh;
        "
      >
        <el-button
          type="primary"
          @click="verification"
          style="padding: 1.5vh 5vw"
          >验证</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="Current Email Verification:"
      :visible="dialogFormVisible3"
      style="text-align: center"
      :show-close="true"
      @close="dialogFormVisible3 = false"
      v-if="Lflag == false"
    >
      <el-form :model="form">
        <el-form-item
          label="Captcha:"
          label-width="8vw"
          style="width: 28vw; margin-left: 20%; float: left"
        >
          <el-input v-model="form.captcha" auto-complete="off"></el-input>
        </el-form-item>
        <el-button
          type="text"
          style="float: left"
          @click="countDown"
          :disabled="!this.canClick"
        >
          {{ content }}
        </el-button>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 5vh;
        "
      >
        <el-button
          type="primary"
          @click="verification"
          style="padding: 1.5vh 5vw"
          >verification</el-button
        >
      </div>
    </el-dialog>

    <!-- 修改邮箱 -->
    <el-dialog
      title="修改邮箱:"
      :visible="dialogFormVisible4"
      style="text-align: center"
      :show-close="true"
      @close="dialogFormVisible4 = false"
      v-if="Lflag == true"
    >
      <el-form :model="form">
        <el-form-item
          label="新邮箱:"
          label-width="8vw"
          style="width: 28vw; margin-left: 20%"
        >
          <el-input v-model="form.newemail" auto-complete="off"></el-input>
        </el-form-item>

        <el-form-item
          label="验证码:"
          label-width="8vw"
          style="width: 28vw; margin-left: 20%; float: left"
        >
          <el-input v-model="form.newcaptcha" auto-complete="off"></el-input>
        </el-form-item>
        <el-button
          type="text"
          style="float: left"
          @click="countDown1"
          :disabled="!this.canClick1"
        >
          {{ contentnewzh }}
        </el-button>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 5vh;
        "
      >
        <el-button type="primary" @click="confemail" style="padding: 1.5vh 5vw"
          >确认修改</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="Modify Email:"
      :visible="dialogFormVisible4"
      style="text-align: center"
      :show-close="true"
      @close="dialogFormVisible4 = false"
      v-if="Lflag == false"
    >
      <el-form :model="form">
        <el-form-item
          label="New Email:"
          label-width="8vw"
          style="width: 28vw; margin-left: 20%"
        >
          <el-input v-model="form.newemail" auto-complete="off"></el-input>
        </el-form-item>

        <el-form-item
          label="Captcha:"
          label-width="8vw"
          style="width: 28vw; margin-left: 20%; float: left"
        >
          <el-input v-model="form.newcaptcha" auto-complete="off"></el-input>
        </el-form-item>
        <el-button
          type="text"
          style="float: left"
          @click="countDown1"
          :disabled="!this.canClick1"
        >
          {{ contentnew }}
        </el-button>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 5vh;
        "
      >
        <el-button type="primary" @click="confemail" style="padding: 1.5vh 5vw"
          >Confirmation of changes
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { checkToken } from "@/api/user";
import { ifemail } from "@/api/data";
import { sendmail_secur } from "@/api/data";
import { verify } from "@/api/data";
import { sendmail } from "@/api/data";
import { saveemail } from "@/api/data";
import { changepassword } from "@/api/data";
export default {
  data() {
    // 是否包含一位数字
    const regNumber = /(?=.*[\d])/;
    // 是否包含一位字母
    const regLetter = /(?=.*[a-zA-Z])/;
    // 是否包含一位特殊字符
    // const regCharacter = /(?=.*[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、])/
    // 校验新密码
    const validatePass = async (rule, value, callback) => {
      value = this.form.newpassword;
      if (this.Lflag == true) {
        if (value === "") {
          callback(new Error("密码不能为空！请重新输入"));
        } else {
          if (value.length > 16) {
            callback(new Error("密码长度不应超过 16 个字符。"));
          } else if (value.length < 6) {
            callback(new Error("密码长度应不少于 6 个字符。"));
          } else {
            if (!/^[a-zA-Z\d]{1}/.test(value)) {
              callback(new Error("密码必须以英文字母或数字开头！"));
            } else {
              if (!regNumber.test(value)) {
                callback(new Error("密码必须由数字、字母组成！"));
              } else if (!regLetter.test(value)) {
                callback(new Error("密码必须由数字、字母组成！"));
              } else {
                callback();
              }
            }
          }
        }
      } else {
        if (value === "") {
          callback(new Error("Password cannot be empty!Please re-enter"));
        } else {
          if (value.length > 16) {
            callback(
              new Error(
                "Passwords should be no more than 16 characters in length."
              )
            );
          } else if (value.length < 6) {
            callback(
              new Error(
                "Passwords should be no less than 6 characters in length."
              )
            );
          } else {
            if (!/^[a-zA-Z\d]{1}/.test(value)) {
              callback(
                new Error(
                  "Passwords must begin with an English letter or number!"
                )
              );
            } else {
              if (!regNumber.test(value)) {
                callback(
                  new Error(
                    "The password must consist of numbers, letters of the alphabet!"
                  )
                );
              } else if (!regLetter.test(value)) {
                callback(
                  new Error(
                    "The password must consist of numbers, letters of the alphabet!"
                  )
                );
              } else {
                callback();
              }
            }
          }
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      value = this.form.confrimpassword;
      if (this.Lflag == true) {
        if (value === "") {
          callback(new Error("请再次输入密码"));
        } else if (value !== this.form.newpassword) {
          callback(new Error("两次输入的密码不一致"));
        } else {
          callback();
        }
      } else {
        if (value === "") {
          callback(new Error("Please enter your password again"));
        } else if (value !== this.form.newpassword) {
          callback(new Error("Inconsistent passwords entered twice!"));
        } else {
          callback();
        }
      }
    };
    return {
      form: {
        Currentpassword: "",
        captcha: "",
        newpassword: "",
        confrimpassword: "",
        newemail: "",
        newcaptcha: "",
        passcaptcha: "",
      },
      resetrules: {
        newpassword: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        confirmpassword: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
      isDisable: false,

      infoshow: true,
      datashow: false,
      alarmshow: false,
      contourLineshow: false,
      cruiseshow: false,
      calculationshow: false,
      _gap_time: 0,
      _beforeUnload_time: 0,
      Lflag: true,
      project: this.$store.state.project,
      username: this.$store.state.username,
      localemail: this.$store.state.email,
      totalTime: 60,
      totalTime1: 60,
      totalTime2: 60,
      content: "Send Email",
      contentzh: "发送邮件",
      contentnew: "Send Email",
      contentnewzh: "发送邮件",
      contenttwo: "Send Email",
      contenttwozh: "发送邮件",
      canClick: true,
      canClick1: true,
      canClick2: true,
      Currentpassword: "",
      newpassword: "",
      confrimpassword: "",
      captcha: "",
      dialogFormVisible1: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      dialogFormVisible4: false,
    };
  },

  created() {
    this.$store.commit("restoretoken"); //将保存在本地的token传给state
    this.$store.commit("restoreproject"); //将保存在本地的项目名传给state
    this.$store.commit("restoLanguage"); //将保存在本地的项目名传给state

    this.$store.commit("restoShow");
    console.log("state====", this.$store.state);
    this.project = this.$store.state.project;
    this.Lflag = this.$store.state.Language; //store里的传到该组件
    console.log(this.$store.state.show_id);
    if (this.$store.state.show_id == null) {
      this.SHOW(1);
    } else {
      this.SHOW(this.$store.state.show_id);
    }

    // console.log(this.$store.state.Language);
    // console.log(this.$store.state.project);
    //这个地方目前没有进行tokencheck，因为路由不会进入这个界面
    // checkToken(this.$qs.stringify({token:this.$store.state.token})).then((res) => {
    //   //console.log(res)
    // });
    this.$nextTick(() => {
      const bodyStyle = document.body.style; // 获取body样式
      bodyStyle.overflow = "auto"; // 设置溢出内容为隐藏
      const htmlStyle = document.getElementsByTagName("html")[0].style; // 获取html样式
      htmlStyle.overflow = "auto"; // 设置溢出内容为隐藏
    });
  },
  computed: {
    maskedEmail() {
      console.log(this.localemail);
      if (!this.localemail) {
        this.isDisable = true;
        return "Unbound email";
      }
      const parts = this.localemail.split("@");
      const localPart = parts[0];
      const domainPart = parts[1];
      // 隐藏本地部分的中间字符，只显示首尾各一个字符
      const maskedLocalPart =
        localPart.slice(0, 3) +
        "*".repeat(localPart.length - 4) +
        localPart.slice(-2);

      this.isDisable = false;
      return `${maskedLocalPart}@${domainPart}`;
    },
  },
  methods: {
    retuenProjectSelect() {
      //返回项目选择界面
      this.$store.dispatch("reselectProject"); //会清除之前选的项目
      this.$router.push("/Project_select"); //跳转界面
    },
    logout() {
      this.$store.dispatch("logout"); //登出
    },
    beforeunloadHandler() {
      this._beforeUnload_time = new Date().getTime();
    },
    unloadHandler(e) {
      this._gap_time = new Date().getTime() - this._beforeUnload_time;
      debugger;
      //判断是窗口关闭还是刷新
      if (this._gap_time <= 5) {
        //如果是登录状态，关闭窗口前，移除用户
        this.$store.dispatch("logout"); //登出
      }
    },
    SHOW(id) {
      this.datashow = false;
      this.infoshow = false;
      this.alarmshow = false;
      this.contourLineshow = false;
      this.cruiseshow = false;
      this.calculationshow = false;
      console.log(id);
      this.$store.commit("setShow", id);
      console.log(this.$store.state.show_id);
      if (id == 1) {
        this.infoshow = true;
      } else if (id == 2) {
        this.datashow = true;
      } else if (id == 3) {
        this.alarmshow = true;
      } else if (id == 4) {
        this.contourLineshow = true;
      } else if (id == 5) {
        this.cruiseshow = true;
      } else if (id == 6) {
        this.calculationshow = true;
      }
    },
    btsetemail() {
      ifemail(this.$qs.stringify({ username: this.username })).then((res) => {
        console.log("res===", res);
        console.log(res.data);
        if (res.data != "") {
          this.dialogFormVisible1 = false;
          this.dialogFormVisible3 = true;
        } else {
          this.dialogFormVisible1 = false;
          this.dialogFormVisible4 = true;
        }
      });
    },

    verification() {
      verify(
        this.$qs.stringify({
          email: this.localemail,
          verification: this.form.captcha,
        })
      ).then((res) => {
        console.log("验证=", res);
        if (res.data == "验证码校验通过") {
          this.dialogFormVisible3 = false;
          this.dialogFormVisible4 = true;
          window.clearInterval(this.clock);
        } else {
        }
      });
    },
    countDown() {
      sendmail_secur(
        this.$qs.stringify({
          TO: this.localemail,
          language: "E",
          username: this.username,
        })
      ).then((res) => {});
      if (!this.canClick) return; //改动的是这两行代码
      this.canClick = false;
      this.content = this.totalTime + "s Resend after";
      this.contentzh = this.totalTime + "s后重新发送";
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.content = this.totalTime + "s Resend after";
        this.contentzh = this.totalTime + "s后重新发送";
        if (this.totalTime < 0 || this.dialogFormVisible3 == false) {
          window.clearInterval(clock);
          this.content = "Resend Email";
          this.content = "重新发送";
          this.totalTime = 60;
          this.canClick = true; //这里重新开启
        }
      }, 1000);
    },
    countDown1() {
      sendmail(
        this.$qs.stringify({ TO: this.form.newemail, language: "E" })
      ).then((res) => {});
      if (!this.canClick1) return;
      this.canClick1 = false;
      this.contentnew = this.totalTime1 + "s Resend after";
      this.contentnewzh = this.totalTime1 + " s后重新发送";
      var clock1 = window.setInterval(() => {
        this.totalTime1--;
        this.contentnew = this.totalTime1 + "s Resend after";
        this.contentnewzh = this.totalTime1 + " s后重新发送";
        if (this.totalTime1 < 0 || this.dialogFormVisible4 == false) {
          window.clearInterval(clock1);
          this.contentnew = "Resend Email";
          this.contentnew = "Resend Email";
          this.totalTime1 = 60;
          this.canClick1 = true; //这里重新开启
        }
      }, 1000);
    },
    countDown2() {
      sendmail_secur(
        this.$qs.stringify({
          TO: this.localemail,
          language: "E",
          username: this.username,
        })
      ).then((res) => {
        console.log("res=====", res);
      });
      if (!this.canClick2) return;
      this.canClick2 = false;
      this.contenttwo = this.totalTime2 + "s Resend after";
      this.contenttwozh = this.totalTime2 + "s后重新发送";
      var clock2 = window.setInterval(() => {
        this.totalTime2--;
        this.contenttwo = this.totalTime2 + "s Resend after";
        this.contenttwozh = this.totalTime2 + "s后重新发送";
        if (this.totalTime2 < 0 || this.dialogFormVisible2 == false) {
          window.clearInterval(clock2);
          this.contenttwo = "Resend Email";
          this.contenttwozh = "重新发送";
          this.totalTime2 = 60;
          this.canClick2 = true; //这里重新开启
        }
      }, 1000);
    },
    confemail() {
      saveemail(
        this.$qs.stringify({
          email: this.form.newemail,
          verification: this.form.newcaptcha,
          username: this.username,
        })
      ).then((res) => {
        if (res.data == "一个邮箱只能绑定一个账号") {
          alert("This email address is already in use");
        }
        console.log("修改邮箱返回=", res);
        if (res.data == "验证码校验通过") {
          alert("Email successfully updated");
          this.localemail = this.form.newemail;
          this.$store.commit("setEmail", this.form.newemail);
          this.dialogFormVisible4 = false;
          this.dialogFormVisible1 = true;
        }
      });
    },
    changepass() {
      changepassword(
        this.$qs.stringify({
          email: this.localemail,
          verification: this.form.passcaptcha,
          new_password: this.form.confrimpassword,
        })
      ).then((res) => {
        console.log("修改密码=", res);
        if (res.data == "密码设置成功") {
          alert("Password changed successfully. Please log in again");
          this.dialogFormVisible2 = false;
          this.logout();
        } else if (res.data == "验证码不对") {
          alert("Invalid verification code");
        }
      });
    },
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    window.addEventListener("unload", (e) => this.unloadHandler(e));
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
    window.removeEventListener("unload", (e) => this.unloadHandler(e));
  },
};
</script>

<style>
.v41_16 {
  width: 0.8065vw;
  height: 0.8065vw;
  background: rgba(155, 152, 167, 1);
  opacity: 1;
  position: absolute;
  border-radius: 50%;
}

.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 3.2258vw;
}

.el-aside {
  color: #333;
}

.no-underline {
  text-decoration: none;
}

.logout {
  height: 1.6129vw;
}
</style>
