<!-- 基本信息界面 -->
<template>
  <div style="width: 100%; height: 100%">
    <div
      style="
        width: 0.5376vw;
        height: 100%;
        display: flex;
        float: left;
        background: rgba(240, 240, 240, 1);
      "
    ></div>
    <div style="width: 6%; height: 100%; float: left"></div>
    <div style="width: 93%; height: 100%; float: left">
      <div style="width: 100%; height: 4.5%"></div>
      <div
        style="width: 100%; height: 5.5%; align-items: center; display: flex"
      >
        <div
          style="
            width: 20%;
            height: 100%;
            float: left;
            align-items: center;
            display: flex;
          "
          class="v41_161"
        >
          <span v-if="Lflag == true" class="v35_1047" style="width: 15%"
            >报警</span
          >
          <span v-if="Lflag == false" class="v35_1047" style="width: 15%"
            >Alarm</span
          >
        </div>
      </div>
      <div style="width: 100%; height: 7%">
        <div
          style="
            width: 15%;
            height: 80%;
            display: flex;
            float: right;
            justify-content: left;
            align-items: center;
          "
        >
          <el-button
            v-if="Lflag == true"
            @click="email_flag = true"
            type="button"
            style="
              width: 60%;
              height: 80%;
              background-color: rgba(25, 44, 250, 1);
              padding-left: 0.8501vw;
              padding-top: 1.0376vh;
              align-items: center;
              display: flex;
              border-radius: 0.2688vw;
              color: #fff;
              font-family: Microsoft YaHei UI;
              font-weight: Regular;
              font-size: 0.8602vw;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            设置报警邮箱
          </el-button>
          <el-button
            v-if="Lflag == false"
            @click="email_flag = true"
            type="button"
            style="
              width: 60%;
              height: 80%;
              background-color: rgba(25, 44, 250, 1);
              padding-left: 0.8501vw;
              padding-top: 1.0376vh;
              align-items: center;
              display: flex;
              border-radius: 0.2688vw;
              color: #fff;
              font-family: Microsoft YaHei UI;
              font-weight: Regular;
              font-size: 0.8602vw;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            Set Alarm Email
          </el-button>
        </div>
      </div>
      <el-dialog
        v-if="Lflag == true"
        title="报警邮箱"
        :visible.sync="email_flag"
        @close="email_flag = false"
      >
        <div style="width: 60%">
          邮箱1：<el-input style="width: 80%" v-model="email1"></el-input>
        </div>
        <div style="height: 2vh"></div>
        <div style="width: 60%">
          邮箱2：<el-input style="width: 80%" v-model="email2"></el-input>
        </div>
        <div style="height: 2vh"></div>
        <div style="width: 60%">
          邮箱3：<el-input style="width: 80%" v-model="email3"></el-input>
        </div>
        <div style="height: 2vh"></div>
        <el-switch
          class="v41_161"
          active-text="开启报警"
          v-model="alarm_flag"
          active-color="#13ce66"
        >
        </el-switch>
        <div slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="set_email()"
            style="
              padding-top: 1vh;
              padding-left: 0.538vw;
              padding-bottom: 1vh;
              padding-right: 0.538vw;
            "
            >确 定</el-button
          >
          <el-button
            @click="email_flag = false"
            style="
              padding-top: 1vh;
              padding-left: 0.538vw;
              padding-bottom: 1vh;
              padding-right: 0.538vw;
            "
            >取 消</el-button
          >
        </div>
      </el-dialog>
      <el-dialog
        v-if="Lflag == false"
        title="Alarm Email"
        :visible.sync="email_flag"
        @close="email_flag = false"
      >
        <div style="width: 60%">
          Email1：<el-input style="width: 80%" v-model="email1"></el-input>
        </div>
        <div style="height: 2vh"></div>
        <div style="width: 60%">
          Email2：<el-input style="width: 80%" v-model="email2"></el-input>
        </div>
        <div style="height: 2vh"></div>
        <div style="width: 60%">
          Email3：<el-input style="width: 80%" v-model="email3"></el-input>
        </div>
        <div style="height: 2vh"></div>
        <el-switch
          class="v41_161"
          active-text="Enable alarms"
          v-model="alarm_flag"
          active-color="#13ce66"
        >
        </el-switch>
        <div slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="set_email()"
            style="
              padding-top: 1vh;
              padding-left: 0.538vw;
              padding-bottom: 1vh;
              padding-right: 0.538vw;
            "
            >OK</el-button
          >
          <el-button
            @click="email_flag = false"
            style="
              padding-top: 1vh;
              padding-left: 0.538vw;
              padding-bottom: 1vh;
              padding-right: 0.538vw;
            "
            >Cancel</el-button
          >
        </div>
      </el-dialog>
      <div style="width: 100%; display: flex; float: left">
        <el-container
          style="width: auto; height: auto"
          v-for="item of this.project"
          :key="item.id"
        >
          <el-button
            type="txt"
            @click="enter(item)"
            style="
              width: 25vw;
              height: 27vh;
              background: rgba(243, 249, 255, 1);
            "
          >
            <div>
              <div style="margin-left: 0vw">{{ item }}</div>
              <div
                style="margin-top: 8vh"
                v-for="(it, key) in alarm"
                :key="key"
              >
                <a v-if="Lflag == true">当前报警是：</a>
                <a v-if="Lflag == false">Current Alarm:</a>
                <span v-if="item == key">
                  <span v-if="it">
                    {{ it }}
                  </span>
                </span>
                <span v-else> 0 </span>
              </div>
            </div>
          </el-button>
        </el-container>
      </div>

      <router-link
        to="/Main/Alarm_record"
        class="no-underline"
        style="justify-content: left; align-items: center; display: flex"
      >
      </router-link>
    </div>
    <div style="background: rgba(255, 255, 255, 1)"></div>
  </div>
</template>

<script>
import { checkToken } from "@/api/user"; //将checkToken方法从/api/user导入，作用是发送网络请求
import { set_alarm_email } from "@/api/data";
import { get_alarm_email } from "@/api/data";
import { FALSE } from "ol/functions";

export default {
  data() {
    return {
      project: "", //用于接收从state里传过来的项目名
      Lflag: true,
      // 地址对象，用于存储选择的省市区信息
      alarm: this.$store.state.alarm,
      site: this.$store.state.site,
      projectNew: "",
      email_flag: false,
      email1: "",
      email2: "",
      email3: "",
      alarm_flag: "",
      key: 0,
    };
  },
  created() {
    //created里的指令在该组件创建的时候执行，created比mounted更早执行
    //console.log(selectedProjectId)//打印，用于测试
    this.$store.commit("restorename"); //将保存在本地的用户名传给state
    this.$store.commit("restoreproject"); //将保存在本地的项目名传给state
    this.$store.commit("restoretoken"); //将保存在本地的token传给state
    this.$store.commit("restoreAlarm");
    this.Lflag = this.$store.state.Language; //store里的传到该组件
    checkToken(this.$qs.stringify({ token: this.$store.state.token })).then(
      (res) => {
        //发送请求：让后端检查token，后端返回的状态在request的response interceptor里拦截并作出相应的响应
        console.log(res);
      }
    );
    get_alarm_email(this.$qs.stringify({ site: this.site })).then((res) => {
      //发送请求：让后端检查token，后端返回的状态在request的response interceptor里拦截并作出相应的响应
      if (res.data[0].email == "true") {
        this.alarm_flag = true;
      } else {
        this.alarm_flag = false;
      }
      this.email1 = res.data[0].email1;
      this.email2 = res.data[0].email2;
      this.email3 = res.data[0].email3;
    });
  },
  mounted: async function () {
    //mounted里的指令在该组件被挂载的时候执行，这里需要加上async，否则可能收不到返回值
    this.project = this.$store.state.project; //将store里的项目名传到本组件
  },
  methods: {
    set_email() {
      console.log(this.site);
      set_alarm_email(
        this.$qs.stringify({
          site: this.site,
          email1: this.email1,
          email2: this.email2,
          email3: this.email3,
          email_flag: this.email_flag,
        })
      ).then((res) => {
        //发送请求：让后端检查token，后端返回的状态在request的response interceptor里拦截并作出相应的响应
        console.log(res);
        var text = "设置成功";
        if (this.Lflag == false) {
          text = "set successful";
        }
        var optionMin = this.$message({
          showClose: true,
          message: text,
          duration: 1000,
          center: true,
          type: "success",
          customClass: "customclass",
        });
      });
    },

    enter(projectNew) {
      this.projectNew = projectNew;
      console.log(this.projectNew);
      this.$store.commit("setProjectNew", projectNew);
      this.$router.push("/Main/Alarm_record");
    },
  },
};
</script>
<style>
.description {
  height: 4.8387vw;
}

.edit {
  top: 53.7634vw;
  right: 5.3763vw;
}

.basic_info {
  resize: none;
  width: 80%;
  height: 2.1505vw;
}

.v41_170 {
  width: 5.1613vw;
  color: rgba(0, 0, 0, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 1.0753vw;
  opacity: 1;
  text-align: left;
}

.v41_172 {
  width: 6.8817vw;
  height: 2.7957vw;
  background: rgba(25, 44, 250, 1);
  padding: 0.5914vw 2.1505vw;
  margin: 0.5376vw;
  opacity: 1;
  border-top-left-radius: 0.2688vw;
  border-top-right-radius: 0.2688vw;
  border-bottom-left-radius: 0.2688vw;
  border-bottom-right-radius: 0.2688vw;
  overflow: hidden;
}

.v41_169 {
  width: 5.1613vw;
  color: rgba(25, 44, 250, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}

.v41_168 {
  width: 2.5806vw;
  color: rgba(255, 255, 255, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}

.v41_166 {
  width: 98%;
  height: 100%;
  background: rgba(243, 249, 255, 1);
  outline-color: rgba(243, 249, 255, 1);
  opacity: 1;
  padding: 0vw;
  border: 0vw;
  border-top-left-radius: 0.5376vw;
  border-top-right-radius: 0.5376vw;
  border-bottom-left-radius: 0.5376vw;
  border-bottom-right-radius: 0.5376vw;
  overflow: hidden;
  color: rgba(0, 0, 0, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  text-align: left;
}

.box-card .text {
  font-size: 0.7527vw;
}

.item {
  margin-bottom: 0.9677vw;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.box-card {
  width: 43.0108vw;
}
</style>
