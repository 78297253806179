import VueRouter from "vue-router";
import Vue from "vue";
import Data_display from '../components/Data_display'
import Basic_Info from '../components/Basic_Info'
import Alarm_record from '../components/Alarm_record'
import Contour_line from '../components/Contour_line'
import Main from '../components/Main'
import Project_select from '../components/Project_select'
import Login from '../components/Login'
import Alarm_device from "@/components/Alarm_device.vue";
import Cruise_monitoring_main from "@/components/Cruise_monitoring_main.vue";
import Cruise_monitoring_compute from "@/components/Cruise_monitoring_compute.vue";
import Eco_display from "@/components/Eco_display.vue";

Vue.use(VueRouter)
//这里定义了路由的关系，以及重定向
const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path:'/',
            redirect: '/Login',//重定向到登陆界面
        },
        {path:'/Project_select',component:Project_select},//项目选择界面
        {path:'/Login',component:Login},//登陆界面，与项目选择界面、主界面同一级
        {
            path:'/Main',//主界面
            redirect: '/Main/Basic_info',//每次访问主界面都会重定向到基本信息界面
            component: Main,
            children:[//以下是主界面的子界面
                {path:'/Main/Basic_info',component: Basic_Info},//基本信息界面
                {path:'/Main/Data_display',component: Data_display},//数据展示界面
                { path: '/Main/Alarm_device',component: Alarm_device,}, 
                { path: '/Main/Alarm_record', component: Alarm_record },
                { path: '/Main/Contour_line', component: Contour_line },//实时源区界面
                { path: '/Main/Cruise_monitoring_main', component: Cruise_monitoring_main },//走航展示界面
                { path: '/Main/Cruise_monitoring_compute', component: Cruise_monitoring_compute },//走航计算界面
                { path: '/Main/Eco_display', component: Eco_display },//走航浓度时间绘图界面
            ]
        },
    ]
})

// router.beforeEach((to, from, next) => {
//     this.$store.commit('restoreIslogin');
//     const isLogin = this.$store.state.isLogin;  // 根据你的登录状态判断是否已登录
//     const Login = to.path === '/Login' // 登录界面的路由路径
  
//     if (isLogin == false && !Login) { // 未登录且不是登录界面
//       next('/login') // 跳转到登录界面
//     } else if (isLogin == false && Login) { // 已登录且是登录界面
//       next('/') // 保持在登录界面不跳转
//     } else {
//       next() // 其他情况正常跳转
//     }
//   })

// router.beforeEach((to, from, next) => {
//     // 检查目标路由是否在合法的路由列表中
//     const allowedRoutes = ['/Login', '/Project_select', '/Main', '/Main/Basic_info',];
//     if (!allowedRoutes.includes(to.path)) {
//       // 目标路由不合法，可以阻止跳转或进行其他处理，比如跳转到默认页面
//       //next('/Project_select');
//       // 或者可以使用以下方法进行跳转：
//       // router.push('/page1');
//     } else {
//       // 合法的路由，继续跳转
//       next();
//     }
//   });

// 捕获未捕获的运行时错误


export default router