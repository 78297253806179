<template>
  <div style="width: 100%; height: 100%">
    <div
      style="
        width: 0.5376vw;
        height: 100%;
        display: flex;
        float: left;
        background: rgba(240, 240, 240, 1);
      "
    ></div>
    <div style="width: 6%; height: 100%; float: left"></div>
    <div style="width: 93%; height: 100%; float: left">
      <div style="width: 100%; height: 4.5%"></div>
      <div
        style="width: 100%; height: 5.5%; align-items: center; display: flex"
      >
        <div
          style="
            width: 20%;
            height: 100%;
            float: left;
            align-items: center;
            display: flex;
          "
          class="v41_161"
        >
          <span v-if="Lflag == true" class="v35_1047" style="width: 15%"
            >羽流计算</span
          >
          <span
            v-if="Lflag == false"
            class="v35_1047"
            style="width: 15%"
          ></span>
        </div>
        <div
          style="
            width: 60%;
            height: 100%;
            float: left;
            align-items: center;
            display: flex;
          "
        ></div>
        <!-- <div
          style="
            width: 20%;
            height: 100%;
            float: right;
            align-items: center;
            justify-content: left;
            display: flex;
          "
          class="v41_161"
        >
          <span
            v-if="Lflag == true"
            class="v41_161"
            style="
              height: 0.8602vh;
              font-size: 0.8602vw;
              align-items: center;
              display: flex;
              justify-content: left;
            "
            >项目名称：{{ this.project }}
          </span>
          <span
            v-if="Lflag == false"
            class="v41_161"
            style="
              height: 0.8602vh;
              font-size: 0.8602vw;
              align-items: center;
              display: flex;
              justify-content: left;
            "
            >Project name：{{ this.project }}
          </span>
        </div> -->
      </div>
      <div style="width: 97%; height: 2vh"></div>
      <div style="width: 97%; height: 80%">
        <div style="width: 33%; height: 100%; float: left">
          <div
            v-if="Calculation_flag == false"
            style="width: 100%; height: 100%"
          >
            <div style="width: 100%; height: 8%">
              <el-select
                v-model="sun"
                placeholder="请选择"
                style="width: 48%; float: left"
              >
                <el-option
                  v-for="item in option1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <div style="width: 4%; height: 100%; float: left"></div>
              <el-select
                v-model="Time"
                placeholder="时段"
                style="width: 48%; float: left"
              >
                <el-option
                  v-for="item in option2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div style="width: 100%; height: 1%"></div>
            风向：<el-input v-model="cloud"></el-input>
            <div style="width: 100%; height: 1%"></div>
            研究区域的经度范围：
            <div style="width: 100%">
              <el-input style="width: 45%" v-model="grid_lon1"></el-input>—
              <el-input style="width: 45%" v-model="grid_lon2"></el-input>
            </div>
            <div style="width: 100%; height: 1%"></div>
            研究区域的纬度范围：
            <div style="width: 100%">
              <el-input style="width: 45%" v-model="grid_lat1"></el-input>—
              <el-input style="width: 45%" v-model="grid_lat2"></el-input>
            </div>
            <div style="width: 100%; height: 1%"></div>
            排放源位置（经度）：<el-input v-model="xs"></el-input>
            <div style="width: 100%; height: 1%"></div>
            排放源位置（纬度）：<el-input v-model="ys"></el-input>
            <div style="width: 100%; height: 1%"></div>
            排放源海拔高度：<el-input v-model="hs"></el-input>
            <div style="width: 100%; height: 1%"></div>
            走航车海拔高度：<el-input v-model="z"></el-input>
            <div style="width: 100%; height: 1%"></div>
            地面粗糙度：<el-input v-model="z0"></el-input>
            <div style="width: 100%; height: 1%"></div>
          </div>
        </div>
        <div style="width: 5%; height: 100%; float: left"></div>
        <div style="width: 55%; height: 100%; float: left">
          <div
            style="width: 100%; height: 66%"
            id="container"
            ref="amap"
            class="container"
          ></div>
          <div style="width: 100%; height: 1%"></div>
          <div
            v-if="Calculation_flag == true"
            style="width: 100%; height: 33%; overflow: auto"
          >
            <el-table
              class="fixedtableHeight"
              ref="multipleTable"
              id="tableshow"
              height="500"
              :data="resultsList"
              style="width: 100%; margin-top: 1%"
            >
              <el-table-column
                :label="item.paramName"
                :property="item.paramIndex"
                v-for="item in titleList"
                :key="item.paramIndex"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row[scope.column.property] }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            v-if="Calculation_flag == false"
            style="width: 100%; height: 33%"
          >
            <div
              style="
                width: 100%;
                height: 30%;
                position: relative;
                float: right;
                display: flex;
                align-items: center;
                justify-content: right;
              "
            >
              <el-input v-model="name"></el-input>
              <div style="width: 30%"></div>
              <el-button
                type="botton"
                style="
                  background-color: rgba(25, 44, 250, 1);
                  padding-left: 2.0968vw;
                  padding-right: 2.0968vw;
                  padding-top: 0.6452vw;
                  padding-bottom: 0.6452vw;
                  align-items: center;
                  display: flex;
                  border-radius: 0.2688vw;
                  color: #fff;
                  font-family: Microsoft YaHei UI;
                  font-weight: Regular;
                  font-size: 0.8602vw;
                "
                @click="start()"
                >开始</el-button
              >
              <el-button
                type="botton"
                style="
                  background-color: rgba(25, 44, 250, 1);
                  padding-left: 2.0968vw;
                  padding-right: 2.0968vw;
                  padding-top: 0.6452vw;
                  padding-bottom: 0.6452vw;
                  align-items: center;
                  display: flex;
                  border-radius: 0.2688vw;
                  color: #fff;
                  font-family: Microsoft YaHei UI;
                  font-weight: Regular;
                  font-size: 0.8602vw;
                "
                @click="end()"
                >结束</el-button
              >
            </div>
            <div
              style="
                width: 100%;
                height: 30%;
                position: relative;
                float: left;
                display: flex;
                align-items: center;
                justify-content: left;
              "
            >
              <el-button
                type="botton"
                style="
                  background-color: rgba(25, 44, 250, 1);
                  padding-left: 2.0968vw;
                  padding-right: 2.0968vw;
                  padding-top: 0.6452vw;
                  padding-bottom: 0.6452vw;
                  align-items: center;
                  display: flex;
                  border-radius: 0.2688vw;
                  color: #fff;
                  font-family: Microsoft YaHei UI;
                  font-weight: Regular;
                  font-size: 0.8602vw;
                "
                @click="Calculation()"
                >计算排放源排放速率</el-button
              >
              <el-button
                type="botton"
                style="
                  background-color: rgba(25, 44, 250, 1);
                  padding-left: 2.0968vw;
                  padding-right: 2.0968vw;
                  padding-top: 0.6452vw;
                  padding-bottom: 0.6452vw;
                  align-items: center;
                  display: flex;
                  border-radius: 0.2688vw;
                  color: #fff;
                  font-family: Microsoft YaHei UI;
                  font-weight: Regular;
                  font-size: 0.8602vw;
                "
                @click="Calculation_results()"
                >查看计算结果</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div style="width: 97%; height: 6%">
        <el-button type="text" @click="return_interface()"
          ><span
            style="
              color: rgba(25, 44, 250, 1);
              font-family: Microsoft YaHei UI;
              font-weight: Regular;
              font-size: 0.8602vw;
            "
            >返回上一级</span
          ></el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { checkToken } from "@/api/user"; //将checkToken方法从/api/user导入，作用是发送网络请求
import { setstart } from "@/api/data";
import { setend } from "@/api/data";
import { ask_plume } from "@/api/data";
import { send_plume } from "@/api/data";
import { asksitelog } from "@/api/data";
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  data() {
    return {
      option1: [
        {
          value: "强",
          label: "强",
        },
        {
          value: "中",
          label: "中",
        },
        {
          value: "弱",
          label: "弱",
        },
      ],
      option2: [
        {
          value: "白天",
          label: "白天",
        },
        {
          value: "黑夜",
          label: "黑夜",
        },
      ],
      username: "", //用于接收从state里传过来的用户名
      project: "", //用于接收从state里传过来的项目名
      table: "",
      database: "",
      site: "",
      name: "",
      Lflag: true,
      zoom_num: "",
      Calculation_flag: false,
      sun: "",
      Time: "",
      cloud: "0.1",
      grid_lon1: "121",
      grid_lat1: "34",
      grid_lon2: "122",
      grid_lat2: "34.5",
      xs: "121.12345",
      ys: "34.12345",
      hs: "2",
      z: "3",
      z0: "0.1",
      map: "",
      map2: "",
      map3: "",
      map4: "",
      concentration1: "", //浓度1
      concentration2: "", //浓度2
      concentration3: "", //浓度3
      concentration4: "", //浓度4
      time: "", //时间
      wind: "", //风向风速
      longitude: "", //经度
      latitude: "", //纬度
      temperature: "", //温度
      pressure: "", //气压
      request_flag: false,
      loglist: [],
      resultsList: [],
      titleList: [],
    };
  },
  created() {
    //created里的指令在该组件创建的时候执行，created比mounted更早执行
    this.Lflag = this.$store.state.Language; //store里的传到该组件
    checkToken(this.$qs.stringify({ token: this.$store.state.token })).then(
      (res) => {
        //发送请求：让后端检查token，后端返回的状态在request的response interceptor里拦截并作出相应的响应
        console.log(res);
      }
    );
    this.username = this.$store.state.username; //将store里的用户名传到本组件
    this.project = this.$store.state.project; //将store里的项目名传到本组件
    this.initAMap(4);
    this.concentration1 = 100;
    this.concentration2 = 200;
    this.concentration3 = 300;
    this.concentration4 = 400;
    this.time = "2024/7/8 12:00:00";
    this.wind = 100;
    this.longitude = 116.333926;
    this.latitude = 39.997245;
    this.temperature = 35;
    this.pressure = 100;
    this.database = this.project[0];
    this.site = this.$store.state.site;
    console.log(this.site);
    asksitelog(this.$qs.stringify({ site: this.site })).then((res) => {
      this.loglist = res.data;
      console.log(res.data);
    });
  },
  mounted() {
    // 隔60s刷新一次数据
    // this.refreshData = setInterval(() => {
    //   askECOdata(this.$qs.stringify({ site: this.site })).then((res) => {
    //     //console.log(res);
    //     this.Datahandle(res.data[0]);
    //     //this.indexList = res.data;
    //     this.refresh(4);
    //     //this.object3Dlayer1.remove(this.wall1);
    //   });
    // }, 1000);
    // 隔60s刷新一次数据
    // this.requestData = setInterval(() => {
    //   this.resultsList = [];
    //   for (var i of this.loglist) {
    //     if (this.request_flag == false) {
    //       this.name = i.name;
    //       console.log(this.name, this.site);
    //       ask_plume(
    //         this.$qs.stringify({ site: this.site, name: this.name })
    //       ).then((res) => {
    //         //console.log(res);
    //         this.resultsList.push(res.data[0]);
    //         console.log(this.resultsList);
    //         this.request_flag = true;
    //       });
    //     }
    //   }
    // }, 20 * 1000);
  },
  methods: {
    Calculation() {
      console.log(this.name);
      console.log(this.site);
      console.log(this.sun);
      console.log(this.cloud);
      console.log(this.grid_lon1);
      console.log(this.grid_lat1);
      console.log(this.grid_lon2);
      console.log(this.grid_lat2);
      console.log(this.xs);
      console.log(this.ys);
      console.log(this.Time);
      console.log(this.hs);
      console.log(this.z);
      console.log(this.z0);
      if (this.request_flag == true) {
        var keys = Object.keys(this.resultsList[0]);
        for (let i in keys) {
          this.titleList.push({ paramIndex: keys[i], paramName: keys[i] });
        }
        console.log(this.titleList);
      }
      send_plume(
        this.$qs.stringify({
          name: this.name,
          table: this.site,
          sun: this.sun,
          cloud: this.cloud,
          lon1: this.grid_lon1,
          lat1: this.grid_lat1,
          lon2: this.grid_lon2,
          lat2: this.grid_lat2,
          xs1: this.xs,
          ys1: this.ys,
          light: this.Time,
          hs: this.hs,
          z: this.z,
          z0: this.z0,
        })
      ).then((res) => {
        console.log(res);
      });
    },
    Calculation_results() {
      this.Calculation_flag = true;
      this.resultsList = [];
      this.titleList = [];
      // for (var i of this.loglist) {

      //this.name = i.name;
      console.log(this.name, this.site);
      ask_plume(this.$qs.stringify({ site: this.site, name: this.name })).then(
        (res) => {
          //console.log(res);
          this.resultsList.push(res.data[0]);
          console.log(this.resultsList);
          this.request_flag = true;
          var keys = Object.keys(this.resultsList[0]);
          for (let i in keys) {
            this.titleList.push({ paramIndex: keys[i], paramName: keys[i] });
          }
          console.log(this.titleList);
        }
      );
    },
    return_interface() {
      if (this.Calculation_flag == true) {
        this.Calculation_flag = false;
      } else if (this.Calculation_flag == false) {
        this.$router.push("/Main/Cruise_monitoring_main");
      }
    },
    start() {
      var stamp = new Date().getTime() + 8 * 60 * 60 * 1000;
      // 格式化北京时间为"YYYY-MM-DD HH:mm:ss"
      var beijingTime = new Date(stamp)
        .toISOString()
        .replace(/T/, " ")
        .replace(/\..+/, "")
        .substring(0, 19);
      // Fri Nov 10 2023 17:47:40 GMT+0800 (中国标准时间)
      // 2023-11-10T09:49:02.550Z
      // 2023-11-10 09:49:02
      console.log(this.database);
      console.log(this.site);
      console.log(beijingTime);
      console.log(this.name);
      setstart(
        this.$qs.stringify({
          database: this.database,
          site: this.site,
          start: beijingTime,
          name: this.name,
        })
      ).then((res) => {
        console.log(res);
      });
    },
    end() {
      var stamp = new Date().getTime() + 8 * 60 * 60 * 1000;
      // 格式化北京时间为"YYYY-MM-DD HH:mm:ss"
      var beijingTime = new Date(stamp)
        .toISOString()
        .replace(/T/, " ")
        .replace(/\..+/, "")
        .substring(0, 19);
      // Fri Nov 10 2023 17:47:40 GMT+0800 (中国标准时间)
      // 2023-11-10T09:49:02.550Z
      // 2023-11-10 09:49:02
      console.log(this.database);
      console.log(this.site);
      console.log(beijingTime);
      console.log(this.name);
      setend(
        this.$qs.stringify({
          database: this.database,
          site: this.site,
          end: beijingTime,
          name: this.name,
        })
      ).then((res) => {
        console.log(res);
      });
    },
    shrink() {
      var div = document.getElementById(
        "container" + this.zoom_num
      ); /*w和h都要用这部分，故单独定义一个div*/
      if (this.zoom_num == "3" || this.zoom_num == "4") {
        div.style.top = 0 + "vw";
      }
      div =
        document.getElementById(
          "container1"
        ); /*w和h都要用这部分，故单独定义一个div*/
      div.style.width = 34.9462 + "vw"; //650px
      div.style.height = 30 + "vh"; //300px
      div =
        document.getElementById(
          "container2"
        ); /*w和h都要用这部分，故单独定义一个div*/
      div.style.width = 34.9462 + "vw"; //650px
      div.style.height = 30 + "vh"; //300px
      div =
        document.getElementById(
          "container3"
        ); /*w和h都要用这部分，故单独定义一个div*/
      div.style.width = 34.9462 + "vw"; //650px
      div.style.height = 30 + "vh"; //300px
      div =
        document.getElementById(
          "container4"
        ); /*w和h都要用这部分，故单独定义一个div*/
      div.style.width = 34.9462 + "vw"; //650px
      div.style.height = 30 + "vh"; //300px
      this.zoomflag = true;
    },
    magnify(id) {
      //document.getElementById("container1").innerHTML = "";
      this.zoom_num = id;
      var div = document.getElementById(
        "container" + this.zoom_num
      ); /*w和h都要用这部分，故单独定义一个div*/
      div.style.width = 75.6989 + "vw"; //1396px
      div.style.height = 65 + "vh"; //645px
      var div_t = document.getElementById("container1");
      if (this.zoom_num == "1") {
        div_t = document.getElementById("container2");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
        div_t = document.getElementById("container3");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
        div_t = document.getElementById("container4");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
      } else if (this.zoom_num == "2") {
        div_t = document.getElementById("container1");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
        div_t = document.getElementById("container3");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
        div_t = document.getElementById("container4");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
      } else if (this.zoom_num == "3") {
        div.style.top = -35.1 + "vh"; //338.3px
        div_t = document.getElementById("container1");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vw";
        div_t = document.getElementById("container2");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
        div_t = document.getElementById("container4");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
      } else if (this.zoom_num == "4") {
        div.style.top = -35.1 + "vh"; //338.3px
        div_t = document.getElementById("container1");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
        div_t = document.getElementById("container2");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
        div_t = document.getElementById("container3");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
      }
      this.zoomflag = false;
    },
    initAMap() {
      AMapLoader.reset(); //清除全局的amap
      //const _this = this;
      window._AMapSecurityConfig = {
        securityJsCode: "c071c8dc815700be13f9aca9de4db974", // 自2021年12月02日升级后, key与安全密钥必须一起使用, 否则可能会出现一些API无法使用，如 DistrictSearch
      };
      AMapLoader.load({
        key: "dcde0cf01341034fcfe241ff85f826c4", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.ControlBar", //方向控件
          "AMap.DistrictSearch",
          "Map3D",
          "AMap.Object3DLayer",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等，如果是地图控件，必须再 map.addControl添加
      })
        .then((AMap) => {
          this.map = new AMap.Map("container", {
            // 设置地图容器id
            resizeEnable: true,
            rotateEnable: true,
            pitchEnable: true,
            zoom: 17,
            pitch: 80,
            rotation: -15,
            viewMode: "3D", //开启3D视图,默认为关闭
            buildingAnimation: true, //楼块出现是否带动画

            expandZoomRange: true,
            zooms: [1, 20],
            center: [121.6, 29.8],
            turboMode: false,
            //showBuildingBlock: false,
            forceVector: true,
            layers: [
              // 高德默认标准图层，开启卫星地图
              new AMap.TileLayer.Satellite(),
            ],
          });
          this.map.addControl(
            new AMap.ControlBar({
              showZoomBar: false,
              showControlButton: true,
              position: {
                right: "10px",
                top: "10px",
              },
            })
          );

          //   function lnglat2pixel(inputVal) {
          //     var lnglat = new AMap.LngLat(inputVal[0], inputVal[1]);
          //     var pixel = map.lngLatToGeodeticCoord(lnglat);
          //     pixel.x = parseInt(pixel.x);
          //     pixel.y = parseInt(pixel.y);
          //     console.log([pixel.x, pixel.y]);
          //     return [pixel.x, pixel.y];
          //   }
          // var object3Dlayer = new AMap.Object3DLayer({
          //   zIndex: 110,
          //   opacity: 1,
          // });
          // map1.add(object3Dlayer);
          // map2.add(object3Dlayer);
          var provinces = [
            [116.333926, 39.997245],
            [116.333926, 39.997225],
            [116.333926, 39.997205],
            [116.333926, 39.997185],
            [116.333926, 39.997165],
            [116.333926, 39.997145],
          ];
          var height = [400, 500, 600, 700, 800];
          for (let i = 0; i < provinces.length - 1; i++) {
            var test = [[]];
            test[0] = this.map.lngLatToGeodeticCoord(provinces[i]);
            test[1] = this.map.lngLatToGeodeticCoord(provinces[i + 1]);
            let object3Dlayer = new AMap.Object3DLayer();
            this.map.add(object3Dlayer);
            var color = [0.4, 1, 1, 1];
            var wall = new AMap.Object3D.Wall({
              path: test,
              height: height[i],
              color: color,
            });
            //wall 有个面,该属性表示哪个面可见,可选值back ,front  ,both表示两面都填充  默认为front
            wall.backOrFront = "front";
            // 是否允许使用透明颜色
            wall.transparent = true;
            object3Dlayer.add(wall);
            var text = new AMap.Text({
              text: height[i],
              verticalAlign: "top",
              position: provinces[i],
              height: height[i] / 1.0,
              style: {
                "background-color": "transparent",
                "-webkit-text-stroke": "red",
                "-webkit-text-stroke-width": "0.5px",
                "text-align": "center",
                border: "none",
                color: "white",
                "font-size": ".5376vw",
              },
            });
            text.setMap(this.map);
          }
          // var center_3d = map.lngLatToGeodeticCoord(provinces);
          // var topColor = [0, 1, 1, 0.9];
          // var topFaceColor = [0, 1, 1, 0.4];
          // var bottomColor = [0, 0, 1, 0.9];

          // //添加一个圆柱体
          // var addRegularPrism = function (center, segment, height, radius) {
          //   var cylinder = new AMap.Object3D.Mesh();
          //   var geometry = cylinder.geometry;
          //   var verticesLength = segment * 2;
          //   var path = [];
          //   for (var i = 0; i < segment; i += 1) {
          //     var angle = (2 * Math.PI * i) / segment;
          //     var x = center.x + Math.cos(angle) * radius;
          //     var y = center.y + Math.sin(angle) * radius;
          //     path.push([x, y]);
          //     geometry.vertices.push(x, y, 0); //底部顶点
          //     geometry.vertices.push(x, y, -height); //顶部顶点

          //     geometry.vertexColors.push.apply(
          //       geometry.vertexColors,
          //       bottomColor
          //     ); //底部颜色
          //     geometry.vertexColors.push.apply(geometry.vertexColors, topColor); //顶部颜色

          //     var bottomIndex = i * 2;
          //     var topIndex = bottomIndex + 1;
          //     var nextBottomIndex = (bottomIndex + 2) % verticesLength;
          //     var nextTopIndex = (bottomIndex + 3) % verticesLength;

          //     geometry.faces.push(bottomIndex, topIndex, nextTopIndex); //侧面三角形1
          //     geometry.faces.push(bottomIndex, nextTopIndex, nextBottomIndex); //侧面三角形2
          //   }

          //   // 构建顶面三角形,为了区分顶面点和侧面点使用不一样的颜色,所以需要独立的顶点
          //   for (var i = 0; i < segment; i += 1) {
          //     geometry.vertices.push.apply(
          //       geometry.vertices,
          //       geometry.vertices.slice(i * 6 + 3, i * 6 + 6)
          //     ); //底部顶点
          //     geometry.vertexColors.push.apply(
          //       geometry.vertexColors,
          //       topFaceColor
          //     );
          //   }

          //   var triangles = AMap.GeometryUtil.triangulateShape(path);
          //   var offset = segment * 2;

          //   for (var v = 0; v < triangles.length; v += 3) {
          //     geometry.faces.push(
          //       triangles[v] + offset,
          //       triangles[v + 2] + offset,
          //       triangles[v + 1] + offset
          //     );
          //   }

          //   cylinder.transparent = true; // 如果使用了透明颜色，请设置true
          //   object3Dlayer.add(cylinder);
          // };
          // var test = [
          //   { x: "116.333926", y: "39.996905", z: "500" },
          //   { x: "116.333926", y: "39.996925", z: "400" },
          //   { x: "116.333926", y: "39.996945", z: "500" },
          //   { x: "116.333926", y: "39.996965", z: "500" },
          //   { x: "116.333926", y: "39.996985", z: "700" },
          //   { x: "116.333926", y: "39.997005", z: "500" },
          //   { x: "116.333926", y: "39.997025", z: "400" },
          //   { x: "116.333926", y: "39.997045", z: "500" },
          //   { x: "116.333926", y: "39.997065", z: "500" },
          //   { x: "116.333926", y: "39.997085", z: "700" },
          //   { x: "116.333926", y: "39.997105", z: "500" },
          //   { x: "116.333926", y: "39.997125", z: "400" },
          //   { x: "116.333926", y: "39.997145", z: "500" },
          //   { x: "116.333926", y: "39.997165", z: "500" },
          //   { x: "116.333926", y: "39.997185", z: "1200" },
          //   { x: "116.333926", y: "39.997205", z: "1000" },
          //   { x: "116.333926", y: "39.997225", z: "400" },
          //   { x: "116.333926", y: "39.997245", z: "600" },
          //   { x: "116.333926", y: "39.997265", z: "700" },
          //   { x: "116.333926", y: "39.997285", z: "800" },
          //   { x: "116.333926", y: "39.997305", z: "500" },
          //   { x: "116.333926", y: "39.997325", z: "600" },
          //   { x: "116.333926", y: "39.997345", z: "400" },
          //   { x: "116.333926", y: "39.997365", z: "450" },
          //   { x: "116.333926", y: "39.997385", z: "400" },
          //   { x: "116.333926", y: "39.997405", z: "500" },
          //   { x: "116.333926", y: "39.997425", z: "400" },
          //   { x: "116.333926", y: "39.997445", z: "500" },
          //   { x: "116.333926", y: "39.997465", z: "500" },
          //   { x: "116.333926", y: "39.997485", z: "700" },
          //   { x: "116.333926", y: "39.997505", z: "500" },
          //   { x: "116.333926", y: "39.997525", z: "400" },
          //   { x: "116.333926", y: "39.997545", z: "500" },
          //   { x: "116.333926", y: "39.997565", z: "500" },
          //   { x: "116.333926", y: "39.997585", z: "700" },
          //   { x: "116.333906", y: "39.997585", z: "500" },
          //   { x: "116.333886", y: "39.997585", z: "400" },
          //   { x: "116.333866", y: "39.997585", z: "500" },
          //   { x: "116.333846", y: "39.997585", z: "500" },
          //   { x: "116.333826", y: "39.997585", z: "700" },
          // ];
          // for (var i = 0; i < test.length; i++) {
          //   center_3d = map.lngLatToGeodeticCoord([test[i].x, test[i].y]);
          //   console.log(center_3d);
          //   addRegularPrism(
          //     center_3d.add(new AMap.Pixel(1, 0)),
          //     12,
          //     test[i].z,
          //     10
          //   ); //圆柱
          //   var text = new AMap.Text({
          //     text: test[i].z,
          //     verticalAlign: "top",
          //     position: [test[i].x, test[i].y],
          //     height: test[i].z / 1.0,
          //     style: {
          //       "background-color": "transparent",
          //       "-webkit-text-stroke": "red",
          //       "-webkit-text-stroke-width": "0.5px",
          //       "text-align": "center",
          //       border: "none",
          //       color: "white",
          //       "font-size": "10px",
          //     },
          //   });
          //   text.setMap(map);
          // }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  destroyed() {},
};
</script>
<style scoped>
::v-deep .el-button {
  padding-left: 0.0538vw;
  padding-top: 0.0538vw;
}
</style>
<style>
.btn {
  position: absolute;
  cursor: pointer;
  z-index: 10;
}
.amap-luopan-bg {
  width: 0.5376vw !important;
  height: 0.5376vw !important;
}
.amap-logo {
  display: none !important;
  visibility: hidden !important;
}
.amap-copyright {
  display: none !important;
  visibility: hidden !important;
}
.description {
  height: 4.8387vh;
}

.edit {
  top: 53.7634vw;
  right: 5.3763vh;
}

.basic_info {
  resize: none;
  width: 80%;
  height: 2.1505vh;
}

.v41_170 {
  width: 5.1613vw;
  color: rgba(0, 0, 0, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 1.0753vw;
  opacity: 1;
  text-align: left;
}

.v41_172 {
  width: 6.8817vw;
  height: 2.7957vh;
  background: rgba(25, 44, 250, 1);
  padding: 0.5914vw 2.1505vw;
  margin: 0.5376vw;
  opacity: 1;
  border-top-left-radius: 0.2688vw;
  border-top-right-radius: 0.2688vw;
  border-bottom-left-radius: 0.2688vw;
  border-bottom-right-radius: 0.2688vw;
  overflow: hidden;
}

.v41_169 {
  width: 5.1613vw;
  color: rgba(25, 44, 250, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}

.v41_168 {
  width: 2.5806vw;
  color: rgba(255, 255, 255, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}

.v41_166 {
  width: 98%;
  height: 100%;
  background: rgba(243, 249, 255, 1);
  outline-color: rgba(243, 249, 255, 1);
  opacity: 1;
  padding: 0vw;
  border: 0vw;
  border-top-left-radius: 0.5376vw;
  border-top-right-radius: 0.5376vw;
  border-bottom-left-radius: 0.5376vw;
  border-bottom-right-radius: 0.5376vw;
  overflow: hidden;
  color: rgba(0, 0, 0, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  text-align: left;
}

.box-card .text {
  font-size: 0.7527vw;
}

.item {
  margin-bottom: 0.9677vw;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.box-card {
  width: 43.0108vw;
}
</style>
