<template>
  <div style="width: 100%; height: 100%">
    <div
      style="
        width: 0.5376vw;
        height: 100%;
        display: flex;
        float: left;
        background: rgba(240, 240, 240, 1);
      "
    ></div>
    <div style="width: 6%; height: 100%; float: left; display: flex"></div>
    <div style="width: 93%; height: 100%; float: left">
      <div style="width: 100%; height: 4.5%"></div>
      <!-- 页面头部 -->
      <div
        style="width: 100%; height: 5.5%; align-items: center; display: flex"
      >
        <div
          style="
            width: 20%;
            height: 100%;
            float: left;
            align-items: center;
            display: flex;
          "
          class="v41_161"
        >
          <span v-if="Lflag == true" class="v35_1047">实时源区</span>
          <span v-if="Lflag == false" class="v35_1047">Footprint</span>
        </div>
        <div
          style="
            width: 60%;
            height: 100%;
            float: left;
            align-items: center;
            display: flex;
          "
        ></div>
        <div
          style="
            width: 20%;
            height: 100%;
            float: right;
            align-items: center;
            display: flex;
          "
          class="v41_161"
        >
          <!-- <span v-if="Lflag == true" class="v41_161"
            >项目名称：{{ this.project }}
          </span>
          <span v-if="Lflag == false" class="v41_161"
            >Project Name：{{ this.project }}
          </span> -->
        </div>
      </div>
      <!-- 操作按钮及查询信息 -->
      <div style="width: 100%; height: 2%"></div>
      <div style="width: 96.2%; height: 5%; display: flex; margin-bottom: 5%">
        <div
          style="
            width: 20.56%;
            height: 100%;
            float: left;
            display: flex;
            align-items: center;
            margin-right: 2%;
            font-size: 1vw;
            font-size: 0.8vw;
          "
        >
          <div v-if="Lflag == true" style="width: 20%; text-align: center">
            时间：
          </div>
          <div
            v-else-if="Lflag == false"
            style="width: 20%; text-align: center"
          >
            TIME:
          </div>
          <el-tooltip
            v-if="Lflag == true"
            effect="light"
            content="页面展示图表时禁止选择时间，需清除页面图表"
            placement="top"
          >
            <el-date-picker
              v-model="requestTime"
              @change="changeTime"
              type="datetime"
              :picker-options="DisableDatesOption"
              value-format="yyyy-MM-dd HH:mm"
              format="yyyy-MM-dd HH:mm"
              default-time
              :disabled="timeDis"
              style="width: 100%"
              popper-class="date-picker"
            >
            </el-date-picker>
          </el-tooltip>
          <el-tooltip
            v-else-if="Lflag == false"
            effect="light"
            content="When displaying charts on a page, it is forbidden to select a time, and the charts on the page must be cleared"
            placement="top"
          >
            <el-date-picker
              v-model="requestTime"
              @change="changeTime"
              type="datetime"
              :picker-options="DisableDatesOption"
              value-format="yyyy-MM-dd HH:mm"
              format="yyyy-MM-dd HH:mm"
              default-time
              :disabled="timeDis"
              style="width: 100%"
              popper-class="date-picker"
            >
            </el-date-picker>
          </el-tooltip>
        </div>
        <!-- 添加经纬度的输入框 -->
        <div
          style="
            width: 23%;
            height: 100%;
            float: left;
            display: flex;
            align-items: center;
            margin-right: 2%;
            font-size: 0.8vw;
          "
        >
          <div style="width: 100%; text-align: center">
            <span v-if="Lflag == true">经度坐标:</span>
            <span v-if="Lflag == false">Longitude coordinates:</span>
          </div>
          <!-- 度 分 秒 -->
          <el-input v-model="wrapLimit"></el-input>
          &deg;
          <el-input v-model="wrapDivide"></el-input>
          '
          <el-input v-model="wrapSecond"></el-input>
          ''
        </div>
        <div
          style="
            width: 23%;
            height: 100%;
            float: left;
            display: flex;
            align-items: center;
            margin-right: 2%;
            font-size: 0.8vw;
          "
        >
          <div style="width: 100%; text-align: center">
            <span v-if="Lflag == true">纬度坐标:</span>
            <span v-if="Lflag == false">Latitude coordinates:</span>
          </div>
          <el-input v-model="weftLimit"></el-input>
          &deg;
          <el-input v-model="weftDivide"></el-input>
          '
          <el-input v-model="weftSecond"></el-input>
          ''
        </div>

        <div
          style="
            width: 28%;
            float: left;
            justify-content: right;
            display: flex;
            margin-left: 3vw;
          "
        >
          <el-button
            v-if="Lflag == true"
            size="mini"
            style="
              background-color: rgba(25, 44, 250, 1);
              color: #fff;
              font-size: 0.8602vw;
            "
            plain
            @click="findbutton"
            >查询</el-button
          >
          <el-button
            v-if="Lflag == false"
            size="mini"
            style="
              background-color: rgba(25, 44, 250, 1);
              color: #fff;
              font-size: 0.8602vw;
            "
            plain
            @click="findbutton"
            >Search</el-button
          >
          <el-tooltip
            v-if="Lflag == true"
            class="v41_161"
            effect="light"
            content="数据下载"
            placement="bottom"
          >
            <el-button
              size="mini"
              type="success"
              @click="downloadbutton"
              style="color: #fff; font-size: 0.8602vw"
              >下载数据</el-button
            >
          </el-tooltip>
          <el-tooltip
            v-else-if="Lflag == false"
            class="v41_161"
            effect="light"
            content="Data downloads"
            placement="bottom"
          >
            <el-button
              size="mini"
              type="success"
              @click="downloadbutton"
              style="color: #fff; font-size: 0.8602vw"
              >DownloadData</el-button
            >
          </el-tooltip>
          <el-button
            v-if="Lflag == true"
            size="mini"
            type="danger"
            @click="cleardata"
            class="v41_161"
            style="color: #fff; font-size: 0.8602vw"
            >清除</el-button
          >
          <el-button
            v-if="Lflag == false"
            size="mini"
            type="danger"
            @click="cleardata"
            class="v41_161"
            style="color: #fff; font-size: 0.8602vw"
            >Clear</el-button
          >
          <el-button
            v-if="Lflag == true"
            size="mini"
            type="danger"
            @click="ce"
            class="v41_161"
            style="color: #fff; font-size: 0.8602vw"
            >保存</el-button
          >
          <el-button
            v-if="Lflag == false"
            size="mini"
            type="danger"
            @click="ce"
            class="v41_161"
            style="color: #fff; font-size: 0.8602vw"
            >Save</el-button
          >
          <!-- <el-button id="ce"  
          @click="ce" 
          style="color: #fff; 
          font-size: 0.8602vw"
          >提交</el-button> -->
        </div>
      </div>

      <!-- 图表展示 -->
      <!-- <div class="canvas"></div> -->

      <!-- 测试按钮，经纬度赋值给输入框 -->
      <!-- <el-button id="shi"  @click="shi" style="color: #fff; font-size: 0.8602vw"></el-button> -->
      <!-- 8800系列不需要地图展示，展示说明图片 -->
      <div id="map" class="map" v-if="Lflag == true">
        <img
          src="../assets/error.png"
          style="width: 80%; height: 90%; object-fit: cover"
        />
      </div>
      <div id="map" class="map" v-if="Lflag == false">
        <img
          src="../assets/errorEN.png"
          style="width: 80%; height: 90%; object-fit: cover"
        />
      </div>
      <!-- 下载文件选择参数 -->
      <el-dialog
        v-if="Lflag == true"
        title="选择下载方式"
        :visible.sync="dialogVisible"
        @close="cancelDowload"
      >
        <div style="display: flex; height: 5.3763vw">
          <div style="width: 5%; float: left"></div>
          <div
            style="
              width: 55%;
              float: left;
              justify-content: left;
              display: flex;
              align-items: center;
            "
          >
            <el-date-picker
              v-model="dowloadRequestTime"
              @change="changeDowloadTime"
              type="datetime"
              :picker-options="DisableDatesOption"
              value-format="yyyy-MM-dd HH:mm"
              format="yyyy-MM-dd HH:mm"
              default-time
              style="width: 100%"
              popper-class="date-picker"
            >
            </el-date-picker>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancelDowload">取 消</el-button>

          <el-button type="primary" @click="download">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog
        v-else-if="Lflag == false"
        title="Select a download method"
        :visible.sync="dialogVisible"
        @close="cancelDowload"
      >
        <div style="display: flex; height: 5.3763vw">
          <div style="width: 5%; float: left"></div>
          <div
            style="
              width: 55%;
              float: left;
              justify-content: left;
              display: flex;
              align-items: center;
            "
          >
            <el-date-picker
              v-model="dowloadRequestTime"
              @change="changeDowloadTime"
              type="datetime"
              :picker-options="DisableDatesOption"
              value-format="yyyy-MM-dd HH:mm"
              format="yyyy-MM-dd HH:mm"
              default-time
              style="width: 100%"
              popper-class="date-picker"
            >
            </el-date-picker>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancelDowload">Cancel</el-button>
          <el-button type="primary" @click="download">OK</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { checkToken } from "@/api/user"; //将checkToken方法从/api/user导入，作用是发送网络请求
import { ffp, getffptime, ffpnew } from "@/api/data";
import * as echarts from "echarts";
import "ol/ol.css";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { OSM, Vector as VectorSource } from "ol/source";
import XYZ from "ol/source/XYZ";
import Map from "ol/Map";
import View from "ol/View";
import GeoJSON from "ol/format/GeoJSON";
import Feature from "ol/Feature";
import { Style, Stroke, Fill } from "ol/style";
import { Polygon, MultiPolygon, MultiLineString } from "ol/geom";
import { defaults as defaultControls, OverviewMap } from "ol/control";
import { defaults } from "ol/interaction";
import { transform } from "ol/proj";
import ZoomSlider from "ol/control/ZoomSlider";
import { askLL } from "@/api/data";
import { saveLL } from "@/api/data";

export default {
  data() {
    return {
      project: "", //用于接收从state里传过来的项目名
      projectArray: [],
      database: "",
      table: "",
      timeDis: true,
      // 查询loading是否显示
      findFlag: false,
      // 是否显示下载界面
      dialogVisible: false,
      checkArr: [],
      requestTime: "2023-03-26 04:00",
      dowloadRequestTime: "2023-03-26 04:00",
      timedata: [], //存在数据的日期
      firstSelect: false, // 是否是初始化查询数据
      isChangeTime: false, // 是否更改时间
      timex: [],
      xyArr: [],
      coordinateArr: [], // 经纬度坐标数组
      genJSON: null, // genJSON数据
      //responseCode: "", // 接受网络状态码

      latitude: "", //维度
      longitude: "", //经度

      DisableDatesOption: {
        //日历上存在数据的日期用红点标出
        cellClassName: (time) => {
          if (this.timedata.includes(this.parseTime(time, "{y}-{m}-{d}"))) {
            return "badge";
          }
        },
        disabledDate(date) {
          return date.getTime() - 8.64e6 > Date.now() + 86400000; //当前时间延后一天
        },
        selectableRange: "00:00:00 -23:00:00",
      },
      // 地图
      map: null,
      // 图层
      areaLayer: null,
      // 输入的经度|度分秒

      wrap: "",
      wrapLimit: "", //121
      wrapDivide: "", //49
      wrapSecond: "", //45.5
      // 输入的纬度|度分秒
      weft: "",
      weftLimit: "", //29
      weftDivide: "", //41
      weftSecond: "", //44.5
      // 天地图key
      // TdKey: "1b0b6d4fd05ff71dc47f1ec5971f9d83",
      // // 天地图矢量图层
      // tdUrl: "http://t0.tianditu.com/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=",
      // // 天地图矢量图层标记
      // tdUrlLabel: "http://t0.tianditu.com/DataServer?T=cta_w&x={x}&y={y}&l={z}&tk=",
      // // 高德地图
      // gdUrl: "http://wprd0{1-4}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=7&x={x}&y={y}&z={z}",
      // 高德地图key
      // gdKey: '4644c76fe9eec2521c69b5baa11461b2',
      Lflag: true,
    };
  },

  //发送请求，让后端检查token
  created() {
    this.$store.commit("restoretoken"); //将保存在本地的token存到state里（因为每次刷新会导致state里的值清空）
    this.Lflag = this.$store.state.Language; //store里的传到该组件
    checkToken(this.$qs.stringify({ token: this.$store.state.token })).then(
      (res) => {
        //发送请求：让后端检查token，后端返回的状态在request的response interceptor里拦截并作出相应的响应
        console.log(res);
      }
    );
    setTimeout(() => {}, 2000);
    this.$store.commit("restoreproject"); //将保存在本地的项目名传给state
    this.project = this.$store.state.project[0]; //将store里的项目名传到本组件
    this.dataTime();

    this.database = this.$store.state.project[0]; //将分割出的第一部分赋值给database
    this.table = this.$store.state.site; //将分割出的第二部分赋值给table并且加上后缀
    console.log("数据库是====", this.database);
    console.log(this.table);
    askLL(
      this.$qs.stringify({ database: this.database, table: this.table })
    ).then((res) => {
      console.log(res.data[0].longitude); //经度
      console.log(res.data[0].latitude); //维度
      this.longitude = res.data[0].longitude;
      this.latitude = res.data[0].latitude;
    });
  },
  mounted() {},
  methods: {
    ce() {
      this.database = this.$store.state.project[0]; //将分割出的第一部分赋值给database
      this.table = this.$store.state.site; //将分割出的第二部分赋值给table并且加上后缀
      saveLL(
        this.$qs.stringify({
          database: this.database,
          table: this.table,
          longitude: this.wrap,
          latitude: this.weft,
        })
      ).then((res) => {
        console.log(res);
      });
      console.log(this.database);
      console.log(this.table);
      console.log(this.wrap);
      console.log(this.weft);
      this.longitude = this.wrap;
      this.latitude = this.weft;
    },
    // 初始化查询有数据的日期
    dataTime() {
      // 获取有数据的日期
      this.projectArray = this.project.split("_"); //将project分割成两部分,前后端约定好用下划线分割
      this.database = "`" + this.$store.state.project[0] + "`"; //将分割出的第一部分赋值给database
      this.table = "`" + this.$store.state.site + "_ffp" + "`"; //将分割出的第二部分赋值给table并且加上后缀
      let startstr = new Date().getTime() + 86400000;
      let endstr = new Date().getTime() - 5184000000 * 6; //当前时间前两个月*6即一年的时间
      let str1 = new Date(parseInt(startstr)).toLocaleDateString();
      let arr = str1.split("/");
      let starttime = arr[0] + "-" + arr[1] + "-" + arr[2] + " " + "00:00:00";
      str1 = new Date(parseInt(endstr)).toLocaleDateString();
      arr = str1.split("/");
      let endtime = arr[0] + "-" + arr[1] + "-" + arr[2] + " " + "00:00:00";

      getffptime(
        this.$qs.stringify({
          start: starttime,
          end: endtime,
          database: this.database,
          table: this.table,
        })
      ).then((res) => {
        this.timedata = res.data;
        if (res.data.length > 0) {
          // 下载还是渲染等值线图判断
          if (!this.dialogVisible) {
            this.requestTime = res.data[res.data.length - 1] + " 00:00";
            // 初始化没有选择时间，需要通过ffpnew来查询数据
            this.firstSelect = true;
            this.findbutton(this.firstSelect);
          }
        } else {
          if (this.Lflag == true) {
            this.$message({
              showClose: true,
              message: "近期无数据，请选择其他设备！",
              duration: 2 * 1000,
              center: true,
              type: "error",
              customClass: "customclass",
            });
          } else {
            this.$message({
              showClose: true,
              message:
                "There is no data in the near future, please select another device!",
              duration: 2 * 1000,
              center: true,
              type: "error",
              customClass: "customclass",
            });
          }
        }
      });
    },
    // 根据genJSON添加图层
    initEcharts() {
      // 生成GenJSON数据
      this.dataConvert();
      // 设置图层
      this.areaLayer = new VectorLayer({
        //矢量图层
        source: new VectorSource({
          features: [],
        }),
      });
      let areaFeature = null;

      // 添加图层
      this.map.addLayer(this.areaLayer);
      for (let i = 0; i < this.genJSON.length; i++) {
        let lineData = this.genJSON[i].type;
        if (lineData == "MultiPolygon") {
          areaFeature = new Feature({
            geometry: new MultiPolygon(this.genJSON[i].coordinates).transform(
              "EPSG:4326",
              "EPSG:3857"
            ),
          });
        } else if (lineData == "Polygon") {
          areaFeature = new Feature({
            geometry: new Polygon(this.genJSON[i].coordinates).transform(
              "EPSG:4326",
              "EPSG:3857"
            ),
          });
        }
        areaFeature.setStyle(
          new Style({
            fill: new Fill({ color: "#FF000030" }),
            stroke: new Stroke({
              width: 1,
              color: [255, 0, 0, 1],
            }),
          })
        );
        this.areaLayer.getSource().addFeatures([areaFeature]);
      }
      const result = this.convertDecimalToDMS(this.longitude);
      this.wrapLimit = result.Limit;
      this.wrapDivide = result.Divide;
      this.wrapSecond = result.second;

      const result1 = this.convertDecimalToDMS(this.latitude);
      this.weftLimit = result1.Limit;
      this.weftDivide = result1.Divide;
      this.weftSecond = result1.second;
    },
    // 查询数据
    findbutton() {
      this.cleardata();

      if (this.firstSelect) {
        if (this.Lflag == true) {
          this.optionMin = this.$message({
            showClose: true,
            message: "查询中，请稍后",
            duration: 3 * 1000,
            center: true,
            type: "success",
            customClass: "customclass",
          });
        } else {
          this.optionMin = this.$message({
            showClose: true,
            message: "Inquiry, please wait",
            duration: 3 * 1000,
            center: true,
            type: "success",
            customClass: "customclass",
          });
        }
        this.database = "`" + this.$store.state.project[0] + "`"; //将分割出的第一部分赋值给database
        this.table = "`" + this.$store.state.site + "_ffp" + "`"; //将分割出的第二部分赋值给table并且加上后缀
        let params = {
          database: this.database,
          table: this.table,
        };
        ffpnew(this.$qs.stringify(params)).then((res) => {
          this.timeDis = true;
          this.handleData(res);
        });
      } else {
        //查询数据
        if (this.requestTime == null) {
          if (this.Lflag == true) {
            this.$message({
              showClose: true,
              message: "请选择时间",
              duration: 2 * 1000,
              center: true,
              type: "error",
              customClass: "customclass",
            });
          } else {
            this.$message({
              showClose: true,
              message: "Please select a time",
              duration: 2 * 1000,
              center: true,
              type: "error",
              customClass: "customclass",
            });
          }
        } else {
          if (this.Lflag == true) {
            this.optionMin = this.$message({
              showClose: true,
              message: "查询中，请稍后",
              duration: 3 * 1000,
              center: true,
              type: "success",
              customClass: "customclass",
            });
          } else {
            this.optionMin = this.$message({
              showClose: true,
              message: "Inquiry, please wait",
              duration: 3 * 1000,
              center: true,
              type: "success",
              customClass: "customclass",
            });
          }
          this.database = "`" + this.$store.state.project[0] + "`"; //将分割出的第一部分赋值给database
          this.table = "`" + this.$store.state.site + "_ffp" + "`"; //将分割出的第二部分赋值给table并且加上后缀
          let params = {
            time: this.requestTime,
            database: this.database,
            table: this.table,
          };
          ffp(this.$qs.stringify(params))
            .then((res) => {
              this.timeDis = true;
              this.handleData(res);
              console.log("ffp的res=====", res);
            })
            .catch((err) => {
              console.log(err);
              console.log("ffp的error=====", res);
            });
        }
      }
      this.firstSelect = false;
      this.database = this.$store.state.project[0];
      this.table = this.$store.state.site;
      this.wrap = this.logLatConver(
        this.wrapLimit,
        this.wrapDivide,
        this.wrapSecond
      );
      // 纬度赋值
      this.weft = this.logLatConver(
        this.weftLimit,
        this.weftDivide,
        this.weftSecond
      );
    },

    // 获取数据后进行处理生成genJson
    handleData(res) {
      if (res.data.length != 0) {
        this.findFlag = true;
        if (this.optionMin) {
          this.optionMin.close();
        }
        if (this.Lflag == true) {
          this.$message({
            showClose: true,
            message: "已查询到数据",
            center: true,
            type: "success",
            customClass: "customclass",
          });
        } else {
          this.$message({
            showClose: true,
            message: "The data has been queried",
            center: true,
            type: "success",
            customClass: "customclass",
          });
        }
        let newArr = [];
        for (let i = 0; i < Object.keys(res.data[0]).length; i++) {
          let xArr = [];
          let yArr = [];
          for (let key in res.data[0]) {
            xArr.push(key);
          }
          for (let key in res.data[1]) {
            yArr.push(key);
          }
          xArr.sort();
          yArr.sort();
          newArr[i] = [res.data[0][xArr[i]], res.data[1][yArr[i]]];
        }
        let newArr1 = [];
        for (let a = 0; a < newArr.length; a++) {
          newArr1[a] = [];
          for (let b = 0; b < newArr[a][0].length; b++) {
            newArr1[a].push([newArr[a][0][b], newArr[a][1][b]]);
          }
        }
        let newArr2 = [];
        for (let c = 0; c < newArr1.length; c++) {
          newArr2[c] = [];
          newArr2[c].push(newArr1[c]);
        }
        this.xyArr = newArr2;
        // 经纬度转换
        // 经度赋值
        this.wrap = this.logLatConver(
          this.wrapLimit,
          this.wrapDivide,
          this.wrapSecond
        );
        // 纬度赋值
        this.weft = this.logLatConver(
          this.weftLimit,
          this.weftDivide,
          this.weftSecond
        );
        // 初始化地图
        this.initMap();
        // 添加图层
        this.initEcharts();
      } else {
        this.findFlag = false;
        if (this.optionMin) {
          this.optionMin.close();
        }
        if (this.Lflag == true) {
          this.$message({
            showClose: true,
            message: "数据为空",
            center: true,
            type: "error",
            customClass: "customclass",
          });
        } else {
          this.$message({
            showClose: true,
            message: "The data is empty",
            center: true,
            type: "error",
            customClass: "customclass",
          });
        }
      }
      const result = this.convertDecimalToDMS(this.longitude);
      this.wrapLimit = result.Limit;
      this.wrapDivide = result.Divide;
      this.wrapSecond = result.second;

      const result1 = this.convertDecimalToDMS(this.latitude);
      this.weftLimit = result1.Limit;
      this.weftDivide = result1.Divide;
      this.weftSecond = result1.second;
    },

    fetchData() {
      axios
        .get("http://121.40.95.239:8088")
        .then((response) => {
          // 处理成功的响应
        })
        .catch((error) => {
          this.error = true;
          console.error("Error fetching data:", error);
        });
    },

    // 将查询的数据转换为经纬度
    dataConvert() {
      this.coordinateArr = JSON.parse(JSON.stringify(this.xyArr));
      for (let i = 0; i < this.coordinateArr.length; i++) {
        for (let j = 0; j < this.coordinateArr[i][0].length; j++) {
          let x = JSON.parse(JSON.stringify(this.coordinateArr[i][0][j][0]));
          let y = JSON.parse(JSON.stringify(this.coordinateArr[i][0][j][1]));
          // 经度（东西方向）一米约等于 0.00001141度  纬度（南北方向）一米约等于0.00000899
          this.coordinateArr[i][0][j][0] = Number(x) * 0.00001141 + this.wrap;
          this.coordinateArr[i][0][j][1] = Number(y) * 0.00000899 + this.weft;
        }
      }
      // 生成GenJSON数据
      this.genJSON = [];
      for (let i = 0; i < this.coordinateArr.length; i++) {
        this.genJSON.push({
          type: "Polygon",
          coordinates: this.coordinateArr[i],
        });
      }
    },

    fuzhijing() {
      const totalDegrees = this.longitude;
      const dms = convertDecimalToDMS(totalDegrees);

      this.wrapLimit = dms.Limit;
      this.wrapDivide = dms.Divide;
      this.wrapSecond = dms.second;

      // 输出结果
      console.log("wrapLimit:", this.wrapLimit);
      console.log("wrapDivide:", this.wrapDivide);
      console.log("wrapSecond:", this.wrapSecond);
    },

    fuzhiwei() {
      const totalDegrees = this.latitude;
      const dms = convertDecimalToDMS(totalDegrees);

      this.weftLimit = dms.Limit;
      this.weftDivide = dms.Divide;
      this.weftSecond = dms.second;

      // 输出结果
      console.log("wrapLimit:", this.weftLimit);
      console.log("wrapDivide:", this.weftDivide);
      console.log("wrapSecond:", this.weftSecond);
    },

    //将输入的经纬度转换为度 分 秒
    convertDecimalToDMS(totalDegrees) {
      // 计算度
      const Limit = Math.floor(totalDegrees);

      // 计算剩余部分的分
      const remainingMinutes = (totalDegrees - Limit) * 60;
      const Divide = Math.floor(remainingMinutes);

      // 计算剩余部分的秒
      const remainingSeconds = (remainingMinutes - Divide) * 60;
      const second = remainingSeconds.toFixed(3); // 保留三位小数

      return {
        Limit: Limit, //du
        Divide: Divide, //fen
        second: second, //miao
      };
    },

    // 经纬度转换
    logLatConver(du, fen, miao) {
      let mFen = 0;
      if (miao != null && miao != "") {
        mFen = Number(miao / 60);
      }
      let fDu = 0;
      if (fen != null && fen != "") {
        fDu = (Number(fen) + mFen) / 60;
      } else {
        fDu = mFen;
      }
      let lDu = 0;
      if (du != null && du != "") {
        lDu = Number(du) + fDu;
      } else {
        lDu = fDu;
      }
      return lDu;
    },
    // 格式化时间
    parseTime(time, cFormat) {
      //时间格式转换
      if (arguments.length === 0 || !time) {
        return null;
      }
      const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
      let date;
      if (typeof time === "object") {
        date = time;
      } else {
        if (typeof time === "string") {
          if (/^[0-9]+$/.test(time)) {
            time = parseInt(time);
          } else {
            time = time.replace(new RegExp(/-/gm), "/");
          }
        }

        if (typeof time === "number" && time.toString().length === 10) {
          time = time * 1000;
        }
        date = new Date(time);
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
      };
      const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key];
        if (key === "a") {
          return ["日", "一", "二", "三", "四", "五", "六"][value];
        }
        return value.toString().padStart(2, "0");
      });
      return time_str;
    },

    // 下载按钮
    downloadbutton() {
      this.dialogVisible = true;
      this.dowloadRequestTime = this.requestTime;
      this.dataTime();
    },
    // 取消下载
    cancelDowload() {
      this.dialogVisible = false;
    },
    // 下载文件
    download() {
      if (this.requestTime == null) {
        if (this.Lflag == true) {
          this.$message({
            showClose: true,
            message: "请选择时间",
            duration: 2 * 1000,
            center: true,
            type: "error",
            customClass: "customclass",
          });
        } else {
          this.$message({
            showClose: true,
            message: "Please select a time",
            duration: 2 * 1000,
            center: true,
            type: "error",
            customClass: "customclass",
          });
        }
      } else {
        let params = {};
        this.database = "`" + this.projectArray[0] + "`"; //将分割出的第一部分赋值给database
        this.table = "`" + this.projectArray[1] + "_ffp" + "`"; //将分割出的第二部分赋值给table并且加上后缀
        // 是否更改过时间
        if (this.isChangeTime) {
          params = {
            time: this.dowloadRequestTime,
            database: this.database,
            table: this.table,
          };
          ffp(this.$qs.stringify(params)).then((res) => {
            this.handleDataDowload(res);
          });
        } else {
          params = {
            database: this.database,
            table: this.table,
          };
          ffpnew(this.$qs.stringify(params)).then((res) => {
            this.handleDataDowload(res);
          });
        }
      }
    },
    // 处理数据下载
    handleDataDowload(res) {
      if (res.data.length != 0) {
        this.findFlag = true;
        if (this.optionMin) {
          this.optionMin.close();
        }
        let newArr = [];
        for (let i = 0; i < Object.keys(res.data[0]).length; i++) {
          let xArr = [];
          let yArr = [];
          for (let key in res.data[0]) {
            xArr.push(key);
          }
          for (let key in res.data[1]) {
            yArr.push(key);
          }
          xArr.sort();
          yArr.sort();
          newArr[i] = [res.data[0][xArr[i]], res.data[1][yArr[i]]];
        }
        let newArr1 = [];
        for (let a = 0; a < newArr.length; a++) {
          newArr1[a] = [];
          for (let b = 0; b < newArr[a][0].length; b++) {
            newArr1[a].push([newArr[a][0][b], newArr[a][1][b]]);
          }
        }
        let newArr2 = [];
        for (let c = 0; c < newArr1.length; c++) {
          newArr2[c] = [];
          newArr2[c].push(newArr1[c]);
        }
        const jsonData = newArr2.join(";");
        let blob = new Blob([jsonData], { type: "application/json" });
        // 创建一个blob的对象
        if ("msSaveOrOpenBlob" in navigator) {
          // 这个判断要不要都行，如果是IE浏览器，使用的是这个，
          window.navigator.msSaveOrOpenBlob(blob, "results.txt");
        } else {
          // 不是IE浏览器使用的下面的
          let url = window.URL.createObjectURL(blob);
          // 上面这个是创建一个blob的对象连链接，
          let link = document.createElement("a");
          // 创建一个链接元素，是属于 a 标签的链接元素，所以括号里才是a，
          link.href = url;
          // 把上面获得的blob的对象链接赋值给新创建的这个 a 链接
          link.download = `${this.dowloadRequestTime}实时源区数据.txt`;
          // 后面的是文件名字，可以更改
          link.click();
          // 使用js点击这个链接
          URL.revokeObjectURL(url);
          // 当你结束使用某个 URL 对象之后，应该通过调用这个方法来让浏览器知道不用在内存中继续保留对这个文件的引用了。
          if (this.Lflag == true) {
            this.$message({
              showClose: true,
              message: "下载完成！",
              center: true,
              type: "success",
              customClass: "customclass",
            });
          } else {
            this.$message({
              showClose: true,
              message: "Download complete!",
              center: true,
              type: "success",
              customClass: "customclass",
            });
          }

          this.dialogVisible = false;
        }
      } else {
        this.findFlag = false;
        if (this.optionMin) {
          this.optionMin.close();
        }
        if (this.Lflag == true) {
          this.$message({
            showClose: true,
            message: "未读取到数据，请重试",
            center: true,
            type: "error",
            customClass: "customclass",
          });
        } else {
          this.$message({
            showClose: true,
            message: "No data was read, please try again",
            center: true,
            type: "error",
            customClass: "customclass",
          });
        }
      }
    },
    cleardata() {
      // 搜索条件的不可用状态解开
      this.timeDis = false;
      //清除页面上的图表
      if (document.getElementsByClassName("map")[0]) {
        document.getElementsByClassName("map")[0].innerHTML = "";
      }
    },
    // 初始化地图
    initMap() {
      console.log("地图初始化");
      // 导入高德地图
      this.map = new Map({
        target: "map", // 地图的dom
        layers: [
          // 卫星图
          new TileLayer({
            source: new XYZ({
              url: "http://wprd0{1-4}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=6&x={x}&y={y}&z={z}",
              // url: 'http://webst0{1-4}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=7&x={x}&y={y}&z={z}'
              // url: 'http://t4.tianditu.com/DataServer?T=vec_w&?tk=1b0b6d4fd05ff71dc47f1ec5971f9d83&x={x}&y={y}&l={z}',
            }),
          }),
          // 行政区标注地图
          new TileLayer({
            source: new XYZ({
              // url: 'http://wprd0{1-4}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=6&x={x}&y={y}&z={z}',
              url: "http://webst0{1-4}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}",
              // url: 'http://t4.tianditu.com/DataServer?T=vec_w&?tk=1b0b6d4fd05ff71dc47f1ec5971f9d83&x={x}&y={y}&l={z}',
            }),
          }),
        ],
        view: new View({
          center: transform([this.wrap, this.weft], "EPSG:4326", "EPSG:3857"), // 中心点
          zoom: 18, // 设置初始化时的地图缩放层级 20会显示为空白
          // zoom: 2,
          projection: "EPSG:3857", // 坐标系
        }),
        controls: defaultControls({
          // 清除地图上的控件
          attribution: false,
          rotate: false,
          zoom: false,
        }),
        interactions: defaults({
          mouseWheelZoom: false,
          doubleClickZoom: false,
          shiftDragZoom: false,
          dragPan: false,
        }),
      });
    },
    // 搜索数据绘图-选定时间
    changeTime(value) {
      this.isChangeTime = true;
      // 分割时间
      let time = value.split(" ");
      let mm = time[1].split(":");
      // 判断是否超过30
      if (mm[1] < 30) {
        this.requestTime = time[0] + " " + mm[0] + ":" + "00";
      } else {
        this.requestTime = time[0] + " " + mm[0] + ":" + "30";
      }
    },
    // 改变时间
    changeDowloadTime(value) {
      this.isChangeTime = true;
      // 分割时间
      let time = value.split(" ");
      let mm = time[1].split(":");
      // 判断是否超过30
      if (mm[1] < 30) {
        this.dowloadRequestTime = time[0] + " " + mm[0] + ":" + "00";
      } else {
        this.dowloadRequestTime = time[0] + " " + mm[0] + ":" + "30";
      }
    },
  },
};
</script>

<style>
.date-picker .el-date-table .badge::after {
  position: absolute;
  content: "";
  right: 0.2151vw;
  top: 0.2151vw;
  width: 0.2688vw;
  height: 0.2688vw;
  border-radius: 100%;
  background-color: red;
}
.date-picker .el-time-spinner {
  text-align: center;
}
/* 日期选择器的分钟选择列表隐藏 */
/* .date-picker .el-scrollbar:nth-of-type(2) {
    display: none !important;
  }*/
/* 日期时间选择器的'此刻'按钮隐藏 */
.date-picker .el-button--text.el-picker-panel__link-btn {
  display: none;
}
.v69_727 {
  color: rgba(25, 44, 250, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Bold;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}

.v69_729 {
  color: rgba(0, 0, 0, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}
.canvas {
  width: 96.2%;
  height: 65%;
  overflow: auto;
  position: relative;
  bottom: 0.625em;
}
#map {
  width: 96.2%;
  height: 65%;
  overflow: auto;
  position: relative;
  bottom: 0.625em;
}
</style>
