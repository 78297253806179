<template>
  <div style="width: 100%; height: 100%">
    <div
      style="
        width: 0.5376vw;
        height: 100%;
        display: flex;
        float: left;
        background: rgba(240, 240, 240, 1);
      "
    ></div>
    <div style="width: 6%; height: 100%; float: left"></div>
    <div style="width: 93%; height: 100%; float: left">
      <div style="width: 100%; height: 4.5%"></div>
      <div
        style="width: 100%; height: 5.5%; align-items: center; display: flex"
      >
        <div
          style="
            width: 20%;
            height: 100%;
            float: left;
            align-items: center;
            display: flex;
          "
          class="v41_161"
        >
          <span v-if="Lflag == true" class="v35_1047" style="width: 15%"
            >数据展示</span
          >
          <span v-if="Lflag == false" class="v35_1047" style="width: 15%"
            >Data display</span
          >
        </div>
        <div
          style="
            width: 60%;
            height: 100%;
            float: left;
            align-items: center;
            display: flex;
          "
        ></div>
        <div
          style="
            width: 20%;
            height: 100%;
            float: right;
            align-items: center;
            justify-content: left;
            display: flex;
          "
          class="v41_161"
        >
          <span
            v-if="Lflag == true"
            class="v41_161"
            style="
              height: 0.8602vh;
              font-size: 0.8602vw;
              align-items: center;
              display: flex;
              justify-content: left;
            "
            >当前数据：{{ this.$store.state.eco_value }}
          </span>
          <span
            v-if="Lflag == false"
            class="v41_161"
            style="
              height: 0.8602vh;
              font-size: 0.8602vw;
              align-items: center;
              display: flex;
              justify-content: left;
            "
            >Data：{{ this.$store.state.eco_value }}
          </span>
        </div>
      </div>
      <div style="width: 97%; height: 2vh"></div>
      <div style="width: 97%; height: 10%">
        <div style="width: 75vw; height: 50%; position: relative; float: left">
          <el-drawer
            v-if="Lflag == true"
            title="设备详细信息"
            style="font-size: 1.0753vw"
            :visible.sync="drawer"
            :direction="direction"
            size="20%"
            :modal="false"
          >
            <div style="width: 100%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >时间：{{ this.time }}
              </span>
            </div>
            <div style="width: 52%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >经度：{{ this.longitude.toFixed(6) }}
              </span>
            </div>
            <div style="width: 48%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >纬度：{{ this.latitude.toFixed(6) }}
              </span>
            </div>
            <div style="width: 100%; height: 5%; float: left"></div>
            <div style="width: 50%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >CH4
              </span>
            </div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >OSS：{{ this.oss_ch4 }}%
              </span>
            </div>

            <div style="width: 100%; height: 5%; float: left; padding-left: 4%">
              <el-tooltip placement="right" effect="light">
                <div slot="content" style="font-size: 0.9677vw">
                  报警信息为：<br />1：LD一级温度异常<br />2：MCT一级温度异常<br />3：实际电流超过实际限制电流<br />4：******预留报警位*****<br />5：LD一级测温断开<br />6：LD二级测温断开<br />7：MCT一级测温断开<br />8：MCT二级测温断开<br />9：******预留报警位******<br />10：******预留报警位******
                  <br />11：循环水温过高<br />12：测循环水温芯片断连<br />13：内部温度过高<br />14：内部湿度过高<br />15：测内部温湿度芯片断连<br />16：外接NTC断开<br />17：******预留报警位******<br />18：下镜片测温芯片通信失败<br />19：信号强度过低<br />20：浓度数据无效<br />21：LD二级温控异常
                  <br />22：MCT二级温控异常<br />23：未检测到激光器电流<br />24：参考池气体浓度过低<br />25：低功耗模式开启<br />26：******预留报警位******<br />27：设置电流有误<br />28：限制电流有误<br />29：供电电压异常<br />30：激光器&TEC参数没有设置完成<br />31：传感器检测有雨<br />32：水箱水位过低
                </div>
                <span
                  class="v41_161"
                  style="
                    float: left;
                    padding-top: 1.8817vh;
                    font-size: 0.9677vw;
                  "
                  >报警信息：{{ this.ch4_alarm }}
                </span>
              </el-tooltip>
            </div>
            <div style="width: 100%; height: 5%; float: left"></div>
            <div style="width: 50%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >N2O
              </span>
            </div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >OSS：{{ this.oss_n2o }}%
              </span>
            </div>
            <div style="width: 100%; height: 5%; float: left; padding-left: 4%">
              <el-tooltip placement="right" effect="light">
                <div slot="content" style="font-size: 0.9677vw">
                  报警信息为：<br />1：LD一级温度异常<br />2：MCT一级温度异常<br />3：实际电流超过实际限制电流<br />4：******预留报警位*****<br />5：LD一级测温断开<br />6：LD二级测温断开<br />7：MCT一级测温断开<br />8：MCT二级测温断开<br />9：******预留报警位******<br />10：******预留报警位******
                  <br />11：循环水温过高<br />12：测循环水温芯片断连<br />13：内部温度过高<br />14：内部湿度过高<br />15：测内部温湿度芯片断连<br />16：外接NTC断开<br />17：******预留报警位******<br />18：下镜片测温芯片通信失败<br />19：信号强度过低<br />20：浓度数据无效<br />21：LD二级温控异常
                  <br />22：MCT二级温控异常<br />23：未检测到激光器电流<br />24：参考池气体浓度过低<br />25：低功耗模式开启<br />26：******预留报警位******<br />27：设置电流有误<br />28：限制电流有误<br />29：供电电压异常<br />30：激光器&TEC参数没有设置完成<br />31：传感器检测有雨<br />32：水箱水位过低
                </div>
                <span
                  class="v41_161"
                  style="
                    float: left;
                    padding-top: 1.8817vh;
                    font-size: 0.9677vw;
                  "
                  >报警信息：{{ this.n2o_alarm }}
                </span>
              </el-tooltip>
            </div>

            <!-- <div style="width: 100%; height: 5%; float: left"></div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >N2O
              </span>
            </div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >OSS：{{ this.oss_n2o }}
              </span>
            </div>
            <div style="width: 100%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >报警信息：
              </span>
            </div>
            <div style="width: 100%; height: 5%; float: left"></div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >CO2
              </span>
            </div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >OSS：{{ this.oss_co2 }}
              </span>
            </div>
            <div style="width: 100%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >报警信息：
              </span>
            </div> -->
            <div style="width: 100%; height: 5%; float: left"></div>
            <div style="width: 50%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >NH3
              </span>
            </div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >OSS：{{ this.oss_nh3 }}%
              </span>
            </div>
            <div style="width: 100%; height: 5%; float: left; padding-left: 4%">
              <el-tooltip placement="right" effect="light">
                <div slot="content" style="font-size: 0.9677vw">
                  报警信息为：<br />1：LD一级温度异常<br />2：MCT一级温度异常<br />3：实际电流超过实际限制电流<br />4：******预留报警位*****<br />5：LD一级测温断开<br />6：LD二级测温断开<br />7：MCT一级测温断开<br />8：MCT二级测温断开<br />9：******预留报警位******<br />10：******预留报警位******
                  <br />11：循环水温过高<br />12：测循环水温芯片断连<br />13：内部温度过高<br />14：内部湿度过高<br />15：测内部温湿度芯片断连<br />16：外接NTC断开<br />17：******预留报警位******<br />18：下镜片测温芯片通信失败<br />19：信号强度过低<br />20：浓度数据无效<br />21：LD二级温控异常
                  <br />22：MCT二级温控异常<br />23：未检测到激光器电流<br />24：参考池气体浓度过低<br />25：低功耗模式开启<br />26：******预留报警位******<br />27：设置电流有误<br />28：限制电流有误<br />29：供电电压异常<br />30：激光器&TEC参数没有设置完成<br />31：传感器检测有雨<br />32：水箱水位过低
                </div>
                <span
                  class="v41_161"
                  style="
                    float: left;
                    padding-top: 1.8817vh;
                    font-size: 0.9677vw;
                  "
                  >报警信息：{{ this.nh3_alarm }}
                </span>
              </el-tooltip>
            </div>
          </el-drawer>
          <el-drawer
            v-if="Lflag == false"
            title="Device details"
            style="font-size: 0.9677vw"
            :visible.sync="drawer"
            :direction="direction"
            size="20%"
            :modal="false"
          >
            <div style="width: 100%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >Time：{{ this.time }}
              </span>
            </div>
            <div style="width: 52%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >LON：{{ this.longitude.toFixed(6) }}
              </span>
            </div>
            <div style="width: 48%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >LAT：{{ this.latitude.toFixed(6) }}
              </span>
            </div>
            <div style="width: 100%; height: 5%; float: left"></div>
            <div style="width: 50%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >CH4
              </span>
            </div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >OSS：{{ this.oss_ch4 }}%
              </span>
            </div>

            <div style="width: 100%; height: 5%; float: left; padding-left: 4%">
              <el-tooltip
                placement="right"
                effect="light"
                v-if="Lflag == false"
              >
                <div slot="content" style="font-size: 0.9677vw">
                  The alarm message is：<br />1：LD primary temperature is
                  abnormal<br />2：MCT primary temperature is abnormal<br />3：Actual
                  current exceeds the actual limit current<br />4：******Reserve
                  Alarm Position******<br />5：LD primary temperature
                  measurement disconnected<br />6：LD secondary temperature
                  measurement disconnected<br />7：MCT primary temperature
                  measurement disconnected<br />8：MCT secondary temperature
                  measurement disconnected<br />9：******Reserve Alarm
                  Position******<br />10：******Reserve Alarm Position******
                  <br />11：Circulating water temperature out of range<br />12：he
                  chip used to measure the temperature of circulating water is
                  disconnected<br />13：nternal temperature out of range<br />14：Internal
                  humidity out of range<br />15：The chip used to measure the
                  internal humidity is disconnected<br />16：External NTC
                  disconnected <br />17：******Reserve Alarm Position******<br />18：Communication
                  failure of temperature measuring chip on lower mirror<br />19：Weak
                  signal strength<br />20：Concentration data is invalid<br />21：LD
                  secondary temperature control anomaly <br />22：MCT secondary
                  temperature control anomaly<br />23：No laser current
                  detected<br />24：Low gas concentration in reference pool<br />25：Power
                  saving mode on<br />26：******Reserve Alarm Position******<br />27：Error
                  setting current<br />28：Error limiting current<br />29：Abnormal
                  supply voltage<br />30：The laser & TEC parameters not set
                  complete<br />31：The sensor detects rain<br />32：Water level
                  is too low
                </div>
                <span
                  class="v41_161"
                  style="
                    float: left;
                    padding-top: 1.8817vh;
                    font-size: 1.0753vw;
                  "
                  >The alarm message：{{ this.ch4_alarm }}
                </span>
              </el-tooltip>
            </div>
            <div style="width: 100%; height: 5%; float: left"></div>
            <div style="width: 50%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >N2O
              </span>
            </div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >OSS：{{ this.oss_n2o }}%
              </span>
            </div>

            <div style="width: 100%; height: 5%; float: left; padding-left: 4%">
              <el-tooltip
                placement="right"
                effect="light"
                v-if="Lflag == false"
              >
                <div slot="content" style="font-size: 0.9677vw">
                  The alarm message is：<br />1：LD primary temperature is
                  abnormal<br />2：MCT primary temperature is abnormal<br />3：Actual
                  current exceeds the actual limit current<br />4：******Reserve
                  Alarm Position******<br />5：LD primary temperature
                  measurement disconnected<br />6：LD secondary temperature
                  measurement disconnected<br />7：MCT primary temperature
                  measurement disconnected<br />8：MCT secondary temperature
                  measurement disconnected<br />9：******Reserve Alarm
                  Position******<br />10：******Reserve Alarm Position******
                  <br />11：Circulating water temperature out of range<br />12：he
                  chip used to measure the temperature of circulating water is
                  disconnected<br />13：nternal temperature out of range<br />14：Internal
                  humidity out of range<br />15：The chip used to measure the
                  internal humidity is disconnected<br />16：External NTC
                  disconnected <br />17：******Reserve Alarm Position******<br />18：Communication
                  failure of temperature measuring chip on lower mirror<br />19：Weak
                  signal strength<br />20：Concentration data is invalid<br />21：LD
                  secondary temperature control anomaly <br />22：MCT secondary
                  temperature control anomaly<br />23：No laser current
                  detected<br />24：Low gas concentration in reference pool<br />25：Power
                  saving mode on<br />26：******Reserve Alarm Position******<br />27：Error
                  setting current<br />28：Error limiting current<br />29：Abnormal
                  supply voltage<br />30：The laser & TEC parameters not set
                  complete<br />31：The sensor detects rain<br />32：Water level
                  is too low
                </div>
                <span
                  class="v41_161"
                  style="
                    float: left;
                    padding-top: 1.8817vh;
                    font-size: 1.0753vw;
                  "
                  >The alarm message：{{ this.n2o_alarm }}
                </span>
              </el-tooltip>
            </div>

            <!-- <div style="width: 100%; height: 5%; float: left"></div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >N2O
              </span>
            </div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >OSS：{{ this.oss_n2o }}
              </span>
            </div>
            <div style="width: 100%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >报警信息：
              </span>
            </div>
            <div style="width: 100%; height: 5%; float: left"></div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >CO2
              </span>
            </div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >OSS：{{ this.oss_co2 }}
              </span>
            </div>
            <div style="width: 100%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >报警信息：
              </span>
            </div> -->
            <div style="width: 100%; height: 5%; float: left"></div>
            <div style="width: 50%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >NH3
              </span>
            </div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >OSS：{{ this.oss_nh3 }}%
              </span>
            </div>
            <div style="width: 100%; height: 5%; float: left; padding-left: 4%">
              <el-tooltip
                placement="right"
                effect="light"
                v-if="Lflag == false"
              >
                <div slot="content" style="font-size: 0.9677vw">
                  The alarm message is：<br />1：LD primary temperature is
                  abnormal<br />2：MCT primary temperature is abnormal<br />3：Actual
                  current exceeds the actual limit current<br />4：******Reserve
                  Alarm Position******<br />5：LD primary temperature
                  measurement disconnected<br />6：LD secondary temperature
                  measurement disconnected<br />7：MCT primary temperature
                  measurement disconnected<br />8：MCT secondary temperature
                  measurement disconnected<br />9：******Reserve Alarm
                  Position******<br />10：******Reserve Alarm Position******
                  <br />11：Circulating water temperature out of range<br />12：he
                  chip used to measure the temperature of circulating water is
                  disconnected<br />13：nternal temperature out of range<br />14：Internal
                  humidity out of range<br />15：The chip used to measure the
                  internal humidity is disconnected<br />16：External NTC
                  disconnected <br />17：******Reserve Alarm Position******<br />18：Communication
                  failure of temperature measuring chip on lower mirror<br />19：Weak
                  signal strength<br />20：Concentration data is invalid<br />21：LD
                  secondary temperature control anomaly <br />22：MCT secondary
                  temperature control anomaly<br />23：No laser current
                  detected<br />24：Low gas concentration in reference pool<br />25：Power
                  saving mode on<br />26：******Reserve Alarm Position******<br />27：Error
                  setting current<br />28：Error limiting current<br />29：Abnormal
                  supply voltage<br />30：The laser & TEC parameters not set
                  complete<br />31：The sensor detects rain<br />32：Water level
                  is too low
                </div>
                <span
                  class="v41_161"
                  style="
                    float: left;
                    padding-top: 1.8817vh;
                    font-size: 0.9677vw;
                  "
                  >The alarm message：{{ this.nh3_alarm }}
                </span>
              </el-tooltip>
            </div>
          </el-drawer>

          <div
            style="
              width: 13%;
              height: 100%;
              float: left;
              display: flex;
              align-items: center;
            "
          >
            <span
              class="v41_161"
              style="float: left; font-size: 1.1828vw"
              v-if="Lflag == true"
              >温度：{{ this.temperature }}℃
            </span>
            <span
              class="v41_161"
              style="float: left; font-size: 1.1828vw"
              v-if="Lflag == false"
              >Temp：{{ this.temperature }}℃
            </span>
          </div>
          <div
            style="
              width: 15%;
              height: 100%;
              float: left;
              display: flex;
              align-items: center;
            "
          >
            <span
              class="v41_161"
              style="font-size: 1.1828vw"
              v-if="Lflag == true"
              >气压：{{ this.pressure }}hpa
            </span>
            <span
              class="v41_161"
              style="font-size: 1.1828vw"
              v-if="Lflag == false"
              >Pressure：{{ this.pressure }}hpa
            </span>
          </div>
          <div
            style="
              width: 20%;
              height: 100%;
              float: left;
              display: flex;
              align-items: center;
            "
          >
            <span
              class="v41_161"
              style="font-size: 1.1828vw"
              v-if="Lflag == true"
              >风向风速: {{ this.wind }}° {{ this.windspeed }}m/s
            </span>
            <span
              class="v41_161"
              style="font-size: 1.1828vw"
              v-if="Lflag == false"
              >WD&WS: {{ this.wind }}° {{ this.windspeed }}m/s
            </span>
          </div>

          <div
            style="
              width: 13%;
              height: 100%;
              display: flex;
              float: right;
              justify-content: right;
              align-items: center;
            "
          >
            <el-button
              v-if="Lflag == true"
              @click="drawer = true"
              type="button"
              style="
                width: 90%;
                height: 80%;
                background-color: rgba(25, 44, 250, 1);
                padding-left: 1.801vw;
                padding-top: 1.0376vh;
                align-items: center;
                display: flex;
                border-radius: 0.2688vw;
                color: #fff;
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
              "
            >
              查看设备信息
            </el-button>
            <el-button
              v-if="Lflag == false"
              @click="drawer = true"
              type="button"
              style="
                width: 90%;
                height: 80%;
                background-color: rgba(25, 44, 250, 1);
                padding-left: 1.801vw;
                padding-top: 1.0376vh;
                align-items: center;
                display: flex;
                border-radius: 0.2688vw;
                color: #fff;
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
              "
            >
              View device
            </el-button>
          </div>
          <div
            style="
              width: 13%;
              height: 100%;
              display: flex;
              float: right;
              justify-content: right;
              align-items: center;
            "
          >
            <el-button
              v-if="Lflag == true"
              @click="
                centerDialogVisible = true;
                key += 1;
              "
              type="button"
              style="
                width: 90%;
                height: 80%;
                background-color: rgba(25, 44, 250, 1);
                padding-left: 0.95vw;
                padding-top: 1.0376vh;
                align-items: center;
                display: flex;
                border-radius: 0.2688vw;
                color: #fff;
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              结束并标记本次走航
            </el-button>
            <el-button
              v-if="Lflag == false"
              @click="
                centerDialogVisible = true;
                key += 1;
              "
              type="button"
              style="
                width: 90%;
                height: 80%;
                background-color: rgba(25, 44, 250, 1);
                padding-left: 0.95vw;
                padding-top: 1.0376vh;
                align-items: center;
                display: flex;
                border-radius: 0.2688vw;
                color: #fff;
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              End&Mark
            </el-button>
          </div>
          <div
            style="
              width: 13%;
              height: 100%;
              display: flex;
              float: right;
              justify-content: right;
              align-items: center;
            "
          >
            <el-button
              v-if="Lflag == true"
              @click="his_click()"
              type="button"
              style="
                width: 90%;
                height: 80%;
                background-color: rgba(25, 44, 250, 1);
                padding-left: 0.8501vw;
                padding-top: 1.0376vh;
                align-items: center;
                display: flex;
                border-radius: 0.2688vw;
                color: #fff;
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              数据来源
            </el-button>
            <el-button
              v-if="Lflag == false"
              @click="his_click()"
              type="button"
              style="
                width: 90%;
                height: 80%;
                background-color: rgba(25, 44, 250, 1);
                padding-left: 0.8501vw;
                padding-top: 1.0376vh;
                align-items: center;
                display: flex;
                border-radius: 0.2688vw;
                color: #fff;
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              Data source
            </el-button>
          </div>
          <div
            style="
              width: 13%;
              height: 100%;
              display: flex;
              float: right;
              justify-content: right;
              align-items: center;
            "
          >
            <el-button
              v-if="Lflag == true"
              @click="setfilename()"
              type="button"
              style="
                width: 90%;
                height: 80%;
                background-color: rgba(25, 44, 250, 1);
                padding-left: 0.8501vw;
                padding-top: 1.0376vh;
                align-items: center;
                display: flex;
                border-radius: 0.2688vw;
                color: #fff;
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              数据导出
            </el-button>
            <el-button
              v-if="Lflag == false"
              @click="exportflag = true"
              type="button"
              style="
                width: 90%;
                height: 80%;
                background-color: rgba(25, 44, 250, 1);
                padding-left: 0.8501vw;
                padding-top: 1.0376vh;
                align-items: center;
                display: flex;
                border-radius: 0.2688vw;
                color: #fff;
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              Data export
            </el-button>
          </div>
        </div>
        <el-dialog
          v-if="Lflag == true"
          title="导出历史数据"
          :visible.sync="exportflag"
          @close="exportflag = false"
        >
          <div
            style="
              width: 60%;
              float: left;
              display: flex;
              justify-content: left;
              align-items: center;
              font-size: 1.0753vw;
            "
          >
            文件命名：
            <el-input style="width: 60%" v-model="filename"></el-input>
          </div>
          <div style="width: 100%; height: 5vh"></div>
          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="exportTableToExcel(exportTable, indexList)"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >确 定</el-button
            >
            <el-button
              @click="exportflag = false"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >取 消</el-button
            >
          </div>
        </el-dialog>
        <el-dialog
          v-if="Lflag == false"
          title="Export historical data"
          :visible.sync="exportflag"
          @close="exportflag = false"
        >
          <div
            style="
              width: 60%;
              float: left;
              display: flex;
              justify-content: left;
              align-items: center;
              font-size: 1.0753vw;
            "
          >
            File naming：
            <el-input style="width: 60%" v-model="filename"></el-input>
          </div>
          <div style="width: 100%; height: 5vh"></div>
          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="exportTableToExcel(exportTable, indexList)"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >Ok</el-button
            >
            <el-button
              @click="exportflag = false"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >Cancel</el-button
            >
          </div>
        </el-dialog>
        <el-dialog
          v-if="Lflag == true"
          title="结束本次走航并命名"
          :visible.sync="centerDialogVisible"
          @close="centerDialogVisible = false"
        >
          <div
            style="
              width: 45%;
              float: left;
              display: flex;
              justify-content: left;
              align-items: center;
              font-size: 1.0753vw;
            "
          >
            走航名称： <el-input style="width: 60%" v-model="name"></el-input>
          </div>
          <div class="block" style="width: 50%; float: left">
            <el-date-picker
              v-model="DataTime"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期 "
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00']"
              style="width: 100%"
              popper-class="picker-popper"
              :key="key"
            >
            </el-date-picker>
          </div>
          <div style="width: 100%; height: 5vh"></div>
          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="resetmap"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >确 定</el-button
            >
            <el-button
              @click="centerDialogVisible = false"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >取 消</el-button
            >
          </div>
        </el-dialog>
        <el-dialog
          v-if="Lflag == false"
          title="End the trip and name it"
          :visible.sync="centerDialogVisible"
          @close="centerDialogVisible = false"
        >
          <div
            style="
              width: 45%;
              float: left;
              display: flex;
              justify-content: left;
              align-items: center;
              font-size: 1.0753vw;
            "
          >
            Name： <el-input style="width: 60%" v-model="name"></el-input>
          </div>
          <div class="block" style="width: 50%; float: left">
            <el-date-picker
              v-model="DataTime"
              type="datetimerange"
              start-placeholder="Start date"
              end-placeholder="End date "
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00']"
              style="width: 100%"
              popper-class="picker-popper"
              :key="key"
            >
            </el-date-picker>
          </div>
          <div style="width: 100%; height: 5vh"></div>
          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="resetmap"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
            >
              OK</el-button
            >
            <el-button
              @click="centerDialogVisible = false"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >Cancel</el-button
            >
          </div>
        </el-dialog>
        <el-dialog
          v-if="Lflag == true"
          title="选择数据来源"
          :visible.sync="DataHis_flag"
          @close="DataHis_flag = false"
        >
          <div
            style="
              width: 45%;
              float: left;
              display: flex;
              justify-content: left;
              align-items: center;
              font-size: 1.0753vw;
            "
          >
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.name"
                :label="item.name"
                :value="item.name"
                @click.native="menuChange(item)"
              >
              </el-option>
            </el-select>
          </div>
          <div class="block" style="width: 50%; float: left">
            <el-date-picker
              v-model="HisTime"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期 "
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00']"
              style="width: 100%"
              popper-class="picker-popper"
              :key="key"
            >
            </el-date-picker>
          </div>
          <div style="width: 100%; height: 6vh"></div>
          <div style="width: 50%; height: 5vh; float: left"></div>
          <div style="width: 50%; height: 5vh; float: right">
            <el-button type="text" @click="delete_his(false)"
              ><span class="v41_161" style="font-size: 0.9677vw"
                >删除当前记录</span
              >
            </el-button>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="hisData"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >确 定</el-button
            >
            <el-button
              @click="DataHis_flag = false"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >取 消</el-button
            >
          </div>
        </el-dialog>
        <el-dialog
          title="Select a data source"
          v-if="Lflag == false"
          :visible.sync="DataHis_flag"
          @close="DataHis_flag = false"
        >
          <div
            style="
              width: 45%;
              float: left;
              display: flex;
              justify-content: left;
              align-items: center;
              font-size: 1.0753vw;
            "
          >
            <el-select v-model="value" placeholder="Please select">
              <el-option
                v-for="item in options"
                :key="item.name"
                :label="item.name"
                :value="item.name"
                @click.native="menuChange(item)"
              >
              </el-option>
            </el-select>
          </div>
          <div class="block" style="width: 50%; float: left">
            <el-date-picker
              v-model="HisTime"
              type="datetimerange"
              start-placeholder="Start date"
              end-placeholder="End date "
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00']"
              style="width: 100%"
              popper-class="picker-popper"
              :key="key"
            >
            </el-date-picker>
          </div>
          <div style="width: 100%; height: 5vh"></div>
          <div style="width: 50%; height: 5vh; float: left"></div>
          <div style="width: 50%; height: 5vh; float: right">
            <el-button type="text" @click="delete_his(false)"
              ><span class="v41_161" style="font-size: 0.9677vw"
                >Deletes the record</span
              >
            </el-button>
          </div>

          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="hisData"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >OK</el-button
            >
            <el-button
              @click="DataHis_flag = false"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >Cancel</el-button
            >
          </div>
        </el-dialog>

        <el-dialog
          v-if="Lflag == true"
          title="删除走航记录"
          :visible.sync="delete_flag"
          @close="delete_flag = false"
        >
          <div
            style="
              width: 100%;
              color: red;
              font-size: 1.0753vw;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            确定要删除&nbsp;&nbsp;"{{ this.value }}"&nbsp;&nbsp;此条记录吗？
          </div>
          <div style="width: 100%; height: 5vh"></div>
          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="delete_his(true)"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >确 定</el-button
            >
            <el-button
              @click="delete_flag = false"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >取 消</el-button
            >
          </div>
        </el-dialog>
        <el-dialog
          v-if="Lflag == false"
          title="Delete the record"
          :visible.sync="delete_flag"
          @close="delete_flag = false"
        >
          <div
            style="
              width: 100%;
              color: red;
              font-size: 1.0753vw;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            Do you want to delete the &nbsp;&nbsp;"{{
              this.value
            }}"&nbsp;&nbsp;record?
          </div>
          <div style="width: 100%; height: 6vh"></div>
          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="delete_his(true)"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >OK</el-button
            >
            <el-button
              @click="delete_flag = false"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >Cancel</el-button
            >
          </div>
        </el-dialog>
        <div
          style="
            width: 34.9462vw;
            height: 50%;
            position: relative;
            float: left;
            display: flex;
            align-items: center;
            justify-content: right;
          "
        ></div>
      </div>
      <div style="width: 97%; height: 1vh"></div>
      <div style="width: 97%; height: 75%; overflow-x: auto">
        <div id="list1" style="width: 97%; height: 50%"></div>
        <div id="list4" style="width: 97%; height: 50%"></div>
        <div id="list2" style="width: 97%; height: 50%"></div>
        <div id="list3" style="width: 97%; height: 50%"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkToken } from "@/api/user"; //将checkToken方法从/api/user导入，作用是发送网络请求
import { askecomap } from "@/api/data";
import { askECOdata } from "@/api/data";
import * as echarts from "echarts";
import { askhistory_by_end_time } from "@/api/data";
import { select_data_by_time } from "@/api/data";
import { get_time_lag } from "@/api/data";
import { setstart } from "@/api/data";
import { setend } from "@/api/data";
import { delete_timelag } from "@/api/data";
export default {
  data() {
    return {
      username: "", //用于接收从state里传过来的用户名
      project: "", //用于接收从state里传过来的项目名
      Lflag: true,
      concentration1: "", //CH4浓度
      concentration2: "", //N2O浓度
      concentration3: "", //浓度3
      concentration4: "", //浓度4
      concentration5: "", //浓度5
      concentrationList1: [], //CH4浓度数组
      concentrationList2: [], //N2O浓度数组
      concentrationList3: [], //浓度3
      concentrationList4: [], //浓度4
      concentrationList5: [], //浓度5
      drive_direction: "", //行驶方向
      car_speed: "", //车速
      time: "", //时间
      windspeed: "", //风速
      wind: "", //风向
      longitude: 0, //经度
      latitude: 0, //纬度
      temperature: "", //温度
      pressure: "", //气压
      indexList: [], //表头数组
      site: "",
      lable1: "CH4_ppb",
      lable2: "N2O_ppb",
      lable3: "CO2_ppm",
      lable4: "NH3_ppb",
      refreshflag: false, //是否索要实时数据的标志位
      drawer: false, //设备信息标志位
      direction: "ltr", //设备信息弹出方向
      oss_ch4: "",
      ch4_alarm: "",
      oss_n2o: "",
      n2o_alarm: "",
      oss_co2: "",
      oss_nh3: "",
      nh3_alarm: "",
      centerDialogVisible: false, //重新绘图界面标志位
      start_time: "", //重新绘图开始时间
      name: "", //走航名称
      DataTime: [], //走航数据时间范围
      key: 0,
      HisTime: [], //历史数据时间范围
      start_flag: false, //用于记录起始时间
      DataHis_flag: false, //查看历史数据弹框
      options: [],
      value: null,
      optionMin: "",
      myChart1: "", //图表
      listdata1: [], //图表数据数组
      myChart2: "",
      listdata2: [],
      myChart3: "",
      listdata3: [],
      myChart4: "",
      listdata4: [],
      delete_flag: false, //删除确认界面
      exportTable: [], //用于数据导出的数组
      exportflag: false, //数据导出弹窗
      filename: "", //文件名称
    };
  },
  created() {
    //created里的指令在该组件创建的时候执行，created比mounted更早执行
    //const selectedProjectId = this.$store.state.projectId; //将项目选择界面所选择的项目id传给selectedProjectId，用于发送网络请求获取对应项目的信息
    //console.log(selectedProjectId); //打印，用于测试
    this.Lflag = this.$store.state.Language; //store里的传到该组件
    checkToken(this.$qs.stringify({ token: this.$store.state.token })).then(
      (res) => {
        //发送请求：让后端检查token，后端返回的状态在request的response interceptor里拦截并作出相应的响应
        //console.log(res);
      }
    );
    this.$store.commit("restoreproject"); //将保存在本地的项目名传给state
    this.project = this.$store.state.project; //将state里的project赋值给组件里的project
    this.table = "`" + this.$store.state.site + "_map" + "`"; //将分割出的第二部分赋值给table并且加上后缀
    this.database = this.project[0];
    console.log(this.database);
    console.log(this.table);
    this.site = this.$store.state.site + "_dat";
    askecomap(
      this.$qs.stringify({ database: this.database, table: this.table })
    ).then((res) => {
      console.log(res);
      this.indexList = res.data;
    });
    get_time_lag(this.$qs.stringify({ site: this.$store.state.site })).then(
      (res) => {
        this.options = res.data;
        this.options.unshift({ name: "实时数据", start: "", end: "" });
        console.log(this.options);
        var text = "数据加载中";
        if (this.Lflag == false) {
          text = "The data is loading";
        }
        this.optionMin = this.$message({
          showClose: true,
          message: text,
          duration: 30 * 1000,
          center: true,
          type: "success",
          customClass: "customclass",
        });
        console.log(this.$store.state.eco_value);
        if (
          this.$store.state.eco_value == null ||
          this.$store.state.eco_value == "实时数据"
        ) {
          askhistory_by_end_time(
            this.$qs.stringify({ site: this.$store.state.site })
          ).then((res) => {
            console.log(res.data);
            this.exportTable = res.data;
            if (
              res.data.length > 1 ||
              this.$store.state.eco_value == "实时数据"
            ) {
              if (this.$store.state.eco_value == "实时数据") {
                this.value = this.$store.state.eco_value;
              }
              this.$store.commit("seteco_value", "实时数据");
              this.value = this.options[this.options.length - 1].name;
              this.HisTime[0] = this.options[this.options.length - 1].start;
              this.HisTime[1] = this.options[this.options.length - 1].end;
              for (let i = 5; i <= res.data.length - 1; i++) {
                this.Datahandle(res.data[i]);
                if (i == 5) {
                  this.DataTime[0] = this.time;
                  this.start_flag = true;
                  console.log(this.time);
                } else if (i == res.data.length - 1) {
                  this.DataTime[1] = this.time;
                }
              }
              this.draw_list(1);
              this.draw_list(2);
              this.draw_list(3);
              this.draw_list(4);
              if (this.optionMin) {
                this.optionMin.close();
              }
              this.refreshflag = true;
            } else {
              this.value = this.options[this.options.length - 1].name;
              this.HisTime[0] = this.options[this.options.length - 1].start;
              this.HisTime[1] = this.options[this.options.length - 1].end;
              this.$store.commit("seteco_value", this.value);
              this.$store.commit("seteco_time", this.HisTime);
              console.log(this.HisTime);
              select_data_by_time(
                this.$qs.stringify({
                  start: this.HisTime[0],
                  end: this.HisTime[1],
                  table: this.$store.state.site,
                })
              ).then((res) => {
                console.log(res.data);
                this.exportTable = res.data;
                for (let i = 1; i <= res.data.length - 1; i++) {
                  this.Datahandle(res.data[i]);
                }
                this.value = "实时数据";
                this.HisTime = [];
                this.draw_list(1);
                this.draw_list(2);
                this.draw_list(3);
                this.draw_list(4);
                if (this.optionMin) {
                  this.optionMin.close();
                }
                this.refreshflag = false;
              });
            }
          });
        } else {
          this.value = this.$store.state.eco_value;
          this.HisTime = this.$store.state.eco_time;

          // for (let i = 0; i < this.options.length - 1; i++) {
          //   if (this.options[i].name == this.value) {
          //     this.HisTime[0] = this.options[i].start;
          //     this.HisTime[1] = this.options[i].end;
          //   }
          // }
          console.log(this.HisTime);
          select_data_by_time(
            this.$qs.stringify({
              start: this.HisTime[0],
              end: this.HisTime[1],
              table: this.$store.state.site,
            })
          ).then((res) => {
            console.log(res.data);
            this.exportTable = res.data;
            for (let i = 1; i <= res.data.length - 1; i++) {
              this.Datahandle(res.data[i]);
            }
            this.value = "实时数据";
            this.HisTime = [];
            this.draw_list(1);
            this.draw_list(2);
            this.draw_list(3);
            this.draw_list(4);
            if (this.optionMin) {
              this.optionMin.close();
            }
            this.refreshflag = false;
          });
        }
      }
    );

    // askECOdata(this.$qs.stringify({ site: this.site })).then((res) => {
    //   this.Datahandle(res.data[0]);
    // });

    this.username = this.$store.state.username; //将store里的用户名传到本组件
    this.concentration1 = 0;
    this.concentration2 = 0;
    this.concentration3 = 0;
    this.concentration4 = 0;
    this.time = "2024/7/8 12:00:00";
    this.wind = 0;
    this.windspeed = 0;
    this.temperature = 0;
    this.pressure = 0;
  },
  mounted() {
    // 隔1s刷新一次数据
    this.refreshData = setInterval(() => {
      if (this.refreshflag == true) {
        askECOdata(this.$qs.stringify({ site: this.site })).then((res) => {
          console.log(res);
          if (res.data[0].infor != "Wrong data") {
            this.Datahandle(res.data[0]);
            if (this.start_flag == false) {
              this.DataTime[0] = this.time;
            } else {
              if (this.centerDialogVisible == false) {
                this.DataTime[1] = this.time;
              }
            }
            this.myChart1.setOption({
              series: [
                {
                  data: this.listdata1,
                },
              ],
            });
            this.myChart2.setOption({
              series: [
                {
                  data: this.listdata2,
                },
              ],
            });
            this.myChart3.setOption({
              series: [
                {
                  data: this.listdata3,
                },
              ],
            });
            this.myChart4.setOption({
              series: [
                {
                  data: this.listdata4,
                },
              ],
            });
          }
        });
      }
    }, 1000);
  },
  methods: {
    setfilename() {
      this.exportflag = true;
      this.filename = this.$store.state.eco_value;
    },
    exportTableToExcel(tableData, headerDefinitions) {
      console.log(this.name);
      var str = this.filename + ".xlsx";
      headerDefinitions.unshift(
        { paramIndex: "id", paramName: "Id" },
        { paramIndex: "time", paramName: "time" }
      );
      const XLSX = require("xlsx");
      const workbook = XLSX.utils.book_new(); // 创建新的工作簿
      const headers = headerDefinitions.map((def) => def.paramName); // 生成表头
      const dataRows = tableData.map((row) => {
        return headerDefinitions.map((def) => row[def.paramIndex]);
      }); // 生成数据行
      const worksheetData = [headers, ...dataRows]; // 将表头和数据合并
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData); // 创建工作表
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1"); // 将工作表添加到工作簿
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      }); // 生成Excel文件
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }); // 创建 Blob 对象
      const url = window.URL.createObjectURL(blob); // 创建下载链接
      const a = document.createElement("a");
      a.href = url;
      a.download = str;
      a.click();

      window.URL.revokeObjectURL(url); // 释放 URL 对象
    },
    delete_his(flag) {
      if (flag == false) {
        console.log(this.value);
        if (this.value == null) {
          var text = "未选择记录";
          if (this.Lflag == false) {
            text = "No record selected";
          }
          this.optionMin = this.$message({
            showClose: true,
            message: text,
            duration: 3 * 1000,
            center: true,
            type: "warning",
            customClass: "customclass",
          });
        } else if (this.value == "实时数据") {
          var text = "实时数据无法删除，请选择正确的记录";
          if (this.Lflag == false) {
            text =
              "Real-time data cannot be deleted, please select the correct record";
          }
          this.optionMin = this.$message({
            showClose: true,
            message: text,
            duration: 3 * 1000,
            center: true,
            type: "warning",
            customClass: "customclass",
          });
        } else {
          this.delete_flag = true;
        }
      } else if (flag == true) {
        delete_timelag(
          this.$qs.stringify({ site: this.$store.state.site, name: this.value })
        ).then((res) => {
          console.log(res);
          if (res.data == 1) {
            var text = "删除成功";
            if (this.Lflag == false) {
              text = "The deletion is successful";
            }
            this.optionMin = this.$message({
              showClose: true,
              message: text,
              duration: 2 * 1000,
              center: true,
              type: "success",
              customClass: "customclass",
            });
            this.value = null;
            this.$store.commit("seteco_value", this.value);
            get_time_lag(
              this.$qs.stringify({ site: this.$store.state.site })
            ).then((res) => {
              this.options = res.data;
              this.options.unshift({ name: "实时数据", start: "", end: "" });
              console.log(this.options);
            });
          } else {
            var text = "删除失败";
            if (this.Lflag == false) {
              text = "Deletion failed";
            }
            this.optionMin = this.$message({
              showClose: true,
              message: text,
              duration: 2 * 1000,
              center: true,
              type: "error",
              customClass: "customclass",
            });
          }
          this.delete_flag = false;
        });
      }
    },
    draw_list(index) {
      function fontSize(res) {
        let clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        if (!clientWidth) return;
        let fontSize = clientWidth / 1860;
        return res * fontSize;
      }
      var option;
      let data = [];
      var lable;
      if (index == 1) {
        this.myChart1 = echarts.init(document.getElementById("list1"));
        data = this.listdata1;
        lable = this.lable1;
      } else if (index == 2) {
        this.myChart2 = echarts.init(document.getElementById("list2"));
        data = this.listdata2;
        lable = this.lable2;
      } else if (index == 3) {
        this.myChart3 = echarts.init(document.getElementById("list3"));
        data = this.listdata3;
        lable = this.lable3;
      } else if (index == 4) {
        this.myChart4 = echarts.init(document.getElementById("list4"));
        data = this.listdata4;
        lable = this.lable4;
      }

      option = {
        // title: {
        //   text: this.lable1,
        // },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          textStyle: {
            fontSize: fontSize(16),
          },
          // formatter: "{a} <br/>{b} : {c}"
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        xAxis: {
          type: "time",
          splitLine: {
            show: false,
          },
          name: "time", // x轴名称
          nameTextStyle: {
            fontSize: fontSize(18),
          },
        },
        yAxis: {
          name: lable, // y轴名称
          type: "value",
          boundaryGap: [0, "100%"],
          splitLine: {
            show: true,
          },
          nameTextStyle: {
            fontSize: fontSize(18),
          },
          min: (value) => {
            if (Math.abs(value) < 1 && Math.abs(value) > 0.00001) {
              let value2 = value * 100000;

              let value2min = Math.floor(value2);

              let num = 10 ** (value2.toString().length - 3);

              return parseFloat(Math.floor(value2min / num) * num).toFixed(4);
            } else {
              //当数据位数不固定时,最小值向下取整
              let value1 = Math.floor(value.min);
              let num = 1 ** (value1.toString().length - 3);
              return Math.floor(value1 / num) * num;
              //return Math.floor(value * 10000) / 10000; // 保留四位小数
            }
          },
          max: (value) => {
            if (Math.abs(value) < 1 && Math.abs(value) > 0.00001) {
              let value2 = value * 100000;
              let value2max = Math.ceil(value2);

              let num = 10 ** (value2max.toString().length - 3);

              return parseFloat(Math.ceil(value2max / num) * num).toFixed(4);
            } else {
              //return Math.ceil(value * 10000) / 10000; // 保留四位小数

              //当数据位数不固定时,最大值向上取整
              let value1 = Math.ceil(value.max);
              //let value1 = value1.toFixed(4); 抛弃
              let num = 10 ** (value1.toString().length - 3);
              return Math.ceil(value1 / num) * num;
            }
          },
          scale: true, //自适应
        },
        series: [
          {
            name: lable,
            type: "line",
            showSymbol: false,
            data: data,
          },
        ],
      };
      if (index == 1) {
        this.myChart1.setOption(option);
        window.addEventListener("resize", () => {
          if (this.myChart1) {
            this.myChart1.resize();
          }
        });
      } else if (index == 2) {
        this.myChart2.setOption(option);
        window.addEventListener("resize", () => {
          if (this.myChart2) {
            this.myChart2.resize();
          }
        });
      } else if (index == 3) {
        this.myChart3.setOption(option);
        window.addEventListener("resize", () => {
          if (this.myChart3) {
            this.myChart3.resize();
          }
        });
      } else if (index == 4) {
        this.myChart4.setOption(option);
        window.addEventListener("resize", () => {
          if (this.myChart4) {
            this.myChart4.resize();
          }
        });
      }
    },
    menuChange(item) {
      this.HisTime[0] = item.start;
      this.HisTime[1] = item.end;
      this.key += 1;
      console.log(this.HisTime);
    },
    his_click() {
      this.DataHis_flag = true;
      this.key += 1;
      get_time_lag(this.$qs.stringify({ site: this.$store.state.site })).then(
        (res) => {
          this.options = res.data;
          this.options.unshift({ name: "实时数据", start: "", end: "" });
          console.log(this.options);
        }
      );
    },
    hisData() {
      var text = "数据加载中";
      if (this.Lflag == false) {
        text = "The data is loading";
      }
      this.optionMin = this.$message({
        showClose: true,
        message: text,
        duration: 30 * 1000,
        center: true,
        type: "success",
        customClass: "customclass",
      });
      if (this.value != "实时数据") {
        select_data_by_time(
          this.$qs.stringify({
            start: this.HisTime[0],
            end: this.HisTime[1],
            table: this.$store.state.site,
          })
        ).then((res) => {
          this.exportTable = res.data;
          this.$store.commit("seteco_value", this.value);
          this.$store.commit("seteco_time", this.HisTime);
          this.value = "实时数据";
          this.HisTime = [];
          this.listdata1 = [];
          this.listdata2 = [];
          this.listdata3 = [];
          this.listdata4 = [];
          for (let i = 1; i <= res.data.length - 1; i++) {
            this.Datahandle(res.data[i]);
          }
          this.draw_list(1);
          this.draw_list(2);
          this.draw_list(3);
          this.draw_list(4);
          if (this.optionMin) {
            this.optionMin.close();
          }
          this.refreshflag = false;
        });
      } else {
        askhistory_by_end_time(
          this.$qs.stringify({ site: this.$store.state.site })
        ).then((res) => {
          console.log(res.data);
          this.exportTable = res.data;
          this.$store.commit("seteco_value", this.value);
          this.value = this.options[this.options.length - 1].name;
          this.HisTime[0] = this.options[this.options.length - 1].start;
          this.HisTime[1] = this.options[this.options.length - 1].end;
          //console.log(this.$store.state.eco_time);
          this.listdata1 = [];
          this.listdata2 = [];
          this.listdata3 = [];
          this.listdata4 = [];
          for (let i = 5; i <= res.data.length - 1; i++) {
            this.Datahandle(res.data[i]);
            if (i == 5) {
              this.DataTime[0] = this.time;
              this.start_flag = true;
              console.log(this.time);
            } else if (i == res.data.length - 1) {
              this.DataTime[1] = this.time;
            }
          }
          this.draw_list(1);
          this.draw_list(2);
          this.draw_list(3);
          this.draw_list(4);
          if (this.optionMin) {
            this.optionMin.close();
          }
          this.refreshflag = true;
        });
      }
      this.DataHis_flag = false;
    },
    resetmap() {
      console.log(this.name, this.DataTime);
      setstart(
        this.$qs.stringify({
          database: this.database,
          site: this.$store.state.site,
          start: this.DataTime[0],
          name: this.name,
        })
      ).then((res) => {
        console.log(res);
        var stamp = new Date().getTime() + 8 * 60 * 60 * 1000;
        // 格式化北京时间为"YYYY-MM-DD HH:mm:ss"
        var beijingTime = new Date(stamp)
          .toISOString()
          .replace(/T/, " ")
          .replace(/\..+/, "")
          .substring(0, 19);
        setend(
          this.$qs.stringify({
            database: this.database,
            site: this.$store.state.site,
            end: this.DataTime[1],
            name: this.name,
            data_end: beijingTime,
          })
        ).then((res) => {
          console.log(res);
        });
      });
      this.refreshflag = true;
      this.centerDialogVisible = false;
    },
    alarmhandle(alarm, hex, state) {
      let binary = parseInt(hex, 16).toString(2);

      // 输出2进制字符串
      //console.log("Binary:", binary); // 输出 Binary: 11010
      // 逐位读取2进制字符串
      let num = 1;
      if (state == false) {
        num = 17;
      }
      for (let i = binary.length - 1; i >= 0; i--) {
        if (binary[i] == 1) {
          //console.log(num);
          if (alarm == 0) {
            alarm = num;
          } else {
            alarm = alarm + "," + num;
          }
        }
        num++;
      }
      return alarm;
    },
    getAttrs(array, attr) {
      var arr = array.map((item) => {
        return item[attr];
      });
      return arr;
    },
    Datahandle(list) {
      //实时数据处理
      this.time = list.time;
      this.wind = list.wd;
      this.windspeed = list.ws;
      this.temperature = list.T;
      this.ch4_alarm = 0;
      this.n2o_alarm = 0;
      this.nh3_alarm = 0;
      for (let k in list) {
        for (let i = 0; i < this.indexList.length; i++) {
          if (k == this.indexList[i].paramIndex) {
            if (this.indexList[i].paramName == "longitude") {
              this.longitude = list[k];
            } else if (this.indexList[i].paramName == "latitude") {
              this.latitude = list[k];
            } else if (this.indexList[i].paramName == "drive_direction") {
              this.drive_direction = list[k];
              //console.log(this.drive_direction);
            } else if (this.indexList[i].paramName == "car_speed") {
              this.car_speed = list[k];
            } else if (this.indexList[i].paramName == "CH4concentration_ppb") {
              this.concentration1 = list[k];
              this.listdata1.push([this.time, this.concentration1]);
              //console.log(this.concentrationList1);
            } else if (this.indexList[i].paramName == "N2Oconcentration_ppb") {
              this.concentration2 = list[k];
              this.listdata2.push([this.time, this.concentration2]);
            } else if (this.indexList[i].paramName == "CO2concentration_ppm") {
              this.concentration3 = list[k];
              this.listdata3.push([this.time, this.concentration3]);
            } else if (this.indexList[i].paramName == "H2Oconcentration_ppm") {
              this.concentration5 = list[k];
            } else if (this.indexList[i].paramName == "NH3concentration_ppb") {
              this.concentration4 = list[k];
              this.listdata4.push([this.time, this.concentration4]);
            } else if (this.indexList[i].paramName == "pressure_kpa") {
              this.pressure = list[k];
            }
            // else if (this.indexList[i].paramName == "T") {
            //   this.temperature = list[k];
            //   //console.log(this.temperature);
            // }
            else if (this.indexList[i].paramName == "8600_OSS") {
              this.oss_ch4 = list[k];
            } else if (this.indexList[i].paramName == "8600_low_alarm") {
              this.ch4_alarm = this.alarmhandle(this.ch4_alarm, list[k], true);
            } else if (this.indexList[i].paramName == "8600_high_alarm") {
              this.ch4_alarm = this.alarmhandle(this.ch4_alarm, list[k], false);
            } else if (this.indexList[i].paramName == "8700_OSS") {
              this.oss_nh3 = list[k];
            } else if (this.indexList[i].paramName == "8700_low_alarm") {
              this.nh3_alarm = this.alarmhandle(this.nh3_alarm, list[k], true);
            } else if (this.indexList[i].paramName == "8700_high_alarm") {
              this.nh3_alarm = this.alarmhandle(this.nh3_alarm, list[k], false);
            } else if (this.indexList[i].paramName == "8500_OSS") {
              this.oss_n2o = list[k];
              //console.log(this.windspeed);
            } else if (this.indexList[i].paramName == "8500_low_alarm") {
              //this.oss_ch4 = list[k];
              //console.log(list[k]);
              this.n2o_alarm = this.alarmhandle(this.n2o_alarm, list[k], true);
            } else if (this.indexList[i].paramName == "8500_high_alarm") {
              //this.oss_ch4 = list[k];
              //console.log(list[k]);
              this.n2o_alarm = this.alarmhandle(this.n2o_alarm, list[k], false);
            }
            // else if (this.indexList[i].paramName == "U_m_s-1") {
            //   this.windspeed = list[k];
            //   //console.log(this.windspeed);
            // } else if (this.indexList[i].paramName == "WD") {
            //   this.wind = list[k];
            //   //console.log(this.wind);
            // }
          }
        }
      }
      //console.log(this.concentration1);
    },
  },
  beforeDestroy() {
    clearInterval(this.refreshData);
    this.refreshData = null;
  },
};
</script>
<style scoped>
::v-deep .el-button {
  padding-left: 0.0538vw;
  padding-top: 0.0538vw;
}
</style>
<style>
.btn {
  position: absolute;
  cursor: pointer;
  z-index: 10;
}
.amap-luopan-bg {
  width: 0.5376vw !important;
  height: 0.5376vw !important;
}
.amap-logo {
  display: none !important;
  visibility: hidden !important;
}
.amap-copyright {
  display: none !important;
  visibility: hidden !important;
}
.description {
  height: 4.8387vh;
}

.edit {
  top: 53.7634vw;
  right: 5.3763vh;
}

.basic_info {
  resize: none;
  width: 80%;
  height: 2.1505vh;
}

.v41_170 {
  width: 5.1613vw;
  color: rgba(0, 0, 0, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 1.0753vw;
  opacity: 1;
  text-align: left;
}

.v41_172 {
  width: 6.8817vw;
  height: 2.7957vh;
  background: rgba(25, 44, 250, 1);
  padding: 0.5914vw 2.1505vw;
  margin: 0.5376vw;
  opacity: 1;
  border-top-left-radius: 0.2688vw;
  border-top-right-radius: 0.2688vw;
  border-bottom-left-radius: 0.2688vw;
  border-bottom-right-radius: 0.2688vw;
  overflow: hidden;
}

.v41_169 {
  width: 5.1613vw;
  color: rgba(25, 44, 250, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}

.v41_168 {
  width: 2.5806vw;
  color: rgba(255, 255, 255, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}

.v41_166 {
  width: 98%;
  height: 100%;
  background: rgba(243, 249, 255, 1);
  outline-color: rgba(243, 249, 255, 1);
  opacity: 1;
  padding: 0vw;
  border: 0vw;
  border-top-left-radius: 0.5376vw;
  border-top-right-radius: 0.5376vw;
  border-bottom-left-radius: 0.5376vw;
  border-bottom-right-radius: 0.5376vw;
  overflow: hidden;
  color: rgba(0, 0, 0, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  text-align: left;
}

.box-card .text {
  font-size: 0.7527vw;
}

.item {
  margin-bottom: 0.9677vw;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.box-card {
  width: 43.0108vw;
}
</style>
