import request from '@/utils/request'

//发送网络请求：获取项目选择页面的内容
export function getProjects(data){
    //console.log(username)
    return request({
        url:'/loaddata',
        method:'post',
        data,
    })
}