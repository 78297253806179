import request from '@/utils/request'//引入request


//发送网络请求：获取基本信息界面文本框中的内容
export function getBasicInfo(data){
    return request({//调用了request
        url:'/getinformation',  //与后端的接口对应
        method:'post',      //所有请求方法都用post，如需传参下面必须是data
        data,         //传递给后端的参数
    })
}
//发送网络请求：提交基本信息界面文本框中内容的改动
export function commitBasicInfo(data) {
    return request({
      url: '/editinformation',
      method: 'post',
      data
    })
  }
//发送网络请求：获取项目选择界面信息
  export function getSelectInfo(data){
    return request({
      url:'/askmap',
      method:'post',
      data
    })
}
//发送网络请求：获取数据展示界面信息
export function paramInfo(data){
  return request({
    url:'/askdata',
    method:'post',
    data
  })
}

export function alarm_init(data){//首次加载报警信息
  return request({
    url:'/askInitAlarm',
    method:'post',
    data
  })
}

export function currentalarm(data){//请求实时报警
  return request({
    url:'/askCurrentAlarm',
    method:'post',
    data
  })
}

export function alarm(data){//报警信息查询
  return request({
    url:'/askalarm',
    method:'post',
    data
  })
}

export function initmap(data){//请求报警信息
  return request({
    url:'/askInitMap',
    method:'post',
    data
  })
}

export function inittime(data){//请求存在数据的日期在日历上标注出来
  return request({
    url:'/asktime',
    method:'post',
    data
  })
}

export function bintime(data){//下载bin文件时请求存在数据的日期
  return request({
    url:'/whereisbin',
    method:'post',
    data
  })
}

export function alarmtime(data){//存在报警信息的日期
  return request({
    url:'/askalarmtime',
    method:'post',
    data
  })
}

export function ffp(data){//请求等值线数据
  return request({
    url:'/ffp',
    method:'post',
    data
  })
}

export function getffptime(data){//请求实时源区存在数据的日期
  return request({
    url:'/askffptime',
    method:'post',
    data
  })
}

export function ffpnew(data){//请求等值线数据
  return request({
    url:'/ffpnew',
    method:'post',
    data
  })
}

export function ifemail(data){
  return request({
    url:'/ifemail',
    method:'post',
    data
  })
}

export function sendmail(data){
  return request({
    url:'/sendmail',
    method:'post',
    data
  })
}

export function sendmail_secur(data){
  return request({
    url:'/sendmail_secur',
    method:'post',
    data
  })
}

export function verify(data){
  return request ({
    url:'/verify',
    method:'post',
    data
  })
}

export function userinfor(data){
  return request ({
    url:'/userinfor',
    method:'post',
    data
  })
}

export function saveemail(data){
  return request ({
    url:'/saveemail',
    method:'post',
    data
  })
}

export function changepassword(data){
  return request ({
    url:'/changepassword',
    method:'post',
    data
  })
}

export function setpassword2(data){
  return request({
    url:'/setpassword2',
    method:'post',
    data
  })
}

export function askLL(data){
  return request({
    url:'askLL',
    method: 'post',
    data
  })
}

export function saveLL(data){
  return request({
    url:'saveLL',
    method:'post',
    data
  })
}

export function savemessage(data){
  return request({
    url:'savemessage',
    method:'post',
    data
  })
}

export function askecomap(data){//请求走航实时数据表头
  return request({
    url:'askecomap',
    method:'post',
    data
  })
}
export function askECOdata(data){//请求走航实时数据
  return request({
    url:'askECOdata',
    method:'post',
    data
  })
}

export function setstart(data){//设置走航开始时间
  return request({
    url:'setstart',
    method:'post',
    data
  })
}

export function setend(data){//设置走航结束时间
  return request({
    url:'setend',
    method:'post',
    data
  })
}

export function ask_plume(data){//设置走航结束时间
  return request({
    url:'ask_plume',
    method:'post',
    data
  })
}
export function send_plume(data){//设置走航结束时间
  return request({
    url:'send_plume',
    method:'post',
    data
  })
}

export function asksitelog(data){//设置走航结束时间
  return request({
    url:'asksitelog',
    method:'post',
    data
  })
}

export function askhistory(data){//获取走航历史数据
  return request({
    url:'askhistory',
    method:'post',
    data
  })
}


export function askhistory_by_time(data){//获得前几分钟的数据，几分钟自己输入
  return request({
    url:'askhistory_by_time',
    method:'post',
    data
  })
}

export function askhistory_by_end_time(data){//获得结束时间后的数据
  return request({
    url:'askhistory_by_end_time',
    method:'post',
    data
  })
}

export function select_data_by_time(data){//根据时间段获取数据
  return request({
    url:'select_data_by_time',
    method:'post',
    data
  })
}

export function get_time_lag(data){//获取站点所以记录
  return request({
    url:'get_time_lag',
    method:'post',
    data
  })
}

export function set_alarm_email(data){//设置报警邮箱
  return request({
    url:'set_alarm_email',
    method:'post',
    data
  })
}

export function get_alarm_email(data){//返回报警邮箱
  return request({
    url:'get_alarm_email',
    method:'post',
    data
  })
}

export function delete_timelag(data){//删除走航记录
  return request({
    url:'delete_timelag',
    method:'post',
    data
  })
}

export function upload_ECO_report(data){//删除走航记录
  return request({
    url:'upload_ECO_report',
    method:'post',
    data
  })
}