<!-- 基本信息界面 -->
<template>
  <div style="width: 100%; height: 100%">
    <div
      style="
        width: 0.5376vw;
        height: 100%;
        display: flex;
        float: left;
        background: rgba(240, 240, 240, 1);
      "
    ></div>
    <div style="width: 6%; height: 100%; float: left"></div>
    <div style="width: 93%; height: 100%; float: left">
      <div style="width: 100%; height: 4.5%"></div>
      <div
        style="width: 100%; height: 5.5%; align-items: center; display: flex"
      >
        <div
          style="
            width: 20%;
            height: 100%;
            float: left;
            align-items: center;
            display: flex;
          "
          class="v41_161"
        >
          <span v-if="Lflag == true" class="v35_1047" style="width: 15%"
            >基本信息</span
          >
          <span v-if="Lflag == false" class="v35_1047" style="width: 15%"
            >Basic Information</span
          >
        </div>
        <div
          style="
            width: 60%;
            height: 100%;
            float: left;
            align-items: center;
            display: flex;
          "
        ></div>
        <div
          style="
            width: 20%;
            height: 100%;
            float: right;
            align-items: center;
            justify-content: left;
            display: flex;
          "
          class="v41_161"
        >
          <span
            v-if="Lflag == true"
            class="v41_161"
            style="
              height: 16px;
              font-size: 0.8602vw;
              align-items: center;
              display: flex;
              justify-content: left;
            "
            >项目名称：{{ this.project }}
          </span>
          <span
            v-if="Lflag == false"
            class="v41_161"
            style="
              height: 16px;
              font-size: 0.8602vw;
              align-items: center;
              display: flex;
              justify-content: left;
            "
            >Project name：{{ this.project }}
          </span>
        </div>
      </div>
      <div style="width: 100%; height: 1.9%"></div>
      <div style="width: 100%; height: 5%">
        <span v-if="Lflag == true" class="v41_170" style="width: 10%"
          >项目信息</span
        >
        <span v-if="Lflag == false" class="v41_170" style="width: 10%"
          >Project information</span
        >
      </div>
      <div style="width: 100%; height: 1%"></div>
      <div
        style="
          width: 96.2%;
          height: 32%;
          background: rgba(243, 249, 255, 1);
          border-radius: 0.5376vw;
        "
      >
        <div style="width: 90%; height: 8%"></div>
        <div
          style="
            width: 90%;
            height: 70%;
            display: flex;
            border-radius: 0.5376vw;
          "
        >
          <div style="width: 2.6882vw"></div>
          <textarea
            class="v41_166"
            :disabled="!isEditing"
            :style="{ 'word-wrap': 'break-word', resize: 'none' }"
            v-model="text"
          >
          </textarea>
        </div>
        <div
          style="
            height: 22%;
            background: rgba(243, 249, 255, 1);
            display: flex;
            border-radius: 0.5376vw;
          "
        >
          <div style="width: 82%; float: left"></div>
          <div
            style="
              width: 18%;
              float: left;
              justify-content: center;
              align-items: center;
              display: flex;
              border-radius: 0.5376vw;
            "
          >
            <el-button calss="edit" type="text" @click="toggleEditing"
              ><span v-if="Lflag == true" class="v41_169">{{
                isEditing ? "禁用编辑" : "启用编辑"
              }}</span>
              <span v-if="Lflag == false" class="v41_169">{{
                isEditing ? "End editing" : "start to edit"
              }}</span>
            </el-button>
          </div>
        </div>
      </div>
      <div style="width: 100%; height: 7.5%; display: flex">
        <div style="width: 75%; float: left" v-if="project[0] != 'ECO'">
          <span v-if="Lflag == true">地址：</span>
          <span v-if="Lflag == false">Address:</span>
          <div class="areabox" v-if="Lflag == true">
            <!-- 省份选择框 -->
            <el-select
              v-model="address.province"
              placeholder="Province"
              style="width: 10vw"
              @change="handleProvinceSelect"
            >
              <!-- 循环省份选项 -->
              <el-option
                v-for="item in pcTextArr"
                :label="item.label"
                :value="item.value"
                :key="item.value"
              />
            </el-select>
            <!-- 城市选择框 -->
            <el-select
              v-model="address.city"
              placeholder="City"
              :disabled="!address.province || cityList.length == 0"
              style="width: 10vw"
              @change="handleCitySelect"
            >
              <!-- 循环城市选项 -->
              <el-option
                v-for="item in cityList"
                :label="item.label"
                :value="item.value"
                :key="item.value"
              />
            </el-select>
          </div>
          <div class="areabox" v-if="Lflag == false">
            <!-- 省份选择框 -->
            <el-select
              v-model="address.province"
              placeholder="Province"
              style="width: 10vw"
              @change="handleProvinceSelect"
            >
              <!-- 循环省份选项 -->
              <el-option
                v-for="item in pcTextArr"
                :label="item.label"
                :value="item.value"
                :key="item.value"
              />
            </el-select>
            <!-- 城市选择框 -->
            <el-select
              v-model="address.city"
              placeholder="City"
              :disabled="!address.province || cityList.length == 0"
              style="width: 10vw"
              @change="handleCitySelect"
            >
              <!-- 循环城市选项 -->
              <el-option
                v-for="item in cityList"
                :label="item.label"
                :value="item.value"
                :key="item.value"
              />
            </el-select>
          </div>
        </div>
        <div
          style="
            width: 25%;
            float: left;
            justify-content: center;
            align-items: center;
            display: flex;
          "
        ></div>
      </div>
      <div
        style="
          width: 90%;
          height: auto;
          margin-top: 4vh;
          justify-content: center;
          align-items: center;
        "
      >
        <el-button
          v-if="Lflag == true"
          type="button"
          style="
            color: #fff;
            font-family: Microsoft YaHei UI;
            font-weight: Regular;
            font-size: 0.8602vw;
            background-color: rgba(25, 44, 250, 1);
            padding-left: 2.0968vw;
            padding-right: 2.0968vw;
            padding-top: 0.6989vw;
            padding-bottom: 0.6989vw;
            border-radius: 0.2688vw;
            margin-left: 67vw;
          "
          @click="save"
          >保存</el-button
        ><el-button
          v-if="Lflag == false"
          type="button"
          style="
            color: #fff;
            font-family: Microsoft YaHei UI;
            font-weight: Regular;
            font-size: 0.8602vw;
            background-color: rgba(25, 44, 250, 1);
            padding-left: 2.0968vw;
            padding-right: 2.0968vw;
            padding-top: 0.6989vw;
            padding-bottom: 0.6989vw;
            border-radius: 0.2688vw;
            margin-left: 67vw;
          "
          @click="save"
          >SAVE</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { checkToken } from "@/api/user"; //将checkToken方法从/api/user导入，作用是发送网络请求
import { getBasicInfo } from "@/api/data"; //将getBasicInfo方法从/api/data导入，作用是发送网络请求
import { commitBasicInfo } from "@/api/data"; //将commitBasicInfo方法从/api/data导入，作用是发送网络请求
import { ffp } from "@/api/data";
import { FALSE } from "ol/functions";
import { pcTextArr } from "element-china-area-data";
import { savemessage } from "@/api/data";
export default {
  data() {
    return {
      username: "", //用于接收从state里传过来的用户名
      text: "", //用于储存文本框信息
      isEditing: false, //文本框状态，是否可以编辑
      project: "", //用于接收从state里传过来的项目名
      Lflag: true,

      // 地址对象，用于存储选择的省市区信息
      address: {
        province: this.$store.state.province, // 省份
        city: this.$store.state.city, // 城市
        area: "", // 区县
      },
      pcTextArr: pcTextArr,
    };
  },
  created() {
    //created里的指令在该组件创建的时候执行，created比mounted更早执行
    const selectedProjectId = this.$store.state.projectId; //将项目选择界面所选择的项目id传给selectedProjectId，用于发送网络请求获取对应项目的信息
    //console.log(selectedProjectId)//打印，用于测试
    this.$store.commit("restorename"); //将保存在本地的用户名传给state
    this.$store.commit("restoreproject"); //将保存在本地的项目名传给state
    this.$store.commit("restoretoken"); //将保存在本地的token传给state
    this.$store.commit("restoreSite");
    this.$store.commit("restoreProvince");
    this.$store.commit("restoreCity");
    this.$store.commit("restoreTimezone");
    this.Lflag = this.$store.state.Language; //store里的传到该组件
    checkToken(this.$qs.stringify({ token: this.$store.state.token })).then(
      (res) => {
        //发送请求：让后端检查token，后端返回的状态在request的response interceptor里拦截并作出相应的响应
        console.log(res);
      }
    );
  },
  mounted: async function () {
    //mounted里的指令在该组件被挂载的时候执行，这里需要加上async，否则可能收不到返回值
    this.username = this.$store.state.username; //将store里的用户名传到本组件
    this.project = this.$store.state.project; //将store里的项目名传到本组件
    this.text = this.$store.state.information;
  },
  computed: {
    // 计算属性，返回当前选择省份的城市列表
    cityList() {
      if (!this.address.province) return [];
      let temp = pcTextArr.find((item) => item.value == this.address.province);
      return temp && temp.children ? temp.children : [];
    },
    // 计算属性，返回当前选择城市的区县列表
    areaList() {
      if (!this.address.province || !this.address.city) return [];
      let temp = this.cityList.find((item) => item.value == this.address.city);
      return temp && temp.children ? temp.children : [];
    },
  },
  methods: {
    toggleEditing() {
      //该方法在点击“禁用编辑/启用编辑”按钮时执行，点击一次状态反转，在最上面标签里实现反转的时候按钮名字变为对应文本
      this.isEditing = !this.isEditing;
    },

    save() {
      //该方法在点击“保存”按钮时执行，发送网络请求并将文本内容发送到后端
      this.isEditing = false; //点击该按钮的时候文本框变为不可编辑模式
      console.log(this.text); //打印，用于测试
      console.log(this.$store.state.site);
      console.log(this.$store.state.province);
      console.log(this.$store.state.city);
      console.log(this.$store.state.timezone);
      savemessage(
        this.$qs.stringify({
          site: this.$store.state.site,
          information: this.text,
          province: this.address.province,
          city: this.address.city,
          timezone: this.$store.state.timezone,
        })
      ).then((res) => {
        this.$message({
          showClose: true,
          message: "保存成功",
          center: true,
          type: "success",
          customClass: "customclass",
        });
        console.log("返回的是", res.data); //打印，用于测试
      });
    },
    // 处理选择省份的函数
    handleProvinceSelect() {
      // 重置城市和区县
      this.address.city = "";
      this.address.area = "";
      // 触发自定义事件，向父组件传递选择的地区信息
      this.emitAddress();
    },
    // 处理选择城市的函数
    handleCitySelect() {
      // 重置区县
      this.address.area = "";
      // 触发自定义事件，向父组件传递选择的地区信息
      this.emitAddress();
    },
    // 处理选择区县的函数
    handleAreaSelect() {
      // 触发自定义事件，向父组件传递选择的地区信息
      this.emitAddress();
    },
    // 触发自定义事件，向父组件传递选择的地区信息
    emitAddress() {
      // 判断是否选择完整地区
      let isComplete = this.areaList.length == 0;
      // 构建地区信息对象
      let data = {
        name: [this.address.province, this.address.city, this.address.area], // 汉字
        isComplete: isComplete, // 是否选择完整，方便校验
      };
      // 触发自定义事件，向父组件传递地区信息
      this.$emit("getAddress", data);
    },
  },
};
</script>
<style>
.description {
  height: 4.8387vw;
}

.edit {
  top: 53.7634vw;
  right: 5.3763vw;
}

.basic_info {
  resize: none;
  width: 80%;
  height: 2.1505vw;
}

.v41_170 {
  width: 5.1613vw;
  color: rgba(0, 0, 0, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 1.0753vw;
  opacity: 1;
  text-align: left;
}

.v41_172 {
  width: 6.8817vw;
  height: 2.7957vw;
  background: rgba(25, 44, 250, 1);
  padding: 0.5914vw 2.1505vw;
  margin: 0.5376vw;
  opacity: 1;
  border-top-left-radius: 0.2688vw;
  border-top-right-radius: 0.2688vw;
  border-bottom-left-radius: 0.2688vw;
  border-bottom-right-radius: 0.2688vw;
  overflow: hidden;
}

.v41_169 {
  width: 5.1613vw;
  color: rgba(25, 44, 250, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}

.v41_168 {
  width: 2.5806vw;
  color: rgba(255, 255, 255, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}

.v41_166 {
  width: 98%;
  height: 100%;
  background: rgba(243, 249, 255, 1);
  outline-color: rgba(243, 249, 255, 1);
  opacity: 1;
  padding: 0vw;
  border: 0vw;
  border-top-left-radius: 0.5376vw;
  border-top-right-radius: 0.5376vw;
  border-bottom-left-radius: 0.5376vw;
  border-bottom-right-radius: 0.5376vw;
  overflow: hidden;
  color: rgba(0, 0, 0, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  text-align: left;
}

.box-card .text {
  font-size: 0.7527vw;
}

.item {
  margin-bottom: 0.9677vw;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.box-card {
  width: 43.0108vw;
}
</style>
