<template>
  <div style="width: 100%; height: 100%">
    <div
      style="
        width: 0.5376vw;
        height: 100%;
        display: flex;
        float: left;
        background: rgba(240, 240, 240, 1);
      "
    ></div>
    <div style="width: 6%; height: 100%; float: left"></div>
    <div style="width: 93%; height: 100%; float: left">
      <div style="width: 100%; height: 4.5%"></div>
      <div
        style="width: 100%; height: 5.5%; align-items: center; display: flex"
      >
        <div
          style="
            width: 20%;
            height: 100%;
            float: left;
            align-items: center;
            display: flex;
          "
          class="v41_161"
        >
          <span v-if="Lflag == true" class="v35_1047" style="width: 15%"
            >走航监测</span
          >
          <span v-if="Lflag == false" class="v35_1047" style="width: 15%"
            >EcoTracX</span
          >
        </div>
        <div
          style="
            width: 60%;
            height: 100%;
            float: left;
            align-items: center;
            display: flex;
          "
        ></div>
        <div
          style="
            width: 20%;
            height: 100%;
            float: right;
            align-items: center;
            justify-content: left;
            display: flex;
          "
          class="v41_161"
        >
          <span
            v-if="Lflag == true"
            class="v41_161"
            style="
              height: 0.8602vh;
              font-size: 0.8602vw;
              align-items: center;
              display: flex;
              justify-content: left;
            "
            >当前数据：{{ this.$store.state.eco_value }}
          </span>
          <span
            v-if="Lflag == false"
            class="v41_161"
            style="
              height: 0.8602vh;
              font-size: 0.8602vw;
              align-items: center;
              display: flex;
              justify-content: left;
            "
            >Dtat：{{ this.$store.state.eco_value }}
          </span>
        </div>
      </div>
      <div style="width: 97%; height: 2vh"></div>
      <div style="width: 97%; height: 10%">
        <div style="width: 75vw; height: 50%; position: relative; float: left">
          <el-drawer
            v-if="Lflag == true"
            title="设备详细信息"
            style="font-size: 1.0753vw"
            :visible.sync="drawer"
            :direction="direction"
            size="20%"
            :modal="false"
          >
            <div style="width: 100%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >时间：{{ this.time }}
              </span>
            </div>
            <div style="width: 52%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >经度：{{ this.longitude.toFixed(6) }}
              </span>
            </div>
            <div style="width: 48%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >纬度：{{ this.latitude.toFixed(6) }}
              </span>
            </div>
            <div style="width: 100%; height: 5%; float: left"></div>
            <div style="width: 50%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >CH4
              </span>
            </div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >OSS：{{ this.oss_ch4 }}%
              </span>
            </div>

            <div style="width: 100%; height: 5%; float: left; padding-left: 4%">
              <el-tooltip placement="right" effect="light">
                <div slot="content" style="font-size: 0.9677vw">
                  报警信息为：<br />1：LD一级温度异常<br />2：MCT一级温度异常<br />3：实际电流超过实际限制电流<br />4：******预留报警位*****<br />5：LD一级测温断开<br />6：LD二级测温断开<br />7：MCT一级测温断开<br />8：MCT二级测温断开<br />9：******预留报警位******<br />10：******预留报警位******
                  <br />11：循环水温过高<br />12：测循环水温芯片断连<br />13：内部温度过高<br />14：内部湿度过高<br />15：测内部温湿度芯片断连<br />16：外接NTC断开<br />17：******预留报警位******<br />18：下镜片测温芯片通信失败<br />19：信号强度过低<br />20：浓度数据无效<br />21：LD二级温控异常
                  <br />22：MCT二级温控异常<br />23：未检测到激光器电流<br />24：参考池气体浓度过低<br />25：低功耗模式开启<br />26：******预留报警位******<br />27：设置电流有误<br />28：限制电流有误<br />29：供电电压异常<br />30：激光器&TEC参数没有设置完成<br />31：传感器检测有雨<br />32：水箱水位过低
                </div>
                <span
                  class="v41_161"
                  style="
                    float: left;
                    padding-top: 1.8817vh;
                    font-size: 0.9677vw;
                  "
                  >报警信息：{{ this.ch4_alarm }}
                </span>
              </el-tooltip>
            </div>
            <div style="width: 100%; height: 5%; float: left"></div>
            <div style="width: 50%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >N2O
              </span>
            </div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >OSS：{{ this.oss_n2o }}%
              </span>
            </div>
            <div style="width: 100%; height: 5%; float: left; padding-left: 4%">
              <el-tooltip placement="right" effect="light">
                <div slot="content" style="font-size: 0.9677vw">
                  报警信息为：<br />1：LD一级温度异常<br />2：MCT一级温度异常<br />3：实际电流超过实际限制电流<br />4：******预留报警位*****<br />5：LD一级测温断开<br />6：LD二级测温断开<br />7：MCT一级测温断开<br />8：MCT二级测温断开<br />9：******预留报警位******<br />10：******预留报警位******
                  <br />11：循环水温过高<br />12：测循环水温芯片断连<br />13：内部温度过高<br />14：内部湿度过高<br />15：测内部温湿度芯片断连<br />16：外接NTC断开<br />17：******预留报警位******<br />18：下镜片测温芯片通信失败<br />19：信号强度过低<br />20：浓度数据无效<br />21：LD二级温控异常
                  <br />22：MCT二级温控异常<br />23：未检测到激光器电流<br />24：参考池气体浓度过低<br />25：低功耗模式开启<br />26：******预留报警位******<br />27：设置电流有误<br />28：限制电流有误<br />29：供电电压异常<br />30：激光器&TEC参数没有设置完成<br />31：传感器检测有雨<br />32：水箱水位过低
                </div>
                <span
                  class="v41_161"
                  style="
                    float: left;
                    padding-top: 1.8817vh;
                    font-size: 0.9677vw;
                  "
                  >报警信息：{{ this.n2o_alarm }}
                </span>
              </el-tooltip>
            </div>
            <!-- 
            <div style="width: 100%; height: 5%; float: left"></div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >CO2
              </span>
            </div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >OSS：{{ this.oss_co2 }}
              </span>
            </div>
            <div style="width: 100%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >报警信息：
              </span>
            </div> -->
            <div style="width: 100%; height: 5%; float: left"></div>
            <div style="width: 50%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >NH3
              </span>
            </div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >OSS：{{ this.oss_nh3 }}%
              </span>
            </div>
            <div style="width: 100%; height: 5%; float: left; padding-left: 4%">
              <el-tooltip placement="right" effect="light">
                <div slot="content" style="font-size: 0.9677vw">
                  报警信息为：<br />1：LD一级温度异常<br />2：MCT一级温度异常<br />3：实际电流超过实际限制电流<br />4：******预留报警位*****<br />5：LD一级测温断开<br />6：LD二级测温断开<br />7：MCT一级测温断开<br />8：MCT二级测温断开<br />9：******预留报警位******<br />10：******预留报警位******
                  <br />11：循环水温过高<br />12：测循环水温芯片断连<br />13：内部温度过高<br />14：内部湿度过高<br />15：测内部温湿度芯片断连<br />16：外接NTC断开<br />17：******预留报警位******<br />18：下镜片测温芯片通信失败<br />19：信号强度过低<br />20：浓度数据无效<br />21：LD二级温控异常
                  <br />22：MCT二级温控异常<br />23：未检测到激光器电流<br />24：参考池气体浓度过低<br />25：低功耗模式开启<br />26：******预留报警位******<br />27：设置电流有误<br />28：限制电流有误<br />29：供电电压异常<br />30：激光器&TEC参数没有设置完成<br />31：传感器检测有雨<br />32：水箱水位过低
                </div>
                <span
                  class="v41_161"
                  style="
                    float: left;
                    padding-top: 1.8817vh;
                    font-size: 0.9677vw;
                  "
                  >报警信息：{{ this.nh3_alarm }}
                </span>
              </el-tooltip>
            </div>
          </el-drawer>
          <el-drawer
            v-if="Lflag == false"
            title="Device details"
            style="font-size: 1.0753vw"
            :visible.sync="drawer"
            :direction="direction"
            size="20%"
            :modal="false"
          >
            <div style="width: 100%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >Time：{{ this.time }}
              </span>
            </div>
            <div style="width: 52%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >LON：{{ this.longitude.toFixed(6) }}
              </span>
            </div>
            <div style="width: 48%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >LAT：{{ this.latitude.toFixed(6) }}
              </span>
            </div>
            <div style="width: 100%; height: 5%; float: left"></div>
            <div style="width: 50%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >CH4
              </span>
            </div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >OSS：{{ this.oss_ch4 }}%
              </span>
            </div>

            <div style="width: 100%; height: 5%; float: left; padding-left: 4%">
              <el-tooltip
                placement="right"
                effect="light"
                v-if="Lflag == false"
              >
                <div slot="content" style="font-size: 0.9677vw">
                  The alarm message is：<br />1：LD primary temperature is
                  abnormal<br />2：MCT primary temperature is abnormal<br />3：Actual
                  current exceeds the actual limit current<br />4：******Reserve
                  Alarm Position******<br />5：LD primary temperature
                  measurement disconnected<br />6：LD secondary temperature
                  measurement disconnected<br />7：MCT primary temperature
                  measurement disconnected<br />8：MCT secondary temperature
                  measurement disconnected<br />9：******Reserve Alarm
                  Position******<br />10：******Reserve Alarm Position******
                  <br />11：Circulating water temperature out of range<br />12：he
                  chip used to measure the temperature of circulating water is
                  disconnected<br />13：nternal temperature out of range<br />14：Internal
                  humidity out of range<br />15：The chip used to measure the
                  internal humidity is disconnected<br />16：External NTC
                  disconnected <br />17：******Reserve Alarm Position******<br />18：Communication
                  failure of temperature measuring chip on lower mirror<br />19：Weak
                  signal strength<br />20：Concentration data is invalid<br />21：LD
                  secondary temperature control anomaly <br />22：MCT secondary
                  temperature control anomaly<br />23：No laser current
                  detected<br />24：Low gas concentration in reference pool<br />25：Power
                  saving mode on<br />26：******Reserve Alarm Position******<br />27：Error
                  setting current<br />28：Error limiting current<br />29：Abnormal
                  supply voltage<br />30：The laser & TEC parameters not set
                  complete<br />31：The sensor detects rain<br />32：Water level
                  is too low
                </div>
                <span
                  class="v41_161"
                  style="
                    float: left;
                    padding-top: 1.8817vh;
                    font-size: 1.0753vw;
                  "
                  >The alarm message：{{ this.ch4_alarm }}
                </span>
              </el-tooltip>
            </div>

            <div style="width: 100%; height: 5%; float: left"></div>
            <div style="width: 50%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >N2O
              </span>
            </div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 0.9677vw"
                >OSS：{{ this.oss_n2o }}
              </span>
            </div>
            <div style="width: 100%; height: 5%; float: left; padding-left: 4%">
              <el-tooltip
                placement="right"
                effect="light"
                v-if="Lflag == false"
              >
                <div slot="content" style="font-size: 0.9677vw">
                  The alarm message is：<br />1：LD primary temperature is
                  abnormal<br />2：MCT primary temperature is abnormal<br />3：Actual
                  current exceeds the actual limit current<br />4：******Reserve
                  Alarm Position******<br />5：LD primary temperature
                  measurement disconnected<br />6：LD secondary temperature
                  measurement disconnected<br />7：MCT primary temperature
                  measurement disconnected<br />8：MCT secondary temperature
                  measurement disconnected<br />9：******Reserve Alarm
                  Position******<br />10：******Reserve Alarm Position******
                  <br />11：Circulating water temperature out of range<br />12：he
                  chip used to measure the temperature of circulating water is
                  disconnected<br />13：nternal temperature out of range<br />14：Internal
                  humidity out of range<br />15：The chip used to measure the
                  internal humidity is disconnected<br />16：External NTC
                  disconnected <br />17：******Reserve Alarm Position******<br />18：Communication
                  failure of temperature measuring chip on lower mirror<br />19：Weak
                  signal strength<br />20：Concentration data is invalid<br />21：LD
                  secondary temperature control anomaly <br />22：MCT secondary
                  temperature control anomaly<br />23：No laser current
                  detected<br />24：Low gas concentration in reference pool<br />25：Power
                  saving mode on<br />26：******Reserve Alarm Position******<br />27：Error
                  setting current<br />28：Error limiting current<br />29：Abnormal
                  supply voltage<br />30：The laser & TEC parameters not set
                  complete<br />31：The sensor detects rain<br />32：Water level
                  is too low
                </div>
                <span
                  class="v41_161"
                  style="
                    float: left;
                    padding-top: 1.8817vh;
                    font-size: 1.0753vw;
                  "
                  >The alarm message：{{ this.n2o_alarm }}
                </span>
              </el-tooltip>
            </div>
            <!-- <div style="width: 100%; height: 5%; float: left"></div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >CO2
              </span>
            </div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >OSS：{{ this.oss_co2 }}
              </span>
            </div>
            <div style="width: 100%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >报警信息：
              </span>
            </div> -->
            <div style="width: 100%; height: 5%; float: left"></div>
            <div style="width: 50%; height: 5%; float: left; padding-left: 4%">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >NH3
              </span>
            </div>
            <div style="width: 50%; height: 5%; float: left">
              <span
                class="v41_161"
                style="float: left; padding-top: 1.8817vh; font-size: 1.0753vw"
                >OSS：{{ this.oss_nh3 }}%
              </span>
            </div>
            <div style="width: 100%; height: 5%; float: left; padding-left: 4%">
              <el-tooltip
                placement="right"
                effect="light"
                v-if="Lflag == false"
              >
                <div slot="content" style="font-size: 0.9677vw">
                  The alarm message is：<br />1：LD primary temperature is
                  abnormal<br />2：MCT primary temperature is abnormal<br />3：Actual
                  current exceeds the actual limit current<br />4：******Reserve
                  Alarm Position******<br />5：LD primary temperature
                  measurement disconnected<br />6：LD secondary temperature
                  measurement disconnected<br />7：MCT primary temperature
                  measurement disconnected<br />8：MCT secondary temperature
                  measurement disconnected<br />9：******Reserve Alarm
                  Position******<br />10：******Reserve Alarm Position******
                  <br />11：Circulating water temperature out of range<br />12：he
                  chip used to measure the temperature of circulating water is
                  disconnected<br />13：nternal temperature out of range<br />14：Internal
                  humidity out of range<br />15：The chip used to measure the
                  internal humidity is disconnected<br />16：External NTC
                  disconnected <br />17：******Reserve Alarm Position******<br />18：Communication
                  failure of temperature measuring chip on lower mirror<br />19：Weak
                  signal strength<br />20：Concentration data is invalid<br />21：LD
                  secondary temperature control anomaly <br />22：MCT secondary
                  temperature control anomaly<br />23：No laser current
                  detected<br />24：Low gas concentration in reference pool<br />25：Power
                  saving mode on<br />26：******Reserve Alarm Position******<br />27：Error
                  setting current<br />28：Error limiting current<br />29：Abnormal
                  supply voltage<br />30：The laser & TEC parameters not set
                  complete<br />31：The sensor detects rain<br />32：Water level
                  is too low
                </div>
                <span
                  class="v41_161"
                  style="
                    float: left;
                    padding-top: 1.8817vh;
                    font-size: 1.0753vw;
                  "
                  >The alarm message：{{ this.nh3_alarm }}
                </span>
              </el-tooltip>
            </div>
          </el-drawer>
          <div
            style="
              width: 13%;
              height: 100%;
              float: left;
              display: flex;
              align-items: center;
            "
          >
            <span
              class="v41_161"
              style="float: left; font-size: 1.1828vw"
              v-if="Lflag == true"
              >温度：{{ this.temperature }}
              ℃
            </span>
            <span
              class="v41_161"
              style="float: left; font-size: 1.1828vw"
              v-if="Lflag == false"
              >Temp：{{ this.temperature }}℃
            </span>
          </div>
          <div
            style="
              width: 15%;
              height: 100%;
              float: left;
              display: flex;
              align-items: center;
            "
          >
            <span
              class="v41_161"
              style="font-size: 1.1828vw"
              v-if="Lflag == true"
              >气压：{{ this.pressure }}
              hpa
            </span>
            <span
              class="v41_161"
              style="font-size: 1.1828vw"
              v-if="Lflag == false"
              >Pressure：{{ this.pressure }}hpa
            </span>
          </div>
          <div
            style="
              width: 20%;
              height: 100%;
              float: left;
              display: flex;
              align-items: center;
            "
          >
            <span
              class="v41_161"
              style="font-size: 1.1828vw"
              v-if="Lflag == true"
              >风向风速: {{ this.wind }}° {{ this.windspeed }}m/s
            </span>
            <span
              class="v41_161"
              style="font-size: 1.1828vw"
              v-if="Lflag == false"
              >WD&WS: {{ this.wind }}° {{ this.windspeed }}m/s
            </span>
          </div>

          <div
            style="
              width: 13%;
              height: 100%;
              display: flex;
              float: right;
              justify-content: right;
              align-items: center;
            "
          >
            <el-button
              v-if="Lflag == true"
              @click="drawer = true"
              type="button"
              style="
                width: 90%;
                height: 80%;
                background-color: rgba(25, 44, 250, 1);
                padding-left: 1.801vw;
                padding-top: 1.0376vh;
                align-items: center;
                display: flex;
                border-radius: 0.2688vw;
                color: #fff;
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
              "
            >
              查看设备信息
            </el-button>
            <el-button
              v-if="Lflag == false"
              @click="drawer = true"
              type="button"
              style="
                width: 90%;
                height: 80%;
                background-color: rgba(25, 44, 250, 1);
                padding-left: 1.801vw;
                padding-top: 1.0376vh;
                align-items: center;
                display: flex;
                border-radius: 0.2688vw;
                color: #fff;
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
              "
            >
              View device
            </el-button>
          </div>
          <div
            style="
              width: 13%;
              height: 100%;
              display: flex;
              float: right;
              justify-content: right;
              align-items: center;
            "
          >
            <el-button
              v-if="Lflag == true"
              @click="
                centerDialogVisible = true;
                key += 1;
              "
              type="button"
              style="
                width: 90%;
                height: 80%;
                background-color: rgba(25, 44, 250, 1);
                padding-left: 0.95vw;
                padding-top: 1.0376vh;
                align-items: center;
                display: flex;
                border-radius: 0.2688vw;
                color: #fff;
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              结束并标记本次走航
            </el-button>
            <el-button
              v-if="Lflag == false"
              @click="
                centerDialogVisible = true;
                key += 1;
              "
              type="button"
              style="
                width: 90%;
                height: 80%;
                background-color: rgba(25, 44, 250, 1);
                padding-left: 0.95vw;
                padding-top: 1.0376vh;
                align-items: center;
                display: flex;
                border-radius: 0.2688vw;
                color: #fff;
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              End&Mark
            </el-button>
          </div>
          <div
            style="
              width: 13%;
              height: 100%;
              display: flex;
              float: right;
              justify-content: right;
              align-items: center;
            "
          >
            <el-button
              v-if="Lflag == true"
              @click="his_click()"
              type="button"
              style="
                width: 90%;
                height: 80%;
                background-color: rgba(25, 44, 250, 1);
                padding-left: 0.8501vw;
                padding-top: 1.0376vh;
                align-items: center;
                display: flex;
                border-radius: 0.2688vw;
                color: #fff;
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              数据来源
            </el-button>
            <el-button
              v-if="Lflag == false"
              @click="his_click()"
              type="button"
              style="
                width: 90%;
                height: 80%;
                background-color: rgba(25, 44, 250, 1);
                padding-left: 0.8501vw;
                padding-top: 1.0376vh;
                align-items: center;
                display: flex;
                border-radius: 0.2688vw;
                color: #fff;
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              Data source
            </el-button>
          </div>
          <div
            style="
              width: 13%;
              height: 100%;
              display: flex;
              float: right;
              justify-content: right;
              align-items: center;
            "
          >
            <el-button
              v-if="Lflag == true"
              @click="setfilename()"
              type="button"
              style="
                width: 90%;
                height: 80%;
                background-color: rgba(25, 44, 250, 1);
                padding-left: 0.8501vw;
                padding-top: 1.0376vh;
                align-items: center;
                display: flex;
                border-radius: 0.2688vw;
                color: #fff;
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              下载
            </el-button>
            <el-button
              v-if="Lflag == false"
              @click="exportflag = true"
              type="button"
              style="
                width: 90%;
                height: 80%;
                background-color: rgba(25, 44, 250, 1);
                padding-left: 0.8501vw;
                padding-top: 1.0376vh;
                align-items: center;
                display: flex;
                border-radius: 0.2688vw;
                color: #fff;
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              Download
            </el-button>
          </div>
        </div>
        <el-dialog
          v-if="Lflag == true"
          :visible.sync="exportflag"
          @close="exportflag = false"
        >
          <el-tabs
            v-model="activeName"
            @tab-click="handleClick"
            style="padding-top: 0.0538vw; padding-bottom: 0.0538vw"
          >
            <el-tab-pane label="数据下载" name="first"
              ><div
                style="
                  width: 60%;
                  float: left;
                  display: flex;
                  justify-content: left;
                  align-items: center;
                  font-size: 1.0753vw;
                "
              >
                文件命名：
                <el-input style="width: 60%" v-model="filename"></el-input></div
            ></el-tab-pane>
            <el-tab-pane label="走航报告下载" name="second">
              <div
                style="
                  width: 60%;
                  float: left;
                  display: flex;
                  justify-content: left;
                  align-items: center;
                  font-size: 1.0753vw;
                "
              >
                文件命名：
                <el-input
                  id="pdfname"
                  style="width: 60%"
                  v-model="pdfname"
                ></el-input>
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="走航报告上传"
              name="third"
              v-if="upload_flag == true"
            >
              <div
                style="
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 1.0753vw;
                "
              >
                <el-upload
                  class="upload-demo"
                  drag
                  action="#"
                  :data="formData"
                  :headers="headers"
                  :before-upload="beforeUpload"
                  :http-request="customRequest"
                  accept=".pdf"
                  multiple
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    将文件拖到此处，或<em>点击上传</em>
                  </div>
                  <div class="el-upload__tip" slot="tip">只能上传pdf文件</div>
                </el-upload>
              </div>
            </el-tab-pane>
          </el-tabs>

          <div style="width: 100%; height: 5vh"></div>
          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="download()"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >确 定</el-button
            >
            <el-button
              @click="exportflag = false"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >取 消</el-button
            >
          </div>
        </el-dialog>
        <el-dialog
          v-if="Lflag == false"
          :visible.sync="exportflag"
          @close="exportflag = false"
        >
          <div
            style="
              width: 60%;
              float: left;
              display: flex;
              justify-content: left;
              align-items: center;
              font-size: 1.0753vw;
            "
          >
            File naming：
            <el-input style="width: 60%" v-model="filename"></el-input>
          </div>
          <div style="width: 100%; height: 5vh"></div>
          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="download()"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >Ok</el-button
            >
            <el-button
              @click="exportflag = false"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >Cancel</el-button
            >
          </div>
        </el-dialog>
        <el-dialog
          v-if="Lflag == true"
          title="结束本次走航并命名"
          :visible.sync="centerDialogVisible"
          @close="centerDialogVisible = false"
        >
          <div
            style="
              width: 45%;
              float: left;
              display: flex;
              justify-content: left;
              align-items: center;
              font-size: 1.0753vw;
            "
          >
            走航名称： <el-input style="width: 60%" v-model="name"></el-input>
          </div>
          <div class="block" style="width: 50%; float: left">
            <el-date-picker
              v-model="DataTime"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期 "
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00']"
              style="width: 100%"
              popper-class="picker-popper"
              :key="key"
            >
            </el-date-picker>
          </div>
          <div style="width: 100%; height: 5vh"></div>
          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="resetmap"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >确 定</el-button
            >
            <el-button
              @click="centerDialogVisible = false"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >取 消</el-button
            >
          </div>
        </el-dialog>
        <el-dialog
          v-if="Lflag == false"
          title="End the trip and name it"
          :visible.sync="centerDialogVisible"
          @close="centerDialogVisible = false"
        >
          <div
            style="
              width: 45%;
              float: left;
              display: flex;
              justify-content: left;
              align-items: center;
              font-size: 1.0753vw;
            "
          >
            Name： <el-input style="width: 60%" v-model="name"></el-input>
          </div>
          <div class="block" style="width: 50%; float: left">
            <el-date-picker
              v-model="DataTime"
              type="datetimerange"
              start-placeholder="Start date"
              end-placeholder="End date "
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00']"
              style="width: 100%"
              popper-class="picker-popper"
              :key="key"
            >
            </el-date-picker>
          </div>
          <div style="width: 100%; height: 5vh"></div>
          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="resetmap"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
            >
              OK</el-button
            >
            <el-button
              @click="centerDialogVisible = false"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >Cancel</el-button
            >
          </div>
        </el-dialog>
        <el-dialog
          title="选择数据来源"
          v-if="Lflag == true"
          :visible.sync="DataHis_flag"
          @close="DataHis_flag = false"
        >
          <div
            style="
              width: 45%;
              float: left;
              display: flex;
              justify-content: left;
              align-items: center;
              font-size: 1.0753vw;
            "
          >
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.name"
                :label="item.name"
                :value="item.name"
                @click.native="menuChange(item)"
              >
              </el-option>
            </el-select>
          </div>
          <div class="block" style="width: 50%; float: left">
            <el-date-picker
              v-model="HisTime"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期 "
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00']"
              style="width: 100%"
              popper-class="picker-popper"
              :key="key"
            >
            </el-date-picker>
          </div>
          <div style="width: 100%; height: 5vh"></div>
          <div style="width: 50%; height: 5vh; float: left">
            <el-switch
              class="v41_161"
              active-text="浓度档位调整"
              v-model="gears_reset"
              active-color="#13ce66"
            >
            </el-switch>
          </div>
          <div style="width: 50%; height: 5vh; float: right">
            <el-button type="text" @click="delete_his(false)"
              ><span class="v41_161" style="font-size: 0.9677vw"
                >删除当前记录</span
              >
            </el-button>
          </div>

          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="hisData"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >确 定</el-button
            >
            <el-button
              @click="DataHis_flag = false"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >取 消</el-button
            >
          </div>
        </el-dialog>
        <el-dialog
          title="Select a data source"
          v-if="Lflag == false"
          :visible.sync="DataHis_flag"
          @close="DataHis_flag = false"
        >
          <div
            style="
              width: 45%;
              float: left;
              display: flex;
              justify-content: left;
              align-items: center;
              font-size: 1.0753vw;
            "
          >
            <el-select v-model="value" placeholder="Please select">
              <el-option
                v-for="item in options"
                :key="item.name"
                :label="item.name"
                :value="item.name"
                @click.native="menuChange(item)"
              >
              </el-option>
            </el-select>
          </div>
          <div class="block" style="width: 50%; float: left">
            <el-date-picker
              v-model="HisTime"
              type="datetimerange"
              start-placeholder="Start date"
              end-placeholder="End date "
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00']"
              style="width: 100%"
              popper-class="picker-popper"
              :key="key"
            >
            </el-date-picker>
          </div>
          <div style="width: 100%; height: 5vh"></div>
          <div style="width: 50%; height: 5vh; float: left">
            <el-switch
              class="v41_161"
              active-text="Level adjustment"
              v-model="gears_reset"
              active-color="#13ce66"
            >
            </el-switch>
          </div>
          <div style="width: 50%; height: 5vh; float: right">
            <el-button type="text" @click="delete_his(false)"
              ><span class="v41_161" style="font-size: 0.9677vw"
                >Deletes the record</span
              >
            </el-button>
          </div>

          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="hisData"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >OK</el-button
            >
            <el-button
              @click="DataHis_flag = false"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >Cancel</el-button
            >
          </div>
        </el-dialog>
        <el-dialog
          v-if="Lflag == true"
          title="删除走航记录"
          :visible.sync="delete_flag"
          @close="delete_flag = false"
        >
          <div
            style="
              width: 100%;
              color: red;
              font-size: 1.0753vw;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            确定要删除&nbsp;&nbsp;"{{ this.value }}"&nbsp;&nbsp;此条记录吗？
          </div>
          <div style="width: 100%; height: 6vh"></div>
          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="delete_his(true)"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >确 定</el-button
            >
            <el-button
              @click="delete_flag = false"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >取 消</el-button
            >
          </div>
        </el-dialog>
        <el-dialog
          v-if="Lflag == false"
          title="Delete the record"
          :visible.sync="delete_flag"
          @close="delete_flag = false"
        >
          <div
            style="
              width: 100%;
              color: red;
              font-size: 1.0753vw;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            Do you want to delete the &nbsp;&nbsp;"{{
              this.value
            }}"&nbsp;&nbsp;record?
          </div>
          <div style="width: 100%; height: 6vh"></div>
          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="delete_his(true)"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >OK</el-button
            >
            <el-button
              @click="delete_flag = false"
              style="
                padding-top: 1vh;
                padding-left: 0.538vw;
                padding-bottom: 1vh;
                padding-right: 0.538vw;
              "
              >Cancel</el-button
            >
          </div>
        </el-dialog>
        <div
          style="
            width: 34.9462vw;
            height: 50%;
            position: relative;
            float: left;
            display: flex;
            align-items: center;
            justify-content: right;
          "
        >
          <div
            style="width: 100%; height: 100%; position: relative; float: left"
          >
            <div
              style="
                width: 20%;
                height: 100%;
                position: relative;
                float: left;
                display: flex;
                align-items: center;
                justify-content: left;
              "
            >
              <el-switch
                class="custom-switch"
                v-if="Lflag == true"
                active-text="视角跟随"
                v-model="follow"
                active-color="#13ce66"
              >
              </el-switch>
              <el-switch
                v-if="Lflag == false"
                class="v41_161"
                active-text="follows"
                v-model="follow"
                active-color="#13ce66"
              >
              </el-switch>
            </div>
            <div
              style="
                width: 40%;
                height: 100%;
                position: relative;
                float: right;
                display: flex;
                align-items: center;
                justify-content: right;
              "
            >
              <span
                v-if="zoomflag == true"
                class="v41_161"
                style="float: right; font-size: 1.1828vw"
                >{{ this.lable1 }} : {{ this.concentration1 }}
              </span>
            </div>
          </div>
        </div>
        <div
          style="
            width: 34.9462vw;
            height: 50%;
            position: relative;
            float: right;
            display: flex;
            align-items: center;
            justify-content: right;
          "
        >
          <div
            style="
              width: 30%;
              height: 100%;
              position: relative;
              float: left;
              display: flex;
              align-items: center;
              justify-content: left;
              font-size: 0.8602vw;
            "
          >
            地图模式&nbsp;
            <el-select
              id="mapControl"
              class="mapControl"
              v-model="mapStyle"
              size="mini"
              :popper-append-to-body="false"
              style="width: 5.6452vw; height: 1.3441vw"
              @change="changemaptype"
            >
              <el-option label="标准图" value="default"></el-option>
              <el-option label="卫星图" value="satellite"></el-option>
              <el-option
                label="卫星&路网图"
                value="satellite_RoadNet"
              ></el-option>
              <el-option
                label="卫星&楼块图"
                value="satellite_Build"
              ></el-option>
              <el-option
                label="卫星&楼块&路网图"
                value="satellite_Build_RoadNet"
              ></el-option>
            </el-select>
          </div>
          <div
            style="
              width: 30%;
              height: 100%;
              position: relative;
              float: left;
              display: flex;
              align-items: center;
              justify-content: left;
              font-size: 0.8602vw;
            "
          >
            透明度&nbsp;&nbsp;
            <el-slider
              style="width: 70%"
              v-model="alphaValue"
              :step="10"
              show-stops
              class="custom-slider"
              @change="resetwall(1, 1, 1, 1)"
            >
            </el-slider>
          </div>
          <div
            style="
              width: 40%;
              height: 100%;
              position: relative;
              float: right;
              display: flex;
              align-items: center;
              justify-content: right;
            "
          >
            <span
              v-if="zoomflag == true"
              class="v41_161"
              style="font-size: 1.1828vw"
              >{{ this.lable2 }} : {{ this.concentration2 }}
            </span>
            <span
              v-if="zoomflag == false && zoom_num == 1"
              class="v41_161"
              style="font-size: 1.1828vw"
              >{{ this.lable1 }} : {{ this.concentration1 }}
            </span>
            <span
              v-if="zoomflag == false && zoom_num == 2"
              class="v41_161"
              style="font-size: 1.1828vw"
              >{{ this.lable2 }} : {{ this.concentration2 }}
            </span>
            <span
              v-if="zoomflag == false && zoom_num == 3"
              class="v41_161"
              style="font-size: 1.1828vw"
              >{{ this.lable3 }} : {{ this.concentration3 }}
            </span>
            <span
              v-if="zoomflag == false && zoom_num == 4"
              class="v41_161"
              style="font-size: 1.1828vw"
              >{{ this.lable4 }} : {{ this.concentration4 }}
            </span>
          </div>
        </div>
      </div>
      <div style="width: 97%; height: 1vh"></div>
      <div
        style="width: 97%; height: 75%"
        id="container"
        ref="amap"
        class="container"
      >
        <div
          style="width: 2%; height: 100%; float: right"
          v-if="zoomflag == false"
        >
          <el-slider
            v-model="Height_prop"
            vertical
            height="63vh"
            @change="prop('5')"
            :min="0.1"
            :max="10"
            :step="0.1"
          >
          </el-slider>
        </div>
        <el-button
          id="btn"
          v-if="zoomflag == false"
          class="btn"
          type="text"
          icon="el-icon-close"
          @click="shrink"
        ></el-button>
        <div style="width: 100%; height: 47%">
          <el-button
            v-if="zoomflag == true"
            id="btn1"
            class="btn"
            type="text"
            icon="el-icon-full-screen"
            @click="magnify('1')"
          ></el-button>

          <div
            id="con1"
            style="width: 47%; height: 30vh; position: relative; float: left"
          >
            <div style="width: 100%; height: 100%" id="container1"></div>
            <div
              class="chart-container"
              v-show="showChart1"
              style="width: 42%; height: 36%"
            >
              <div
                id="lineChart1"
                style="width: 14.3011vw; height: 11.7vh"
              ></div>
            </div>
            <button
              v-if="color_flag1 == true"
              class="toggle-button"
              :class="{ expanded: isExpanded1 }"
              @click="set_chat(1)"
              :style="buttonStyle1"
            >
              <span class="arrow">{{ arrowSymbol1 }}</span>
            </button>

            <div
              v-if="color_flag1 == true"
              id="VerticalColorScale1"
              style="
                position: absolute;
                width: 3.3vw;
                height: 15vh;
                background-color: rgb(239, 235, 235);
                opacity: 0.7;
                font-size: 0.5376vw;
                z-index: 100;
                left: 90%;
                top: 45%;
              "
            >
              <div
                class="mc-ui-absolute-pane"
                style="
                  position: absolute;
                  left: 10.6735%;
                  top: 16.0804%;
                  width: 20%;
                  height: 80%;
                "
              >
                <div
                  style="
                    width: 100%;
                    height: 16%;
                    background-color: rgb(146, 2, 151);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 16%;

                    background-color: rgb(163, 4, 15);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 17%;
                    background-color: rgb(230, 4, 4);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 17%;
                    background-color: rgb(234, 156, 6);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 17%;
                    background-color: rgb(234, 199, 6);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 17%;
                    background-color: rgb(6, 234, 119);
                  "
                ></div>
              </div>
              <label
                id="VerticalColorScale_lable1"
                style="
                  position: absolute;
                  top: 3.02513%;
                  width: 100%;
                  font-size: 0.4301vw;
                  text-align: center;
                "
                >(ppb)</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 79.8995%;
                  width: 30%;
                "
                >{{ standard1[0] }}</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 66.5829%;
                  width: 30%;
                "
                >{{ standard1[1] }}</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 53.6432%;
                  width: 30%;
                "
                >{{ standard1[2] }}</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 40.4523%;
                  width: 30%;
                "
                >{{ standard1[3] }}</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 27.6382%;
                  width: 30%;
                "
                >{{ standard1[4] }}</label
              >
            </div>
          </div>

          <div
            style="width: 3%; height: 30vh; position: relative; float: left"
            v-if="zoomflag == true"
          >
            <el-slider
              v-model="Height_prop1"
              vertical
              height="30vh"
              @change="prop('1')"
              :min="0.1"
              :max="10"
              :step="0.1"
            >
            </el-slider>
          </div>
          <div
            style="width: 3%; height: 30vh; position: relative; float: left"
            v-if="zoomflag == true"
          >
            <el-slider
              v-model="Height_prop2"
              vertical
              height="30vh"
              @change="prop('2')"
              :min="0.1"
              :max="10"
              :step="0.1"
            >
            </el-slider>
          </div>
          <el-button
            v-if="zoomflag == true"
            id="btn1"
            class="btn"
            type="text"
            icon="el-icon-full-screen"
            @click="magnify('2')"
          ></el-button>
          <div
            id="con2"
            style="width: 47%; height: 30vh; position: relative; float: left"
          >
            <div style="width: 100%; height: 100%" id="container2"></div>
            <div
              class="chart-container"
              v-show="showChart2"
              style="width: 42%; height: 36%"
            >
              <div
                id="lineChart2"
                style="width: 14.3011vw; height: 11.7vh"
              ></div>
            </div>
            <button
              v-if="color_flag2 == true"
              class="toggle-button"
              :class="{ expanded: isExpanded2 }"
              @click="set_chat(2)"
              :style="buttonStyle2"
            >
              <span class="arrow">{{ arrowSymbol2 }}</span>
            </button>
            <div
              v-if="color_flag2 == true"
              id="VerticalColorScale2"
              style="
                position: absolute;
                width: 3.3vw;
                height: 15vh;
                background-color: rgb(239, 235, 235);
                opacity: 0.7;
                font-size: 0.5376vw;
                z-index: 100;
                left: 90%;
                top: 45%;
              "
            >
              <div
                class="mc-ui-absolute-pane"
                style="
                  position: absolute;
                  left: 10.6735%;
                  top: 16.0804%;
                  width: 20%;
                  height: 80%;
                "
              >
                <div
                  style="
                    width: 100%;
                    height: 16%;
                    background-color: rgb(146, 2, 151);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 16%;

                    background-color: rgb(163, 4, 15);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 17%;
                    background-color: rgb(230, 4, 4);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 17%;
                    background-color: rgb(234, 156, 6);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 17%;
                    background-color: rgb(234, 199, 6);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 17%;
                    background-color: rgb(6, 234, 119);
                  "
                ></div>
              </div>
              <label
                style="
                  position: absolute;
                  top: 3.02513%;
                  width: 100%;
                  font-size: 0.4301vw;
                  text-align: center;
                "
                >(ppb)</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 79.8995%;
                  width: 30%;
                "
                >{{ standard2[0] }}</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 66.5829%;
                  width: 30%;
                "
                >{{ standard2[1] }}</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 53.6432%;
                  width: 30%;
                "
                >{{ standard2[2] }}</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 40.4523%;
                  width: 30%;
                "
                >{{ standard2[3] }}</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 27.6382%;
                  width: 30%;
                "
                >{{ standard2[4] }}</label
              >
            </div>
          </div>
        </div>
        <div style="width: 100%; height: 6%">
          <div
            style="
              width: 34.9462vw;
              height: 50%;
              position: relative;
              float: left;
              display: flex;
              align-items: center;
              justify-content: right;
            "
          >
            <span
              v-if="zoomflag == true"
              class="v41_161"
              style="float: right; font-size: 1.1828vw"
              >{{ this.lable3 }} : {{ this.concentration3 }}
            </span>
          </div>
          <div
            style="
              width: 34.9462vw;
              height: 50%;
              position: relative;
              float: right;
              display: flex;
              align-items: center;
              justify-content: right;
            "
          >
            <span
              v-if="zoomflag == true"
              class="v41_161"
              style="font-size: 1.1828vw"
              >{{ this.lable4 }} : {{ this.concentration4 }}
            </span>
          </div>
        </div>
        <div style="width: 100%; height: 47%">
          <el-button
            v-if="zoomflag == true"
            id="btn1"
            class="btn"
            type="text"
            icon="el-icon-full-screen"
            @click="magnify('3')"
          ></el-button>
          <div
            id="con3"
            style="width: 47%; height: 30vh; position: relative; float: left"
          >
            <div style="width: 100%; height: 100%" id="container3"></div>
            <div
              class="chart-container"
              v-show="showChart3"
              style="width: 42%; height: 36%"
            >
              <div
                id="lineChart3"
                style="width: 14.3011vw; height: 11.7vh"
              ></div>
            </div>
            <button
              v-if="color_flag3 == true"
              class="toggle-button"
              :class="{ expanded: isExpanded3 }"
              @click="set_chat(3)"
              :style="buttonStyle3"
            >
              <span class="arrow">{{ arrowSymbol3 }}</span>
            </button>
            <div
              v-if="color_flag3 == true"
              id="VerticalColorScale3"
              style="
                position: absolute;
                width: 3.3vw;
                height: 15vh;
                background-color: rgb(239, 235, 235);
                opacity: 0.7;
                font-size: 0.5376vw;
                z-index: 100;
                left: 90%;
                top: 45%;
              "
            >
              <div
                class="mc-ui-absolute-pane"
                style="
                  position: absolute;
                  left: 10.6735%;
                  top: 16.0804%;
                  width: 20%;
                  height: 80%;
                "
              >
                <div
                  style="
                    width: 100%;
                    height: 16%;
                    background-color: rgb(146, 2, 151);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 16%;

                    background-color: rgb(163, 4, 15);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 17%;
                    background-color: rgb(230, 4, 4);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 17%;
                    background-color: rgb(234, 156, 6);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 17%;
                    background-color: rgb(234, 199, 6);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 17%;
                    background-color: rgb(6, 234, 119);
                  "
                ></div>
              </div>
              <label
                style="
                  position: absolute;
                  top: 3.02513%;
                  width: 100%;
                  font-size: 0.4301vw;
                  text-align: center;
                "
                >(ppm)</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 79.8995%;
                  width: 30%;
                "
                >{{ standard3[0] }}</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 66.5829%;
                  width: 30%;
                "
                >{{ standard3[1] }}</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 53.6432%;
                  width: 30%;
                "
                >{{ standard3[2] }}</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 40.4523%;
                  width: 30%;
                "
                >{{ standard3[3] }}</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 27.6382%;
                  width: 30%;
                "
                >{{ standard3[4] }}</label
              >
            </div>
          </div>

          <div
            style="width: 3%; height: 30vh; position: relative; float: left"
            v-if="zoomflag == true"
          >
            <el-slider
              v-model="Height_prop3"
              vertical
              height="30vh"
              @change="prop('3')"
              :min="0.1"
              :max="10"
              :step="0.1"
            >
            </el-slider>
          </div>
          <div
            style="width: 3%; height: 30vh; position: relative; float: left"
            v-if="zoomflag == true"
          >
            <el-slider
              v-model="Height_prop4"
              vertical
              height="30vh"
              @change="prop('4')"
              :min="0.1"
              :max="10"
              :step="0.1"
            >
            </el-slider>
          </div>
          <el-button
            v-if="zoomflag == true"
            id="btn1"
            class="btn"
            type="text"
            icon="el-icon-full-screen"
            @click="magnify('4')"
          ></el-button>
          <div
            id="con4"
            style="width: 47%; height: 30vh; position: relative; float: left"
          >
            <div id="container4" style="width: 100%; height: 100%"></div>
            <div
              class="chart-container"
              v-show="showChart4"
              style="width: 42%; height: 36%"
            >
              <div
                id="lineChart4"
                style="width: 14.3011vw; height: 11.7vh"
              ></div>
            </div>
            <button
              v-if="color_flag4 == true"
              class="toggle-button"
              :class="{ expanded: isExpanded4 }"
              @click="set_chat(4)"
              :style="buttonStyle4"
            >
              <span class="arrow">{{ arrowSymbol4 }}</span>
            </button>
            <div
              v-if="color_flag4 == true"
              id="VerticalColorScale4"
              style="
                position: absolute;
                width: 3.3vw;
                height: 15vh;
                background-color: rgb(239, 235, 235);
                opacity: 0.7;
                font-size: 0.5376vw;
                z-index: 1000;
                left: 90%;
                top: 45%;
              "
            >
              <div
                class="mc-ui-absolute-pane"
                style="
                  position: absolute;
                  left: 10.6735%;
                  top: 16.0804%;
                  width: 20%;
                  height: 80%;
                "
              >
                <div
                  style="
                    width: 100%;
                    height: 16%;
                    background-color: rgb(146, 2, 151);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 16%;

                    background-color: rgb(163, 4, 15);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 17%;
                    background-color: rgb(230, 4, 4);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 17%;
                    background-color: rgb(234, 156, 6);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 17%;
                    background-color: rgb(234, 199, 6);
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 17%;
                    background-color: rgb(6, 234, 119);
                  "
                ></div>
              </div>
              <label
                style="
                  position: absolute;
                  top: 3.02513%;
                  width: 100%;
                  font-size: 0.4301vw;
                  text-align: center;
                "
                >(ppb)</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 79.8995%;
                  width: 30%;
                "
                >{{ standard4[0] }}</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 66.5829%;
                  width: 30%;
                "
                >{{ standard4[1] }}</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 53.6432%;
                  width: 30%;
                "
                >{{ standard4[2] }}</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 40.4523%;
                  width: 30%;
                "
                >{{ standard4[3] }}</label
              >
              <label
                style="
                  position: absolute;
                  left: 35.1224%;
                  top: 27.6382%;
                  width: 30%;
                "
                >{{ standard4[4] }}</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkToken } from "@/api/user"; //将checkToken方法从/api/user导入，作用是发送网络请求
import { askecomap } from "@/api/data";
import { askECOdata } from "@/api/data";
import { askhistory } from "@/api/data";
import { ask_plume } from "@/api/data";
import { delete_timelag } from "@/api/data";
import { upload_ECO_report } from "@/api/data";
import { askhistory_by_end_time } from "@/api/data";
import { select_data_by_time } from "@/api/data";
import { get_time_lag } from "@/api/data";
import { setstart } from "@/api/data";
import { setend } from "@/api/data";
import AMapLoader from "@amap/amap-jsapi-loader";
import * as echarts from "echarts";
import axios from "axios";
export default {
  data() {
    return {
      username: "", //用于接收从state里传过来的用户名
      project: "", //用于接收从state里传过来的项目名
      Lflag: true,
      zoomflag: true, //用于区分地图是否放大
      zoom_num: "",
      map1: "",
      map2: "",
      map3: "",
      map4: "",
      concentration1: "", //CH4浓度
      concentration2: "", //N2O浓度
      concentration3: "", //浓度3
      concentration4: "", //浓度4
      concentration5: "", //浓度5
      concentrationList1: [], //CH4浓度数组
      concentrationList2: [], //N2O浓度数组
      concentrationList3: [], //浓度3
      concentrationList4: [], //浓度4
      concentrationList5: [], //浓度5
      drive_direction: "", //行驶方向
      car_speed: "", //车速
      time: "", //时间
      windspeed: "", //风速
      wind: "", //风向
      longitude: 0, //经度
      latitude: 0, //纬度
      longitudeList: [], //经度数组
      latitudeList: [], //纬度数组
      temperature: "", //温度
      pressure: "", //气压
      indexList: [], //表头数组
      site: "",
      LLlist: [], //实时数据经纬度数组
      HLLlist: [], //历史数据经纬度数组
      wall1: "", //墙面
      object3Dlayer1: "",
      wall2: "",
      object3Dlayer2: "",
      wall3: "",
      object3Dlayer3: "",
      wall4: "",
      object3Dlayer4: "",
      temp: [], //临时存放经纬度
      lable1: "CH4_ppb",
      lable2: "N2O_ppb",
      lable3: "CO2_ppm",
      lable4: "NH3_ppb",
      refreshflag: false, //是否索要实时数据的标志位
      con_cnt: 0, //实时数据错误计数，连续30次数据为Wrong data，判断为连接异常，停止实时数据索要
      Height_prop1: 1, //浓度1高度比例
      Height_prop2: 1, //浓度2高度比例
      Height_prop3: 1, //浓度3高度比例
      Height_prop4: 1, //浓度4高度比例
      Height_prop: 1,
      standard_color: [
        [0.0235, 0.9176, 0.4667, 0.5], //绿
        [0.9176, 0.78, 0.0235, 0.5], //黄
        [0.9176, 0.6117, 0.0235, 0.5], //橙
        [0.9019, 0.1568, 0.1568, 0.5], //红
        [0.639, 0.01568, 0.0588, 0.5], //赤
        [0.5725, 0.00784, 0.592, 0.5], //紫
      ], //颜色挡位
      // standard1: [2000, 2050, 2100, 2200, 2500], //浓度1浓度挡位
      standard1: [2100, 2200, 2500, 3000, 4000], //浓度1浓度挡位
      standard2: [350, 370, 400, 450, 500], //浓度2浓度挡位
      standard3: [400, 500, 700, 1000, 1500], //浓度3浓度挡位
      standard4: [20, 50, 100, 200, 400], //浓度4浓度挡位
      color_flag1: true, //用于隐藏颜色图例的标志位
      color_flag2: true,
      color_flag3: true,
      color_flag4: true,
      marker1: "", //小车
      marker2: "",
      marker3: "",
      marker4: "",
      drawer: false, //设备信息标志位
      direction: "ltr", //设备信息弹出方向
      oss_ch4: "",
      ch4_alarm: "",
      oss_n2o: "",
      n2o_alarm: "",
      oss_co2: "",
      oss_nh3: "",
      nh3_alarm: "",
      centerDialogVisible: false, //重新绘图界面标志位
      start_time: "", //重新绘图开始时间
      compute_flag: false,
      sun: "",
      Time: "",
      cloud: "0.1",
      grid_lon1: "121",
      grid_lat1: "34",
      grid_lon2: "122",
      grid_lat2: "34.5",
      xs: "121.12345",
      ys: "34.12345",
      hs: "2",
      z: "3",
      z0: "0.1",
      option1: [
        {
          value: "强",
          label: "强",
        },
        {
          value: "中",
          label: "中",
        },
        {
          value: "弱",
          label: "弱",
        },
      ],
      option2: [
        {
          value: "白天",
          label: "白天",
        },
        {
          value: "黑夜",
          label: "黑夜",
        },
      ],
      name: "", //走航名称
      DataTime: [], //走航数据时间范围
      key: 0, //时间选择器刷新k
      HisTime: [], //历史数据时间范围
      start_flag: false, //用于记录起始时间
      DataHis_flag: false, //查看历史数据弹框
      options: [], //历史数据列表
      value: null, //查询历史数据时选择的name
      follow: false, //位置跟随
      optionMin: "", //加载提示
      gears_reset: false, //档位调整
      delete_flag: false, //删除确认界面
      init_flag: false,
      exportTable: [], //用于数据导出的数组
      exportflag: false, //数据导出弹窗
      filename: "", //文件名称
      mapStyle: "satellite_RoadNet", //布局选择值
      layers1: "", //初始布局
      layers2: "",
      layers3: "",
      layers4: "",
      rt_layers1: [], //实时布局
      rt_layers2: [],
      rt_layers3: [],
      rt_layers4: [],
      alphaValue: 50, //透明度%
      showChart1: true,
      isExpanded1: false,
      showChart2: true,
      isExpanded2: false,
      showChart3: true,
      isExpanded3: false,
      showChart4: true,
      isExpanded4: false,
      myChart1: "", //图表
      listdata1: [], //图表数据数组
      myChart2: "",
      listdata2: [],
      myChart3: "",
      listdata3: [],
      myChart4: "",
      listdata4: [],
      activeName: "first",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      formData: {
        site: this.$store.state.site,
        name: this.$store.state.eco_value,
      },
      downloadmode: 1,
      pdfname: "",
      pdflist: [],
      upload_flag: false,
    };
  },
  created() {
    //created里的指令在该组件创建的时候执行，created比mounted更早执行
    //const selectedProjectId = this.$store.state.projectId; //将项目选择界面所选择的项目id传给selectedProjectId，用于发送网络请求获取对应项目的信息
    //console.log(selectedProjectId); //打印，用于测试
    this.Lflag = this.$store.state.Language; //store里的传到该组件
    checkToken(this.$qs.stringify({ token: this.$store.state.token })).then(
      (res) => {
        //发送请求：让后端检查token，后端返回的状态在request的response interceptor里拦截并作出相应的响应
        //console.log(res);
      }
    );
    this.$store.commit("restoreproject"); //将保存在本地的项目名传给state
    this.project = this.$store.state.project; //将state里的project赋值给组件里的project
    this.table = "`" + this.$store.state.site + "_map" + "`"; //将分割出的第二部分赋值给table并且加上后缀
    this.database = this.project[0];
    console.log(this.database);
    console.log(this.table);
    askecomap(
      this.$qs.stringify({ database: this.database, table: this.table })
    ).then((res) => {
      console.log(res);
      this.indexList = res.data;
    });
    this.site = this.$store.state.site + "_dat";
    get_time_lag(this.$qs.stringify({ site: this.$store.state.site })).then(
      (res) => {
        this.options = res.data;
        this.options.unshift({ name: "实时数据", start: "", end: "" });
        console.log(this.options);
        var text = "数据加载中";
        if (this.Lflag == false) {
          text = "The data is loading";
        }
        this.optionMin = this.$message({
          showClose: true,
          message: text,
          duration: 30 * 1000,
          center: true,
          type: "success",
          customClass: "customclass",
        });
        console.log(this.$store.state.eco_value);
        if (
          this.$store.state.eco_value == null ||
          this.$store.state.eco_value == "实时数据"
        ) {
          askhistory_by_end_time(
            this.$qs.stringify({ site: this.$store.state.site })
          ).then((res) => {
            console.log(res.data);
            this.exportTable = res.data;
            if (
              res.data.length > 1 ||
              this.$store.state.eco_value == "实时数据"
            ) {
              if (this.$store.state.eco_value == "实时数据") {
                this.value = this.$store.state.eco_value;
              }
              this.$store.commit("seteco_value", "实时数据");
              this.value = this.options[this.options.length - 1].name;
              this.HisTime[0] = this.options[this.options.length - 1].start;
              this.HisTime[1] = this.options[this.options.length - 1].end;
              for (let i = 5; i <= res.data.length - 1; i++) {
                this.Datahandle(res.data[i]);
                if (i == 5) {
                  this.DataTime[0] = this.time;
                  this.start_flag = true;
                  console.log(this.time);
                } else if (i == res.data.length - 1) {
                  this.DataTime[1] = this.time;
                }
                if (this.longitude != 0 && this.latitude != 0) {
                  this.HLLlist.push([this.longitude, this.latitude]);
                }
              }
              this.initAMap(0, 1, 1, 1, 1);
              if (this.optionMin) {
                this.optionMin.close();
              }
              this.refreshflag = true;
            } else {
              this.value = this.options[this.options.length - 1].name;
              this.HisTime[0] = this.options[this.options.length - 1].start;
              this.HisTime[1] = this.options[this.options.length - 1].end;
              this.$store.commit("seteco_value", this.value);
              this.$store.commit("seteco_time", this.HisTime);
              console.log(
                this.options[this.options.length - 1].start,
                this.HisTime[1],
                this.$store.state.site
              );
              select_data_by_time(
                this.$qs.stringify({
                  start: this.HisTime[0],
                  end: this.HisTime[1],
                  table: this.$store.state.site,
                })
              ).then((res) => {
                console.log(res.data);
                this.exportTable = res.data;
                this.value = "实时数据";
                this.HisTime = [];
                this.concentrationList1 = [];
                this.concentrationList2 = [];
                this.concentrationList3 = [];
                this.concentrationList4 = [];
                this.concentrationList5 = [];
                this.longitudeList = [];
                this.latitudeList = [];
                this.HLLlist = [];
                this.listdata1 = [];
                this.listdata2 = [];
                this.listdata3 = [];
                this.listdata4 = [];
                this.concentration1 = 0;
                this.concentration2 = 0;
                this.concentration3 = 0;
                this.concentration4 = 0;
                this.concentration5 = 0;
                for (let i = 5; i <= res.data.length - 1; i++) {
                  this.Datahandle(res.data[i]);
                  if (this.longitude != 0 && this.latitude != 0) {
                    this.HLLlist.push([this.longitude, this.latitude]);
                  }
                }
                //console.log(this.HLLlist);
                this.initAMap(0, 1, 1, 1, 1);
                if (this.optionMin) {
                  this.optionMin.close();
                }
                this.refreshflag = false;
              });
            }
          });
        } else {
          this.value = this.$store.state.eco_value;
          this.HisTime = this.$store.state.eco_time;
          // for (let i = 0; i < this.options.length - 1; i++) {
          //   if (this.options[i].name == this.value) {
          //     this.HisTime[0] = this.options[i].start;
          //     this.HisTime[1] = this.options[i].end;
          //   }
          // }
          console.log(this.HisTime[0], this.HisTime[1], this.$store.state.site);
          select_data_by_time(
            this.$qs.stringify({
              start: this.HisTime[0],
              end: this.HisTime[1],
              table: this.$store.state.site,
            })
          ).then((res) => {
            console.log(res.data);
            this.exportTable = res.data;
            this.value = "实时数据";
            this.HisTime = [];
            this.concentrationList1 = [];
            this.concentrationList2 = [];
            this.concentrationList3 = [];
            this.concentrationList4 = [];
            this.concentrationList5 = [];
            this.longitudeList = [];
            this.latitudeList = [];
            this.HLLlist = [];
            this.listdata1 = [];
            this.listdata2 = [];
            this.listdata3 = [];
            this.listdata4 = [];
            this.concentration1 = 0;
            this.concentration2 = 0;
            this.concentration3 = 0;
            this.concentration4 = 0;
            this.concentration5 = 0;
            for (let i = 5; i <= res.data.length - 1; i++) {
              this.Datahandle(res.data[i]);
              if (this.longitude != 0 && this.latitude != 0) {
                this.HLLlist.push([this.longitude, this.latitude]);
              }
            }
            //console.log(this.HLLlist);
            this.initAMap(0, 1, 1, 1, 1);
            if (this.optionMin) {
              this.optionMin.close();
            }
            this.refreshflag = false;
          });
        }
      }
    );
    // askhistory(
    //   this.$qs.stringify({ database: this.database, table: this.site })
    // ).then((res) => {
    //   console.log(res.data);
    //   for (let i = res.data.length - 5; i >= 0; i--) {
    //     this.Datahandle(res.data[i]);
    //     if (i == res.data.length - 5) {
    //       this.DataTime[0] = this.time;
    //       this.start_flag = true;
    //       console.log(this.time);
    //     } else if (i == 0) {
    //       this.DataTime[1] = this.time;
    //     }
    //     this.HLLlist.push([this.longitude, this.latitude]);
    //   }
    //   //console.log(this.HLLlist);
    //   this.initAMap(1, 1, 1, 1, 1);
    //   this.refreshflag = true;
    // });

    // askECOdata(this.$qs.stringify({ site: this.site })).then((res) => {
    //   //console.log(res);
    //   this.Datahandle(res.data[0]);
    //   //this.indexList = res.data;
    // });

    this.username = this.$store.state.username; //将store里的用户名传到本组件
    //console.log(this.project);
    this.concentration1 = 0;
    this.concentration2 = 0;
    this.concentration3 = 0;
    this.concentration4 = 0;
    this.time = "2024/7/8 12:00:00";
    this.wind = 0;
    this.windspeed = 0;
    // this.latitude = 39.997245;
    // this.longitude = 116.333926;
    this.temperature = 0;
    this.pressure = 0;
  },
  mounted() {
    // 隔1s刷新一次数据
    this.refreshData = setInterval(() => {
      if (this.refreshflag == true && this.init_flag == true) {
        askECOdata(this.$qs.stringify({ site: this.site })).then((res) => {
          console.log(res);
          if (res.data[0].infor != "Wrong data") {
            this.Datahandle(res.data[0]);
            if (this.start_flag == false) {
              this.DataTime[0] = this.time;
            } else {
              if (this.centerDialogVisible == false) {
                this.DataTime[1] = this.time;
              }
            }
            //console.log(this.DataTime[1]);
            //console.log([this.longitude, this.latitude]);
            if (this.longitude != 0 && this.latitude != 0) {
              this.HLLlist.push([this.longitude, this.latitude]);
            }
            this.refresh(4);
            if (this.showChart1 == true) {
              this.myChart1.setOption({
                series: [
                  {
                    data: this.listdata1,
                  },
                ],
              });
            }
            if (this.showChart2 == true) {
              this.myChart2.setOption({
                series: [
                  {
                    data: this.listdata2,
                  },
                ],
              });
            }
            if (this.showChart3 == true) {
              this.myChart3.setOption({
                series: [
                  {
                    data: this.listdata3,
                  },
                ],
              });
            }
            if (this.showChart4 == true) {
              this.myChart4.setOption({
                series: [
                  {
                    data: this.listdata4,
                  },
                ],
              });
            }
          }
          //this.indexList = res.data;
        });
      }
      //console.log(this.zoom_num);
      if (this.follow == true) {
        if (this.zoom_num == 1) {
          this.map1.setCenter([this.longitude, this.latitude]);
        } else if (this.zoom_num == 2) {
          this.map2.setCenter([this.longitude, this.latitude]);
        }
        // this.map2.setCenter([this.longitude, this.latitude]);
        // this.map3.setCenter([this.longitude, this.latitude]);
        else if (this.zoom_num == 4) {
          this.map4.setCenter([this.longitude, this.latitude]);
        } else if (this.zoom_num == 0) {
          this.map1.setCenter([this.longitude, this.latitude]);
          this.map2.setCenter([this.longitude, this.latitude]);
          this.map4.setCenter([this.longitude, this.latitude]);
        }
      }
    }, 1000);
  },
  computed: {
    buttonStyle1() {
      return {
        top: this.isExpanded1 ? "auto" : "63.9%",
        bottom: this.isExpanded1 ? ".1075vw" : "auto",
        right: this.isExpanded1 ? "auto" : "57.8%",
        left: this.isExpanded1 ? ".1075vw" : "auto",
      };
    },
    arrowSymbol1() {
      return this.isExpanded1 ? "∧" : "∨";
    },
    buttonStyle2() {
      return {
        top: this.isExpanded2 ? "auto" : "63.9%",
        bottom: this.isExpanded2 ? ".1075vw" : "auto",
        right: this.isExpanded2 ? "auto" : "57.8%",
        left: this.isExpanded2 ? ".1075vw" : "auto",
      };
    },
    arrowSymbol2() {
      return this.isExpanded2 ? "∧" : "∨";
    },
    buttonStyle3() {
      return {
        top: this.isExpanded3 ? "auto" : "63.9%",
        bottom: this.isExpanded3 ? ".1075vw" : "auto",
        right: this.isExpanded3 ? "auto" : "57.8%",
        left: this.isExpanded3 ? ".1075vw" : "auto",
      };
    },
    arrowSymbol3() {
      return this.isExpanded3 ? "∧" : "∨";
    },
    buttonStyle4() {
      return {
        top: this.isExpanded4 ? "auto" : "63.9%",
        bottom: this.isExpanded4 ? ".1075vw" : "auto",
        right: this.isExpanded4 ? "auto" : "57.8%",
        left: this.isExpanded4 ? ".1075vw" : "auto",
      };
    },
    arrowSymbol4() {
      return this.isExpanded4 ? "∧" : "∨";
    },
  },
  methods: {
    beforeUpload(file) {
      const isPDF = file.type === "application/pdf";
      if (!isPDF) {
        var text = "请上传PDF文件";
        if (this.Lflag == false) {
          text = "Please upload a PDF file";
        }
        this.optionMin = this.$message({
          showClose: true,
          message: text,
          duration: 2 * 1000,
          center: true,
          type: "error",
          customClass: "customclass",
        });
        return false;
      }
      return true;
    },
    customRequest(options) {
      const formData = new FormData();
      formData.append("file", options.file);
      formData.append("site", this.$store.state.site);
      formData.append("name", this.$store.state.eco_value);

      axios
        .post("/upload_ECO_report", formData, {
          headers: this.headers,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            // 更新上传进度
            options.onProgress({ percent: percentCompleted });
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.length > 0) {
            var text = "上传成功";
            if (this.Lflag == false) {
              text = "The upload is successful";
            }
            this.optionMin = this.$message({
              showClose: true,
              message: text,
              duration: 2 * 1000,
              center: true,
              type: "success",
              customClass: "customclass",
            });
          }
        });
    },
    handleClick(tab) {
      if (tab.name == "first") {
        this.downloadmode = 1;
      } else if (tab.name == "second") {
        this.pdfname = "暂无走航报告";
        // 将 disabled 属性设置为 true
        this.downloadmode = 2;
        let url =
          "http://121.40.95.239:8088/download_ECO_report?" +
          "site=" +
          this.$store.state.site +
          "&name=" +
          this.$store.state.eco_value;
        // let a = document.createElement("a");
        // a.href = url;
        // a.click();
        axios
          .get("/download_ECO_report", {
            responseType: "blob", // 设置响应类型为 blob
            params: {
              site: this.$store.state.site,
              name: this.$store.state.eco_value,
            },
          })
          .then((response) => {
            const contentDisposition = response.headers["content-disposition"];
            this.pdfname = "downloaded_file.pdf";
            if (contentDisposition) {
              const match = contentDisposition.match(/filename="?([^"]+)"?/);
              if (match) {
                this.pdfname = decodeURIComponent(match[1]);
                this.pdfname = this.pdfname.replace(".pdf", "");
              }
            }

            this.pdflist = response.data;
            // 将 disabled 属性设置为 true
          })
          .catch((error) => {});
      } else if (tab.name == "third") {
        this.downloadmode = 3;
      }
    },
    download() {
      if (this.downloadmode == 1) {
        this.exportTableToExcel(this.exportTable, this.indexList);
      } else if (this.downloadmode == 2) {
        console.log(this.pdflist);
        const url = window.URL.createObjectURL(new Blob([this.pdflist]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", this.pdfname + ".pdf"); // 设置下载文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    set_chat(id) {
      if (id == 1) {
        if (this.showChart1 == true) {
          this.showChart1 = false;
          this.isExpanded1 = true;
          this.myChart1.dispose();
        } else {
          this.showChart1 = true;
          this.isExpanded1 = false;
          this.draw_list(1);
        }
      } else if (id == 2) {
        if (this.showChart2 == true) {
          this.showChart2 = false;
          this.isExpanded2 = true;
          this.myChart2.dispose();
        } else {
          this.showChart2 = true;
          this.isExpanded2 = false;
          this.draw_list(2);
        }
      } else if (id == 3) {
        if (this.showChart3 == true) {
          this.showChart3 = false;
          this.isExpanded3 = true;
          this.myChart3.dispose();
        } else {
          this.showChart3 = true;
          this.isExpanded3 = false;
          this.draw_list(3);
        }
      } else if (id == 4) {
        if (this.showChart4 == true) {
          this.showChart4 = false;
          this.isExpanded4 = true;
          this.myChart4.dispose();
        } else {
          this.showChart4 = true;
          this.isExpanded4 = false;
          this.draw_list(4);
        }
      }
    },
    changemaptype(value) {
      console.log(value);
      this.map1.destroy();
      this.map2.destroy();
      this.map3.destroy();
      this.map4.destroy();
      switch (value) {
        case "default":
          this.rt_layers1 = [];
          this.rt_layers2 = [];
          this.rt_layers3 = [];
          this.rt_layers4 = [];
          break;
        case "satellite":
          this.rt_layers1 = [this.layers1.satellite];
          this.rt_layers2 = [this.layers2.satellite];
          this.rt_layers3 = [this.layers3.satellite];
          this.rt_layers4 = [this.layers4.satellite];
          break;
        case "satellite_RoadNet":
          this.rt_layers1 = [this.layers1.satellite, this.layers1.roadNet];
          this.rt_layers2 = [this.layers2.satellite, this.layers2.roadNet];
          this.rt_layers3 = [this.layers3.satellite, this.layers3.roadNet];
          this.rt_layers4 = [this.layers4.satellite, this.layers4.roadNet];
          break;
        case "satellite_Build":
          this.rt_layers1 = [this.layers1.satellite, this.layers1.Build];
          this.rt_layers2 = [this.layers2.satellite, this.layers2.Build];
          this.rt_layers3 = [this.layers3.satellite, this.layers3.Build];
          this.rt_layers4 = [this.layers4.satellite, this.layers4.Build];
          break;
        case "satellite_Build_RoadNet":
          this.rt_layers1 = [
            this.layers1.satellite,
            this.layers1.Build,
            this.layers1.roadNet,
          ];
          this.rt_layers2 = [
            this.layers2.satellite,
            this.layers2.Build,
            this.layers2.roadNet,
          ];
          this.rt_layers3 = [
            this.layers3.satellite,
            this.layers3.Build,
            this.layers3.roadNet,
          ];
          this.rt_layers4 = [
            this.layers4.satellite,
            this.layers4.Build,
            this.layers4.roadNet,
          ];
          break;
      }
      this.initAMap(1, 1, 1, 1, 1);
    },
    setfilename() {
      this.exportflag = true;
      this.filename = this.$store.state.eco_value;
    },
    exportTableToExcel(tableData, headerDefinitions) {
      console.log(this.name);
      var str = this.filename + ".xlsx";
      headerDefinitions.unshift(
        { paramIndex: "id", paramName: "Id" },
        { paramIndex: "time", paramName: "time" }
      );
      const XLSX = require("xlsx");
      const workbook = XLSX.utils.book_new(); // 创建新的工作簿
      const headers = headerDefinitions.map((def) => def.paramName); // 生成表头
      const dataRows = tableData.map((row) => {
        return headerDefinitions.map((def) => row[def.paramIndex]);
      }); // 生成数据行
      const worksheetData = [headers, ...dataRows]; // 将表头和数据合并
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData); // 创建工作表
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1"); // 将工作表添加到工作簿
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      }); // 生成Excel文件
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }); // 创建 Blob 对象
      const url = window.URL.createObjectURL(blob); // 创建下载链接
      const a = document.createElement("a");
      a.href = url;
      a.download = str;
      a.click();

      window.URL.revokeObjectURL(url); // 释放 URL 对象
    },
    delete_his(flag) {
      if (flag == false) {
        if (this.value == null) {
          var text = "未选择记录";
          if (this.Lflag == false) {
            text = "No record selected";
          }
          this.optionMin = this.$message({
            showClose: true,
            message: text,
            duration: 3 * 1000,
            center: true,
            type: "warning",
            customClass: "customclass",
          });
        } else if (this.value == "实时数据") {
          var text = "实时数据无法删除，请选择正确的记录";
          if (this.Lflag == false) {
            text =
              "Real-time data cannot be deleted, please select the correct record";
          }
          this.optionMin = this.$message({
            showClose: true,
            message: text,
            duration: 3 * 1000,
            center: true,
            type: "warning",
            customClass: "customclass",
          });
        } else {
          this.delete_flag = true;
        }
      } else if (flag == true) {
        delete_timelag(
          this.$qs.stringify({ site: this.$store.state.site, name: this.value })
        ).then((res) => {
          console.log(res);
          if (res.data == 1) {
            var text = "删除成功";
            if (this.Lflag == false) {
              text = "The deletion is successful";
            }
            this.optionMin = this.$message({
              showClose: true,
              message: text,
              duration: 2 * 1000,
              center: true,
              type: "success",
              customClass: "customclass",
            });
            this.value = null;
            this.$store.commit("seteco_value", this.value);
            get_time_lag(
              this.$qs.stringify({ site: this.$store.state.site })
            ).then((res) => {
              this.options = res.data;
              this.options.unshift({ name: "实时数据", start: "", end: "" });
              console.log(this.options);
            });
          } else {
            var text = "删除失败";
            if (this.Lflag == false) {
              text = "Deletion failed";
            }
            this.optionMin = this.$message({
              showClose: true,
              message: text,
              duration: 2 * 1000,
              center: true,
              type: "error",
              customClass: "customclass",
            });
          }
          this.delete_flag = false;
        });
      }
    },
    gear_compute(list_temp) {
      console.log(list_temp);
      var temp;
      var list = [];
      var standard = [];
      var sum = 0;
      for (let i = 0; i < list_temp.length; i++) {
        if (list_temp[i] < 0) {
          sum += 0;
        } else {
          sum += list_temp[i];
        }
      }
      const average = sum / list_temp.length;
      console.log(average);
      for (let i = 0; i < list_temp.length; i++) {
        if (list_temp[i] < 5 * average) {
          if (list_temp[i] < 0) {
            list.push(0);
          } else {
            list.push(list_temp[i]);
          }
        }
      }
      console.log(Math.max(...list), average);
      temp = Math.max(...list) - average;
      standard[0] = average.toFixed(2);
      standard[1] = (average + temp * 0.225).toFixed(2);
      standard[2] = (average + temp * 0.45).toFixed(2);
      standard[3] = (average + temp * 0.675).toFixed(2);
      standard[4] = (average + temp * 0.9).toFixed(2);
      console.log(standard);
      return standard;
    },
    reset_gears() {
      console.log(this.gears_reset);
      if (this.gears_reset == false) {
        this.standard1 = [2100, 2200, 2500, 3000, 4000]; //浓度1浓度挡位
        this.standard2 = [350, 370, 400, 450, 500]; //浓度2浓度挡位
        this.standard3 = [400, 500, 700, 1000, 1500]; //浓度3浓度挡位
        this.standard4 = [20, 50, 100, 200, 400]; //浓度4浓度挡位
      } else {
        if (this.concentrationList1.length > 5) {
          this.standard1 = this.gear_compute(this.concentrationList1);
        }
        if (this.concentrationList2.length > 5) {
          this.standard2 = this.gear_compute(this.concentrationList2);
        }
        if (this.concentrationList3.length > 5) {
          this.standard3 = this.gear_compute(this.concentrationList3);
        }
        if (this.concentrationList4.length > 5) {
          this.standard4 = this.gear_compute(this.concentrationList4);
        }
      }
    },
    menuChange(item) {
      this.HisTime[0] = item.start;
      this.HisTime[1] = item.end;
      this.key += 1;
      console.log(this.HisTime);
    },
    his_click() {
      this.DataHis_flag = true;
      this.key += 1;
      get_time_lag(this.$qs.stringify({ site: this.$store.state.site })).then(
        (res) => {
          this.options = res.data;
          this.options.unshift({ name: "实时数据", start: "", end: "" });
          console.log(this.options);
        }
      );
    },
    hisData() {
      var text = "数据加载中";
      if (this.Lflag == false) {
        text = "The data is loading";
      }
      this.optionMin = this.$message({
        showClose: true,
        message: text,
        duration: 30 * 1000,
        center: true,
        type: "success",
        customClass: "customclass",
      });
      console.log(this.value);
      if (this.value != "实时数据") {
        select_data_by_time(
          this.$qs.stringify({
            start: this.HisTime[0],
            end: this.HisTime[1],
            table: this.$store.state.site,
          })
        ).then((res) => {
          console.log(res.data);
          this.exportTable = res.data;
          this.$store.commit("seteco_value", this.value);
          this.$store.commit("seteco_time", this.HisTime);
          this.value = "实时数据";
          this.HisTime = [];
          this.concentrationList1 = [];
          this.concentrationList2 = [];
          this.concentrationList3 = [];
          this.concentrationList4 = [];
          this.concentrationList5 = [];
          this.longitudeList = [];
          this.latitudeList = [];
          this.HLLlist = [];
          this.listdata1 = [];
          this.listdata2 = [];
          this.listdata3 = [];
          this.listdata4 = [];
          this.concentration1 = 0;
          this.concentration2 = 0;
          this.concentration3 = 0;
          this.concentration4 = 0;
          this.concentration5 = 0;
          for (let i = 5; i <= res.data.length - 1; i++) {
            this.Datahandle(res.data[i]);
            if (this.longitude != 0 && this.latitude != 0) {
              this.HLLlist.push([this.longitude, this.latitude]);
            }
          }
          console.log(this.HLLlist);
          this.reset_gears();
          //console.log(this.HLLlist);
          this.initAMap(1, 1, 1, 1, 1);
          if (this.optionMin) {
            this.optionMin.close();
          }
          this.refreshflag = false;
        });
      } else {
        askhistory_by_end_time(
          this.$qs.stringify({ site: this.$store.state.site })
        ).then((res) => {
          this.exportTable = res.data;
          this.$store.commit("seteco_value", this.value);
          this.value = this.options[this.options.length - 1].name;
          this.HisTime[0] = this.options[this.options.length - 1].start;
          this.HisTime[1] = this.options[this.options.length - 1].end;
          this.concentrationList1 = [];
          this.concentrationList2 = [];
          this.concentrationList3 = [];
          this.concentrationList4 = [];
          this.concentrationList5 = [];
          this.longitudeList = [];
          this.latitudeList = [];
          this.HLLlist = [];
          this.listdata1 = [];
          this.listdata2 = [];
          this.listdata3 = [];
          this.listdata4 = [];
          this.concentration1 = 0;
          this.concentration2 = 0;
          this.concentration3 = 0;
          this.concentration4 = 0;
          this.concentration5 = 0;
          console.log(res.data);
          for (let i = 5; i <= res.data.length - 1; i++) {
            this.Datahandle(res.data[i]);
            if (i == 5) {
              this.DataTime[0] = this.time;
              this.start_flag = true;
              console.log(this.time);
            } else if (i == res.data.length - 1) {
              this.DataTime[1] = this.time;
            }
            if (this.longitude != 0 && this.latitude != 0) {
              this.HLLlist.push([this.longitude, this.latitude]);
            }
          }
          //console.log(this.HLLlist);
          this.initAMap(1, 1, 1, 1, 1);
          if (this.optionMin) {
            this.optionMin.close();
          }
          this.refreshflag = true;
        });
      }

      this.DataHis_flag = false;
    },
    resetmap() {
      console.log(this.name, this.DataTime);
      setstart(
        this.$qs.stringify({
          database: this.database,
          site: this.$store.state.site,
          start: this.DataTime[0],
          name: this.name,
        })
      ).then((res) => {
        console.log(res);
        var stamp = new Date().getTime() + 8 * 60 * 60 * 1000;
        // 格式化北京时间为"YYYY-MM-DD HH:mm:ss"
        var beijingTime = new Date(stamp)
          .toISOString()
          .replace(/T/, " ")
          .replace(/\..+/, "")
          .substring(0, 19);
        console.log(beijingTime, this.DataTime[1]);
        setend(
          this.$qs.stringify({
            database: this.database,
            site: this.$store.state.site,
            end: this.DataTime[1],
            name: this.name,
            data_end: beijingTime,
          })
        ).then((res) => {
          console.log(res);
        });
      });
      // let str1 = new Date().getTime();
      // let str2 = new Date(this.start_time).getTime();
      // let time = Math.ceil((str1 - str2) / 1000 / 60);
      this.concentrationList1 = [];
      this.concentrationList2 = [];
      this.concentrationList3 = [];
      this.concentrationList4 = [];
      this.concentrationList5 = [];
      this.longitudeList = [];
      this.latitudeList = [];
      this.HLLlist = [];
      this.listdata1 = [];
      this.listdata2 = [];
      this.listdata3 = [];
      this.listdata4 = [];
      this.concentration1 = 0;
      this.concentration2 = 0;
      this.concentration3 = 0;
      this.concentration4 = 0;
      this.concentration5 = 0;
      this.refreshflag = false;
      // console.log(time, this.site);
      // askhistory_by_time(
      //   this.$qs.stringify({ time_interval: time, table: this.site })
      // ).then((res) => {
      //   console.log(res);
      //   for (let i = 0; i < res.data.length - 5; i++) {
      //     this.Datahandle(res.data[i]);
      //     this.HLLlist.push([this.longitude, this.latitude]);
      //   }
      //   console.log(this.HLLlist);
      this.initAMap(1, 1, 1, 1, 1);
      this.refreshflag = true;
      // });
      this.centerDialogVisible = false;
    },
    alarmhandle(alarm, hex, state) {
      let binary = parseInt(hex, 16).toString(2);

      // 输出2进制字符串
      //console.log("Binary:", binary); // 输出 Binary: 11010
      // 逐位读取2进制字符串
      let num = 1;
      if (state == false) {
        num = 17;
      }
      for (let i = binary.length - 1; i >= 0; i--) {
        if (binary[i] == 1) {
          //console.log(num);
          if (alarm == 0) {
            alarm = num;
          } else {
            alarm = alarm + "," + num;
          }
        }
        num++;
      }
      return alarm;
    },
    wgs84togcj02(lng, lat) {
      //GPS坐标转换成高德坐标
      // 定义一些常量
      var PI = 3.1415926535897932384626;
      var a = 6378245.0;
      var ee = 0.00669342162296594323;

      var lat = +lat;
      var lng = +lng;

      // 判断是否在国内，不在国内则不做偏移
      if (!(lng > 73.66 && lng < 135.05 && lat > 3.86 && lat < 53.55)) {
        return [lng, lat];
      } else {
        // 转换lat
        var dlatplng = lng - 105.0,
          dlatplat = lat - 35.0;
        var dlat =
          -100.0 +
          2.0 * dlatplng +
          3.0 * dlatplat +
          0.2 * dlatplat * dlatplat +
          0.1 * dlatplng * dlatplat +
          0.2 * Math.sqrt(Math.abs(dlatplng));
        dlat +=
          ((20.0 * Math.sin(6.0 * dlatplng * PI) +
            20.0 * Math.sin(2.0 * dlatplng * PI)) *
            2.0) /
          3.0;
        dlat +=
          ((20.0 * Math.sin(dlatplat * PI) +
            40.0 * Math.sin((dlatplat / 3.0) * PI)) *
            2.0) /
          3.0;
        dlat +=
          ((160.0 * Math.sin((dlatplat / 12.0) * PI) +
            320 * Math.sin((dlatplat * PI) / 30.0)) *
            2.0) /
          3.0;

        // 转换lng
        var dlngplng = lng - 105.0,
          dlngplat = lat - 35.0;
        var dlng =
          300.0 +
          dlngplng +
          2.0 * dlngplat +
          0.1 * dlngplng * dlngplng +
          0.1 * dlngplng * dlngplat +
          0.1 * Math.sqrt(Math.abs(dlngplng));
        dlng +=
          ((20.0 * Math.sin(6.0 * dlngplng * PI) +
            20.0 * Math.sin(2.0 * dlngplng * PI)) *
            2.0) /
          3.0;
        dlng +=
          ((20.0 * Math.sin(dlngplng * PI) +
            40.0 * Math.sin((dlngplng / 3.0) * PI)) *
            2.0) /
          3.0;
        dlng +=
          ((150.0 * Math.sin((dlngplng / 12.0) * PI) +
            300.0 * Math.sin((dlngplng / 30.0) * PI)) *
            2.0) /
          3.0;

        var radlat = (lat / 180.0) * PI;
        var magic = Math.sin(radlat);
        magic = 1 - ee * magic * magic;
        var sqrtmagic = Math.sqrt(magic);
        dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI);
        dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI);
        var mglat = lat + dlat;
        var mglng = lng + dlng;

        return [mglng, mglat];
      }
    },
    resetwall(id1, id2, id3, id4) {
      if (id1 == 1) {
        this.map1.remove(this.object3Dlayer1);
        this.object3Dlayer1 = null;
        this.object3Dlayer1 = new AMap.Object3DLayer();
      }
      if (id2 == 1) {
        this.map2.remove(this.object3Dlayer2);
        this.object3Dlayer2 = null;
        this.object3Dlayer2 = new AMap.Object3DLayer();
      }
      if (id3 == 1) {
        this.map3.remove(this.object3Dlayer3);
        this.object3Dlayer3 = null;
        this.object3Dlayer3 = new AMap.Object3DLayer();
      }
      if (id4 == 1) {
        this.map4.remove(this.object3Dlayer4);
        this.object3Dlayer4 = null;
        this.object3Dlayer4 = new AMap.Object3DLayer();
      }
      for (let i = 0; i < this.HLLlist.length - 1; i++) {
        const test = [
          this.map1.lngLatToGeodeticCoord(this.HLLlist[i]),
          this.map1.lngLatToGeodeticCoord(this.HLLlist[i + 1]),
        ];
        var color = [0.4, 1, 1, 0.5];
        if (id1 == 1) {
          if (this.concentrationList1[i] >= this.standard1[4]) {
            color = this.standard_color[5];
          } else if (this.concentrationList1[i] >= this.standard1[3]) {
            color = this.standard_color[4];
          } else if (this.concentrationList1[i] >= this.standard1[2]) {
            color = this.standard_color[3];
          } else if (this.concentrationList1[i] >= this.standard1[1]) {
            color = this.standard_color[2];
          } else if (this.concentrationList1[i] >= this.standard1[0]) {
            color = this.standard_color[1];
          } else {
            color = this.standard_color[0];
          }
          color[3] = this.alphaValue / 100;
          var wall = new AMap.Object3D.Wall({
            path: test,
            height: (this.concentrationList1[i] / 10) * this.Height_prop1,
            color: color,
          });
          //wall 有个面,该属性表示哪个面可见,可选值back ,front  ,both表示两面都填充  默认为front
          wall.backOrFront = "front";
          // 是否允许使用透明颜色
          wall.transparent = true;
          this.object3Dlayer1.add(wall);
        }
        if (id2 == 1) {
          if (this.concentrationList2[i] >= this.standard2[4]) {
            color = this.standard_color[5];
          } else if (this.concentrationList2[i] >= this.standard2[3]) {
            color = this.standard_color[4];
          } else if (this.concentrationList2[i] >= this.standard2[2]) {
            color = this.standard_color[3];
          } else if (this.concentrationList2[i] >= this.standard2[1]) {
            color = this.standard_color[2];
          } else if (this.concentrationList2[i] >= this.standard2[0]) {
            color = this.standard_color[1];
          } else {
            color = this.standard_color[0];
          }
          color[3] = this.alphaValue / 100;
          var wall = new AMap.Object3D.Wall({
            path: test,
            height: (this.concentrationList2[i] / 1.5) * this.Height_prop2,
            color: color,
          });
          //wall 有个面,该属性表示哪个面可见,可选值back ,front  ,both表示两面都填充  默认为front
          wall.backOrFront = "front";
          // 是否允许使用透明颜色
          wall.transparent = true;
          this.object3Dlayer2.add(wall);
        }
        if (id3 == 1) {
          if (this.concentrationList3[i] >= this.standard3[4]) {
            color = this.standard_color[5];
          } else if (this.concentrationList3[i] >= this.standard3[3]) {
            color = this.standard_color[4];
          } else if (this.concentrationList3[i] >= this.standard3[2]) {
            color = this.standard_color[3];
          } else if (this.concentrationList3[i] >= this.standard3[1]) {
            color = this.standard_color[2];
          } else if (this.concentrationList3[i] >= this.standard3[0]) {
            color = this.standard_color[1];
          } else {
            color = this.standard_color[0];
          }
          color[3] = this.alphaValue / 100;
          var wall = new AMap.Object3D.Wall({
            path: test,
            height: this.concentrationList3[i] * this.Height_prop3,
            color: color,
          });
          //wall 有个面,该属性表示哪个面可见,可选值back ,front  ,both表示两面都填充  默认为front
          wall.backOrFront = "front";
          // 是否允许使用透明颜色
          wall.transparent = true;
          this.object3Dlayer3.add(wall);
        }
        if (id4 == 1) {
          if (this.concentrationList4[i] >= this.standard4[4]) {
            color = this.standard_color[5];
          } else if (this.concentrationList4[i] >= this.standard4[3]) {
            color = this.standard_color[4];
          } else if (this.concentrationList4[i] >= this.standard4[2]) {
            color = this.standard_color[3];
          } else if (this.concentrationList4[i] >= this.standard4[1]) {
            color = this.standard_color[2];
          } else if (this.concentrationList4[i] >= this.standard4[0]) {
            color = this.standard_color[1];
          } else {
            color = this.standard_color[0];
          }
          color[3] = this.alphaValue / 100;
          var wall = new AMap.Object3D.Wall({
            path: test,
            height: this.concentrationList4[i] * 10 * this.Height_prop4,
            color: color,
          });
          //wall 有个面,该属性表示哪个面可见,可选值back ,front  ,both表示两面都填充  默认为front
          wall.backOrFront = "front";
          // 是否允许使用透明颜色
          wall.transparent = true;
          this.object3Dlayer4.add(wall);
        }
      }
      if (id1 == 1) {
        this.map1.add(this.object3Dlayer1);
      }
      if (id2 == 1) {
        this.map2.add(this.object3Dlayer2);
      }
      if (id3 == 1) {
        this.map3.add(this.object3Dlayer3);
      }
      if (id4 == 1) {
        this.map4.add(this.object3Dlayer4);
      }
    },
    prop(id) {
      //刷新浓度与高度比例
      console.log(id);
      if (id == 1) {
        this.resetwall(1, 0, 0, 0);
      } else if (id == 2) {
        this.resetwall(0, 1, 0, 0);
      } else if (id == 3) {
        this.resetwall(0, 0, 1, 0);
      } else if (id == 4) {
        this.resetwall(0, 0, 0, 1);
      } else if (id == 5) {
        if (this.zoom_num == 1) {
          this.Height_prop1 = this.Height_prop;
          this.resetwall(1, 0, 0, 0);
        } else if (this.zoom_num == 2) {
          this.Height_prop2 = this.Height_prop;
          this.resetwall(0, 1, 0, 0);
        } else if (this.zoom_num == 3) {
          this.Height_prop3 = this.Height_prop;
          this.resetwall(0, 0, 1, 0);
        } else if (this.zoom_num == 4) {
          this.Height_prop4 = this.Height_prop;
          this.resetwall(0, 0, 0, 1);
        }
      }
    },
    refresh(id) {
      //刷新绘图
      var test = [[]];
      test[0] = this.map1.lngLatToGeodeticCoord(this.LLlist[0]);
      test[1] = this.map1.lngLatToGeodeticCoord(this.LLlist[1]);
      //this.marker1.setPosition(this.LLlist[1]);
      if (this.car_speed > 1) {
        this.marker1.moveAlong(this.LLlist, 2);
      }
      //console.log(test);
      //this.object3Dlayer1 = new AMap.Object3DLayer();
      //this.map1.add(this.object3Dlayer1);
      var color = [0.4, 1, 1, 0.5];
      if (this.concentration1 >= this.standard1[4]) {
        color = this.standard_color[5];
      } else if (this.concentration1 >= this.standard1[3]) {
        color = this.standard_color[4];
      } else if (this.concentration1 >= this.standard1[2]) {
        color = this.standard_color[3];
      } else if (this.concentration1 >= this.standard1[1]) {
        color = this.standard_color[2];
      } else if (this.concentration1 >= this.standard1[0]) {
        color = this.standard_color[1];
      } else {
        color = this.standard_color[0];
      }
      if (this.concentration1 < 0) {
        this.concentration1 = 0;
      }
      this.wall1 = new AMap.Object3D.Wall({
        path: test,
        height: (this.concentration1 / 10) * this.Height_prop1,
        color: color,
      });
      //wall 有个面,该属性表示哪个面可见,可选值back ,front  ,both表示两面都填充  默认为front
      this.wall1.backOrFront = "front";
      // 是否允许使用透明颜色
      this.wall1.transparent = true;
      this.object3Dlayer1.add(this.wall1);
      // var text1 = new AMap.Text({
      //   text: this.concentration1,
      //   verticalAlign: "top",
      //   position: this.LLlist[1],
      //   height: this.concentration1 * 100,
      //   style: {
      //     "background-color": "transparent",
      //     "-webkit-text-stroke": "red",
      //     "-webkit-text-stroke-width": "0.5px",
      //     "text-align": "center",
      //     border: "none",
      //     color: "white",
      //     "font-size": ".5376vw",
      //   },
      // });
      // if (this.con_cnt == 20) {
      //   text1.setMap(this.map1);
      // }

      var polyline1 = new AMap.Polyline({
        map: this.map1,
        path: this.LLlist,
        strokeColor: "#29e2e5", //线条颜色
        strokeOpacity: 1, //透明度 0透明
        strokeWeight: 3, //轮廓线宽
        showDir: true,
        //strokeStyle: "solid", //实线
      });
      // 缩放地图到合适的视野级别
      //this.map1.setFitView([polyline1]);
      if (id >= 2) {
        if (this.car_speed > 1) {
          this.marker2.moveAlong(this.LLlist, 2);
        }
        // this.object3Dlayer2 = new AMap.Object3DLayer();
        // this.map2.add(this.object3Dlayer2);
        var color = [0.4, 1, 1, 0.5];
        if (this.concentration2 >= this.standard2[4]) {
          color = this.standard_color[5];
        } else if (this.concentration2 >= this.standard2[3]) {
          color = this.standard_color[4];
        } else if (this.concentration2 >= this.standard2[2]) {
          color = this.standard_color[3];
        } else if (this.concentration2 >= this.standard2[1]) {
          color = this.standard_color[2];
        } else if (this.concentration2 >= this.standard2[0]) {
          color = this.standard_color[1];
        } else {
          color = this.standard_color[0];
        }
        this.wall2 = new AMap.Object3D.Wall({
          path: test,
          height: (this.concentration2 / 1.5) * this.Height_prop2,
          color: color,
        });
        //wall 有个面,该属性表示哪个面可见,可选值back ,front  ,both表示两面都填充  默认为front
        this.wall2.backOrFront = "front";
        // 是否允许使用透明颜色
        this.wall2.transparent = true;
        this.object3Dlayer2.add(this.wall2);
        var polyline2 = new AMap.Polyline({
          map: this.map2,
          path: this.LLlist,
          strokeColor: "#29e2e5", //线条颜色
          strokeOpacity: 1, //透明度 0透明
          strokeWeight: 3, //轮廓线宽
          showDir: true,
          //strokeStyle: "solid", //实线
        });
        // var text2 = new AMap.Text({
        //   text: this.concentration2,
        //   verticalAlign: "top",
        //   position: this.LLlist[1],
        //   height: this.concentration2,
        //   style: {
        //     "background-color": "transparent",
        //     "-webkit-text-stroke": "red",
        //     "-webkit-text-stroke-width": "0.5px",
        //     "text-align": "center",
        //     border: "none",
        //     color: "white",
        //     "font-size": ".5376vw",
        //   },
        // });
        // if (this.con_cnt == 20) {
        //   text2.setMap(this.map2);
        // }
      }
      // if (id >= 3) {
      //   // this.object3Dlayer3 = new AMap.Object3DLayer();
      //   // this.map3.add(this.object3Dlayer3);
      //   var color = [0.4, 1, 1, 0.5];
      //   if (this.concentration3 > this.standard3[2]) {
      //     color = this.standard_color[3];
      //   } else if (this.concentration3 > this.standard3[1]) {
      //     color = this.standard_color[2];
      //   } else if (this.concentration3 > this.standard3[0]) {
      //     color = this.standard_color[1];
      //   } else {
      //     color = this.standard_color[0];
      //   }
      //   this.wall3 = new AMap.Object3D.Wall({
      //     path: test,
      //     height: this.concentration3 * this.Height_prop3,
      //     color: color,
      //   });
      //   //wall 有个面,该属性表示哪个面可见,可选值back ,front  ,both表示两面都填充  默认为front
      //   this.wall3.backOrFront = "front";
      //   // 是否允许使用透明颜色
      //   this.wall3.transparent = true;
      //   this.object3Dlayer3.add(this.wall3);
      //   var polyline3 = new AMap.Polyline({
      //     map: this.map3,
      //     path: this.LLlist,
      //     strokeColor: "#29e2e5", //线条颜色
      //     strokeOpacity: 1, //透明度 0透明
      //     strokeWeight: 3, //轮廓线宽
      //     showDir: true,
      //     //strokeStyle: "solid", //实线
      //   });
      //   // var text3 = new AMap.Text({
      //   //   text: this.concentration3,
      //   //   verticalAlign: "top",
      //   //   position: this.LLlist[1],
      //   //   height: this.concentration3,
      //   //   style: {
      //   //     "background-color": "transparent",
      //   //     "-webkit-text-stroke": "red",
      //   //     "-webkit-text-stroke-width": "0.5px",
      //   //     "text-align": "center",
      //   //     border: "none",
      //   //     color: "white",
      //   //     "font-size": ".5376vw",
      //   //   },
      //   // });
      //   // if (this.con_cnt == 20) {
      //   //   text3.setMap(this.map3);
      //   // }
      // }
      if (id >= 4) {
        //this.marker4.setPosition(this.LLlist[1]);
        if (this.car_speed > 1) {
          this.marker4.moveAlong(this.LLlist, 2);
        }
        // this.object3Dlayer4 = new AMap.Object3DLayer();
        // this.map4.add(this.object3Dlayer4);
        var color = [0.4, 1, 1, 0.5];
        if (this.concentration4 >= this.standard4[4]) {
          color = this.standard_color[5];
        } else if (this.concentration4 >= this.standard4[3]) {
          color = this.standard_color[4];
        } else if (this.concentration4 >= this.standard4[2]) {
          color = this.standard_color[3];
        } else if (this.concentration4 >= this.standard4[1]) {
          color = this.standard_color[2];
        } else if (this.concentration4 >= this.standard4[0]) {
          color = this.standard_color[1];
        } else {
          color = this.standard_color[0];
        }
        if (this.concentration4 < 0) {
          this.concentration4 = 0;
        }
        this.wall4 = new AMap.Object3D.Wall({
          path: test,
          height: this.concentration4 * 10 * this.Height_prop4,
          color: color,
        });
        //wall 有个面,该属性表示哪个面可见,可选值back ,front  ,both表示两面都填充  默认为front
        this.wall4.backOrFront = "front";
        // 是否允许使用透明颜色
        this.wall4.transparent = true;
        this.object3Dlayer4.add(this.wall4);
        var polyline4 = new AMap.Polyline({
          map: this.map4,
          path: this.LLlist,
          strokeColor: "#29e2e5", //线条颜色
          strokeOpacity: 1, //透明度 0透明
          strokeWeight: 3, //轮廓线宽
          showDir: true,
          //strokeStyle: "solid", //实线
        });
        // var text4 = new AMap.Text({
        //   text: this.concentration4,
        //   verticalAlign: "top",
        //   position: this.LLlist[1],
        //   height: this.concentration4 * 10,
        //   style: {
        //     "background-color": "transparent",
        //     "-webkit-text-stroke": "red",
        //     "-webkit-text-stroke-width": "0.5px",
        //     "text-align": "center",
        //     border: "none",
        //     color: "white",
        //     "font-size": ".5376vw",
        //   },
        // });
        // if (this.con_cnt == 20) {
        //   text4.setMap(this.map4);
        // }
      }
      // if (this.con_cnt == 20) {
      //   this.con_cnt = 0;
      // }
    },
    getAttrs(array, attr) {
      var arr = array.map((item) => {
        return item[attr];
      });
      return arr;
    },
    compute() {
      //跳转到羽流参数界面
      this.$router.push("/Main/Cruise_monitoring_compute");
    },
    Datahandle(list) {
      //实时数据处理
      //console.log(list);
      var refresh = false;
      this.time = list.time;
      this.wind = list.wd;
      this.windspeed = list.ws;
      this.temperature = list.T;
      this.ch4_alarm = 0;
      this.n2o_alarm = 0;
      this.nh3_alarm = 0;
      this.concentration1 = 0;
      this.concentration2 = 0;
      this.concentration3 = 0;
      this.concentration4 = 0;
      for (let k in list) {
        for (let i = 0; i < this.indexList.length; i++) {
          if (k == this.indexList[i].paramIndex) {
            if (this.indexList[i].paramName == "longitude") {
              this.longitudeList.push(list[k]);
              this.longitude = list[k];
              refresh = true;
              //console.log(this.longitudeList);
            } else if (this.indexList[i].paramName == "latitude") {
              this.latitudeList.push(list[k]);
              this.latitude = list[k];
              //console.log(this.latitudeList);
            } else if (this.indexList[i].paramName == "drive_direction") {
              this.drive_direction = list[k];
              //console.log(this.drive_direction);
            } else if (this.indexList[i].paramName == "car_speed") {
              this.car_speed = list[k];
              //console.log(this.car_speed);
            } else if (this.indexList[i].paramName == "CH4concentration_ppb") {
              this.concentrationList1.push(list[k]);
              this.concentration1 = list[k];
              this.listdata1.push([this.time, this.concentration1]);
              //console.log(this.concentrationList1);
            } else if (this.indexList[i].paramName == "N2Oconcentration_ppb") {
              this.concentrationList2.push(list[k]);
              this.concentration2 = list[k];
              this.listdata2.push([this.time, this.concentration2]);
              //console.log(this.concentrationList2);
            } else if (this.indexList[i].paramName == "CO2concentration_ppm") {
              this.concentrationList3.push(list[k]);
              this.concentration3 = list[k];
              this.listdata3.push([this.time, this.concentration3]);
              //console.log(this.concentrationList3);
            } else if (this.indexList[i].paramName == "H2Oconcentration_ppm") {
              this.concentrationList5.push(list[k]);
              this.concentration5 = list[k];
              //console.log(this.concentrationList4);
            } else if (this.indexList[i].paramName == "NH3concentration_ppb") {
              this.concentrationList4.push(list[k]);
              this.concentration4 = list[k];
              this.listdata4.push([this.time, this.concentration4]);
              //console.log(this.concentrationList4);
            } else if (this.indexList[i].paramName == "pressure_kpa") {
              this.pressure = list[k];
              //console.log(this.pressure);
            }
            // else if (this.indexList[i].paramName == "T") {
            //   this.temperature = list[k];
            //   //console.log(this.temperature);
            // }
            else if (this.indexList[i].paramName == "8600_OSS") {
              this.oss_ch4 = list[k];
              //console.log(this.windspeed);
            } else if (this.indexList[i].paramName == "8600_low_alarm") {
              //this.oss_ch4 = list[k];
              //console.log(list[k]);
              this.ch4_alarm = this.alarmhandle(this.ch4_alarm, list[k], true);
            } else if (this.indexList[i].paramName == "8600_high_alarm") {
              //this.oss_ch4 = list[k];
              //console.log(list[k]);
              this.ch4_alarm = this.alarmhandle(this.ch4_alarm, list[k], false);
            } else if (this.indexList[i].paramName == "8700_OSS") {
              this.oss_nh3 = list[k];
              //console.log(this.windspeed);
            } else if (this.indexList[i].paramName == "8700_low_alarm") {
              //this.oss_ch4 = list[k];
              //console.log(list[k]);
              this.nh3_alarm = this.alarmhandle(this.nh3_alarm, list[k], true);
            } else if (this.indexList[i].paramName == "8700_high_alarm") {
              //this.oss_ch4 = list[k];
              //console.log(list[k]);
              this.nh3_alarm = this.alarmhandle(this.nh3_alarm, list[k], false);
            } else if (this.indexList[i].paramName == "8500_OSS") {
              this.oss_n2o = list[k];
              //console.log(this.windspeed);
            } else if (this.indexList[i].paramName == "8500_low_alarm") {
              //this.oss_ch4 = list[k];
              //console.log(list[k]);
              this.n2o_alarm = this.alarmhandle(this.n2o_alarm, list[k], true);
            } else if (this.indexList[i].paramName == "8500_high_alarm") {
              //this.oss_ch4 = list[k];
              //console.log(list[k]);
              this.n2o_alarm = this.alarmhandle(this.n2o_alarm, list[k], false);
            }
            // else if (this.indexList[i].paramName == "U_m_s-1") {
            //   this.windspeed = list[k];
            //   //console.log(this.windspeed);
            // } else if (this.indexList[i].paramName == "WD") {
            //   this.wind = list[k];
            //   //console.log(this.wind);
            // }
          }
        }
      }
      if (this.latitude != 0 && this.longitude != 0 && refresh == true) {
        var gaode = this.wgs84togcj02(this.longitude, this.latitude);
        this.longitude = gaode[0];
        this.latitude = gaode[1];
        // if (this.temp == [this.latitude, this.longitude]) {
        //   return;
        // }
        if (
          this.LLlist.length == 0 &&
          this.latitude != 0 &&
          this.longitude != 0
        ) {
          this.LLlist[0] = [this.longitude, this.latitude];
        } else if (this.LLlist.length == 1) {
          this.LLlist[1] = [this.longitude, this.latitude];
          this.temp = [this.longitude, this.latitude];
        } else {
          this.LLlist[0] = this.temp;
          this.LLlist[1] = [this.longitude, this.latitude];
        }
        this.temp = [this.longitude, this.latitude];
        //console.log(this.LLlist[0], this.LLlist[1]);
      }
      refresh = false;
    },
    shrink() {
      //缩小
      this.color_flag1 = true;
      this.color_flag2 = true;
      this.color_flag3 = true;
      this.color_flag4 = true;
      this.showChart1 = !this.showChart1;
      this.showChart2 = !this.showChart2;
      this.showChart3 = !this.showChart3;
      this.showChart4 = !this.showChart4;
      if (this.zoom_num == 1) {
        this.showChart1 = !this.showChart1;
        this.Height_prop1 = this.Height_prop;
      } else if (this.zoom_num == 2) {
        this.showChart2 = !this.showChart2;
        this.Height_prop2 = this.Height_prop;
      } else if (this.zoom_num == 3) {
        this.showChart3 = !this.showChart3;
        this.Height_prop3 = this.Height_prop;
      } else if (this.zoom_num == 4) {
        this.showChart4 = !this.showChart4;
        this.Height_prop4 = this.Height_prop;
      }
      var div = document.getElementById(
        "con" + this.zoom_num
      ); /*w和h都要用这部分，故单独定义一个div*/
      if (this.zoom_num == "3" || this.zoom_num == "4") {
        div.style.top = 0 + "vw";
      }
      div = document.getElementById("VerticalColorScale" + this.zoom_num);
      div.style.width = 3.3 + "vw";
      div.style.height = 15 + "vh";
      div =
        document.getElementById("con1"); /*w和h都要用这部分，故单独定义一个div*/
      div.style.width = 34.9462 + "vw"; //650px
      div.style.height = 30 + "vh"; //300px
      div =
        document.getElementById("con2"); /*w和h都要用这部分，故单独定义一个div*/
      div.style.width = 34.9462 + "vw"; //650px
      div.style.height = 30 + "vh"; //300px
      div =
        document.getElementById("con3"); /*w和h都要用这部分，故单独定义一个div*/
      div.style.width = 34.9462 + "vw"; //650px
      div.style.height = 30 + "vh"; //300px
      div =
        document.getElementById("con4"); /*w和h都要用这部分，故单独定义一个div*/
      div.style.width = 34.9462 + "vw"; //650px
      div.style.height = 30 + "vh"; //300px
      this.zoomflag = true;
      this.zoom_num = 0;

      div = document.getElementById("lineChart1");
      div.style.width = 14.3011 + "vw";
      div.style.height = 11.7 + "vh";

      this.myChart1.dispose();
      this.draw_list(1);

      div = document.getElementById("lineChart2");
      div.style.width = 14.3011 + "vw";
      div.style.height = 11.7 + "vh";

      this.myChart2.dispose();
      this.draw_list(2);

      div = document.getElementById("lineChart3");
      div.style.width = 14.3011 + "vw";
      div.style.height = 11.7 + "vh";
      this.myChart3.dispose();
      this.draw_list(3);

      div = document.getElementById("lineChart4");
      div.style.width = 14.3011 + "vw";
      div.style.height = 11.7 + "vh";
      this.myChart4.dispose();
      this.draw_list(4);
    },
    magnify(id) {
      //放大
      //document.getElementById("container1").innerHTML = "";
      this.zoom_num = id;
      this.showChart1 = !this.showChart1;
      this.showChart2 = !this.showChart2;
      this.showChart3 = !this.showChart3;
      this.showChart4 = !this.showChart4;
      if (this.zoom_num == 1) {
        this.showChart1 = !this.showChart1;
        this.color_flag1 = true;
        this.color_flag2 = false;
        this.color_flag3 = false;
        this.color_flag4 = false;
        this.Height_prop = this.Height_prop1;
      } else if (this.zoom_num == 2) {
        this.showChart2 = !this.showChart2;
        this.color_flag1 = false;
        this.color_flag2 = true;
        this.color_flag3 = false;
        this.color_flag4 = false;
        this.Height_prop = this.Height_prop2;
      } else if (this.zoom_num == 3) {
        this.showChart3 = !this.showChart3;
        this.color_flag1 = false;
        this.color_flag2 = false;
        this.color_flag3 = true;
        this.color_flag4 = false;
        this.Height_prop = this.Height_prop3;
      } else if (this.zoom_num == 4) {
        this.showChart4 = !this.showChart4;
        this.color_flag1 = false;
        this.color_flag2 = false;
        this.color_flag3 = false;
        this.color_flag4 = true;
        this.Height_prop = this.Height_prop4;
      }
      this.zoomflag = false;
      var div = document.getElementById(
        "con" + this.zoom_num
      ); /*w和h都要用这部分，故单独定义一个div*/
      div.style.width = 72.5806 + "vw"; //1350px
      div.style.height = 65 + "vh"; //645px
      var div_t = document.getElementById("con1");
      if (this.zoom_num == "1") {
        div_t = document.getElementById("con2");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
        div_t = document.getElementById("con3");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
        div_t = document.getElementById("con4");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
        div_t = document.getElementById("VerticalColorScale1");
        div_t.style.width = 5.6 + "vw";
        div_t.style.height = 30 + "vh";
        div_t = document.getElementById("lineChart1");
        div_t.style.width = 31 + "vw";
        div_t.style.height = 23 + "vh";
        this.myChart1.dispose();
        this.draw_list(1);
      } else if (this.zoom_num == "2") {
        div_t = document.getElementById("con1");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
        div_t = document.getElementById("con3");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
        div_t = document.getElementById("con4");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
        div_t = document.getElementById("VerticalColorScale2");
        div_t.style.width = 5.6 + "vw";
        div_t.style.height = 30 + "vh";
        div_t = document.getElementById("lineChart2");
        div_t.style.width = 31 + "vw";
        div_t.style.height = 23 + "vh";
        this.myChart2.dispose();
        this.draw_list(2);
      } else if (this.zoom_num == "3") {
        div.style.top = -35.1 + "vh"; //338.3px
        div_t = document.getElementById("con1");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vw";
        div_t = document.getElementById("con2");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
        div_t = document.getElementById("con4");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
        div_t = document.getElementById("VerticalColorScale3");
        div_t.style.width = 5.6 + "vw";
        div_t.style.height = 30 + "vh";
        div_t = document.getElementById("lineChart3");
        div_t.style.width = 31 + "vw";
        div_t.style.height = 23 + "vh";
        this.myChart3.dispose();
        this.draw_list(3);
      } else if (this.zoom_num == "4") {
        div.style.top = -35.1 + "vh"; //338.3px
        div_t = document.getElementById("con1");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
        div_t = document.getElementById("con2");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
        div_t = document.getElementById("con3");
        div_t.style.width = 0 + "vw";
        div_t.style.height = 0 + "vh";
        div_t = document.getElementById("VerticalColorScale4");
        div_t.style.width = 5.6 + "vw";
        div_t.style.height = 30 + "vh";
        div_t = document.getElementById("lineChart4");
        div_t.style.width = 31 + "vw";
        div_t.style.height = 23 + "vh";
        this.myChart4.dispose();
        this.draw_list(4);
      }
    },
    draw_list(index) {
      function fontSize(res) {
        let clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        if (!clientWidth) return;
        let fontSize = clientWidth / 1860;
        return res * fontSize;
      }
      var size = 8;
      if (this.zoomflag == false) {
        size = 12;
      }
      var option;
      let data = [];
      var lable;
      if (index == 1) {
        this.myChart1 = echarts.init(document.getElementById("lineChart1"));
        data = this.listdata1;
        lable = this.lable1;
      } else if (index == 2) {
        this.myChart2 = echarts.init(document.getElementById("lineChart2"));
        data = this.listdata2;
        lable = this.lable2;
      } else if (index == 3) {
        this.myChart3 = echarts.init(document.getElementById("lineChart3"));
        data = this.listdata3;
        lable = this.lable3;
      } else if (index == 4) {
        this.myChart4 = echarts.init(document.getElementById("lineChart4"));
        data = this.listdata4;
        lable = this.lable4;
      }
      // 将日期字符串转换为时间戳
      const convertedData = data.map((item) => {
        const date = new Date(item[0]).getTime();
        return [date, item[1]];
      });

      option = {
        // title: {
        //   text: this.lable1,
        // },
        grid: {
          top: "15%", // 调整图表顶部空白区域
          bottom: "5%", // 调整图表底部空白区域
          left: "10%",
          right: "10%",
          containLabel: true, // 确保标签包含在图表区域内
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          textStyle: {
            fontSize: fontSize(size + 4),
          },
          // formatter: "{a} <br/>{b} : {c}"
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        xAxis: {
          type: "time",
          splitLine: {
            show: false,
          },
          nameGap: fontSize(5), // 调整名称与轴线的距离（单位：像素）
          axisLabel: {
            rotate: 30,
            fontSize: fontSize(size), // 修改y轴刻度字体大小
          },
          name: "time", // x轴名称
          nameTextStyle: {
            fontSize: fontSize(size),
          },
        },
        yAxis: {
          name: lable, // y轴名称
          nameGap: fontSize(5), // 调整名称与轴线的距离（单位：像素）
          type: "value",
          boundaryGap: [0, "100%"],
          axisLabel: {
            fontSize: fontSize(size), // 修改y轴刻度字体大小
          },
          splitLine: {
            show: true,
          },
          nameTextStyle: {
            fontSize: fontSize(size),
          },
          min: (value) => {
            if (Math.abs(value) < 1 && Math.abs(value) > 0.00001) {
              let value2 = value * 100000;

              let value2min = Math.floor(value2);

              let num = 10 ** (value2.toString().length - 3);

              return parseFloat(Math.floor(value2min / num) * num).toFixed(4);
            } else {
              //当数据位数不固定时,最小值向下取整
              let value1 = Math.floor(value.min);
              let num = 1 ** (value1.toString().length - 3);
              return Math.floor(value1 / num) * num;
              //return Math.floor(value * 10000) / 10000; // 保留四位小数
            }
          },
          max: (value) => {
            if (Math.abs(value) < 1 && Math.abs(value) > 0.00001) {
              let value2 = value * 100000;
              let value2max = Math.ceil(value2);

              let num = 10 ** (value2max.toString().length - 3);

              return parseFloat(Math.ceil(value2max / num) * num).toFixed(4);
            } else {
              //return Math.ceil(value * 10000) / 10000; // 保留四位小数

              //当数据位数不固定时,最大值向上取整
              let value1 = Math.ceil(value.max);
              //let value1 = value1.toFixed(4); 抛弃
              let num = 10 ** (value1.toString().length - 3);
              return Math.ceil(value1 / num) * num;
            }
          },
          scale: true, //自适应
        },
        series: [
          {
            name: lable,
            type: "line",
            showSymbol: false,
            data: convertedData,
          },
        ],
      };
      if (index == 1) {
        this.myChart1.setOption(option);
        window.addEventListener("resize", () => {
          if (this.myChart1) {
            this.myChart1.resize();
          }
        });
      } else if (index == 2) {
        this.myChart2.setOption(option);
        window.addEventListener("resize", () => {
          if (this.myChart2) {
            this.myChart2.resize();
          }
        });
      } else if (index == 3) {
        this.myChart3.setOption(option);
        window.addEventListener("resize", () => {
          if (this.myChart3) {
            this.myChart3.resize();
          }
        });
      } else if (index == 4) {
        this.myChart4.setOption(option);
        window.addEventListener("resize", () => {
          if (this.myChart4) {
            this.myChart4.resize();
          }
        });
      }
    },
    initAMap(id, id1, id2, id3, id4) {
      //AMapLoader.reset();
      //const _this = this;
      window._AMapSecurityConfig = {
        securityJsCode: "c071c8dc815700be13f9aca9de4db974", // 自2021年12月02日升级后, key与安全密钥必须一起使用, 否则可能会出现一些API无法使用，如 DistrictSearch
      };
      AMapLoader.load({
        key: "dcde0cf01341034fcfe241ff85f826c4", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.ControlBar", //方向控件
          "AMap.DistrictSearch",
          "Map3D",
          "AMap.Object3DLayer",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等，如果是地图控件，必须再 map.addControl添加
      })
        .then((AMap) => {
          this.layers1 = {
            satellite: new AMap.TileLayer.Satellite({
              rejectMapMask: true, //
            }),
            roadNet: new AMap.TileLayer.RoadNet(),
            Build: new AMap.Buildings({
              zooms: [16, 18],
              zIndex: 10,
              heightFactor: 2, //2倍于默认高度，3D下有效
            }),
          };
          this.layers2 = {
            satellite: new AMap.TileLayer.Satellite({
              rejectMapMask: true, //
            }),
            roadNet: new AMap.TileLayer.RoadNet(),
            Build: new AMap.Buildings({
              zooms: [16, 18],
              zIndex: 10,
              heightFactor: 2, //2倍于默认高度，3D下有效
            }),
          };
          this.layers3 = {
            satellite: new AMap.TileLayer.Satellite({
              rejectMapMask: true, //
            }),
            roadNet: new AMap.TileLayer.RoadNet(),
            Build: new AMap.Buildings({
              zooms: [16, 18],
              zIndex: 10,
              heightFactor: 2, //2倍于默认高度，3D下有效
            }),
          };
          this.layers4 = {
            satellite: new AMap.TileLayer.Satellite({
              rejectMapMask: true, //
            }),
            roadNet: new AMap.TileLayer.RoadNet(),
            Build: new AMap.Buildings({
              zooms: [16, 18],
              zIndex: 10,
              heightFactor: 2, //2倍于默认高度，3D下有效
            }),
          };
          if (id == 0) {
            this.rt_layers1 = [this.layers1.satellite, this.layers1.roadNet];
            this.rt_layers2 = [this.layers2.satellite, this.layers2.roadNet];
            this.rt_layers3 = [this.layers3.satellite, this.layers3.roadNet];
            this.rt_layers4 = [this.layers4.satellite, this.layers4.roadNet];
          }
          if (id1 == 1) {
            this.map1 = new AMap.Map("container1", {
              // 设置地图容器id
              resizeEnable: true,
              rotateEnable: true,
              pitchEnable: true,
              zoom: 20,
              pitch: 65,
              rotation: 45,
              viewMode: "3D", //开启3D视图,默认为关闭
              buildingAnimation: true, //楼块出现是否带动画
              //preLoad: Infinity, // 预加载 4 层深度的瓦片
              expandZoomRange: true,
              zooms: [1, 20],
              center: [this.longitude, this.latitude],
              //turboMode: false,
              //showBuildingBlock: false,
              //forceVector: true,
              layers: this.rt_layers1,
            });
            this.map1.addControl(
              new AMap.ControlBar({
                showZoomBar: false,
                showControlButton: true,
                position: {
                  right: "10px",
                  top: "10px",
                },
              })
            );
            this.object3Dlayer1 = new AMap.Object3DLayer();
            this.map1.add(this.object3Dlayer1);
            this.marker1 = new AMap.Marker({
              map: this.map1,
              icon: "https://webapi.amap.com/images/car.png",
              offset: new AMap.Pixel(-26, -13), // 调整图片偏移
              autoRotation: true, // 自动旋转
              // draggable: false,
              // cursor: "pointer",
              // raiseOnDrag: true,
              // angle: 0, // 图片旋转角度
            });
          }

          if (id2 == 1) {
            this.map2 = new AMap.Map("container2", {
              // 设置地图容器id
              resizeEnable: true,
              rotateEnable: true,
              pitchEnable: true,
              zoom: 20,
              pitch: 65,
              rotation: 45,
              viewMode: "3D", //开启3D视图,默认为关闭
              buildingAnimation: true, //楼块出现是否带动画

              expandZoomRange: true,
              zooms: [1, 20],
              center: [this.longitude, this.latitude],
              turboMode: false,
              //showBuildingBlock: false,
              forceVector: true,
              layers: this.rt_layers2,
            });
            this.map2.addControl(
              new AMap.ControlBar({
                showZoomBar: false,
                showControlButton: true,
                position: {
                  right: "10px",
                  top: "10px",
                },
              })
            );
            this.object3Dlayer2 = new AMap.Object3DLayer();
            this.map2.add(this.object3Dlayer2);
            this.marker2 = new AMap.Marker({
              map: this.map2,
              icon: "https://webapi.amap.com/images/car.png",
              offset: new AMap.Pixel(-26, -13), // 调整图片偏移
              autoRotation: true, // 自动旋转
              // draggable: false,
              // cursor: "pointer",
              // raiseOnDrag: true,
              // angle: 0, // 图片旋转角度
            });
          }
          if (id3 == 1) {
            this.map3 = new AMap.Map("container3", {
              // 设置地图容器id
              resizeEnable: true,
              rotateEnable: true,
              pitchEnable: true,
              zoom: 20,
              pitch: 65,
              rotation: 45,
              viewMode: "3D", //开启3D视图,默认为关闭
              buildingAnimation: true, //楼块出现是否带动画

              expandZoomRange: true,
              zooms: [1, 20],
              center: [this.longitude, this.latitude],
              turboMode: false,
              //showBuildingBlock: false,
              forceVector: true,
              layers: this.rt_layers3,
            });
            this.map3.addControl(
              new AMap.ControlBar({
                showZoomBar: false,
                showControlButton: true,
                position: {
                  right: "10px",
                  top: "10px",
                },
              })
            );
            this.object3Dlayer3 = new AMap.Object3DLayer();
            this.map3.add(this.object3Dlayer3);
          }
          if (id4 == 1) {
            this.map4 = new AMap.Map("container4", {
              // 设置地图容器id
              resizeEnable: true,
              rotateEnable: true,
              pitchEnable: true,
              zoom: 20,
              pitch: 65,
              rotation: 45,
              viewMode: "3D", //开启3D视图,默认为关闭
              buildingAnimation: true, //楼块出现是否带动画

              expandZoomRange: true,
              zooms: [1, 20],
              center: [this.longitude, this.latitude],
              // turboMode: false,
              // //showBuildingBlock: false,
              // forceVector: true,
              layers: this.rt_layers4,
            });
            // 添加控件
            this.map4.addControl(
              new AMap.ControlBar({
                showZoomBar: false,
                showControlButton: true,
                position: {
                  right: "10px",
                  top: "10px",
                },
              })
            );
            this.object3Dlayer4 = new AMap.Object3DLayer();
            this.map4.add(this.object3Dlayer4);
            this.marker4 = new AMap.Marker({
              map: this.map4,
              icon: "https://webapi.amap.com/images/car.png",
              offset: new AMap.Pixel(-26, -13), // 调整图片偏移
              autoRotation: true, // 自动旋转
              // draggable: false,
              // cursor: "pointer",
              //raiseOnDrag: true,
              // angle: 0, // 图片旋转角度
            });
          }
          //_this.map1 = map1;
          //_this.map2 = map2;
          //_this.map1 = map3;
          // _this.map2 = map4;
          function lnglat2pixel(inputVal) {
            var lnglat = new AMap.LngLat(inputVal[0], inputVal[1]);
            var pixel = map.lngLatToGeodeticCoord(lnglat);
            pixel.x = parseInt(pixel.x);
            pixel.y = parseInt(pixel.y);
            console.log([pixel.x, pixel.y]);
            return [pixel.x, pixel.y];
          }
          // var object3Dlayer = new AMap.Object3DLayer({
          //   zIndex: 110,
          //   opacity: 1,
          // });
          // map1.add(object3Dlayer);
          // map2.add(object3Dlayer);
          var provinces = [
            [116.333926, 39.997245],
            [116.333926, 39.997225],
            [116.333926, 39.997205],
            [116.333926, 39.997185],
            [116.333926, 39.997165],
            [116.333926, 39.997145],
          ];
          //console.log(this.concentrationList4);
          var height = [400, 500, 600, 700, 800];
          for (let i = 0; i < this.HLLlist.length - 1; i++) {
            const test = [
              this.map1.lngLatToGeodeticCoord(this.HLLlist[i]),
              this.map1.lngLatToGeodeticCoord(this.HLLlist[i + 1]),
            ];
            //console.log(i, this.concentrationList4[i]);
            // if (i == this.HLLlist.length - 2) {
            //   console.log(this.concentrationList4);
            // }
            var color = [0.4, 1, 1, 0.5];
            if (id1 == 1) {
              if (this.concentrationList1[i] >= this.standard1[4]) {
                color = this.standard_color[5];
              } else if (this.concentrationList1[i] >= this.standard1[3]) {
                color = this.standard_color[4];
              } else if (this.concentrationList1[i] >= this.standard1[2]) {
                color = this.standard_color[3];
              } else if (this.concentrationList1[i] >= this.standard1[1]) {
                color = this.standard_color[2];
              } else if (this.concentrationList1[i] >= this.standard1[0]) {
                color = this.standard_color[1];
              } else {
                color = this.standard_color[0];
              }
              if (this.concentrationList1[i] < 0) {
                this.concentrationList1[i] = 0;
              }
              if (this.concentrationList2[i] < 0) {
                this.concentrationList2[i] = 0;
              }
              if (this.concentrationList3[i] < 0) {
                this.concentrationList3[i] = 0;
              }
              if (this.concentrationList4[i] < 0) {
                this.concentrationList4[i] = 0;
              }
              color[3] = this.alphaValue / 100;
              this.wall1 = new AMap.Object3D.Wall({
                path: test,
                height: (this.concentrationList1[i] / 10) * this.Height_prop1,
                color: color,
              });
              //wall 有个面,该属性表示哪个面可见,可选值back ,front  ,both表示两面都填充  默认为front
              this.wall1.backOrFront = "front";
              // 是否允许使用透明颜色
              this.wall1.transparent = true;
              this.object3Dlayer1.add(this.wall1);
            }
            if (id2 == 1) {
              if (this.concentrationList2[i] >= this.standard2[4]) {
                color = this.standard_color[5];
              } else if (this.concentrationList2[i] >= this.standard2[3]) {
                color = this.standard_color[4];
              } else if (this.concentrationList2[i] >= this.standard2[2]) {
                color = this.standard_color[3];
              } else if (this.concentrationList2[i] >= this.standard2[1]) {
                color = this.standard_color[2];
              } else if (this.concentrationList2[i] >= this.standard2[0]) {
                color = this.standard_color[1];
              } else {
                color = this.standard_color[0];
              }
              color[3] = this.alphaValue / 100;
              this.wall2 = new AMap.Object3D.Wall({
                path: test,
                height: (this.concentrationList2[i] / 1.5) * this.Height_prop2,
                color: color,
              });
              //wall 有个面,该属性表示哪个面可见,可选值back ,front  ,both表示两面都填充  默认为front
              this.wall2.backOrFront = "front";
              // 是否允许使用透明颜色
              this.wall2.transparent = true;
              this.object3Dlayer2.add(this.wall2);
              // if (i % 20 == 0) {
              //   var text2 = new AMap.Text({
              //     text: this.concentrationList2[i],
              //     verticalAlign: "top",
              //     position: this.HLLlist[i],
              //     height: this.concentrationList2[i] / 1.0,
              //     style: {
              //       "background-color": "transparent",
              //       "-webkit-text-stroke": "red",
              //       "-webkit-text-stroke-width": "0.5px",
              //       "text-align": "center",
              //       border: "none",
              //       color: "white",
              //       "font-size": ".5376vw",
              //     },
              //   });
              //   text2.setMap(this.map2);
              // }
            }
            if (id3 == 1) {
              if (this.concentrationList3[i] > this.standard3[2]) {
                color = this.standard_color[3];
              } else if (this.concentrationList3[i] > this.standard3[1]) {
                color = this.standard_color[2];
              } else if (this.concentrationList3[i] > this.standard3[0]) {
                color = this.standard_color[1];
              } else {
                color = this.standard_color[0];
              }
              color[3] = this.alphaValue / 100;
              var wall3 = new AMap.Object3D.Wall({
                path: test,
                height: this.concentrationList3[i] * this.Height_prop3,
                color: color,
              });
              //wall 有个面,该属性表示哪个面可见,可选值back ,front  ,both表示两面都填充  默认为front
              wall3.backOrFront = "front";
              // 是否允许使用透明颜色
              wall3.transparent = true;
              this.object3Dlayer3.add(wall3);
              // if (i % 20 == 0) {
              //   var text3 = new AMap.Text({
              //     text: this.concentrationList3[i],
              //     verticalAlign: "top",
              //     position: this.HLLlist[i],
              //     height: this.concentrationList3[i] / 1.0,
              //     style: {
              //       "background-color": "transparent",
              //       "-webkit-text-stroke": "red",
              //       "-webkit-text-stroke-width": "0.5px",
              //       "text-align": "center",
              //       border: "none",
              //       color: "white",
              //       "font-size": ".5376vw",
              //     },
              //   });
              //   text3.setMap(this.map3);
              // }
            }
            if (id4 == 1) {
              if (this.concentrationList4[i] >= this.standard4[4]) {
                color = this.standard_color[5];
              } else if (this.concentrationList4[i] >= this.standard4[3]) {
                color = this.standard_color[4];
              } else if (this.concentrationList4[i] >= this.standard4[2]) {
                color = this.standard_color[3];
              } else if (this.concentrationList4[i] >= this.standard4[1]) {
                color = this.standard_color[2];
              } else if (this.concentrationList4[i] >= this.standard4[0]) {
                color = this.standard_color[1];
              } else {
                color = this.standard_color[0];
              }
              color[3] = this.alphaValue / 100;
              var wall4 = new AMap.Object3D.Wall({
                path: test,
                height: this.concentrationList4[i] * 10 * this.Height_prop4,
                color: color,
              });
              //wall 有个面,该属性表示哪个面可见,可选值back ,front  ,both表示两面都填充  默认为front
              wall4.backOrFront = "front";
              // 是否允许使用透明颜色
              wall4.transparent = true;
              this.object3Dlayer4.add(wall4);
              // if (i % 20 == 0) {
              //   var text4 = new AMap.Text({
              //     text: this.concentrationList4[i],
              //     verticalAlign: "top",
              //     position: this.HLLlist[i],
              //     height: this.concentrationList4[i] * 10,
              //     style: {
              //       "background-color": "transparent",
              //       "-webkit-text-stroke": "red",
              //       "-webkit-text-stroke-width": "0.5px",
              //       "text-align": "center",
              //       border: "none",
              //       color: "white",
              //       "font-size": ".5376vw",
              //     },
              //   });
              //   text4.setMap(this.map4);
              // }
            }
          }
          this.draw_list(1);
          this.draw_list(2);
          this.draw_list(3);
          this.draw_list(4);
          // var canvasDir = document.createElement("canvas");
          // var width = 24;
          // canvasDir.width = width;
          // canvasDir.height = width;
          // var context = canvasDir.getContext("2d");
          // context.strokeStyle = "red";
          // context.lineJoin = "round";
          // context.lineWidth = 8;
          // context.moveTo(-4, width - 4);
          // context.lineTo(width / 2, 6);
          // context.lineTo(width + 4, width - 4);
          // context.stroke();
          var polyline1 = new AMap.Polyline({
            map: this.map1,
            path: this.HLLlist,
            strokeColor: "#29e2e5", //线条颜色
            strokeOpacity: 1, //透明度 0透明
            strokeWeight: 3, //轮廓线宽
            showDir: true,
            //strokeStyle: "solid", //实线
          });

          var polyline2 = new AMap.Polyline({
            map: this.map2,
            path: this.HLLlist,
            strokeColor: "#29e2e5", //线条颜色
            strokeOpacity: 1, //透明度 0透明
            strokeWeight: 3, //轮廓线宽
            showDir: true,
            //strokeStyle: "solid", //实线
          });
          var polyline3 = new AMap.Polyline({
            map: this.map3,
            path: this.HLLlist,
            strokeColor: "#29e2e5", //线条颜色
            strokeOpacity: 1, //透明度 0透明
            strokeWeight: 3, //轮廓线宽
            showDir: true,
            //strokeStyle: "solid", //实线
          });
          var polyline4 = new AMap.Polyline({
            map: this.map4,
            path: this.HLLlist,
            strokeColor: "#29e2e5", //线条颜色
            strokeOpacity: 1, //透明度 0透明
            strokeWeight: 3, //轮廓线宽
            showDir: true,
            //strokeStyle: "solid", //实线
          });

          // var center_3d = map.lngLatToGeodeticCoord(provinces);
          // var topColor = [0, 1, 1, 0.9];
          // var topFaceColor = [0, 1, 1, 0.4];
          // var bottomColor = [0, 0, 1, 0.9];

          // //添加一个圆柱体
          // var addRegularPrism = function (center, segment, height, radius) {
          //   var cylinder = new AMap.Object3D.Mesh();
          //   var geometry = cylinder.geometry;
          //   var verticesLength = segment * 2;
          //   var path = [];
          //   for (var i = 0; i < segment; i += 1) {
          //     var angle = (2 * Math.PI * i) / segment;
          //     var x = center.x + Math.cos(angle) * radius;
          //     var y = center.y + Math.sin(angle) * radius;
          //     path.push([x, y]);
          //     geometry.vertices.push(x, y, 0); //底部顶点
          //     geometry.vertices.push(x, y, -height); //顶部顶点

          //     geometry.vertexColors.push.apply(
          //       geometry.vertexColors,
          //       bottomColor
          //     ); //底部颜色
          //     geometry.vertexColors.push.apply(geometry.vertexColors, topColor); //顶部颜色

          //     var bottomIndex = i * 2;
          //     var topIndex = bottomIndex + 1;
          //     var nextBottomIndex = (bottomIndex + 2) % verticesLength;
          //     var nextTopIndex = (bottomIndex + 3) % verticesLength;

          //     geometry.faces.push(bottomIndex, topIndex, nextTopIndex); //侧面三角形1
          //     geometry.faces.push(bottomIndex, nextTopIndex, nextBottomIndex); //侧面三角形2
          //   }

          //   // 构建顶面三角形,为了区分顶面点和侧面点使用不一样的颜色,所以需要独立的顶点
          //   for (var i = 0; i < segment; i += 1) {
          //     geometry.vertices.push.apply(
          //       geometry.vertices,
          //       geometry.vertices.slice(i * 6 + 3, i * 6 + 6)
          //     ); //底部顶点
          //     geometry.vertexColors.push.apply(
          //       geometry.vertexColors,
          //       topFaceColor
          //     );
          //   }

          //   var triangles = AMap.GeometryUtil.triangulateShape(path);
          //   var offset = segment * 2;

          //   for (var v = 0; v < triangles.length; v += 3) {
          //     geometry.faces.push(
          //       triangles[v] + offset,
          //       triangles[v + 2] + offset,
          //       triangles[v + 1] + offset
          //     );
          //   }

          //   cylinder.transparent = true; // 如果使用了透明颜色，请设置true
          //   object3Dlayer.add(cylinder);
          // };
          // var test = [
          //   { x: "116.333926", y: "39.996905", z: "500" },
          //   { x: "116.333926", y: "39.996925", z: "400" },
          //   { x: "116.333926", y: "39.996945", z: "500" },
          //   { x: "116.333926", y: "39.996965", z: "500" },
          //   { x: "116.333926", y: "39.996985", z: "700" },
          //   { x: "116.333926", y: "39.997005", z: "500" },
          //   { x: "116.333926", y: "39.997025", z: "400" },
          //   { x: "116.333926", y: "39.997045", z: "500" },
          //   { x: "116.333926", y: "39.997065", z: "500" },
          //   { x: "116.333926", y: "39.997085", z: "700" },
          //   { x: "116.333926", y: "39.997105", z: "500" },
          //   { x: "116.333926", y: "39.997125", z: "400" },
          //   { x: "116.333926", y: "39.997145", z: "500" },
          //   { x: "116.333926", y: "39.997165", z: "500" },
          //   { x: "116.333926", y: "39.997185", z: "1200" },
          //   { x: "116.333926", y: "39.997205", z: "1000" },
          //   { x: "116.333926", y: "39.997225", z: "400" },
          //   { x: "116.333926", y: "39.997245", z: "600" },
          //   { x: "116.333926", y: "39.997265", z: "700" },
          //   { x: "116.333926", y: "39.997285", z: "800" },
          //   { x: "116.333926", y: "39.997305", z: "500" },
          //   { x: "116.333926", y: "39.997325", z: "600" },
          //   { x: "116.333926", y: "39.997345", z: "400" },
          //   { x: "116.333926", y: "39.997365", z: "450" },
          //   { x: "116.333926", y: "39.997385", z: "400" },
          //   { x: "116.333926", y: "39.997405", z: "500" },
          //   { x: "116.333926", y: "39.997425", z: "400" },
          //   { x: "116.333926", y: "39.997445", z: "500" },
          //   { x: "116.333926", y: "39.997465", z: "500" },
          //   { x: "116.333926", y: "39.997485", z: "700" },
          //   { x: "116.333926", y: "39.997505", z: "500" },
          //   { x: "116.333926", y: "39.997525", z: "400" },
          //   { x: "116.333926", y: "39.997545", z: "500" },
          //   { x: "116.333926", y: "39.997565", z: "500" },
          //   { x: "116.333926", y: "39.997585", z: "700" },
          //   { x: "116.333906", y: "39.997585", z: "500" },
          //   { x: "116.333886", y: "39.997585", z: "400" },
          //   { x: "116.333866", y: "39.997585", z: "500" },
          //   { x: "116.333846", y: "39.997585", z: "500" },
          //   { x: "116.333826", y: "39.997585", z: "700" },
          // ];
          // for (var i = 0; i < test.length; i++) {
          //   center_3d = map.lngLatToGeodeticCoord([test[i].x, test[i].y]);
          //   console.log(center_3d);
          //   addRegularPrism(
          //     center_3d.add(new AMap.Pixel(1, 0)),
          //     12,
          //     test[i].z,
          //     10
          //   ); //圆柱
          //   var text = new AMap.Text({
          //     text: test[i].z,
          //     verticalAlign: "top",
          //     position: [test[i].x, test[i].y],
          //     height: test[i].z / 1.0,
          //     style: {
          //       "background-color": "transparent",
          //       "-webkit-text-stroke": "red",
          //       "-webkit-text-stroke-width": "0.5px",
          //       "text-align": "center",
          //       border: "none",
          //       color: "white",
          //       "font-size": "10px",
          //     },
          //   });
          //   text.setMap(map);
          // }
          this.init_flag = true;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.refreshData);
    this.refreshData = null;
  },
};
</script>
<style scoped>
::v-deep .el-button {
  padding-left: 0.0538vw;
  padding-top: 0.0538vw;
}
</style>
<style>
.chart-container {
  position: absolute;
  bottom: 0.1075vw;
  left: 0.1075vw;
  background: rgba(255, 255, 255, 0.7);
  padding: 0.0538vw;
  box-shadow: 0 0.0538vw 0.1075vw rgba(0, 0, 0, 0.1);
}

.chart {
  width: 100%;
  height: 100%;
}

.toggle-button {
  position: absolute;
  z-index: 1000;
  width: 1.0753vw;
  height: 1.0753vw;
  border: none;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);

  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrow {
  position: absolute;
  bottom: -0.0538vw;
  font-size: 1.0753vw;
  color: #333;
}
.toggle-button:hover {
  background-color: #f0f0f0;
}
.custom-slider .el-slider__button {
  width: 0.5376vw;
  height: 0.5376vw;
}
#mapControl {
  background-color: rgba(255, 255, 255, 0.8); /* 设置背景透明度 */
  border: 0.0538vw solid #ddd;
  border-radius: 0.2151vw;
  padding: 0.0538vw;
}

.mapControl .el-select-dropdown {
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ) !important; /* 设置下拉框的背景色 */
  border: none !important; /* 移除边框 */
}

/* 给下拉框中的选中项添加背景色 */
.mapControl .el-select-dropdown__item.selected {
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ) !important; /* 设置选中项的背景色 */
}

.btn {
  position: absolute;
  cursor: pointer;
  z-index: 10;
}
.amap-luopan-bg {
  width: 0.5376vw !important;
  height: 0.5376vw !important;
}
.amap-logo {
  display: none !important;
  visibility: hidden !important;
}
.amap-copyright {
  display: none !important;
  visibility: hidden !important;
}
.description {
  height: 4.8387vh;
}

.edit {
  top: 53.7634vw;
  right: 5.3763vh;
}

.basic_info {
  resize: none;
  width: 80%;
  height: 2.1505vh;
}
.custom-switch {
  color: rgba(25, 44, 250, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}
.custom-switch .el-switch__label * {
  font-size: 0.8602vw !important;
}
.v41_170 {
  width: 5.1613vw;
  color: rgba(0, 0, 0, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 1.0753vw;
  opacity: 1;
  text-align: left;
}

.v41_172 {
  width: 6.8817vw;
  height: 2.7957vh;
  background: rgba(25, 44, 250, 1);
  padding: 0.5914vw 2.1505vw;
  margin: 0.5376vw;
  opacity: 1;
  border-top-left-radius: 0.2688vw;
  border-top-right-radius: 0.2688vw;
  border-bottom-left-radius: 0.2688vw;
  border-bottom-right-radius: 0.2688vw;
  overflow: hidden;
}

.v41_169 {
  width: 5.1613vw;
  color: rgba(25, 44, 250, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}

.v41_168 {
  width: 2.5806vw;
  color: rgba(255, 255, 255, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}

.v41_166 {
  width: 98%;
  height: 100%;
  background: rgba(243, 249, 255, 1);
  outline-color: rgba(243, 249, 255, 1);
  opacity: 1;
  padding: 0vw;
  border: 0vw;
  border-top-left-radius: 0.5376vw;
  border-top-right-radius: 0.5376vw;
  border-bottom-left-radius: 0.5376vw;
  border-bottom-right-radius: 0.5376vw;
  overflow: hidden;
  color: rgba(0, 0, 0, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  text-align: left;
}

.box-card .text {
  font-size: 0.7527vw;
}

.item {
  margin-bottom: 0.9677vw;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.box-card {
  width: 43.0108vw;
}
</style>
