import Vue from 'vue'
import Vuex from 'vuex'
import {login} from '@/api/user'
import router from '@/router' 


Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    project: null,//用来储存项目名
    token:null,//用来储存token
    username:null,//用来储存用户名
    isLogin: false,//用来记录登陆状态
    time: null,
    Language:true,
    email:null,
    site:null,
    city:null,
    province:null,
    timezone:null,
    information:null,
    alarm:null,
    projectNew: null,
    eco_value: null,
    show_id: null,
    eco_time:null,
  },
  mutations: {
    setShow(state, data) {
      state.show_id = data;
      localStorage.setItem('show_id', JSON.stringify(data));
    },
    restoShow(state) {
      const storedShow = localStorage.getItem('show_id');
      state.show_id = storedShow ? JSON.parse(storedShow) : null;
      //console.log(state.username1)
    },
    seteco_time(state, data) {
      state.eco_time = data;
      localStorage.setItem('eco_time', JSON.stringify(data));
    },
    seteco_value(state, data) {
      state.eco_value = data;
      localStorage.setItem('eco_value', JSON.stringify(data));
    },
    setLanguage(state,data) {
      state.Language = data;
      localStorage.setItem('Language', JSON.stringify(data));
    },
    restoLanguage(state) {
      const storedLanguage = localStorage.getItem('Language');
      state.Language = storedLanguage ? JSON.parse(storedLanguage) : null;
      //console.log(state.username1)
    },
    settime(state,data) {
      state.time = data;
      localStorage.setItem('time', JSON.stringify(data));
    },
    // 存储邮箱数据到localStorage和Vuex的state
    setEmail(state, data) {
      state.email = data;
      localStorage.setItem('email', JSON.stringify(data));
    },
    // 存储站点信息到localStorage和Vuex的state
    setSite(state, data) {
      state.site = data;
      localStorage.setItem('site', JSON.stringify(data));
    },
    // 存储城市信息到localStorage和Vuex的state
    setCity(state,data){
      state.city = data;
      localStorage.setItem('city', JSON.stringify(data));
    },
    // 存储省份信息到localStorage和Vuex的state
    setProvince(state,data){
      state.province = data;
      localStorage.setItem('province', JSON.stringify(data));
    },
    // 存储时区信息到localStorage和Vuex的state
    setTimezone(state,data){
      state.timezone = data;
      localStorage.setItem('timezone', JSON.stringify(data));
    },
    // 存储站点信息到localStorage和Vuex的state
    setInformation(state,data){
      state.information = data;
      localStorage.setItem('information', JSON.stringify(data));
    },
    setAlarm(state,data){
      state.alarm = data;
      localStorage.setItem('alarm', JSON.stringify(data));
    },
    setProjectNew(state,data){
      state.projectNew = data;
      localStorage.setItem('projectNew', JSON.stringify(data));
    },
    // 从localStorage恢复数据到Vuex的state
    restoreEmail(state) {
      const storedEmail = localStorage.getItem('email');
      if (storedEmail) {
        try {
          state.email = JSON.parse(storedEmail);
        } catch (error) {
          console.error('Error parsing stored email:', error);
        }
      } else {
        state.email = null;
      }
    },
    // 从localStorage恢复数据到Vuex的state
    restoreSite(state) {
      const storedSite= localStorage.getItem('site');
      if (storedSite) {
        try {
          state.site = JSON.parse(storedSite);
        } catch (error) {
          console.error('Error parsing stored site:', error);
        }
      } else {
        state.site = null;
      }
    },
    // 从localStorage恢复数据到Vuex的state
    restoreCity(state) {
      const storedCity= localStorage.getItem('city');
      if (storedCity) {
        try {
          state.city = JSON.parse(storedCity);
        } catch (error) {
          console.error('Error parsing stored city:', error);
        }
      } else {
        state.city = null;
      }
    },
    // 从localStorage恢复数据到Vuex的state
    restoreProvince(state) {
      const storedPovince= localStorage.getItem('province');
      if (storedPovince) {
        try {
          state.province = JSON.parse(storedPovince);
        } catch (error) {
          console.error('Error parsing stored province:', error);
        }
      } else {
        state.province = null;
      }
    },
    // 从localStorage恢复数据到Vuex的state
    restoreTimezone(state) {
      const storedTimezone = localStorage.getItem('timezone');
      if (storedTimezone) {
        try {
          state.timezone = JSON.parse(storedTimezone);
        } catch (error) {
          console.error('Error parsing stored timezone:', error);
        }
      } else {
        state.timezone = null;
      }
    },
    // 从localStorage恢复数据到Vuex的state
    restoreInformation(state) {
      const storedInformation = localStorage.getItem('information');
      if (storedInformation) {
        try {
          state.information = JSON.parse(storedInformation);
        } catch (error) {
          console.error('Error parsing stored information:', error);
        }
      } else {
        state.information = null;
      }
    },
    restoreAlarm(state) {
      const storedAlarm = localStorage.getItem('alarm');
      if (storedAlarm) {
        try {
          state.alarm = JSON.parse(storedAlarm);
        } catch (error) {
          console.error('Error parsing stored alarm:', error);
        }
      } else {
        state.alarm = null;
      }
    },
    restoreProjectNew(state) {
      const storedProjectNew = localStorage.getItem('projectNew');
      if (storedProjectNew) {
        try {
          state.projectNew = JSON.parse(storedProjectNew);
        } catch (error) {
          console.error('Error parsing stored projectNew:', error);
        }
      } else {
        state.projectNew = null;
      }
    },
    // 存储数据到localStorage和Vuex的state
    username(state, data) {
      state.username = data;
      localStorage.setItem('username', JSON.stringify(data));
      //console.log(state.username1)
    },
    // 从localStorage恢复数据到Vuex的state
    restorename(state) {
      const storedName = localStorage.getItem('username');
      state.username = storedName ? JSON.parse(storedName) : null;
      //console.log(state.username1)
    },
    // 存储数据到localStorage和Vuex的state
    project(state, data) {
      state.project = data;
      localStorage.setItem('project', JSON.stringify(data));
    },
    // 从localStorage恢复数据到Vuex的state
    restoreproject(state) {
      const storedProject = localStorage.getItem('project');
      state.project = storedProject ? JSON.parse(storedProject) : null;
      //console.log(state.username1)
    },
    // 存储数据到localStorage和Vuex的state
    setToken(state, data) {
      state.token = data;
      localStorage.setItem('token', JSON.stringify(data));
      console.log(state.token)
    },
    // 从localStorage恢复数据到Vuex的state
    restoretoken(state) {
      const storedToken = localStorage.getItem('token');
      state.token = storedToken ? JSON.parse(storedToken) : null;
      //console.log(state.username1)
    },
    // 将state里的token清除
    clearToken(state) {
      state.token = null;
      //console.log(state.token)
    },
    // 将state里的用户名清除
    clearUsername(state) {
      state.username = null;
      //console.log(state.username)
    },
    // 将state里的项目名清除
    clearProject(state) {
      state.project = null;
      //console.log(state.project)
    },
    // 将state里的邮箱清除
    clearEmail(state){
      state.email=null;
    },
    // 将state里的站点清除
    clearSite(state){
      state.site=null;
    },
    // 将state里的城市清除
    clearCity(state){
      state.city=null;
    },
    // 将state里的省份清除
    clearProvince(state){
      state.province=null;
    },
    // 将state里的时区清除
    clearTimezone(state){
      state.timezone=null;
    },
    // 将state里的站点的信息清除
    clearInformation(state){
      state.information=null;
    },
    clearAlarm(state){
      state.alarm=null;
    },
    clearProjectNew(state){
      state.projectNew=null;
    },
    //储存数据到store和本地
    isLogin(state, data) {
      state.isLogin = data;
      localStorage.setItem('isLogin', JSON.stringify(data));
      //console.log(state.username1)
    },
    // 从localStorage恢复数据到Vuex的state
    restoreIslogin(state) {
      const storedIslogin = localStorage.getItem('isLogin');
      state.isLogin = storedIslogin ? JSON.parse(storedIslogin) : null;
      //console.log(state.username1)
    },
    //将login设为真
    isLoginTrue(state){
      state.isLogin = true
    },
    //将login设为假
    isLoginFalse(state){
      state.isLogin = false
    }
    
  },
  actions:{
    //发送登录请求
    login({ commit }, userInfo) {
        //const {} = userInfo
        return new Promise((resolve, reject) => {
          login(userInfo).then(response => {//在这里也会收到返回值
            const { data } = response
            if(data.data.token){//如果有token，就把token保存
              commit('setToken', data.data.token)
              //setToken(data.data.token)
            }
            else{
              //console.log(data.token)
              console.log('没有收到token')
            }
            //console.log(this.state.token)
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
    },
    //登出
    logout({ commit }) {
      commit('clearToken');//在store清除token
      commit('clearUsername');//在store清除用户名
      commit('clearProject');//在store清除项目名
      commit('clearEmail');
      commit('clearSite');
      commit('clearCity');
      commit('clearProvince');
      commit('clearTimezone');
      commit('clearInformation');
      commit('clearAlarm');
      commit('clearProjectNew');
      localStorage.removeItem('email');
      localStorage.removeItem('site');
      localStorage.removeItem('city');
      localStorage.removeItem('province');
      localStorage.removeItem('timezone');
      localStorage.removeItem('information');
      localStorage.removeItem('alarm');
      localStorage.removeItem('projectNew');
      localStorage.removeItem('token');//在本地清除token
      localStorage.removeItem('username');//在本地清除用户名
      localStorage.removeItem('project');//在本地清除项目名
      //router.push({ path: '/' }, { replace: true })
      router.push('/Login');//页面跳转到登录界面
    },
    //清除项目名，专用于main界面下“返回项目选择界面”的按钮
    reselectProject({ commit }) {
      commit('clearProject');
      localStorage.removeItem('project');
    },
  },
  // 在创建 store 时恢复 email 数据
  plugins: [store => {
    store.commit('restoreEmail');
    store.commit('restorename');
    store.commit('restoreSite');
    store.commit('restoreCity');
    store.commit('restoreProvince');
    store.commit('restoreTimezone');
    store.commit('restoreInformation');
    store.commit('restoreAlarm');
    store.commit('restoreProjectNew');
  }]
})

export default store