<!-- 报警记录界面 -->
<template>
  <div style="width: 100%; height: 100%">
    <div
      style="
        width: 0.5376vw;
        height: 100%;
        display: flex;
        float: left;
        background: rgba(240, 240, 240, 1);
      "
    ></div>
    <div style="width: 6%; height: 100%; float: left; display: flex"></div>
    <div style="width: 93%; height: 100%; float: left">
      <div style="width: 100%; height: 4.5%"></div>
      <div
        style="width: 100%; height: 5.5%; align-items: center; display: flex"
      >
        <div
          style="
            width: 20%;
            height: 100%;
            float: left;
            align-items: center;
            display: flex;
          "
          class="v41_161"
        >
          <span v-if="Lflag == true" class="v35_1047" style="width: 15%"
            >报警记录</span
          >
          <span v-if="Lflag == false" class="v35_1047" style="width: 15%"
            >Alarm Record</span
          >
        </div>
        <div
          style="
            width: 60%;
            height: 100%;
            float: left;
            align-items: center;
            display: flex;
          "
        ></div>
        <div
          style="
            width: 20%;
            height: 100%;
            float: right;
            align-items: center;
            display: flex;
          "
          class="v41_161"
        >
          <span v-if="Lflag == true" class="v41_161"
            >项目名称：{{ this.projectNew }}
          </span>
          <span v-if="Lflag == false" class="v41_161"
            >Project Name：{{ this.projectNew }}
          </span>
        </div>
      </div>
      <div style="width: 96.2%; height: 10%; display: flex">
        <div style="width: 45%; float: left; display: flex">
          <div
            style="
              width: 75%;
              float: left;
              justify-content: left;
              display: flex;
              align-items: center;
            "
          >
            <el-tooltip
              v-if="Lflag == true"
              class="v35_1033"
              effect="light"
              placement="bottom"
              content="先选择开始结束日期，确定日期后可对日期进行具体的时间调整"
            >
              <el-date-picker
                v-model="requestTime"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期 "
                :picker-options="DisableDatesOption"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00']"
                popper-class="picker-popper"
              >
              </el-date-picker>
            </el-tooltip>
            <el-tooltip
              v-else-if="Lflag == false"
              class="v35_1033"
              effect="light"
              placement="bottom"
              content="Select the start and end dates first, and then adjust the date after the date is confirmed"
            >
              <el-date-picker
                v-model="requestTime"
                type="datetimerange"
                start-placeholder="Start date"
                end-placeholder="End date "
                :picker-options="DisableDatesOption"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00']"
                popper-class="picker-popper"
              >
              </el-date-picker>
            </el-tooltip>
          </div>
          <div
            style="
              width: 25%;
              float: left;
              justify-content: left;
              display: flex;
              align-items: center;
            "
          >
            <el-button
              type="botton"
              v-if="Lflag == true"
              style="
                background-color: rgba(25, 44, 250, 1);
                padding-left: 2.0968vw;
                padding-right: 2.0968vw;
                align-items: center;
                display: flex;
                padding-top: 0.6989vw;
                padding-bottom: 0.6989vw;
                align-items: center;
                display: flex;
                border-radius: 0.2688vw;
                color: #fff;
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
              "
              @click="findbutton"
              plain
              >查询</el-button
            >
            <el-button
              type="botton"
              v-else-if="Lflag == false"
              style="
                background-color: rgba(25, 44, 250, 1);
                padding-left: 2.0968vw;
                padding-right: 2.0968vw;
                align-items: center;
                display: flex;
                padding-top: 0.6989vw;
                padding-bottom: 0.6989vw;
                align-items: center;
                display: flex;
                border-radius: 0.2688vw;
                color: #fff;
                font-family: Microsoft YaHei UI;
                font-weight: Regular;
                font-size: 0.8602vw;
              "
              @click="findbutton"
              plain
              >Search</el-button
            >
          </div>
        </div>
        <el-tooltip
          effect="light"
          content="实时报警"
          placement="top"
          v-if="Lflag == true"
        >
          <div
            style="
              width: 55%;
              float: left;
              border-radius: 0.2151vw;
              border: 0.0538vw solid rgba(25, 44, 250, 1);
            "
          >
            <div style="width: 100%; height: 10%"></div>
            <div style="width: 100%; height: 80%; display: flex">
              <div
                style="display: flex; width: 100%"
                v-for="item in actualtime"
                :key="item.id"
              >
                <div
                  style="
                    width: 30%;
                    height: 100%;
                    float: left;
                    border-right: 0.0538vw solid rgba(25, 44, 250, 1);
                  "
                >
                  <div
                    style="
                      width: 100%;
                      height: 50%;
                      justify-content: center;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <span class="v69_727">时间</span>
                  </div>
                  <div
                    style="
                      width: 100%;
                      height: 50%;
                      justify-content: center;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <span class="v69_729">{{ item.time }}</span>
                  </div>
                </div>
                <div
                  style="
                    width: 10%;
                    height: 100%;
                    float: left;
                    border-right: 0.0538vw solid rgba(25, 44, 250, 1);
                  "
                >
                  <div
                    style="
                      width: 100%;
                      height: 50%;
                      justify-content: center;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <span class="v69_727">ID</span>
                  </div>
                  <div
                    style="
                      width: 100%;
                      height: 50%;
                      justify-content: center;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <span class="v69_729">{{ item.id }}</span>
                  </div>
                </div>
                <div style="width: 60%; height: 100%; float: left">
                  <div
                    style="
                      width: 100%;
                      height: 50%;
                      justify-content: center;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <span class="v69_727">报警码</span>
                  </div>
                  <div
                    style="
                      width: 100%;
                      height: 50%;
                      justify-content: center;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <span class="v69_729">{{ item.alarm_num }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 100%; height: 10%"></div>
          </div>
        </el-tooltip>
        <el-tooltip
          effect="light"
          content="Real-time alarms"
          placement="top"
          v-else-if="Lflag == false"
        >
          <div
            style="
              width: 55%;
              float: left;
              border-radius: 0.2151vw;
              border: 0.0538vw solid rgba(25, 44, 250, 1);
            "
          >
            <div style="width: 100%; height: 10%"></div>
            <div style="width: 100%; height: 80%; display: flex">
              <div
                style="display: flex; width: 100%"
                v-for="item in actualtime"
                :key="item.id"
              >
                <div
                  style="
                    width: 30%;
                    height: 100%;
                    float: left;
                    border-right: 0.0538vw solid rgba(25, 44, 250, 1);
                  "
                >
                  <div
                    style="
                      width: 100%;
                      height: 50%;
                      justify-content: center;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <span class="v69_727">TIME</span>
                  </div>
                  <div
                    style="
                      width: 100%;
                      height: 50%;
                      justify-content: center;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <span class="v69_729">{{ item.time }}</span>
                  </div>
                </div>
                <div
                  style="
                    width: 10%;
                    height: 100%;
                    float: left;
                    border-right: 0.0538vw solid rgba(25, 44, 250, 1);
                  "
                >
                  <div
                    style="
                      width: 100%;
                      height: 50%;
                      justify-content: center;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <span class="v69_727">ID</span>
                  </div>
                  <div
                    style="
                      width: 100%;
                      height: 50%;
                      justify-content: center;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <span class="v69_729">{{ item.id }}</span>
                  </div>
                </div>
                <div style="width: 60%; height: 100%; float: left">
                  <div
                    style="
                      width: 100%;
                      height: 50%;
                      justify-content: center;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <span class="v69_727">Warning Code</span>
                  </div>
                  <div
                    style="
                      width: 100%;
                      height: 50%;
                      justify-content: center;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <span class="v69_729">{{ item.alarm_num }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 100%; height: 10%"></div>
          </div>
        </el-tooltip>
      </div>
      <div style="width: 100%; height: 3%"></div>
      <div
        v-if="tableFlag == true"
        style="width: 96.2%; height: 59%; overflow: auto"
      >
        <el-table
          class="fixedtableHeight"
          ref="multipleTable"
          id="tableshow"
          height="500"
          :data="
            alarmList.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize
            )
          "
          style="width: 100%; margin-top: 1%"
        >
          <el-table-column
            :label="item.paramName"
            :property="item.paramIndex"
            v-for="item in alarmParmList"
            :key="item.paramIndex"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row[scope.column.property] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="width: 96.2%; height: 3%"></div>
      <div
        v-if="tableFlag == true"
        style="
          width: 96.2%;
          height: 5%;
          justify-content: right;
          display: flex;
          align-items: center;
        "
      >
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="alarmList.length"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { checkToken } from "@/api/user";
import { alarm_init } from "@/api/data";
import { currentalarm } from "@/api/data";
import { initmap } from "@/api/data";
import { alarm } from "@/api/data";
import { alarmtime } from "@/api/data";

export default {
  data() {
    const that = this;
    return {
      requestTime: [],
      refreshData: null,
      alarmList: [],
      actualtime: [{ time: "", id: "", alarm_num: "" }],
      database: "",
      table: "",
      project: this.$store.state.projectNew,
      projectNew: this.$store.state.projectNew,
      site:this.$store.state.site,
      projectArray: [],
      currentPage: 1, // 当前页码
      total: 0, // 总条数
      pageSize: 20, // 每页的数据条数
      findFlag: false,
      alarm_msg: [],
      timedata: [], //存在数据的日期
      tableFlag: false,
      alarmParmList: [
        { paramIndex: "time", paramName: "时间" },
        { paramIndex: "id", paramName: "ID" },
        { paramIndex: "alarm_num", paramName: "报警码" },
        { paramIndex: "alarm_message", paramName: "报警信息" },
      ],
      DisableDatesOption: {
        cellClassName: (time) => {
          if (that.timedata.includes(this.parseTime(time, "{y}-{m}-{d}"))) {
            return "badge";
          }
        },
        disabledDate(date) {
          return date.getTime() > Date.now();
        },
      },
      Lflag: true,
    };
  },

  //发送请求，让后端检查token
  created: async function () {
    this.$store.commit("restoretoken"); //将保存在本地的token存到state里（因为每次刷新会导致state里的值清空）
    this.Lflag = this.$store.state.Language; //store里的传到该组件
    if (this.Lflag == true) {
      this.alarmParmList = [
        { paramIndex: "time", paramName: "时间" },
        { paramIndex: "id", paramName: "ID" },
        { paramIndex: "alarm_num", paramName: "报警码" },
        { paramIndex: "alarm_message", paramName: "报警信息" },
      ];
    } else {
      this.alarmParmList = [
        { paramIndex: "time", paramName: "TIME" },
        { paramIndex: "id", paramName: "ID" },
        { paramIndex: "alarm_num", paramName: "Warning Code" },
        { paramIndex: "alarm_message", paramName: "Alarm information" },
      ];
    }
    checkToken(this.$qs.stringify({ token: this.$store.state.token })).then(
      (res) => {
        //发送请求：让后端检查token，后端返回的状态在request的response interceptor里拦截并作出相应的响应
        console.log(res);
      }
    );
    this.getAlarmList();
    this.getcurrentList();
    let startstr = new Date().getTime() + 86400000;
    let endstr = new Date().getTime() - 5184000000 * 3; //当前时间前两个月*3
    //let start = new Date(str);
    let str1 = new Date(parseInt(startstr)).toLocaleDateString();
    let arr = str1.split("/");
    let starttime = arr[0] + "-" + arr[1] + "-" + arr[2] + " " + "00:00:00";
    console.log(starttime);
    str1 = new Date(parseInt(endstr)).toLocaleDateString();
    arr = str1.split("/");
    let endtime = arr[0] + "-" + arr[1] + "-" + arr[2] + " " + "00:00:00";
    console.log(endtime);
    alarmtime(
      this.$qs.stringify({
        start: starttime,
        end: endtime,
        database: this.database,
        table: this.table,
      })
    ).then((res) => {
      this.timedata = res.data;
      console.log(this.timedata);
    });
  },
  mounted() {
    // 隔60s刷新一次数据
    this.refreshData = setInterval(() => {
      this.getcurrentList(); //需要刷新的数据
    }, 60 * 1000);
  },
  methods: {
    getAlarmList() {
      this.$store.commit("restoreProjectNew"); //将保存在本地的项目名传给state
      this.project = this.$store.state.projectNew; //将state里的project赋值给组件里的project
      this.projectArray = this.project; //将project分割成两部分,前后端约定好用下划线分割
      this.database = "`" + this.project + "`"; //将分割出的第一部分赋值给database
      this.table = "`" + this.site + "_alarm" + "`"; //将分割出的第二部分赋值给table并且加上后缀
      alarm_init(
        this.$qs.stringify({ database: this.database, table: this.table })
      )
        .then((res) => {
          console.log(res);
          this.alarmList = res.data;
          this.watch();
        })
        .catch((e) => {
          /*  this.optionMin = this.$message({
            showClose: true,
            message: "数据为空",
            duration: 3 * 1000,
            center: true,
            type: "error",
            customClass: "customclass",
          }); */
        });
    },
    getcurrentList() {
      console.log(this.database);
      console.log(this.table);
      currentalarm(
        this.$qs.stringify({ database: this.database, table: this.table })
      )
        .then((res) => {
          console.log(res);
          this.actualtime = res.data;
        })
        .catch((e) => {
          if (this.optionMin) {
            this.optionMin.close();
          }
          /*this.$message({
            showClose: true,
            message: "数据为空",
            duration: 3 * 1000,
            center: true,
            type: "error",
            customClass: "customclass",
          }); */
        });
    },
    parseTime(time, cFormat) {
      if (arguments.length === 0 || !time) {
        return null;
      }
      const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
      let date;
      if (typeof time === "object") {
        date = time;
      } else {
        if (typeof time === "string") {
          if (/^[0-9]+$/.test(time)) {
            time = parseInt(time);
          } else {
            time = time.replace(new RegExp(/-/gm), "/");
          }
        }

        if (typeof time === "number" && time.toString().length === 10) {
          time = time * 1000;
        }
        date = new Date(time);
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
      };
      const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key];
        if (key === "a") {
          return ["日", "一", "二", "三", "四", "五", "六"][value];
        }
        return value.toString().padStart(2, "0");
      });
      return time_str;
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    findbutton() {
      this.tableFlag = false;
      alarm(
        this.$qs.stringify({
          start: this.requestTime[0],
          end: this.requestTime[1],
          database: this.database,
          table: this.table,
        })
      ).then((res) => {
        console.log(res);
        this.alarmList = res.data;
        this.watch();
      });
    },
    watch() {
      if(this.Lflag == true){
        initmap(this.$qs.stringify({ database: this.database ,language : 'C' })).then((res) => {
        console.log(res);
        this.alarm_msg = res.data;
        for (let i = 0; i < this.alarmList.length; i++) {
          this.alarmList[i].alarm_message = "";
          let alarm1 = this.alarmList[i].alarm_num;
          let alarm2 = alarm1.split(";");
          for (let j = 0; j < alarm2.length; j++) {
            for (let k = 0; k < this.alarm_msg.length; k++) {
              if (alarm2[j] == this.alarm_msg[k].id) {
                if (j == alarm2.length - 1) {
                  this.alarmList[i].alarm_message =
                  this.alarmList[i].alarm_message + this.alarm_msg[k].remark;
                } else {
                  this.alarmList[i].alarm_message =
                  this.alarmList[i].alarm_message +
                  this.alarm_msg[k].remark +
                  ";";
                }
              }                                                                                                                                                                                                   
            }
          }
        }
        this.tableFlag = true;
        });
        console.log(this.$i18n.locale)
      }else{
          initmap(this.$qs.stringify({ database: this.database , language : 'E'  })).then((res) => {
          console.log(res);
          this.alarm_msg = res.data;
          for (let i = 0; i < this.alarmList.length; i++) {
            this.alarmList[i].alarm_message = "";
            let alarm1 = this.alarmList[i].alarm_num;
            let alarm2 = alarm1.split(";");
            for (let j = 0; j < alarm2.length; j++) {
              for (let k = 0; k < this.alarm_msg.length; k++) {
                if (alarm2[j] == this.alarm_msg[k].id) {
                  if (j == alarm2.length - 1) {
                    this.alarmList[i].alarm_message =
                    this.alarmList[i].alarm_message + this.alarm_msg[k].remark;
                  } else {
                    this.alarmList[i].alarm_message =
                    this.alarmList[i].alarm_message +
                    this.alarm_msg[k].remark +
                    ";";
                  }
                }
              }
            }
          }
          this.tableFlag = true;
        });
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.refreshData);
    this.refreshData = null;
  },
};
</script>

<style scoped>
::v-deep .el-table tr {
  font-size: 0.8602vw;
  background-color: rgb(255, 255, 255, 1);
}

::v-deep .el-table td {
  background-color: rgba(228, 241, 255, 1);
  padding: 0vw;
  border-color: rgb(255, 255, 255);
  border-right: 0.1613vw;
  font-size: 0.8602vw;
}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  font-size: 0.8602vw;
  border-radius: 0.2688vw;
  border-bottom: 0.2688vw solid rgb(255, 255, 255);
  padding: 0px;
  border-color: rgb(255, 255, 255);
  border-right: 0.2688vw solid rgb(255, 255, 255);
}
::v-deep .el-range-editor.el-input__inner {
  width: 21.5054vw;
  display: flex;
  padding: 0.1613vw 0.5376vw;
  height: 2.1505vw;
  line-height: 2.1505vw;
  font-size: 0.7527vw;
}
::v-deep .el-date-editor .el-range__icon {
  line-height: 2.1505vw;
  font-size: 0.7527vw;
}
::v-deep .el-date-editor .el-range-separator {
  line-height: 2.1505vw;
  font-size: 0.7527vw;
}
::v-deep .el-date-editor .el-range-input {
  font-size: 0.7527vw;
}
</style>
<style>
.v69_727 {
  color: rgba(25, 44, 250, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Bold;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}

.v69_729 {
  color: rgba(0, 0, 0, 1);
  font-family: Microsoft YaHei UI;
  font-weight: Regular;
  font-size: 0.8602vw;
  opacity: 1;
  text-align: left;
}

.classRow {
  width: 25%;
  display: flex;
}

.classCenter {
  width: 50%;
  box-flex: 5;
  -webkit-box-flex: 5;
  -moz-box-flex: 5;
  -webkit-flex: 5;
  -ms-flex: 5;
  flex: 5;

  display: flex;
  justify-content: center;
}

.classRight {
  width: 25%;
  box-flex: 1;
  /*灵活度*/
  -webkit-box-flex: 1;
  /* Safari and Chrome */
  -moz-box-flex: 1;
  /* Firefox */
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;

  display: flex;
  justify-content: flex-end;
}
</style>
